define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "admin": {
      "bad_data_created_at": "Created {relative_created_at} ({created_at})",
      "list_bad_data": "List Bad Data Reports"
    },
    "algorithms": {
      "exhaustive": "Exhaustive",
      "fuel": "Fuel",
      "fuel_jumps": "Fuel Jumps",
      "guided": "Guided",
      "optimistic": "Optimistic",
      "pessimistic": "Pessimistic"
    },
    "allegiance": {
      "Alliance": "Alliance",
      "Empire": "Empire",
      "Federation": "Federation",
      "Guardian": "Guardian",
      "Independent": "Independent",
      "None": "None",
      "Pilots Federation": "Pilots Federation",
      "Player Pilots": "Player Pilots",
      "Thargoid": "Thargoid"
    },
    "ammonia": {
      "blank": "Leave destination blank in order to create a circular tour starting at your source system and returning there at the end.",
      "guides": "You can find more information about the community goal <a href=\"https://www.elitedangerous.com/community/goals/\">here</a> and <a href=\"https://forums.frontier.co.uk/threads/support-sirius-atmospherics-initiative-by-providing-ammonia-world-exploration-data-exploration.559984/\">here</a>.",
      "low_results": "The number of systems returned is less than you asked for.  Try increasing the radius of the search in order to find more bodies.",
      "title": "Ammonia World Route",
      "warning": "This router will allow you to find ammonia worlds around your current location for the community goal.  It will prioritise high scan value so if you only find far away worlds you may wish to reduce your search radius."
    },
    "atmosphere": {
      "Ammonia": "Ammonia",
      "Ammonia and Oxygen": "Ammonia and Oxygen",
      "Ammonia-rich": "Ammonia-rich",
      "Argon": "Argon",
      "Argon-rich": "Argon-rich",
      "Carbon dioxide": "Carbon dioxide",
      "Carbon dioxide-rich": "Carbon dioxide-rich",
      "Helium": "Helium",
      "Hot Argon": "Hot Argon",
      "Hot Argon-rich": "Hot Argon-rich",
      "Hot Carbon dioxide": "Hot Carbon dioxide",
      "Hot Carbon dioxide-rich": "Hot Carbon dioxide-rich",
      "Hot Metallic vapour": "Hot Metallic vapour",
      "Hot Silicate vapour": "Hot Silicate vapour",
      "Hot Sulphur dioxide": "Hot Sulphur dioxide",
      "Hot Water": "Hot Water",
      "Hot Water-rich": "Hot Water-rich",
      "Hot thick Ammonia": "Hot thick Ammonia",
      "Hot thick Ammonia-rich": "Hot thick Ammonia-rich",
      "Hot thick Argon": "Hot thick Argon",
      "Hot thick Argon-rich": "Hot thick Argon-rich",
      "Hot thick Carbon dioxide": "Hot thick Carbon dioxide",
      "Hot thick Carbon dioxide-rich": "Hot thick Carbon dioxide-rich",
      "Hot thick Metallic vapour": "Hot thick Metallic vapour",
      "Hot thick Methane": "Hot thick Methane",
      "Hot thick Methane-rich": "Hot thick Methane-rich",
      "Hot thick Nitrogen": "Hot thick Nitrogen",
      "Hot thick No atmosphere": "Hot thick No atmosphere",
      "Hot thick Silicate vapour": "Hot thick Silicate vapour",
      "Hot thick Sulphur dioxide": "Hot thick Sulphur dioxide",
      "Hot thick Water": "Hot thick Water",
      "Hot thick Water-rich": "Hot thick Water-rich",
      "Hot thin Carbon dioxide": "Hot thin Carbon dioxide",
      "Hot thin Metallic vapour": "Hot thin Metallic vapour",
      "Hot thin Silicate vapour": "Hot thin Silicate vapour",
      "Hot thin Sulphur dioxide": "Hot thin Sulphur dioxide",
      "Metallic vapour": "Metallic vapour",
      "Methane": "Methane",
      "Methane-rich": "Methane-rich",
      "Neon": "Neon",
      "Neon-rich": "Neon-rich",
      "Nitrogen": "Nitrogen",
      "No atmosphere": "No atmosphere",
      "Oxygen": "Oxygen",
      "Silicate vapour": "Silicate vapour",
      "Suitable for water-based life": "Suitable for water-based life",
      "Sulphur dioxide": "Sulphur dioxide",
      "Thick Ammonia": "Thick Ammonia",
      "Thick Ammonia and Oxygen": "Thick Ammonia and Oxygen",
      "Thick Ammonia-rich": "Thick Ammonia-rich",
      "Thick Argon": "Thick Argon",
      "Thick Argon-rich": "Thick Argon-rich",
      "Thick Carbon dioxide": "Thick Carbon dioxide",
      "Thick Carbon dioxide-rich": "Thick Carbon dioxide-rich",
      "Thick Helium": "Thick Helium",
      "Thick Methane": "Thick Methane",
      "Thick Methane-rich": "Thick Methane-rich",
      "Thick Nitrogen": "Thick Nitrogen",
      "Thick No atmosphere": "Thick No atmosphere",
      "Thick Suitable for water-based life": "Thick Suitable for water-based life",
      "Thick Sulphur dioxide": "Thick Sulphur dioxide",
      "Thick Water": "Thick Water",
      "Thick Water-rich": "Thick Water-rich",
      "Thin Ammonia": "Thin Ammonia",
      "Thin Ammonia and Oxygen": "Thin Ammonia and Oxygen",
      "Thin Ammonia-rich": "Thin Ammonia-rich",
      "Thin Argon": "Thin Argon",
      "Thin Argon-rich": "Thin Argon-rich",
      "Thin Carbon dioxide": "Thin Carbon dioxide",
      "Thin Carbon dioxide-rich": "Thin Carbon dioxide-rich",
      "Thin Helium": "Thin Helium",
      "Thin Methane": "Thin Methane",
      "Thin Methane-rich": "Thin Methane-rich",
      "Thin Neon": "Thin Neon",
      "Thin Neon-rich": "Thin Neon-rich",
      "Thin Nitrogen": "Thin Nitrogen",
      "Thin No atmosphere": "Thin No atmosphere",
      "Thin Oxygen": "Thin Oxygen",
      "Thin Sulphur dioxide": "Thin Sulphur dioxide",
      "Thin Water": "Thin Water",
      "Thin Water-rich": "Thin Water-rich",
      "Water": "Water",
      "Water-rich": "Water-rich"
    },
    "atmosphere_composition": {
      "Ammonia": "Ammonia",
      "Argon": "Argon",
      "Carbon dioxide": "Carbon dioxide",
      "Helium": "Helium",
      "Hydrogen": "Hydrogen",
      "Iron": "Iron",
      "Methane": "Methane",
      "Neon": "Neon",
      "Nitrogen": "Nitrogen",
      "Oxygen": "Oxygen",
      "Silicates": "Silicates",
      "Sulphur dioxide": "Sulphur dioxide",
      "Water": "Water"
    },
    "bad_data": {
      "archive": "Archive",
      "description": "Report a problem with {system}",
      "error_description": "There was a problem with your submission, please report it using the contact details at the bottom of the page.",
      "reason": "Reason",
      "report": "Report problem with this system",
      "send": "Send report",
      "success_description": "Thank you for your submission, we'll take a look at it."
    },
    "belts": {
      "add_field": "Add filter",
      "inner_radius": "Inner Radius (m)",
      "mass": "Mass (Mt)",
      "outer_radius": "Outer Radius (m)",
      "remove_field": "Remove filter",
      "select_type": "Select type",
      "type": "Type",
      "types": {
        "Icy": "Icy",
        "Metal Rich": "Metal Rich",
        "Metallic": "Metallic",
        "Rocky": "Rocky"
      }
    },
    "body": {
      "belts": "Belts",
      "body": "Body",
      "composition": "Composition",
      "landmarks": "Landmarks ({count})",
      "no_id64": "No Body ID provided",
      "not_found": "Body not found",
      "rings": "Rings",
      "signals": "Signals ({count})",
      "signals_updated_at": "Signals Updated {relative_updated_at} ({updated_at})",
      "stations": "Stations ({size})",
      "updated_at": "Updated {relative_updated_at} ({updated_at})"
    },
    "boolean": {
      "Any": "Any",
      "No": "No",
      "Yes": "Yes"
    },
    "categories": {
      "Chemicals": "Chemicals",
      "Consumer Items": "Consumer Items",
      "Foods": "Foods",
      "Industrial Materials": "Industrial Materials",
      "Legal Drugs": "Legal Drugs",
      "Machinery": "Machinery",
      "Medicines": "Medicines",
      "Metals": "Metals",
      "Minerals": "Minerals",
      "Salvage": "Salvage",
      "Slavery": "Slavery",
      "Technology": "Technology",
      "Textiles": "Textiles",
      "Waste": "Waste",
      "Weapons": "Weapons"
    },
    "commodities": {
      "AI Relics": "AI Relics",
      "Advanced Catalysers": "Advanced Catalysers",
      "Advanced Medicines": "Advanced Medicines",
      "Aepyornis Egg": "Aepyornis Egg",
      "Aganippe Rush": "Aganippe Rush",
      "Agri-Medicines": "Agri-Medicines",
      "Agronomic Treatment": "Agronomic Treatment",
      "Alacarakmo Skin Art": "Alacarakmo Skin Art",
      "Albino Quechua Mammoth Meat": "Albino Quechua Mammoth Meat",
      "Alexandrite": "Alexandrite",
      "Algae": "Algae",
      "Altairian Skin": "Altairian Skin",
      "Aluminium": "Aluminium",
      "Alya Body Soap": "Alya Body Soap",
      "Ancient Artefact": "Ancient Artefact",
      "Ancient Key": "Ancient Key",
      "Anduliga Fire Works": "Anduliga Fire Works",
      "Animal Meat": "Animal Meat",
      "Animal Monitors": "Animal Monitors",
      "Anomaly Particles": "Anomaly Particles",
      "Antimatter Containment Unit": "Antimatter Containment Unit",
      "Antique Jewellery": "Antique Jewellery",
      "Antiquities": "Antiquities",
      "Any Na Coffee": "Any Na Coffee",
      "Apa Vietii": "Apa Vietii",
      "Aquaponic Systems": "Aquaponic Systems",
      "Arouca Conventual Sweets": "Arouca Conventual Sweets",
      "Articulation Motors": "Articulation Motors",
      "Assault Plans": "Assault Plans",
      "Atmospheric Processors": "Atmospheric Processors",
      "Auto-Fabricators": "Auto-Fabricators",
      "Az Cancri Formula 42": "Az Cancri Formula 42",
      "Azure Milk": "Azure Milk",
      "Baked Greebles": "Baked Greebles",
      "Baltah'sine Vacuum Krill": "Baltah'sine Vacuum Krill",
      "Banki Amphibious Leather": "Banki Amphibious Leather",
      "Basic Medicines": "Basic Medicines",
      "Bast Snake Gin": "Bast Snake Gin",
      "Battle Weapons": "Battle Weapons",
      "Bauxite": "Bauxite",
      "Beer": "Beer",
      "Belalans Ray Leather": "Belalans Ray Leather",
      "Benitoite": "Benitoite",
      "Bertrandite": "Bertrandite",
      "Beryllium": "Beryllium",
      "Bioreducing Lichen": "Bioreducing Lichen",
      "Biowaste": "Biowaste",
      "Bismuth": "Bismuth",
      "Black Box": "Black Box",
      "Bootleg Liquor": "Bootleg Liquor",
      "Borasetani Pathogenetics": "Borasetani Pathogenetics",
      "Bromellite": "Bromellite",
      "Buckyball Beer Mats": "Buckyball Beer Mats",
      "Building Fabricators": "Building Fabricators",
      "Burnham Bile Distillate": "Burnham Bile Distillate",
      "CD-75 Kitten Brand Coffee": "CD-75 Kitten Brand Coffee",
      "CMM Composite": "CMM Composite",
      "Caustic Tissue Sample": "Caustic Tissue Sample",
      "Centauri Mega Gin": "Centauri Mega Gin",
      "Ceramic Composites": "Ceramic Composites",
      "Ceremonial Heike Tea": "Ceremonial Heike Tea",
      "Ceti Rabbits": "Ceti Rabbits",
      "Chameleon Cloth": "Chameleon Cloth",
      "Chateau De Aegaeon": "Chateau De Aegaeon",
      "Chemical Waste": "Chemical Waste",
      "Cherbones Blood Crystals": "Cherbones Blood Crystals",
      "Chi Eridani Marine Paste": "Chi Eridani Marine Paste",
      "Classified Experimental Equipment": "Classified Experimental Equipment",
      "Clothing": "Clothing",
      "Cobalt": "Cobalt",
      "Coffee": "Coffee",
      "Coltan": "Coltan",
      "Combat Stabilisers": "Combat Stabilisers",
      "Commercial Samples": "Commercial Samples",
      "Computer Components": "Computer Components",
      "Conductive Fabrics": "Conductive Fabrics",
      "Consumer Technology": "Consumer Technology",
      "Copper": "Copper",
      "Coquim Spongiform Victuals": "Coquim Spongiform Victuals",
      "Crom Silver Fesh": "Crom Silver Fesh",
      "Crop Harvesters": "Crop Harvesters",
      "Cryolite": "Cryolite",
      "Crystalline Spheres": "Crystalline Spheres",
      "Damaged Escape Pod": "Damaged Escape Pod",
      "Damna Carapaces": "Damna Carapaces",
      "Data Core": "Data Core",
      "Delta Phoenicis Palms": "Delta Phoenicis Palms",
      "Deuringas Truffles": "Deuringas Truffles",
      "Diplomatic Bag": "Diplomatic Bag",
      "Diso Ma Corn": "Diso Ma Corn",
      "Domestic Appliances": "Domestic Appliances",
      "Duradrives": "Duradrives",
      "Earth Relics": "Earth Relics",
      "Eden Apples of Aerial": "Eden Apples of Aerial",
      "Eleu Thermals": "Eleu Thermals",
      "Emergency Power Cells": "Emergency Power Cells",
      "Encrypted Correspondence": "Encrypted Correspondence",
      "Encrypted Data Storage": "Encrypted Data Storage",
      "Energy Grid Assembly": "Energy Grid Assembly",
      "Eranin Pearl Whisky": "Eranin Pearl Whisky",
      "Eshu Umbrellas": "Eshu Umbrellas",
      "Esuseku Caviar": "Esuseku Caviar",
      "Ethgreze Tea Buds": "Ethgreze Tea Buds",
      "Evacuation Shelter": "Evacuation Shelter",
      "Exhaust Manifold": "Exhaust Manifold",
      "Experimental Chemicals": "Experimental Chemicals",
      "Explosives": "Explosives",
      "Fish": "Fish",
      "Food Cartridges": "Food Cartridges",
      "Fossil Remnants": "Fossil Remnants",
      "Fruit and Vegetables": "Fruit and Vegetables",
      "Fujin Tea": "Fujin Tea",
      "Galactic Travel Guide": "Galactic Travel Guide",
      "Gallite": "Gallite",
      "Gallium": "Gallium",
      "Geawen Dance Dust": "Geawen Dance Dust",
      "Gene Bank": "Gene Bank",
      "Geological Equipment": "Geological Equipment",
      "Geological Samples": "Geological Samples",
      "Gerasian Gueuze Beer": "Gerasian Gueuze Beer",
      "Giant Irukama Snails": "Giant Irukama Snails",
      "Giant Verrix": "Giant Verrix",
      "Gilya Signature Weapons": "Gilya Signature Weapons",
      "Gold": "Gold",
      "Goman Yaupon Coffee": "Goman Yaupon Coffee",
      "Goslarite": "Goslarite",
      "Grain": "Grain",
      "Grandidierite": "Grandidierite",
      "Guardian Casket": "Guardian Casket",
      "Guardian Orb": "Guardian Orb",
      "Guardian Relic": "Guardian Relic",
      "Guardian Tablet": "Guardian Tablet",
      "Guardian Totem": "Guardian Totem",
      "Guardian Urn": "Guardian Urn",
      "H.E. Suits": "H.E. Suits",
      "HIP 10175 Bush Meat": "HIP 10175 Bush Meat",
      "HIP 118311 Swarm": "HIP 118311 Swarm",
      "HIP Organophosphates": "HIP Organophosphates",
      "HIP Proto-Squid": "HIP Proto-Squid",
      "HN Shock Mount": "HN Shock Mount",
      "HR 7221 Wheat": "HR 7221 Wheat",
      "Hafnium 178": "Hafnium 178",
      "Haiden Black Brew": "Haiden Black Brew",
      "Hardware Diagnostic Sensor": "Hardware Diagnostic Sensor",
      "Harma Silver Sea Rum": "Harma Silver Sea Rum",
      "Havasupai Dream Catcher": "Havasupai Dream Catcher",
      "Heatsink Interlink": "Heatsink Interlink",
      "Helvetitj Pearls": "Helvetitj Pearls",
      "Holva Duelling Blades": "Holva Duelling Blades",
      "Honesty Pills": "Honesty Pills",
      "Hostages": "Hostages",
      "Hydrogen Fuel": "Hydrogen Fuel",
      "Hydrogen Peroxide": "Hydrogen Peroxide",
      "Imperial Slaves": "Imperial Slaves",
      "Indi Bourbon": "Indi Bourbon",
      "Indite": "Indite",
      "Indium": "Indium",
      "Insulating Membrane": "Insulating Membrane",
      "Ion Distributor": "Ion Distributor",
      "Jadeite": "Jadeite",
      "Jaques Quinentian Still": "Jaques Quinentian Still",
      "Jaradharre Puzzle Box": "Jaradharre Puzzle Box",
      "Jaroua Rice": "Jaroua Rice",
      "Jotun Mookah": "Jotun Mookah",
      "Kachirigin Filter Leeches": "Kachirigin Filter Leeches",
      "Kamitra Cigars": "Kamitra Cigars",
      "Kamorin Historic Weapons": "Kamorin Historic Weapons",
      "Karetii Couture": "Karetii Couture",
      "Karsuki Locusts": "Karsuki Locusts",
      "Kinago Violins": "Kinago Violins",
      "Kongga Ale": "Kongga Ale",
      "Koro Kung Pellets": "Koro Kung Pellets",
      "LTT Hyper Sweet": "LTT Hyper Sweet",
      "Land Enrichment Systems": "Land Enrichment Systems",
      "Landmines": "Landmines",
      "Lanthanum": "Lanthanum",
      "Large Survey Data Cache": "Large Survey Data Cache",
      "Lavian Brandy": "Lavian Brandy",
      "Leather": "Leather",
      "Leathery Eggs": "Leathery Eggs",
      "Leestian Evil Juice": "Leestian Evil Juice",
      "Lepidolite": "Lepidolite",
      "Liquid oxygen": "Liquid oxygen",
      "Liquor": "Liquor",
      "Lithium": "Lithium",
      "Lithium Hydroxide": "Lithium Hydroxide",
      "Live Hecate Sea Worms": "Live Hecate Sea Worms",
      "Low Temperature Diamonds": "Low Temperature Diamonds",
      "Lucan Onionhead": "Lucan Onionhead",
      "Lyrae Weed": "Lyrae Weed",
      "Magnetic Emitter Coil": "Magnetic Emitter Coil",
      "Marine Equipment": "Marine Equipment",
      "Master Chefs": "Master Chefs",
      "Mechucos High Tea": "Mechucos High Tea",
      "Medb Starlube": "Medb Starlube",
      "Medical Diagnostic Equipment": "Medical Diagnostic Equipment",
      "Meta-Alloys": "Meta-Alloys",
      "Methane Clathrate": "Methane Clathrate",
      "Methanol Monohydrate Crystals": "Methanol Monohydrate Crystals",
      "Micro Controllers": "Micro Controllers",
      "Micro-weave Cooling Hoses": "Micro-weave Cooling Hoses",
      "Microbial Furnaces": "Microbial Furnaces",
      "Military Grade Fabrics": "Military Grade Fabrics",
      "Military Intelligence": "Military Intelligence",
      "Military Plans": "Military Plans",
      "Mineral Extractors": "Mineral Extractors",
      "Mineral Oil": "Mineral Oil",
      "Modular Terminals": "Modular Terminals",
      "Moissanite": "Moissanite",
      "Mokojing Beast Feast": "Mokojing Beast Feast",
      "Mollusc Brain Tissue": "Mollusc Brain Tissue",
      "Mollusc Fluid": "Mollusc Fluid",
      "Mollusc Membrane": "Mollusc Membrane",
      "Mollusc Mycelium": "Mollusc Mycelium",
      "Mollusc Soft Tissue": "Mollusc Soft Tissue",
      "Mollusc Spores": "Mollusc Spores",
      "Momus Bog Spaniel": "Momus Bog Spaniel",
      "Monazite": "Monazite",
      "Motrona Experience Jelly": "Motrona Experience Jelly",
      "Mukusubii Chitin-os": "Mukusubii Chitin-os",
      "Mulachi Giant Fungus": "Mulachi Giant Fungus",
      "Muon Imager": "Muon Imager",
      "Musgravite": "Musgravite",
      "Mysterious Idol": "Mysterious Idol",
      "Nanobreakers": "Nanobreakers",
      "Nanomedicines": "Nanomedicines",
      "Narcotics": "Narcotics",
      "Natural Fabrics": "Natural Fabrics",
      "Neofabric Insulation": "Neofabric Insulation",
      "Neritus Berries": "Neritus Berries",
      "Nerve Agents": "Nerve Agents",
      "Ngadandari Fire Opals": "Ngadandari Fire Opals",
      "Nguna Modern Antiques": "Nguna Modern Antiques",
      "Njangari Saddles": "Njangari Saddles",
      "Non Euclidian Exotanks": "Non Euclidian Exotanks",
      "Non-Lethal Weapons": "Non-Lethal Weapons",
      "Occupied Escape Pod": "Occupied Escape Pod",
      "Ochoeng Chillies": "Ochoeng Chillies",
      "Onionhead": "Onionhead",
      "Onionhead Alpha Strain": "Onionhead Alpha Strain",
      "Onionhead Beta Strain": "Onionhead Beta Strain",
      "Onionhead Gamma Strain": "Onionhead Gamma Strain",
      "Ophiuch Exino Artefacts": "Ophiuch Exino Artefacts",
      "Orrerian Vicious Brew": "Orrerian Vicious Brew",
      "Osmium": "Osmium",
      "Painite": "Painite",
      "Palladium": "Palladium",
      "Pantaa Prayer Sticks": "Pantaa Prayer Sticks",
      "Pavonis Ear Grubs": "Pavonis Ear Grubs",
      "Performance Enhancers": "Performance Enhancers",
      "Personal Effects": "Personal Effects",
      "Personal Gifts": "Personal Gifts",
      "Personal Weapons": "Personal Weapons",
      "Pesticides": "Pesticides",
      "Platinum": "Platinum",
      "Platinum Alloy": "Platinum Alloy",
      "Pod Core Tissue": "Pod Core Tissue",
      "Pod Dead Tissue": "Pod Dead Tissue",
      "Pod Mesoglea": "Pod Mesoglea",
      "Pod Outer Tissue": "Pod Outer Tissue",
      "Pod Shell Tissue": "Pod Shell Tissue",
      "Pod Surface Tissue": "Pod Surface Tissue",
      "Pod Tissue": "Pod Tissue",
      "Political Prisoners": "Political Prisoners",
      "Polymers": "Polymers",
      "Power Converter": "Power Converter",
      "Power Generators": "Power Generators",
      "Power Transfer Bus": "Power Transfer Bus",
      "Praseodymium": "Praseodymium",
      "Precious Gems": "Precious Gems",
      "Progenitor Cells": "Progenitor Cells",
      "Prohibited Research Materials": "Prohibited Research Materials",
      "Prototype Tech": "Prototype Tech",
      "Pyrophyllite": "Pyrophyllite",
      "Radiation Baffle": "Radiation Baffle",
      "Rajukru Multi-Stoves": "Rajukru Multi-Stoves",
      "Rapa Bao Snake Skins": "Rapa Bao Snake Skins",
      "Rare Artwork": "Rare Artwork",
      "Reactive Armour": "Reactive Armour",
      "Rebel Transmissions": "Rebel Transmissions",
      "Reinforced Mounting Plate": "Reinforced Mounting Plate",
      "Resonating Separators": "Resonating Separators",
      "Rhodplumsite": "Rhodplumsite",
      "Robotics": "Robotics",
      "Rockforth Fertiliser": "Rockforth Fertiliser",
      "Rusani Old Smokey": "Rusani Old Smokey",
      "Rutile": "Rutile",
      "SAP 8 Core Container": "SAP 8 Core Container",
      "Samarium": "Samarium",
      "Sanuma Decorative Meat": "Sanuma Decorative Meat",
      "Saxon Wine": "Saxon Wine",
      "Scientific Research": "Scientific Research",
      "Scientific Samples": "Scientific Samples",
      "Scrap": "Scrap",
      "Semiconductors": "Semiconductors",
      "Serendibite": "Serendibite",
      "Shan's Charis Orchid": "Shan's Charis Orchid",
      "Silver": "Silver",
      "Skimmer Components": "Skimmer Components",
      "Slaves": "Slaves",
      "Small Survey Data Cache": "Small Survey Data Cache",
      "Soontill Relics": "Soontill Relics",
      "Sothis Crystalline Gold": "Sothis Crystalline Gold",
      "Space Pioneer Relics": "Space Pioneer Relics",
      "Structural Regulators": "Structural Regulators",
      "Superconductors": "Superconductors",
      "Surface Stabilisers": "Surface Stabilisers",
      "Survival Equipment": "Survival Equipment",
      "Synthetic Fabrics": "Synthetic Fabrics",
      "Synthetic Meat": "Synthetic Meat",
      "Synthetic Reagents": "Synthetic Reagents",
      "Taaffeite": "Taaffeite",
      "Tactical Data": "Tactical Data",
      "Tanmark Tranquil Tea": "Tanmark Tranquil Tea",
      "Tantalum": "Tantalum",
      "Tarach Spice": "Tarach Spice",
      "Tauri Chimes": "Tauri Chimes",
      "Tea": "Tea",
      "Technical Blueprints": "Technical Blueprints",
      "Telemetry Suite": "Telemetry Suite",
      "Terra Mater Blood Bores": "Terra Mater Blood Bores",
      "Thallium": "Thallium",
      "Thargoid Basilisk Tissue Sample": "Thargoid Basilisk Tissue Sample",
      "Thargoid Biological Matter": "Thargoid Biological Matter",
      "Thargoid Cyclops Tissue Sample": "Thargoid Cyclops Tissue Sample",
      "Thargoid Glaive Tissue Sample": "Thargoid Glaive Tissue Sample",
      "Thargoid Heart": "Thargoid Heart",
      "Thargoid Hydra Tissue Sample": "Thargoid Hydra Tissue Sample",
      "Thargoid Link": "Thargoid Link",
      "Thargoid Medusa Tissue Sample": "Thargoid Medusa Tissue Sample",
      "Thargoid Orthrus Tissue Sample": "Thargoid Orthrus Tissue Sample",
      "Thargoid Probe": "Thargoid Probe",
      "Thargoid Relic": "Thargoid Relic",
      "Thargoid Resin": "Thargoid Resin",
      "Thargoid Scout Tissue Sample": "Thargoid Scout Tissue Sample",
      "Thargoid Sensor": "Thargoid Sensor",
      "Thargoid Technology Samples": "Thargoid Technology Samples",
      "The Hutton Mug": "The Hutton Mug",
      "The Waters of Shintara": "The Waters of Shintara",
      "Thermal Cooling Units": "Thermal Cooling Units",
      "Thorium": "Thorium",
      "Thrutis Cream": "Thrutis Cream",
      "Tiegfries Synth Silk": "Tiegfries Synth Silk",
      "Time Capsule": "Time Capsule",
      "Tiolce Waste2Paste Units": "Tiolce Waste2Paste Units",
      "Titan Deep Tissue Sample": "Titan Deep Tissue Sample",
      "Titan Maw Deep Tissue Sample": "Titan Maw Deep Tissue Sample",
      "Titan Maw Partial Tissue Sample": "Titan Maw Partial Tissue Sample",
      "Titan Maw Tissue Sample": "Titan Maw Tissue Sample",
      "Titan Partial Tissue Sample": "Titan Partial Tissue Sample",
      "Titan Tissue Sample": "Titan Tissue Sample",
      "Titanium": "Titanium",
      "Tobacco": "Tobacco",
      "Toxandji Virocide": "Toxandji Virocide",
      "Toxic Waste": "Toxic Waste",
      "Trade Data": "Trade Data",
      "Trinkets of Hidden Fortune": "Trinkets of Hidden Fortune",
      "Tritium": "Tritium",
      "Ultra-Compact Processor Prototypes": "Ultra-Compact Processor Prototypes",
      "Unclassified Relic": "Unclassified Relic",
      "Unoccupied Escape Pod": "Unoccupied Escape Pod",
      "Unstable Data Core": "Unstable Data Core",
      "Uraninite": "Uraninite",
      "Uranium": "Uranium",
      "Uszaian Tree Grub": "Uszaian Tree Grub",
      "Utgaroar Millennial Eggs": "Utgaroar Millennial Eggs",
      "Uzumoku Low-G Wings": "Uzumoku Low-G Wings",
      "V Herculis Body Rub": "V Herculis Body Rub",
      "Vanayequi Ceratomorpha Fur": "Vanayequi Ceratomorpha Fur",
      "Vega Slimweed": "Vega Slimweed",
      "Vidavantian Lace": "Vidavantian Lace",
      "Void Extract Coffee": "Void Extract Coffee",
      "Void Opal": "Void Opal",
      "Volkhab Bee Drones": "Volkhab Bee Drones",
      "Water": "Water",
      "Water Purifiers": "Water Purifiers",
      "Wheemete Wheat Cakes": "Wheemete Wheat Cakes",
      "Wine": "Wine",
      "Witchhaul Kobe Beef": "Witchhaul Kobe Beef",
      "Wolf Fesh": "Wolf Fesh",
      "Wreckage Components": "Wreckage Components",
      "Wulpa Hyperbore Systems": "Wulpa Hyperbore Systems",
      "Wuthielo Ku Froth": "Wuthielo Ku Froth",
      "Xihe Biomorphic Companions": "Xihe Biomorphic Companions",
      "Yaso Kondi Leaf": "Yaso Kondi Leaf",
      "Zeessze Ant Grub Glue": "Zeessze Ant Grub Glue"
    },
    "dates": {
      "forever": "Forever"
    },
    "download": {
      "body": "Body Name",
      "count": "Count",
      "distance": "Distance To Arrival",
      "distance_travelled": "Distance",
      "fuel_remaining": "Fuel Left",
      "fuel_used": "Fuel Used",
      "icy_ring": "Icy Ring",
      "jumps": "Jumps",
      "landmark_subtype": "Landmark Subtype",
      "landmark_value": "Value",
      "mapping_value": "Estimated Mapping Value",
      "neutron": "Neutron Star",
      "no": "No",
      "pristine": "Pristine",
      "refuel": "Refuel",
      "remaining": "Distance Remaining",
      "restock_tritium": "Restock Tritium",
      "scan_value": "Estimated Scan Value",
      "subtype": "Body Subtype",
      "system": "System Name",
      "terraform": "Is Terraformable",
      "total_value": "Total Value",
      "tritium_left": "Tritium in tank",
      "tritium_market": "Tritium in market",
      "yes": "Yes"
    },
    "dumps": {
      "description": "Nightly dumps of galaxy related data",
      "file_description": "Description",
      "filename": "Filename",
      "files": {
        "galaxy.json.gz": "The entire galaxy including bodies and stations",
        "galaxy_1day.json.gz": "Only systems where any information within that system was updated in the last 24 hours",
        "galaxy_1month.json.gz": "Only systems where any information within that system was updated in the last 32 days",
        "galaxy_7days.json.gz": "Only systems where any information within that system was updated in the last 7 days",
        "galaxy_populated.json.gz": "Only systems with population greater than 0",
        "galaxy_stations.json.gz": "Only systems with a station (including fleet carriers)",
        "systems.json.gz": "Just system details (no bodies or stations)",
        "systems_1day.json.gz": "Just system details (no bodies or stations) updated in the last 24 hours",
        "systems_1month.json.gz": "Just system details (no bodies or stations) updated in the last month",
        "systems_1week.json.gz": "Just system details (no bodies or stations) updated in the last 1 week",
        "systems_2weeks.json.gz": "Just system details (no bodies or stations) updated in the last 2 weeks",
        "systems_6months.json.gz": "Just system details (no bodies or stations) updated in the last 6 months"
      },
      "modified": "Generated",
      "size": "Filesize",
      "title": "Data Dumps"
    },
    "earth": {
      "blank": "Leave destination blank in order to create a circular tour starting at your source system and returning there at the end.",
      "guides": "You can find more information about the community goal <a href=\"https://www.elitedangerous.com/community/goals/\">here</a> and <a href=\"https://forums.frontier.co.uk/threads/support-the-saud-kruger-exploration-campaign.590091/\">here</a>.",
      "low_results": "The number of systems returned is less than you asked for.  Try increasing the radius of the search in order to find more bodies.",
      "title": "Earth-like World Route",
      "warning": "This router will allow you to find earth-like worlds around your current location for the community goal.  It will prioritise high scan value so if you only find far away worlds you may wish to reduce your search radius."
    },
    "economy": {
      "Agriculture": "Agriculture",
      "Colony": "Colony",
      "Damaged": "Damaged",
      "Engineering": "Engineering",
      "Extraction": "Extraction",
      "High Tech": "High Tech",
      "Industrial": "Industrial",
      "Military": "Military",
      "None": "None",
      "Prison": "Prison",
      "Private Enterprise": "Private Enterprise",
      "Refinery": "Refinery",
      "Repair": "Repair",
      "Rescue": "Rescue",
      "Service": "Service",
      "Terraforming": "Terraforming",
      "Tourism": "Tourism"
    },
    "engineer-plotter": {
      "add_blueprint": "Add blueprint",
      "blueprint": "{type} ({name} grade {grade})",
      "close_ship_build": "Close",
      "delete_blueprint": "Delete blueprint",
      "description": "This page will allow you to plot an efficient route to engineer your ship.",
      "engineer_blueprint": "Engineer {slot} with {name} to grade {grade}",
      "engineer_blueprint_effect": "Engineer {slot} with {name} to grade {grade} and effect {effect}",
      "load_ship_build": "Load ship build",
      "nothing_to_engineer": "Nothing to engineer",
      "process_current_ship_build": "Process current blueprints",
      "process_target_ship_build": "Process target blueprints",
      "system_title": "{engineer} in {system} ({distance}LY)",
      "title": "Engineer Plotter"
    },
    "error": "An unexpected error has occurred",
    "exact-plotter": {
      "description": "This page will allow you to plot between two different star systems.  It will attempt to plot you an exact route, including fuel usage, neutron supercharges and injection boosts if so desired.",
      "edsy_profile": "Submit commander profile to ED:Shipyard",
      "edsy_profile_description": "Submit your commander profile to ED:Shipyard so that you can export your current ship to SLEF.  This will open in a new window so you do not lose your place on the site.",
      "edsy_profile_fail": "Could not fetch ship profile.",
      "edsy_profile_instructions": "Once you have the profile imported you can click Ops in the top right of ED:Shipyard and export your build.  You should copy the SLEF and paste it into the ship build box on the router.",
      "fuel_remaining_description": "Fuel currently in the fuel tank.  This does not include the reserve tank",
      "fuel_used_description": "Fuel used in the jump",
      "load_profile": "Load Ship Profile",
      "load_profile_description": "Load your current ship profile from the Frontier servers",
      "submit_edsy_profile": "Submit profile",
      "system_error": "Error in system  <a href=\"{link}\">{system}</a>",
      "title": "Galaxy Plotter",
      "warning": "This router is in beta and the results are not guaranteed to be ideal, it may also route you through systems which require a permit."
    },
    "exobiology": {
      "blank": "Leave destination blank if you don't mind where you finish",
      "low_results": "The number of systems returned is less than you asked for.  Try increasing the radius of the search in order to find more bodies.  If you still do not get enough results you may have set the minimum value too high.",
      "title": "Expressway to Exomastery"
    },
    "fields": {
      "add_atmosphere_composition_filter": "Add atmosphere composition filter",
      "add_destination": "Add Destination",
      "add_market_filter": "Add market filter",
      "add_material_filter": "Add material filter",
      "add_sort_field": "Add sort field",
      "add_via": "Add Via",
      "algorithm": "Routing Algorithm",
      "algorithm_description": "Which routing algorithm to use, different algorithms may work faster, find better routes or in some cases be unable to find a route.",
      "allegiance": "Allegiance",
      "allow_planetary": "Allow Planetary",
      "allow_planetary_description": "Include planetary stations in the route",
      "allow_prohibited": "Allow Prohibited",
      "allow_prohibited_description": "Include goods which are illegal in the destination system",
      "amount": "Amount",
      "amount_of_commodity": "Amount of {commodity}",
      "arg_of_periapsis": "Arg Of Periapsis",
      "ascending_node": "Ascending Node",
      "atmosphere": "Atmosphere",
      "atmosphere_composition": "Atmosphere Composition",
      "atmosphere_composition_fields": "Atmosphere Composition",
      "atmosphere_compositions": "Atmosphere Compositions",
      "axis_tilt": "Axial Tilt",
      "belt_fields": "Belt filters",
      "belts": "Belts",
      "blueprint": "Blueprint",
      "body_count": "Body Count",
      "body_name": "Body Name",
      "body_subtype": "Body Subtype",
      "body_type": "Body Type",
      "buy_price": "Buy Price",
      "calculate": "Calculate",
      "cargo": "Cargo",
      "combined_ring_signal": "Combined Ring Signal",
      "combined_signal": "Combined Body Signal",
      "commodity": "Commodity",
      "commodity_not_found": "Commodity not found",
      "completed": "Done",
      "controlling_minor_faction": "Controlling Faction",
      "controlling_minor_faction_state": "Controlling Faction State",
      "coordinates": "Reference Coordinates",
      "count": "Count",
      "cumulative_profit": "Cumulative Profit",
      "delete_destination": "Delete Destination",
      "delete_via": "Delete Via",
      "demand": "Demand",
      "destination_system": "Destination System",
      "determine_required_fuel": "Determine Tritium Requirements",
      "determine_required_fuel_description": "Calculate how much Tritium would be required to complete the entire route.",
      "distance": "Distance (LY)",
      "distance_from_coords": "Distance (LY)",
      "distance_to_arrival": "Distance to Arrival (LS)",
      "distance_to_arrival_short": "Distance (LS)",
      "earth_masses": "Earth Masses",
      "effect": "Effect",
      "efficiency": "Efficiency (%)",
      "efficiency_description": "Increase this to reduce how far off the direct route the system will plot to get to a neutron star (An efficiency of 100 will not deviate from the direct route in order to plot from A to B and will most likely break down the journey into 20000 LY blocks).",
      "engineers": "Engineers",
      "engineers_description": "List of engineers to use, select none in order to allow all engineers.",
      "estimated_mapping_value": "Estimated Mapping Value",
      "estimated_mapping_value_short": "Mapping Value",
      "estimated_scan_value": "Estimated Scan Value",
      "estimated_scan_value_short": "Scan Value",
      "exclude_secondary": "Exclude Secondary Stars",
      "exclude_secondary_description": "Prevent the system using secondary neutron and scoopable stars to help with the route",
      "export_commodities": "Export Commodities",
      "faction_influence": "Faction Influence",
      "faction_name": "Faction Name",
      "faction_presence": "Faction Presence",
      "faction_state": "Faction State",
      "find_stations": "Find Stations",
      "flying_time": "{time} flying time",
      "fuel_remaining": "Fuel Left (tonnes)",
      "fuel_remaining_description": "Tritium currently in the fuel tank.  You may have to transfer from the market to get it to this level",
      "fuel_used": "Fuel Used (tonnes)",
      "fuel_used_description": "Tritium used in the jump",
      "full_spectral_class": "Full Spectral Class",
      "genuses": "Genuses",
      "government": "Government",
      "gravity": "Gravity",
      "has_large_pad": "Has large pad",
      "has_market": "Has market",
      "has_outfitting": "Has outfitting",
      "has_shipyard": "Has shipyard",
      "icy_ring": "Icy ring",
      "icy_ring_description": "Does the system have an icy ring?",
      "import_commodities": "Import Commodities",
      "influence": "Influence",
      "is_dssa_carrier": "DSSA Carrier",
      "is_landable": "Landable",
      "is_main_star": "Main Star",
      "is_planetary": "Planetary",
      "is_supercharged": "Already Supercharged",
      "is_supercharged_description": "Is your ship already supercharged?",
      "jumps": "Jumps",
      "jumps_description": "Estimated number of jumps to this system.",
      "jumps_remaining": "Jumps Left",
      "landmark_subtype": "Landmark Subtype",
      "landmark_type": "Landmark Type",
      "landmark_value": "Landmark Value",
      "landmarks": "Landmarks",
      "large_pads": "Large Pads",
      "latitude": "Latitude",
      "longitude": "Longitude",
      "loop": "Loop",
      "loop_description": "Force the route to return to the starting system.",
      "luminosity_class": "Luminosity Class",
      "main_fields": "Main Fields",
      "market": "Market Commodity",
      "market_capacity": "Market Capacity",
      "market_capacity_description": "The amount of capacity taken up by the commodities in your market",
      "market_fields": "Market Fields",
      "market_updated_at": "Market Last Updated At",
      "material": "Material",
      "material_fields": "Materials",
      "materials": "Materials",
      "maximum_cargo": "Maximum Cargo Capacity",
      "maximum_distance": "Maximum distance to arrival (LS)",
      "maximum_distance_description": "Maximum distance the station can be from the arrival star",
      "maximum_hop_distance": "Maximum Hop Distance",
      "maximum_hop_distance_description": "Maximum distance between hops (this can be larger than your ship range)",
      "maximum_hops": "Maximum Hops",
      "maximum_market_age": "Maximum Market Age",
      "maximum_market_age_description": "Maximum age the stations in the route can have had their market data updated",
      "maximum_systems": "Maximum Systems",
      "maximum_systems_description": "This is maximum number of systems that the plotter will route you through, lower this for a shorter trip.",
      "mean_anomaly": "Mean Anomaly",
      "medium_pads": "Medium Pads",
      "minimum_landmark_value": "Minimum value",
      "minimum_scan_value": "Minimum scan value",
      "minor_faction_presences": "Faction Presence",
      "module_capacity": "Module Capacity",
      "module_capacity_description": "The amount of capacity taken up by the modules installed on your fleet carrier",
      "module_category": "Category",
      "module_fields": "Module Fields",
      "module_price": "Module Price",
      "modules": "Modules",
      "name": "Name",
      "needs_permit": "Needs Permit",
      "neutron": "Neutron",
      "neutron_description": "Is the star a neutron star?",
      "orbital_eccentricity": "Orbital Eccentricity",
      "orbital_inclination": "Orbital Inclination",
      "orbital_period": "Orbital period (days)",
      "outfitting_updated_at": "Outfitting Last Updated At",
      "pads": "Pads",
      "permit": "Allow Permit Systems",
      "permit_description": "Allow systems which require permits to be included in the run",
      "population": "Population",
      "power": "Power",
      "power_state": "Power State",
      "primary_economy": "Economy",
      "pristine": "Pristine",
      "pristine_description": "Is the ring reserve level pristine?",
      "profit": "Profit",
      "prohibited_commodities": "Prohibited Commodities",
      "radius": "Radius",
      "range": "Range (LY)",
      "range_description": "The range of your ship.",
      "record_type": "Type",
      "reference_coordinates": "Reference Coordinates",
      "reference_route": "Reference Route",
      "reference_system": "Reference System",
      "refuel": "Refuel?",
      "refuel_description": "Do you need to refuel?",
      "region": "Region",
      "remaining": "Remaining (LY)",
      "requires_large_pad": "Requires Large Pad",
      "requires_large_pad_description": "Filter out stations which do not have a large pad",
      "reserve_level": "Reserve Level",
      "reset": "Reset",
      "restock": "Restock?",
      "restock_amount": "Restock Amount",
      "restock_amount_description": "The amount of tritium you need to aquire to restock",
      "restock_description": "Do you need to restock tritium?",
      "reverse": "Reverse Route",
      "ring_fields": "Ring filters",
      "ring_signal": "Ring Signal",
      "ring_signals": "Ring Signals",
      "rings": "Rings",
      "rotational_period": "Rotational period",
      "scanned": "Scanned",
      "scanned_description": "Uncheck to mark a body as not scanned",
      "search": "Search",
      "search_radius": "Radius (LY)",
      "search_radius_description": "This is the distance in LY around which the plotter will look for valuable worlds for you to visit.  A value of 25 LY tends to give a nice balance for A to B routes keeping the number of jumps reasonbly low whilst still giving a nice payout.  For circular routes (leaving destination blank) you will probably want to increase this to 100-500 LY.",
      "secondary_economy": "Secondary Economy",
      "security": "Security",
      "select_all": "All",
      "select_allegiance": "Select allegiance",
      "select_atmosphere": "Select atmosphere",
      "select_body_subtype": "Select body subtype",
      "select_body_type": "Select body type",
      "select_controlling_minor_faction": "Select controlling faction",
      "select_controlling_minor_faction_state": "Select controlling faction state",
      "select_destination_system": "Select destination",
      "select_export_commodities": "Select export commodities",
      "select_faction_presence": "Select faction presence",
      "select_full_spectral_class": "Select full spectral class",
      "select_genus": "Select genus",
      "select_government": "Select government",
      "select_import_commodities": "Select import commodities",
      "select_landmark_subtype": "Select landmark subtype",
      "select_landmark_type": "Select landmark type",
      "select_modules": "Select modules",
      "select_none": "None",
      "select_power": "Select power",
      "select_power_state": "Select power state",
      "select_primary_economy": "Select economy",
      "select_prohibited_commodities": "Select prohibited commodities",
      "select_reference_system": "Select reference system",
      "select_region": "Select region",
      "select_reserve_level": "Select reserve level",
      "select_ring_signal": "Select ring signal",
      "select_secondary_economy": "Select secondary economy",
      "select_security": "Select security",
      "select_services": "Select services",
      "select_ships": "Select ships",
      "select_signal": "Select body signal",
      "select_source_system": "Select source",
      "select_spectral_class": "Select spectral class",
      "select_state": "Select state",
      "select_station_type": "Select station type",
      "select_subtype": "Select subtype",
      "select_system_power": "Select system power",
      "select_terraforming_state": "Select terraforming state",
      "select_thargoid_war_failure_state": "Select Thargoid war state after failure",
      "select_thargoid_war_state": "Select Thargoid war state",
      "select_thargoid_war_success_state": "Select Thargoid war state after success",
      "select_type": "Select type",
      "select_volcanism_type": "Select volcanism",
      "sell_price": "Sell Price",
      "semi_major_axis": "Semi Major Axis",
      "services": "Services",
      "ship": "Ship",
      "ship_build": "Ship Build",
      "ship_build_description": "Paste ship build JSON exported from <a href=\"https://coriolis.io\">https://coriolis.io</a> or SLEF from <a href=\"https://edsy.org\">https://edsy.org</a>",
      "ship_price": "Ship Price",
      "ships": "Ships",
      "shipyard_updated_at": "Shipyard Last Updated At",
      "signal": "Body Signal",
      "signal_count": "Count",
      "signal_fields": "Signal filters",
      "signals": "Signals",
      "signals_updated_at": "Signals Last Updated At",
      "single_value": "Only one possible value",
      "small_pads": "Small Pads",
      "solar_masses": "Solar Masses",
      "solar_radius": "Solar Radius",
      "solid_composition": "Solid Composition",
      "sorting": "Sorting Fields",
      "sorting_fields": "Sorting",
      "source_station": "Source Station",
      "source_system": "Source System",
      "spectral_class": "Spectral Class",
      "starting_capital": "Starting Capital",
      "state": "State",
      "station_name": "Station Name",
      "station_type": "Station Type",
      "stock_history": "Stock History",
      "subtype": "Subtype",
      "summary": "Search summary",
      "supply": "Supply",
      "surface_pressure": "Surface pressure (Atmospheres)",
      "surface_temperature": "Surface temperature (K)",
      "system_name": "System Name",
      "system_power": "System Power",
      "system_power_state": "Power State",
      "system_region": "System Region",
      "system_x": "X",
      "system_y": "Y",
      "system_z": "Z",
      "terraforming_description": "Is the body a terraformable candidate?",
      "terraforming_short": "Terra",
      "terraforming_state": "Terraforming State",
      "thargoid_war_days_remaining": "Thargoid war days remaining",
      "thargoid_war_failure_state": "Thargoid war state after failure",
      "thargoid_war_ports_remaining": "Thargoid war ports remaining",
      "thargoid_war_progress": "Thargoid war progress",
      "thargoid_war_state": "Thargoid war state",
      "thargoid_war_success_reached": "Thargoid war success state reached",
      "thargoid_war_success_state": "Thargoid war state after success",
      "time": "Calculation Time",
      "time_description": "Amount of time to spend calculating the route (in seconds, more time should produce shorter routes)",
      "time_remaining": "Estimated Time Remaining",
      "time_taken": "Estimated Time Taken",
      "timestamp": "{from} to {to}",
      "total_profit": "Total Profit",
      "total_value": "Total Value",
      "tritium_fuel": "Tritium in tank",
      "tritium_fuel_description": "The amount of tritium in your fuel tank",
      "tritium_market": "Tritium in market",
      "tritium_market_description": "The amount of tritium in your commodities market",
      "type": "Type",
      "unique": "Avoid Loops",
      "unique_description": "Prevent the system revisiting stations which are already in the route",
      "updated_at": "Last Updated At",
      "use_injections": "Use FSD Injections",
      "use_injections_description": "Use FSD synthesis to boost when a neutron star is not available.",
      "use_mapping_value": "Use Mapping Value",
      "use_mapping_value_description": "Use the mapping value rather than the scan value",
      "use_supercharge": "Use Supercharge",
      "use_supercharge_description": "Use neutron stars to supercharge your FSD",
      "used_capacity": "Used Capacity",
      "used_capacity_description": "Fill in the capacity shown in the upper right corner of the carrier managment screen.",
      "via": "Via System",
      "volcanism_type": "Volcanism",
      "x": "X",
      "x_coordinate": "X Coordinate",
      "y": "Y",
      "y_coordinate": "Y Coordinate",
      "z": "Z",
      "z_coordinate": "Z Coordinate"
    },
    "fields_help": {
      "body_name": "Use this to filter for bodies with a name matching ie Sol would match Sol but not Solati 6.",
      "destination": "Use this to set the route destination",
      "reference_coords": "Use this instead of Reference System to indicate which coordinates you want to search relative to (for distances etc).",
      "reference_route": "Use this to indicate a route syou want to search relative to (for distances etc).",
      "reference_system": "Use this to indicate which system you want to search relative to (for distances etc).",
      "source": "Use this to set the route source",
      "station_name": "Use this to filter for stations with a word matching in the name",
      "system_name": "Use this to filter for bodies in a system with a word matching ie Col would match Col 285 Sector IY-W b16-6 but not Colonia."
    },
    "fleet-carrier": {
      "description": "This page will allow you to plot between two different star systems for your fleet carrier.  The result will show you every system you need to route to.  It will also let you know when you should gather more fuel.  Time estimates are based on 20 minutes per jump and yielding 150 tonnes of tritium per hour mining for a single player.",
      "import_destinations": "Import destinations",
      "import_destinations_description": "Import a waypoint file from EDSM as a set of destinations (A simple text file with one system name per line).",
      "importing": "Importing destinations",
      "move_destination_down": "Move destination down",
      "move_destination_up": "Move destination up",
      "refuel_destination": "Refuel at this waypoint",
      "refueling_destination": "{destination} (Refuel)",
      "title": "Fleet Carrier Router",
      "warning": "This router will allow you to plot a fleet carrier route between systems"
    },
    "footer": {
      "copyright": "Copyright {from}-{to} Gareth Harper.",
      "donations": "If you would like to donate towards keeping this site updated and running you may check out my patreon page <a href=\"https://www.patreon.com/spansh\">here</a>.",
      "features": "If you have feature requests or find bugs you can visit the <a href=\"https://forums.frontier.co.uk/threads/neutron-highway-long-range-route-planner.308674/\">forum thread</a> for this tool or you can email <a href=\"mailto:plotter@spansh.co.uk\">plotter@spansh.co.uk</a>.",
      "thanks": "Thanks to all of the players and explorers who provide the data which powers this website."
    },
    "genuses": {
      "Aleoids": "Aleoids",
      "Amphora Plants": "Amphora Plants",
      "Anemones": "Anemones",
      "Bacterial": "Bacterial",
      "Bark Mounds": "Bark Mounds",
      "Brain Trees": "Brain Trees",
      "Cactoids": "Cactoids",
      "Clypeus": "Clypeus",
      "Conchas": "Conchas",
      "Crystalline Shards": "Crystalline Shards",
      "Electricae": "Electricae",
      "Fonticulus": "Fonticulus",
      "Fumerolas": "Fumerolas",
      "Fungoids": "Fungoids",
      "Osseus": "Osseus",
      "Recepta": "Recepta",
      "Shrubs": "Shrubs",
      "Stratum": "Stratum",
      "Tubers": "Tubers",
      "Tubus": "Tubus",
      "Tussocks": "Tussocks"
    },
    "goals": {
      "bounty": "Bounty",
      "carto": "Universal Cartographics",
      "combatbond": "Combat Bond",
      "expires": "Finishes {date}",
      "fetch_error": "Could not fetch goals from Frontier",
      "fetching": "Fetching goals from Frontier",
      "mining": "Mining",
      "none": "No currently listed community goals",
      "research": "Research",
      "trade": "Bring {commodity} to {station} in {system}",
      "tradelist": "Trading"
    },
    "government": {
      "Anarchy": "Anarchy",
      "Communism": "Communism",
      "Confederacy": "Confederacy",
      "Cooperative": "Cooperative",
      "Corporate": "Corporate",
      "Democracy": "Democracy",
      "Dictatorship": "Dictatorship",
      "Engineer": "Engineer",
      "Feudal": "Feudal",
      "None": "None",
      "Patronage": "Patronage",
      "Prison": "Prison",
      "Prison Colony": "Prison Colony",
      "Private Ownership": "Private Ownership",
      "Theocracy": "Theocracy",
      "Workshop (Engineer)": "Workshop (Engineer)"
    },
    "landmark_subtype": {
      "Abandoned Base": "Abandoned Base",
      "Albens Bell Mollusc": "Albens Bell Mollusc",
      "Albidum Chalice Pod": "Albidum Chalice Pod",
      "Albidum Ice Crystals": "Albidum Ice Crystals",
      "Albidum Peduncle Tree": "Albidum Peduncle Tree",
      "Albidum Quadripartite Pod": "Albidum Quadripartite Pod",
      "Albidum Silicate Crystals": "Albidum Silicate Crystals",
      "Albidum Sinuous Tubers": "Albidum Sinuous Tubers",
      "Albulum Gourd Mollusc": "Albulum Gourd Mollusc",
      "Aleoida Arcus": "Aleoida Arcus",
      "Aleoida Coronamus": "Aleoida Coronamus",
      "Aleoida Gravis": "Aleoida Gravis",
      "Aleoida Laminiae": "Aleoida Laminiae",
      "Aleoida Spica": "Aleoida Spica",
      "Ammonia Ice Fumarole": "Ammonia Ice Fumarole",
      "Ammonia Ice Geyser": "Ammonia Ice Geyser",
      "Amphora Plant": "Amphora Plant",
      "Aurarium Gyre Pod": "Aurarium Gyre Pod",
      "Aurarium Gyre Tree": "Aurarium Gyre Tree",
      "Aureum Brain Tree": "Aureum Brain Tree",
      "Bacterium Acies": "Bacterium Acies",
      "Bacterium Alcyoneum": "Bacterium Alcyoneum",
      "Bacterium Aurasus": "Bacterium Aurasus",
      "Bacterium Bullaris": "Bacterium Bullaris",
      "Bacterium Cerbrus": "Bacterium Cerbrus",
      "Bacterium Informem": "Bacterium Informem",
      "Bacterium Nebulus": "Bacterium Nebulus",
      "Bacterium Omentum": "Bacterium Omentum",
      "Bacterium Scopulum": "Bacterium Scopulum",
      "Bacterium Tela": "Bacterium Tela",
      "Bacterium Verrata": "Bacterium Verrata",
      "Bacterium Vesicula": "Bacterium Vesicula",
      "Bacterium Volu": "Bacterium Volu",
      "Bark Mounds": "Bark Mounds",
      "Bear": "Bear",
      "Blatteum Bell Mollusc": "Blatteum Bell Mollusc",
      "Blatteum Bioluminescent Anemone": "Blatteum Bioluminescent Anemone",
      "Blatteum Collared Pod": "Blatteum Collared Pod",
      "Blatteum Quadripartite Pod": "Blatteum Quadripartite Pod",
      "Blatteum Sinuous Tubers": "Blatteum Sinuous Tubers",
      "Bowl": "Bowl",
      "Cactoida Cortexum": "Cactoida Cortexum",
      "Cactoida Lapis": "Cactoida Lapis",
      "Cactoida Peperatis": "Cactoida Peperatis",
      "Cactoida Pullulanta": "Cactoida Pullulanta",
      "Cactoida Vermis": "Cactoida Vermis",
      "Caeruleum Chalice Pod": "Caeruleum Chalice Pod",
      "Caeruleum Lagrange Cloud": "Caeruleum Lagrange Cloud",
      "Caeruleum Octahedral Pod": "Caeruleum Octahedral Pod",
      "Caeruleum Peduncle Pod": "Caeruleum Peduncle Pod",
      "Caeruleum Peduncle Tree": "Caeruleum Peduncle Tree",
      "Caeruleum Quadripartite Pod": "Caeruleum Quadripartite Pod",
      "Caeruleum Sinuous Tubers": "Caeruleum Sinuous Tubers",
      "Caeruleum Torus Mollusc": "Caeruleum Torus Mollusc",
      "Candidum Peduncle Pod": "Candidum Peduncle Pod",
      "Candidum Rhizome Pod": "Candidum Rhizome Pod",
      "Carbon Dioxide Fumarole": "Carbon Dioxide Fumarole",
      "Carbon Dioxide Gas Vent": "Carbon Dioxide Gas Vent",
      "Carbon Dioxide Ice Fumarole": "Carbon Dioxide Ice Fumarole",
      "Carbon Dioxide Ice Geyser": "Carbon Dioxide Ice Geyser",
      "Cereum Aster Pod": "Cereum Aster Pod",
      "Cereum Aster Tree": "Cereum Aster Tree",
      "Cereum Bullet Mollusc": "Cereum Bullet Mollusc",
      "Clypeus Lacrimam": "Clypeus Lacrimam",
      "Clypeus Margaritus": "Clypeus Margaritus",
      "Clypeus Speculumi": "Clypeus Speculumi",
      "Cobalteum Globe Mollusc": "Cobalteum Globe Mollusc",
      "Cobalteum Rhizome Pod": "Cobalteum Rhizome Pod",
      "Common Thargoid Barnacle": "Common Thargoid Barnacle",
      "Concha Aureolas": "Concha Aureolas",
      "Concha Biconcavis": "Concha Biconcavis",
      "Concha Labiata": "Concha Labiata",
      "Concha Renibus": "Concha Renibus",
      "Crashed Ship": "Crashed Ship",
      "Crater Outpost": "Crater Outpost",
      "Crater Port": "Crater Port",
      "Croceum Anemone": "Croceum Anemone",
      "Croceum Globe Mollusc": "Croceum Globe Mollusc",
      "Croceum Gourd Mollusc": "Croceum Gourd Mollusc",
      "Croceum Lagrange Cloud": "Croceum Lagrange Cloud",
      "Croceum Lagrange Storm Cloud": "Croceum Lagrange Storm Cloud",
      "Crossroads": "Crossroads",
      "Crystalline Shards": "Crystalline Shards",
      "E02-Type Anomaly": "E02-Type Anomaly",
      "E03-Type Anomaly": "E03-Type Anomaly",
      "E04-Type Anomaly": "E04-Type Anomaly",
      "Electricae Pluma": "Electricae Pluma",
      "Electricae Radialem": "Electricae Radialem",
      "Fistbump": "Fistbump",
      "Flavum Bullet Mollusc": "Flavum Bullet Mollusc",
      "Flavum Ice Crystals": "Flavum Ice Crystals",
      "Flavum Metallic Crystals": "Flavum Metallic Crystals",
      "Flavum Silicate Crystals": "Flavum Silicate Crystals",
      "Fonticulua Campestris": "Fonticulua Campestris",
      "Fonticulua Digitos": "Fonticulua Digitos",
      "Fonticulua Fluctus": "Fonticulua Fluctus",
      "Fonticulua Lapida": "Fonticulua Lapida",
      "Fonticulua Segmentatus": "Fonticulua Segmentatus",
      "Fonticulua Upupam": "Fonticulua Upupam",
      "Frutexa Acus": "Frutexa Acus",
      "Frutexa Collum": "Frutexa Collum",
      "Frutexa Fera": "Frutexa Fera",
      "Frutexa Flabellum": "Frutexa Flabellum",
      "Frutexa Flammasis": "Frutexa Flammasis",
      "Frutexa Metallicum": "Frutexa Metallicum",
      "Frutexa Sponsae": "Frutexa Sponsae",
      "Fumerola Aquatis": "Fumerola Aquatis",
      "Fumerola Carbosis": "Fumerola Carbosis",
      "Fumerola Extremus": "Fumerola Extremus",
      "Fumerola Nitris": "Fumerola Nitris",
      "Fungoida Bullarum": "Fungoida Bullarum",
      "Fungoida Gelata": "Fungoida Gelata",
      "Fungoida Setisis": "Fungoida Setisis",
      "Fungoida Stabitis": "Fungoida Stabitis",
      "Green Class I Gas Giant": "Green Class I Gas Giant",
      "Green Class II Gas Giant": "Green Class II Gas Giant",
      "Green Class III Gas Giant": "Green Class III Gas Giant",
      "Green Class IV Gas Giant": "Green Class IV Gas Giant",
      "Green Gas Giant with Ammonia Life": "Green Gas Giant with Ammonia Life",
      "Green Water Giant": "Green Water Giant",
      "Guardian Beacon": "Guardian Beacon",
      "Guardian Codex": "Guardian Codex",
      "Guardian Data Terminal": "Guardian Data Terminal",
      "Guardian Pylon": "Guardian Pylon",
      "Guardian Relic Tower": "Guardian Relic Tower",
      "Guardian Sentinel": "Guardian Sentinel",
      "Gypseeum Bell Mollusc": "Gypseeum Bell Mollusc",
      "Gypseeum Brain Tree": "Gypseeum Brain Tree",
      "Gypseeum Peduncle Pod": "Gypseeum Peduncle Pod",
      "Gypseeum Rhizome Pod": "Gypseeum Rhizome Pod",
      "Hammerbot": "Hammerbot",
      "Installation": "Installation",
      "Iron Magma Lava Spout": "Iron Magma Lava Spout",
      "K01-Type Anomaly": "K01-Type Anomaly",
      "K03-Type Anomaly": "K03-Type Anomaly",
      "K04-Type Anomaly": "K04-Type Anomaly",
      "K06-Type Anomaly": "K06-Type Anomaly",
      "K08-Type Anomaly": "K08-Type Anomaly",
      "K09-Type Anomaly": "K09-Type Anomaly",
      "K12-Type Anomaly": "K12-Type Anomaly",
      "K13-Type Anomaly": "K13-Type Anomaly",
      "L01-Type Anomaly": "L01-Type Anomaly",
      "L06-Type Anomaly": "L06-Type Anomaly",
      "L07-Type Anomaly": "L07-Type Anomaly",
      "L08-Type Anomaly": "L08-Type Anomaly",
      "L09-Type Anomaly": "L09-Type Anomaly",
      "Lacrosse": "Lacrosse",
      "Lattice Mineral Spheres": "Lattice Mineral Spheres",
      "Lindigoticum Aster Pod": "Lindigoticum Aster Pod",
      "Lindigoticum Bell Mollusc": "Lindigoticum Bell Mollusc",
      "Lindigoticum Brain Tree": "Lindigoticum Brain Tree",
      "Lindigoticum Bulb Mollusc": "Lindigoticum Bulb Mollusc",
      "Lindigoticum Calcite Plates": "Lindigoticum Calcite Plates",
      "Lindigoticum Capsule Mollusc": "Lindigoticum Capsule Mollusc",
      "Lindigoticum Ice Crystals": "Lindigoticum Ice Crystals",
      "Lindigoticum Parasol Mollusc": "Lindigoticum Parasol Mollusc",
      "Lindigoticum Reel Mollusc": "Lindigoticum Reel Mollusc",
      "Lindigoticum Silicate Crystals": "Lindigoticum Silicate Crystals",
      "Lindigoticum Sinuous Tubers": "Lindigoticum Sinuous Tubers",
      "Lindigoticum Umbrella Mollusc": "Lindigoticum Umbrella Mollusc",
      "Lividum Brain Tree": "Lividum Brain Tree",
      "Lividum Bullet Mollusc": "Lividum Bullet Mollusc",
      "Lividum Collared Pod": "Lividum Collared Pod",
      "Luteolum Anemone": "Luteolum Anemone",
      "Luteolum Bell Mollusc": "Luteolum Bell Mollusc",
      "Luteolum Bulb Mollusc": "Luteolum Bulb Mollusc",
      "Luteolum Calcite Plates": "Luteolum Calcite Plates",
      "Luteolum Capsule Mollusc": "Luteolum Capsule Mollusc",
      "Luteolum Lagrange Cloud": "Luteolum Lagrange Cloud",
      "Luteolum Lagrange Storm Cloud": "Luteolum Lagrange Storm Cloud",
      "Luteolum Parasol Mollusc": "Luteolum Parasol Mollusc",
      "Luteolum Reel Mollusc": "Luteolum Reel Mollusc",
      "Luteolum Umbrella Mollusc": "Luteolum Umbrella Mollusc",
      "Methane Ice Fumarole": "Methane Ice Fumarole",
      "Methane Ice Geyser": "Methane Ice Geyser",
      "Nitrogen Ice Fumarole": "Nitrogen Ice Fumarole",
      "Nitrogen Ice Geyser": "Nitrogen Ice Geyser",
      "Niveum Globe Mollusc": "Niveum Globe Mollusc",
      "Niveum Octahedral Pod": "Niveum Octahedral Pod",
      "Osseus Cornibus": "Osseus Cornibus",
      "Osseus Discus": "Osseus Discus",
      "Osseus Fractus": "Osseus Fractus",
      "Osseus Pellebantus": "Osseus Pellebantus",
      "Osseus Pumice": "Osseus Pumice",
      "Osseus Spiralis": "Osseus Spiralis",
      "Ostrinum Brain Tree": "Ostrinum Brain Tree",
      "Ostrinum Chalice Pod": "Ostrinum Chalice Pod",
      "Ostrinum Globe Mollusc": "Ostrinum Globe Mollusc",
      "Ostrinum Peduncle Tree": "Ostrinum Peduncle Tree",
      "P01-Type Anomaly": "P01-Type Anomaly",
      "P02-Type Anomaly": "P02-Type Anomaly",
      "P03-Type Anomaly": "P03-Type Anomaly",
      "P04-Type Anomaly": "P04-Type Anomaly",
      "P05-Type Anomaly": "P05-Type Anomaly",
      "P07-Type Anomaly": "P07-Type Anomaly",
      "P09-Type Anomaly": "P09-Type Anomaly",
      "P11-Type Anomaly": "P11-Type Anomaly",
      "P12-Type Anomaly": "P12-Type Anomaly",
      "P13-Type Anomaly": "P13-Type Anomaly",
      "P14-Type Anomaly": "P14-Type Anomaly",
      "P15-Type Anomaly": "P15-Type Anomaly",
      "Phoeniceum Gourd Mollusc": "Phoeniceum Gourd Mollusc",
      "Prasinum Aster Tree": "Prasinum Aster Tree",
      "Prasinum Bioluminescent Anemone": "Prasinum Bioluminescent Anemone",
      "Prasinum Globe Mollusc": "Prasinum Globe Mollusc",
      "Prasinum Ice Crystals": "Prasinum Ice Crystals",
      "Prasinum Metallic Crystals": "Prasinum Metallic Crystals",
      "Prasinum Silicate Crystals": "Prasinum Silicate Crystals",
      "Prasinum Sinuous Tubers": "Prasinum Sinuous Tubers",
      "Proto-Lagrange Cloud": "Proto-Lagrange Cloud",
      "Puniceum Anemone": "Puniceum Anemone",
      "Puniceum Brain Tree": "Puniceum Brain Tree",
      "Purpureum Gourd Mollusc": "Purpureum Gourd Mollusc",
      "Purpureum Ice Crystals": "Purpureum Ice Crystals",
      "Purpureum Metallic Crystals": "Purpureum Metallic Crystals",
      "Purpureum Peduncle Pod": "Purpureum Peduncle Pod",
      "Purpureum Silicate Crystals": "Purpureum Silicate Crystals",
      "Q02-Type Anomaly": "Q02-Type Anomaly",
      "Q06-Type Anomaly": "Q06-Type Anomaly",
      "Q08-Type Anomaly": "Q08-Type Anomaly",
      "Q09-Type Anomaly": "Q09-Type Anomaly",
      "Recepta Conditivus": "Recepta Conditivus",
      "Recepta Deltahedronix": "Recepta Deltahedronix",
      "Recepta Umbrux": "Recepta Umbrux",
      "Robolobster": "Robolobster",
      "Roseum Anemone": "Roseum Anemone",
      "Roseum Bioluminescent Anemone": "Roseum Bioluminescent Anemone",
      "Roseum Brain Tree": "Roseum Brain Tree",
      "Roseum Globe Mollusc": "Roseum Globe Mollusc",
      "Roseum Gyre Pod": "Roseum Gyre Pod",
      "Roseum Ice Crystals": "Roseum Ice Crystals",
      "Roseum Lagrange Cloud": "Roseum Lagrange Cloud",
      "Roseum Lagrange Storm Cloud": "Roseum Lagrange Storm Cloud",
      "Roseum Silicate Crystals": "Roseum Silicate Crystals",
      "Roseum Sinuous Tubers": "Roseum Sinuous Tubers",
      "Roseus Sinuous Tubers": "Roseus Sinuous Tubers",
      "Rubellum Aster Pod": "Rubellum Aster Pod",
      "Rubellum Chalice Pod": "Rubellum Chalice Pod",
      "Rubellum Peduncle Tree": "Rubellum Peduncle Tree",
      "Rubeum Bioluminescent Anemone": "Rubeum Bioluminescent Anemone",
      "Rubeum Bullet Mollusc": "Rubeum Bullet Mollusc",
      "Rubeum Ice Crystals": "Rubeum Ice Crystals",
      "Rubeum Metallic Crystals": "Rubeum Metallic Crystals",
      "Rubeum Rhizome Pod": "Rubeum Rhizome Pod",
      "Rubeum Silicate Crystals": "Rubeum Silicate Crystals",
      "Rubicundum Collared Pod": "Rubicundum Collared Pod",
      "Rubicundum Lagrange Cloud": "Rubicundum Lagrange Cloud",
      "Rubicundum Lagrange Storm Cloud": "Rubicundum Lagrange Storm Cloud",
      "Rufum Gourd Mollusc": "Rufum Gourd Mollusc",
      "Rufum Peduncle Pod": "Rufum Peduncle Pod",
      "Rutulum Globe Mollusc": "Rutulum Globe Mollusc",
      "Settlement": "Settlement",
      "Silicate Magma Lava Spout": "Silicate Magma Lava Spout",
      "Silicate Vapour Fumarole": "Silicate Vapour Fumarole",
      "Silicate Vapour Gas Vent": "Silicate Vapour Gas Vent",
      "Silicate Vapour Ice Fumarole": "Silicate Vapour Ice Fumarole",
      "Solid Mineral Spheres": "Solid Mineral Spheres",
      "Squid": "Squid",
      "Stolon Pod": "Stolon Pod",
      "Stratum Araneamus": "Stratum Araneamus",
      "Stratum Cucumisis": "Stratum Cucumisis",
      "Stratum Excutitus": "Stratum Excutitus",
      "Stratum Frigus": "Stratum Frigus",
      "Stratum Laminamus": "Stratum Laminamus",
      "Stratum Limaxus": "Stratum Limaxus",
      "Stratum Paleas": "Stratum Paleas",
      "Stratum Tectonicas": "Stratum Tectonicas",
      "Sulphur Dioxide Fumarole": "Sulphur Dioxide Fumarole",
      "Sulphur Dioxide Gas Vent": "Sulphur Dioxide Gas Vent",
      "Sulphur Dioxide Ice Fumarole": "Sulphur Dioxide Ice Fumarole",
      "Surface Station": "Surface Station",
      "T01-Type Anomaly": "T01-Type Anomaly",
      "T04-Type Anomaly": "T04-Type Anomaly",
      "Thargoid Barnacle Barbs": "Thargoid Barnacle Barbs",
      "Thargoid Caustic Generator": "Thargoid Caustic Generator",
      "Thargoid Device": "Thargoid Device",
      "Thargoid Interceptor Shipwreck": "Thargoid Interceptor Shipwreck",
      "Thargoid Pod": "Thargoid Pod",
      "Thargoid Scavengers": "Thargoid Scavengers",
      "Thargoid Scout Shipwreck": "Thargoid Scout Shipwreck",
      "Thargoid Structure": "Thargoid Structure",
      "Thargoid Uplink Device": "Thargoid Uplink Device",
      "Tourist Beacon": "Tourist Beacon",
      "Tubus Cavas": "Tubus Cavas",
      "Tubus Compagibus": "Tubus Compagibus",
      "Tubus Conifer": "Tubus Conifer",
      "Tubus Rosarium": "Tubus Rosarium",
      "Tubus Sororibus": "Tubus Sororibus",
      "Turtle": "Turtle",
      "Tussock Albata": "Tussock Albata",
      "Tussock Capillum": "Tussock Capillum",
      "Tussock Caputus": "Tussock Caputus",
      "Tussock Catena": "Tussock Catena",
      "Tussock Cultro": "Tussock Cultro",
      "Tussock Divisa": "Tussock Divisa",
      "Tussock Ignis": "Tussock Ignis",
      "Tussock Pennata": "Tussock Pennata",
      "Tussock Pennatis": "Tussock Pennatis",
      "Tussock Propagito": "Tussock Propagito",
      "Tussock Serrati": "Tussock Serrati",
      "Tussock Stigmasis": "Tussock Stigmasis",
      "Tussock Triticum": "Tussock Triticum",
      "Tussock Ventusa": "Tussock Ventusa",
      "Tussock Virgam": "Tussock Virgam",
      "Unknown": "Unknown",
      "Violaceum Sinuous Tubers": "Violaceum Sinuous Tubers",
      "Viride Brain Tree": "Viride Brain Tree",
      "Viride Bulb Mollusc": "Viride Bulb Mollusc",
      "Viride Bullet Mollusc": "Viride Bullet Mollusc",
      "Viride Capsule Mollusc": "Viride Capsule Mollusc",
      "Viride Chalice Pod": "Viride Chalice Pod",
      "Viride Gyre Tree": "Viride Gyre Tree",
      "Viride Lagrange Cloud": "Viride Lagrange Cloud",
      "Viride Lagrange Storm Cloud": "Viride Lagrange Storm Cloud",
      "Viride Peduncle Tree": "Viride Peduncle Tree",
      "Viride Reel Mollusc": "Viride Reel Mollusc",
      "Viride Sinuous Tubers": "Viride Sinuous Tubers",
      "Viride Umbrella Mollusc": "Viride Umbrella Mollusc",
      "Water Fumarole": "Water Fumarole",
      "Water Gas Vent": "Water Gas Vent",
      "Water Geyser": "Water Geyser",
      "Water Ice Fumarole": "Water Ice Fumarole",
      "Water Ice Geyser": "Water Ice Geyser",
      "Wrecked Ship": "Wrecked Ship"
    },
    "landmark_type": {
      "Abandoned Base": "Abandoned Base",
      "Aleoida": "Aleoida",
      "Amphora Plant": "Amphora Plant",
      "Anemone": "Anemone",
      "Aster": "Aster",
      "Bacterium": "Bacterium",
      "Bark Mounds": "Bark Mounds",
      "Brain Tree": "Brain Tree",
      "Cactoida": "Cactoida",
      "Calcite Plates": "Calcite Plates",
      "Chalice Pod": "Chalice Pod",
      "Clypeus": "Clypeus",
      "Collared Pod": "Collared Pod",
      "Concha": "Concha",
      "Crashed Ship": "Crashed Ship",
      "Crystalline Shard": "Crystalline Shard",
      "E-Type Anomaly": "E-Type Anomaly",
      "Electricae": "Electricae",
      "Fonticulua": "Fonticulua",
      "Frutexa": "Frutexa",
      "Fumarole": "Fumarole",
      "Fumerola": "Fumerola",
      "Fungal Gourd": "Fungal Gourd",
      "Fungoida": "Fungoida",
      "Gas Vent": "Gas Vent",
      "Geyser": "Geyser",
      "Guardian": "Guardian",
      "Guardian Ruin": "Guardian Ruin",
      "Guardian Structure": "Guardian Structure",
      "Gyre": "Gyre",
      "Ice Crystals": "Ice Crystals",
      "K-Type Anomaly": "K-Type Anomaly",
      "L-Type Anomaly": "L-Type Anomaly",
      "Lagrange Cloud": "Lagrange Cloud",
      "Lava Spout": "Lava Spout",
      "Metallic Crystals": "Metallic Crystals",
      "Mineral Spheres": "Mineral Spheres",
      "Mollusc": "Mollusc",
      "Osseus": "Osseus",
      "P-Type Anomaly": "P-Type Anomaly",
      "Peduncle": "Peduncle",
      "Planets": "Planets",
      "Q-Type Anomaly": "Q-Type Anomaly",
      "Quadripartite": "Quadripartite",
      "Recepta": "Recepta",
      "Rhizome": "Rhizome",
      "Shards": "Shards",
      "Silicate Crystals": "Silicate Crystals",
      "Stolon": "Stolon",
      "Storm Cloud": "Storm Cloud",
      "Stratum": "Stratum",
      "Surface Station": "Surface Station",
      "T-Type Anomaly": "T-Type Anomaly",
      "Thargoid": "Thargoid",
      "Thargoid Barnacle": "Thargoid Barnacle",
      "Thargoid Structure": "Thargoid Structure",
      "Tourist Beacon": "Tourist Beacon",
      "Tubers": "Tubers",
      "Tubus": "Tubus",
      "Tussock": "Tussock",
      "Void": "Void",
      "Wrecked Ship": "Wrecked Ship"
    },
    "map": {
      "description": "A map of all known neutron stars in the galaxy.",
      "title": "Neutron Star Map"
    },
    "market": {
      "buy_price": "Buy price",
      "buy_price_header": "{commodity} buy price",
      "demand": "Demand",
      "demand_header": "{commodity} demand",
      "remove_field": "Remove filter",
      "sell_price": "Sell price",
      "sell_price_header": "{commodity} sell price",
      "supply": "Supply",
      "supply_header": "{commodity} supply"
    },
    "material": {
      "Antimony": "Antimony",
      "Arsenic": "Arsenic",
      "Cadmium": "Cadmium",
      "Carbon": "Carbon",
      "Chromium": "Chromium",
      "Germanium": "Germanium",
      "Iron": "Iron",
      "Manganese": "Manganese",
      "Mercury": "Mercury",
      "Molybdenum": "Molybdenum",
      "Nickel": "Nickel",
      "Niobium": "Niobium",
      "Phosphorus": "Phosphorus",
      "Polonium": "Polonium",
      "Ruthenium": "Ruthenium",
      "Selenium": "Selenium",
      "Sulphur": "Sulphur",
      "Technetium": "Technetium",
      "Tellurium": "Tellurium",
      "Tin": "Tin",
      "Tungsten": "Tungsten",
      "Vanadium": "Vanadium",
      "Yttrium": "Yttrium",
      "Zinc": "Zinc",
      "Zirconium": "Zirconium"
    },
    "modules": {
      "add_field": "Add filter",
      "categories": {
        "hardpoint": "Hardpoint",
        "internal": "Internal",
        "standard": "Standard",
        "utility": "Utility"
      },
      "class": "Class",
      "name": "Module name",
      "names": {
        "AX Missile Rack": "AX Missile Rack",
        "AX Multi-Cannon": "AX Multi-Cannon",
        "Abrasion Blaster": "Abrasion Blaster",
        "Advanced Docking Computer": "Advanced Docking Computer",
        "Advanced Missile Rack": "Advanced Missile Rack",
        "Advanced Multi-Cannon": "Advanced Multi-Cannon",
        "Advanced Planetary Approach Suite": "Advanced Planetary Approach Suite",
        "Advanced Plasma Accelerator": "Advanced Plasma Accelerator",
        "Auto Field-Maintenance Unit": "Auto Field-Maintenance Unit",
        "Beam Laser": "Beam Laser",
        "Bi-Weave Shield Generator": "Bi-Weave Shield Generator",
        "Burst Laser": "Burst Laser",
        "Business Class Passenger Cabin": "Business Class Passenger Cabin",
        "Cannon": "Cannon",
        "Cargo Rack": "Cargo Rack",
        "Cargo Scanner": "Cargo Scanner",
        "Caustic Sink Launcher": "Caustic Sink Launcher",
        "Chaff Launcher": "Chaff Launcher",
        "Collector Limpet Controller": "Collector Limpet Controller",
        "Corrosion Resistant Cargo Rack": "Corrosion Resistant Cargo Rack",
        "Cytoscrambler Burst Laser": "Cytoscrambler Burst Laser",
        "Decontamination Limpet Controller": "Decontamination Limpet Controller",
        "Detailed Surface Scanner": "Detailed Surface Scanner",
        "Economy Class Passenger Cabin": "Economy Class Passenger Cabin",
        "Electronic Countermeasure": "Electronic Countermeasure",
        "Enforcer Cannon": "Enforcer Cannon",
        "Enhanced AX Missile Rack": "Enhanced AX Missile Rack",
        "Enhanced AX Multi-Cannon": "Enhanced AX Multi-Cannon",
        "Enhanced Performance Thrusters": "Enhanced Performance Thrusters",
        "Enhanced Xeno Scanner": "Enhanced Xeno Scanner",
        "Enzyme Missile Rack": "Enzyme Missile Rack",
        "Experimental Weapon Stabiliser": "Experimental Weapon Stabiliser",
        "Fighter Hangar": "Fighter Hangar",
        "First Class Passenger Cabin": "First Class Passenger Cabin",
        "Fragment Cannon": "Fragment Cannon",
        "Frame Shift Drive": "Frame Shift Drive",
        "Frame Shift Drive Interdictor": "Frame Shift Drive Interdictor",
        "Frame Shift Wake Scanner": "Frame Shift Wake Scanner",
        "Fuel Scoop": "Fuel Scoop",
        "Fuel Tank": "Fuel Tank",
        "Fuel Transfer Limpet Controller": "Fuel Transfer Limpet Controller",
        "Guardian FSD Booster": "Guardian FSD Booster",
        "Guardian Gauss Cannon": "Guardian Gauss Cannon",
        "Guardian Hull Reinforcement": "Guardian Hull Reinforcement",
        "Guardian Hybrid Power Distributor": "Guardian Hybrid Power Distributor",
        "Guardian Hybrid Power Plant": "Guardian Hybrid Power Plant",
        "Guardian Module Reinforcement": "Guardian Module Reinforcement",
        "Guardian Plasma Charger": "Guardian Plasma Charger",
        "Guardian Shard Cannon": "Guardian Shard Cannon",
        "Guardian Shield Reinforcement": "Guardian Shield Reinforcement",
        "Hatch Breaker Limpet Controller": "Hatch Breaker Limpet Controller",
        "Heat Sink Launcher": "Heat Sink Launcher",
        "Hull Reinforcement Package": "Hull Reinforcement Package",
        "Imperial Hammer Rail Gun": "Imperial Hammer Rail Gun",
        "Kill Warrant Scanner": "Kill Warrant Scanner",
        "Life Support": "Life Support",
        "Lightweight Alloy": "Lightweight Alloy",
        "Limpet Control": "Limpet Control",
        "Luxury Class Passenger Cabin": "Luxury Class Passenger Cabin",
        "Meta Alloy Hull Reinforcement": "Meta Alloy Hull Reinforcement",
        "Military Grade Composite": "Military Grade Composite",
        "Mine Launcher": "Mine Launcher",
        "Mining Lance": "Mining Lance",
        "Mining Laser": "Mining Laser",
        "Mining Multi Limpet Controller": "Mining Multi Limpet Controller",
        "Mirrored Surface Composite": "Mirrored Surface Composite",
        "Missile Rack": "Missile Rack",
        "Module Reinforcement Package": "Module Reinforcement Package",
        "Multi-Cannon": "Multi-Cannon",
        "Operations Multi Limpet Controller": "Operations Multi Limpet Controller",
        "Pacifier Frag-Cannon": "Pacifier Frag-Cannon",
        "Pack-Hound Missile Rack": "Pack-Hound Missile Rack",
        "Planetary Approach Suite": "Planetary Approach Suite",
        "Planetary Vehicle Hangar": "Planetary Vehicle Hangar",
        "Plasma Accelerator": "Plasma Accelerator",
        "Point Defence": "Point Defence",
        "Power Distributor": "Power Distributor",
        "Power Plant": "Power Plant",
        "Prismatic Shield Generator": "Prismatic Shield Generator",
        "Prospector Limpet Controller": "Prospector Limpet Controller",
        "Pulse Disruptor Laser": "Pulse Disruptor Laser",
        "Pulse Laser": "Pulse Laser",
        "Pulse Wave Analyser": "Pulse Wave Analyser",
        "Rail Gun": "Rail Gun",
        "Reactive Surface Composite": "Reactive Surface Composite",
        "Recon Limpet Controller": "Recon Limpet Controller",
        "Refinery": "Refinery",
        "Reinforced Alloy": "Reinforced Alloy",
        "Remote Release Flak Launcher": "Remote Release Flak Launcher",
        "Remote Release Flechette Launcher": "Remote Release Flechette Launcher",
        "Repair Limpet Controller": "Repair Limpet Controller",
        "Rescue Multi Limpet Controller": "Rescue Multi Limpet Controller",
        "Research Limpet Controller": "Research Limpet Controller",
        "Retributor Beam Laser": "Retributor Beam Laser",
        "Rocket Propelled FSD Disruptor": "Rocket Propelled FSD Disruptor",
        "Seeker Missile Rack": "Seeker Missile Rack",
        "Seismic Charge Launcher": "Seismic Charge Launcher",
        "Sensors": "Sensors",
        "Shield Booster": "Shield Booster",
        "Shield Cell Bank": "Shield Cell Bank",
        "Shield Generator": "Shield Generator",
        "Shock Cannon": "Shock Cannon",
        "Shock Mine Launcher": "Shock Mine Launcher",
        "Shutdown Field Neutraliser": "Shutdown Field Neutraliser",
        "Standard Docking Computer": "Standard Docking Computer",
        "Sub-Surface Displacement Missile": "Sub-Surface Displacement Missile",
        "Supercruise Assist": "Supercruise Assist",
        "Thrusters": "Thrusters",
        "Torpedo Pylon": "Torpedo Pylon",
        "Universal Multi Limpet Controller": "Universal Multi Limpet Controller",
        "Xeno Multi Limpet Controller": "Xeno Multi Limpet Controller",
        "Xeno Scanner": "Xeno Scanner"
      },
      "rating": "Rating",
      "remove_field": "Remove filter",
      "select_class": "Select class",
      "select_name": "Select module name",
      "select_rating": "Select rating"
    },
    "navigation": {
      "admin": "Admin",
      "ammonia": "Ammonia World Route",
      "bodies": "Bodies",
      "community-goals": "Community Goals",
      "donations": "Donations",
      "dumps": "Data Dumps",
      "earth": "Earth-like World Route",
      "exact-plotter": "Galaxy Plotter",
      "exobiology": "Expressway to Exomastery",
      "fleet-carrier": "Fleet Carrier Router",
      "helpers": "Helpers",
      "language": "Language",
      "login": "Login",
      "logout": "Logout",
      "map": "Map",
      "nearest": "Nearest System",
      "new": "(New)",
      "oldsite": "Old site",
      "plotter": "Neutron Plotter",
      "riches": "Road to Riches",
      "routers": "Route Planners",
      "search": "Search",
      "settings": "Settings",
      "stations": "Stations",
      "systems": "Systems",
      "thanks": "Thanks",
      "theme": "Theme",
      "toggle": "Toggle navigation",
      "tourist": "Tourist Planner",
      "trade": "Trade Planner"
    },
    "nearest": {
      "description": "This page will allow you find the nearest known system to a set of coordinates.",
      "title": "Nearest Known System"
    },
    "no": "No",
    "plotter": {
      "description": "This page will allow you to plot between two different star systems.  The result will show you every time you need to go to the galaxy map in order to plot a new route (for instance when you are at a neutron star).  It will prioritise neutron stars so should be avoided for journeys which are lower than 500 light years (It will likely try to route you to Jackson's Lighthouse first if you're in the bubble).",
      "exact_plotter": "If you are looking for a more complete plotter which includes refuelling, secondary neutron stars and FSD injection then use the new <a href=\"{link}\">Galaxy Plotter</a>",
      "title": "Neutron Router",
      "warning": "If you have a low maximum range (10-20 LY) you may end up using a boosted jump into a system which you do not have enough range to jump out of.  You should be careful when plotting using this and check that you can get out of the system when you are on the galaxy map."
    },
    "power_state": {
      "Contested": "Contested",
      "Control": "Control",
      "Controlled": "Controlled",
      "Exploited": "Exploited",
      "HomeSystem": "Home System",
      "InPrepareRadius": "In Prepare Radius",
      "Prepared": "Prepared",
      "Turmoil": "Turmoil"
    },
    "region": {
      "Acheron": "Acheron",
      "Achilles's Altar": "Achilles's Altar",
      "Aquila's Halo": "Aquila's Halo",
      "Arcadian Stream": "Arcadian Stream",
      "Dryman's Point": "Dryman's Point",
      "Elysian Shore": "Elysian Shore",
      "Empyrean Straits": "Empyrean Straits",
      "Errant Marches": "Errant Marches",
      "Formorian Frontier": "Formorian Frontier",
      "Galactic Centre": "Galactic Centre",
      "Hawking's Gap": "Hawking's Gap",
      "Hieronymus Delta": "Hieronymus Delta",
      "Inner Orion Spur": "Inner Orion Spur",
      "Inner Orion-Perseus Conflux": "Inner Orion-Perseus Conflux",
      "Inner Scutum-Centaurus Arm": "Inner Scutum-Centaurus Arm",
      "Izanami": "Izanami",
      "Kepler's Crest": "Kepler's Crest",
      "Lyra's Song": "Lyra's Song",
      "Mare Somnia": "Mare Somnia",
      "Newton's Vault": "Newton's Vault",
      "Norma Arm": "Norma Arm",
      "Norma Expanse": "Norma Expanse",
      "Odin's Hold": "Odin's Hold",
      "Orion-Cygnus Arm": "Orion-Cygnus Arm",
      "Outer Arm": "Outer Arm",
      "Outer Orion Spur": "Outer Orion Spur",
      "Outer Orion-Perseus Conflux": "Outer Orion-Perseus Conflux",
      "Outer Scutum-Centaurus Arm": "Outer Scutum-Centaurus Arm",
      "Perseus Arm": "Perseus Arm",
      "Ryker's Hope": "Ryker's Hope",
      "Sagittarius-Carina Arm": "Sagittarius-Carina Arm",
      "Sanguineous Rim": "Sanguineous Rim",
      "Temple": "Temple",
      "Tenebrae": "Tenebrae",
      "The Abyss": "The Abyss",
      "The Conduit": "The Conduit",
      "The Formidine Rift": "The Formidine Rift",
      "The Veils": "The Veils",
      "The Void": "The Void",
      "Trojan Belt": "Trojan Belt",
      "Vulcan Gate": "Vulcan Gate",
      "Xibalba": "Xibalba"
    },
    "reserve_level": {
      "Common": "Common",
      "Depleted": "Depleted",
      "Low": "Low",
      "Major": "Major",
      "Pristine": "Pristine"
    },
    "results": {
      "atmosphere_composition_to_show": "Atmosphere composition to show",
      "bodies_count": "{count} bodies",
      "body_belts": "{name} Belts",
      "body_landmarks": "{name} Landmarks",
      "body_rings": "{name} Rings",
      "close": "Close",
      "commodities_to_show": "Commodities to show",
      "commodity_fields_to_show": "Commodities fields to show",
      "copy_to_clipboard": "Copy {system} to clipboard",
      "create_route": "Create a tourist route from this search",
      "display_search": "Display search on map",
      "download": "Download as CSV",
      "download_search": "Download as CSV",
      "estimated_jumps": "Estimated Jumps: {jumps}",
      "estimated_time": "Estimated Time: {time}",
      "fields_to_show": "Fields to show",
      "first": "First",
      "galaxy_map_visits": "Number of visits to the galaxy map required: {total_visits}",
      "hide_search": "Hide Search",
      "jumps": "Jumps: {jumps}",
      "landmarks_count": "{count} landmarks",
      "last": "Last",
      "materials_to_show": "Materials to show",
      "next": "Next",
      "no_results": "There are no results found for this search",
      "no_search": "Run a search to see results",
      "none": "None",
      "previous": "Previous",
      "results": "Results",
      "results_per_page": "Results per page",
      "route": "From {source} to {destination}",
      "show_route": "Show Route",
      "show_search": "Show Search",
      "showing": "Showing results {start} to {end} of {count}",
      "systems_count": "{count} systems",
      "totals": "Totals",
      "unknown_location": "Unknown location"
    },
    "riches": {
      "blank": "Leave destination blank in order to create a circular tour starting at your source system and returning there at the end.",
      "guides": "You can find some guides and videos for using the FSS and DSS <a href=\"https://forums.frontier.co.uk/showthread.php/464149-The-FSS-A-pictoral-guide\">here</a> and <a href=\"https://forums.frontier.co.uk/showthread.php/465649-Setup-and-Use-of-the-FSS-and-DSS-With-Demonstration-Videos\">here</a>.",
      "low_results": "The number of systems returned is less than you asked for.  Try increasing the radius of the search in order to find more bodies.  If you still do not get enough results you may have set the minimum scan value too high.",
      "title": "Road 2 Riches",
      "warning": "You should decide if you want to DSS (Detailed Surface Scanner which requires you to travel to the body in system and surface map) or simply FSS (Full Spectrum Scanner from the system jump in point).  FSS is faster for each system but will pay substantially less credits.  The checkbox for \"Use Mapping Value\" will rank bodies by DSS reward rather then FSS reward."
    },
    "ring_signal": {
      "Alexandrite": "Alexandrite",
      "Bauxite": "Bauxite",
      "Benitoite": "Benitoite",
      "Bertrandite": "Bertrandite",
      "Biological": "Biological",
      "Bromellite": "Bromellite",
      "Cobalt": "Cobalt",
      "Coltan": "Coltan",
      "Gallite": "Gallite",
      "Geological": "Geological",
      "Gold": "Gold",
      "Grandidierite": "Grandidierite",
      "Guardian": "Guardian",
      "Human": "Human",
      "Hydrogen Peroxide": "Hydrogen Peroxide",
      "Indite": "Indite",
      "Lepidolite": "Lepidolite",
      "Liquid oxygen": "Liquid oxygen",
      "Lithium Hydroxide": "Lithium Hydroxide",
      "Low Temperature Diamonds": "Low Temperature Diamonds",
      "Methane Clathrate": "Methane Clathrate",
      "Methanol Monohydrate Crystals": "Methanol Monohydrate Crystals",
      "Monazite": "Monazite",
      "Musgravite": "Musgravite",
      "Osmium": "Osmium",
      "Other": "Other",
      "Painite": "Painite",
      "Platinum": "Platinum",
      "Praseodymium": "Praseodymium",
      "Rhodplumsite": "Rhodplumsite",
      "Rutile": "Rutile",
      "Samarium": "Samarium",
      "Serendibite": "Serendibite",
      "Silver": "Silver",
      "Thargoid": "Thargoid",
      "Tritium": "Tritium",
      "Uraninite": "Uraninite",
      "Void Opal": "Void Opal",
      "Water": "Water"
    },
    "rings": {
      "add_field": "Add filter",
      "inner_radius": "Inner Radius (m)",
      "mass": "Mass (Mt)",
      "outer_radius": "Outer Radius (m)",
      "remove_field": "Remove filter",
      "select_type": "Select type",
      "type": "Type",
      "types": {
        "Icy": "Icy",
        "Metal Rich": "Metal Rich",
        "Metallic": "Metallic",
        "Rocky": "Rocky"
      }
    },
    "scan_types": {
      "Exobiology": "Exobiology",
      "Salesman": "Road to Riches"
    },
    "scanned-bodies": {
      "title": "{scanType} Scanned Bodies ({start} to {end} of {total})"
    },
    "search": {
      "copy_name_to_clipboard": "Copy name to clipboard",
      "description": "Top {size} results of {count} searching for \"{query}\"",
      "fail": "Cannot complete search, please try later.",
      "types": {
        "body": "Body",
        "station": "Station",
        "system": "System"
      }
    },
    "security": {
      "Anarchy": "Anarchy",
      "High": "High",
      "Lawless": "Lawless",
      "Low": "Low",
      "Medium": "Medium"
    },
    "services": {
      "Apex Interstellar": "Apex Interstellar",
      "Autodock": "Autodock",
      "Bartender": "Bartender",
      "Black Market": "Black Market",
      "Contacts": "Contacts",
      "Crew Lounge": "Crew Lounge",
      "Dock": "Dock",
      "Facilitator": "Interstellar Factors Contact",
      "Fleet Carrier Administration": "Fleet Carrier Administration",
      "Fleet Carrier Fuel": "Fleet Carrier Fuel",
      "Fleet Carrier Management": "Fleet Carrier Management",
      "Fleet Carrier Vendor": "Fleet Carrier Vendor",
      "Flight Controller": "Flight Controller",
      "Frontline Solutions": "Frontline Solutions",
      "Interstellar Factors Contact": "Interstellar Factors Contact",
      "Livery": "Livery",
      "Market": "Market",
      "Material Trader": "Material Trader",
      "Missions": "Missions",
      "Missions Generated": "Missions Generated",
      "Module Packs": "Fleet Carrier Administration",
      "On Dock Mission": "On Dock Mission",
      "Outfitting": "Outfitting",
      "Pioneer Supplies": "Pioneer Supplies",
      "Powerplay": "Powerplay",
      "Redemption Office": "Redemption Office",
      "Refuel": "Refuel",
      "Repair": "Repair",
      "Restock": "Restock",
      "Search and Rescue": "Search and Rescue",
      "Shipyard": "Shipyard",
      "Shop": "Shop",
      "Social Space": "Social Space",
      "Station Menu": "Station Menu",
      "Station Operations": "Station Operations",
      "Technology Broker": "Technology Broker",
      "Tuning": "Tuning",
      "Universal Cartographics": "Universal Cartographics",
      "Vista Genomics": "Vista Genomics",
      "Workshop": "Workshop"
    },
    "settings": {
      "confirm_delete": "Confirm Delete User",
      "confirm_delete_warning": "Warning, if you continue you will delete your user account and any associated saved data, including but not limited to road to riches saved scans.  You can recreate your user account by linking your frontier account again but your saved data will be gone forever and cannot be restored.  Are you sure you want to continue?",
      "delete_help": "Delete your user account and any associated data.",
      "delete_user": "Delete User",
      "link_patreon": "Link Patreon Account",
      "link_patreon_help": "If you're a patron, link your patreon account here to enable extra rewards.",
      "linked_no_patron": "You have already linked but you are not currently a patron.",
      "linked_patron": "You have already linked and you are a patron, thankyou."
    },
    "ships": {
      "Adder": "Adder",
      "Alliance Challenger": "Alliance Challenger",
      "Alliance Chieftain": "Alliance Chieftain",
      "Alliance Crusader": "Alliance Crusader",
      "Anaconda": "Anaconda",
      "Asp Explorer": "Asp Explorer",
      "Asp Scout": "Asp Scout",
      "Beluga Liner": "Beluga Liner",
      "Cobra MkIII": "Cobra MkIII",
      "Cobra MkIV": "Cobra MkIV",
      "Diamondback Explorer": "Diamondback Explorer",
      "Diamondback Scout": "Diamondback Scout",
      "Dolphin": "Dolphin",
      "Eagle": "Eagle",
      "Federal Assault Ship": "Federal Assault Ship",
      "Federal Corvette": "Federal Corvette",
      "Federal Dropship": "Federal Dropship",
      "Federal Gunship": "Federal Gunship",
      "Fer-de-Lance": "Fer-de-Lance",
      "Hauler": "Hauler",
      "Imperial Clipper": "Imperial Clipper",
      "Imperial Courier": "Imperial Courier",
      "Imperial Cutter": "Imperial Cutter",
      "Imperial Eagle": "Imperial Eagle",
      "Keelback": "Keelback",
      "Krait MkII": "Krait MkII",
      "Krait Phantom": "Krait Phantom",
      "Mamba": "Mamba",
      "Orca": "Orca",
      "Python": "Python",
      "Sidewinder": "Sidewinder",
      "Type-10 Defender": "Type-10 Defender",
      "Type-6 Transporter": "Type-6 Transporter",
      "Type-7 Transporter": "Type-7 Transporter",
      "Type-9 Heavy": "Type-9 Heavy",
      "Viper MkIII": "Viper MkIII",
      "Viper MkIV": "Viper MkIV",
      "Vulture": "Vulture"
    },
    "signal": {
      "Alexandrite": "Alexandrite",
      "Bauxite": "Bauxite",
      "Benitoite": "Benitoite",
      "Bertrandite": "Bertrandite",
      "Biological": "Biological",
      "Bromellite": "Bromellite",
      "Cobalt": "Cobalt",
      "Coltan": "Coltan",
      "Gallite": "Gallite",
      "Geological": "Geological",
      "Gold": "Gold",
      "Grandidierite": "Grandidierite",
      "Guardian": "Guardian",
      "Human": "Human",
      "Hydrogen Peroxide": "Hydrogen Peroxide",
      "Indite": "Indite",
      "Lepidolite": "Lepidolite",
      "Liquid oxygen": "Liquid oxygen",
      "Lithium Hydroxide": "Lithium Hydroxide",
      "Low Temperature Diamonds": "Low Temperature Diamonds",
      "Major Anomaly": "Major Anomaly",
      "Methane Clathrate": "Methane Clathrate",
      "Methanol Monohydrate Crystals": "Methanol Monohydrate Crystals",
      "Monazite": "Monazite",
      "Musgravite": "Musgravite",
      "Osmium": "Osmium",
      "Other": "Other",
      "Painite": "Painite",
      "Platinum": "Platinum",
      "Praseodymium": "Praseodymium",
      "Rhodplumsite": "Rhodplumsite",
      "Rutile": "Rutile",
      "Samarium": "Samarium",
      "Serendibite": "Serendibite",
      "Silver": "Silver",
      "Thargoid": "Thargoid",
      "Tritium": "Tritium",
      "Uraninite": "Uraninite",
      "Void Opal": "Void Opal",
      "Water": "Water"
    },
    "solid_composition": {
      "Ice": "Ice",
      "Metal": "Metal",
      "Rock": "Rock"
    },
    "sorting": {
      "asc": "Ascending",
      "desc": "Descending",
      "field_ascending": "Sort by {field} ascending",
      "field_descending": "Sort by {field} descending"
    },
    "state": {
      "Blight": "Blight",
      "Boom": "Boom",
      "Bust": "Bust",
      "Civil Liberty": "Civil Liberty",
      "Civil Unrest": "Civil Unrest",
      "Civil War": "Civil War",
      "Drought": "Drought",
      "Election": "Election",
      "Expansion": "Expansion",
      "Famine": "Famine",
      "Infrastructure Failure": "Infrastructure Failure",
      "Investment": "Investment",
      "Lockdown": "Lockdown",
      "Natural Disaster": "Natural Disaster",
      "None": "None",
      "Outbreak": "Outbreak",
      "Pirate Attack": "Pirate Attack",
      "Public Holiday": "Public Holiday",
      "Retreat": "Retreat",
      "Terrorism": "Terrorism",
      "Terrorist Attack": "Terrorist Attack",
      "War": "War"
    },
    "station": {
      "market": "Market",
      "market_updated_at": "Market updated {relative_updated_at} ({updated_at})",
      "no_market_id": "Market id not supplied",
      "not_found": "Station not found",
      "outfitting": "Outfitting",
      "outfitting_updated_at": "Outfitting updated {relative_updated_at} ({updated_at})",
      "shipyard": "Shipyard",
      "shipyard_updated_at": "Shipyard updated {relative_updated_at} ({updated_at})",
      "station": "Station",
      "stock_history": "{commodity} Stock history",
      "updated_at": "Updated {relative_updated_at} ({updated_at})"
    },
    "station_type": {
      "Asteroid base": "Asteroid base",
      "Coriolis Starport": "Coriolis Starport",
      "Drake-Class Carrier": "Drake-Class Carrier",
      "Mega ship": "Mega ship",
      "Ocellus Starport": "Ocellus Starport",
      "Orbis Starport": "Orbis Starport",
      "Outpost": "Outpost",
      "Planetary Outpost": "Planetary Outpost",
      "Planetary Port": "Planetary Port",
      "Settlement": "Settlement",
      "Unknown": "Unknown"
    },
    "stock": {
      "date_range": "Stock date range",
      "fields": {
        "demand": "Demand",
        "max_buy_price": "Maximum buy price",
        "max_sell_price": "Maximum sell price",
        "min_buy_price": "Minimum buy price",
        "min_sell_price": "Minimum sell price",
        "open_buy_price": "Buy price",
        "open_sell_price": "Sell price",
        "supply": "Supply"
      },
      "station_stock": "{name} stock history for {commodity}",
      "update_dates": "Change date range"
    },
    "subtype": {
      "A (Blue-White super giant) Star": "A (Blue-White super giant) Star",
      "A (Blue-White) Star": "A (Blue-White) Star",
      "Ammonia world": "Ammonia world",
      "B (Blue-White super giant) Star": "B (Blue-White super giant) Star",
      "B (Blue-White) Star": "B (Blue-White) Star",
      "Black Hole": "Black Hole",
      "C Star": "C Star",
      "CJ Star": "CJ Star",
      "CN Star": "CN Star",
      "Class I gas giant": "Class I gas giant",
      "Class II gas giant": "Class II gas giant",
      "Class III gas giant": "Class III gas giant",
      "Class IV gas giant": "Class IV gas giant",
      "Class V gas giant": "Class V gas giant",
      "Earth-like world": "Earth-like world",
      "F (White super giant) Star": "F (White super giant) Star",
      "F (White) Star": "F (White) Star",
      "G (White-Yellow super giant) Star": "G (White-Yellow super giant) Star",
      "G (White-Yellow) Star": "G (White-Yellow) Star",
      "Gas giant with ammonia-based life": "Gas giant with ammonia-based life",
      "Gas giant with water-based life": "Gas giant with water-based life",
      "Helium gas giant": "Helium gas giant",
      "Helium-rich gas giant": "Helium-rich gas giant",
      "Herbig Ae/Be Star": "Herbig Ae/Be Star",
      "High metal content world": "High metal content world",
      "Icy body": "Icy body",
      "K (Yellow-Orange giant) Star": "K (Yellow-Orange giant) Star",
      "K (Yellow-Orange) Star": "K (Yellow-Orange) Star",
      "L (Brown dwarf) Star": "L (Brown dwarf) Star",
      "M (Red dwarf) Star": "M (Red dwarf) Star",
      "M (Red giant) Star": "M (Red giant) Star",
      "M (Red super giant) Star": "M (Red super giant) Star",
      "MS-type Star": "MS-type Star",
      "Metal-rich body": "Metal-rich body",
      "Neutron Star": "Neutron Star",
      "O (Blue-White) Star": "O (Blue-White) Star",
      "Rocky Ice world": "Rocky Ice world",
      "Rocky body": "Rocky body",
      "S-type Star": "S-type Star",
      "Supermassive Black Hole": "Supermassive Black Hole",
      "T (Brown dwarf) Star": "T (Brown dwarf) Star",
      "T Tauri Star": "T Tauri Star",
      "Water giant": "Water giant",
      "Water world": "Water world",
      "White Dwarf (D) Star": "White Dwarf (D) Star",
      "White Dwarf (DA) Star": "White Dwarf (DA) Star",
      "White Dwarf (DAB) Star": "White Dwarf (DAB) Star",
      "White Dwarf (DAV) Star": "White Dwarf (DAV) Star",
      "White Dwarf (DAZ) Star": "White Dwarf (DAZ) Star",
      "White Dwarf (DB) Star": "White Dwarf (DB) Star",
      "White Dwarf (DBV) Star": "White Dwarf (DBV) Star",
      "White Dwarf (DBZ) Star": "White Dwarf (DBZ) Star",
      "White Dwarf (DC) Star": "White Dwarf (DC) Star",
      "White Dwarf (DCV) Star": "White Dwarf (DCV) Star",
      "White Dwarf (DQ) Star": "White Dwarf (DQ) Star",
      "Wolf-Rayet C Star": "Wolf-Rayet C Star",
      "Wolf-Rayet N Star": "Wolf-Rayet N Star",
      "Wolf-Rayet NC Star": "Wolf-Rayet NC Star",
      "Wolf-Rayet O Star": "Wolf-Rayet O Star",
      "Wolf-Rayet Star": "Wolf-Rayet Star",
      "Y (Brown dwarf) Star": "Y (Brown dwarf) Star"
    },
    "system": {
      "bodies": "Bodies ({size})",
      "bodies_with_total": "Bodies ({size}/{total})",
      "factions": "Factions ({size})",
      "no_id64": "No system ID provided",
      "not_found": "System not found",
      "stations": "Stations ({size})",
      "system": "System",
      "thargoid_war": "Thargoids",
      "title": "System",
      "updated_at": "Updated {relative_updated_at} ({updated_at})"
    },
    "terraforming_state": {
      "Candidate for terraforming": "Candidate for terraforming",
      "Not terraformable": "Not terraformable",
      "Terraformable": "Candidate for terraforming",
      "Terraformed": "Terraformed",
      "Terraforming": "Terraforming"
    },
    "thanks": {
      "description": "A lot of the features on this site would not have been possible without the extensive help I've received.  This list isn't exhaustive and I'm grateful to everyone who has helped.  If you feel someone has been left out then please contact me.",
      "developers": {
        "description": "The other developers who have helped by providing data dumps, information and advice.",
        "title": "Developers"
      },
      "patreon": {
        "description": "The patrons who have contributed towards the costs of hosting and developing.",
        "title": "Patrons"
      },
      "players": {
        "description": "I would like to thank all of the players and explorers who run the various data collection tools which allow us to gather data about the galaxy which makes these sites even possible but a few have gone above and beyond.",
        "title": "Players"
      },
      "title": "Thank You",
      "translators": {
        "description": "The translators who have made the native language versions of this site.",
        "title": "Translators"
      }
    },
    "thargoid_war_state": {
      "Thargoid Controlled": "Thargoid Controlled",
      "Thargoid Harvest": "Thargoid Harvest",
      "Thargoid Probing": "Thargoid Probing",
      "Thargoid Recovery": "Thargoid Recovery",
      "Thargoid Stronghold": "Thargoid Stronghold"
    },
    "titles": {
      "admin": "Admin",
      "ammonia": "Ammonia Worlds",
      "ammonia_results": "Ammonia Worlds Route",
      "bad_data": "Report Data Problem",
      "body": "Body {body}",
      "body_search": "Body Search",
      "body_search_results": "Body Search Results",
      "community-goals": "Community Goals",
      "dumps": "Data Dumps",
      "earth": "Earth-like Worlds",
      "earth_results": "Earth-like Worlds Route",
      "engineer_plotter": "Engineer Plotter",
      "engineer_plotter_results": "Engineer Plotter Route",
      "error": "Error",
      "exact_plotter": "Galaxy Plotter",
      "exact_plotter_results": "Galaxy Plotter Results",
      "exobiology": "Expressway to Exomastery",
      "exobiology_results": "Expressway to Exomastery Results",
      "fleet_carrier": "Fleet Carrier",
      "fleet_carrier_results": "Fleet Carrier Route",
      "list_bad_data": "List Bad Data Reports",
      "main": "Elite: Dangerous Index",
      "nearest": "Nearest Known System",
      "plotter": "Neutron Plotter",
      "plotter_results": "Neutron Plotter Route",
      "riches": "Road to Riches",
      "riches_results": "Road to Riches Route",
      "scanned-bodies": "Scanned Bodies",
      "search": "Search",
      "station": "Station {station}",
      "station_search": "Station Search",
      "station_search_results": "Station Search Results",
      "station_stock": "Station {name} {commodity} Stock History",
      "system": "System {system}",
      "system_search": "System Search",
      "system_search_results": "System Search Results",
      "thanks": "Thanks",
      "tourist": "Tourist Planner",
      "tourist_results": "Tourist Planner Route",
      "trade": "Trade Planner",
      "trade-to-system": "Trade To System",
      "trade-to-system_results": "Trade To System Results",
      "trade_results": "Trade Planner Results",
      "user": "User Settings"
    },
    "tourist": {
      "description": "This page will allow you to plan a route between several systems you wish to visit in an optimal order.",
      "import_destinations": "Import destinations",
      "import_destinations_description": "Import a waypoint file from EDSM as a set of destinations (A simple text file with one system name per line).",
      "importing": "Importing destinations",
      "title": "Tourist Router"
    },
    "trade": {
      "copy_destination_to_clipboard": "Copy destination system name {system} to clipboard",
      "copy_source_to_clipboard": "Copy source system name {system} to clipboard",
      "distance": "{distance} LY",
      "finishing_system": "Then fly {distance} LY to {station} in <a href=\"{link}\">{system}</a>",
      "finishing_system_link": "Then fly {distance} LY to <a href=\"{stationLink}\">{station}</a> in <a href=\"{link}\">{system}</a>",
      "first_system": "Starting at {station} (updated {updated_at}) in <a href=\"{link}\">{system}</a>",
      "first_system_link": "Starting at <a href=\"{stationLink}\">{station}</a> (updated {updated_at}) in <a href=\"{link}\">{system}</a>",
      "flying_instructions": "Fly {distance}LS ({time} flying time) to {station}.",
      "flying_instructions_link": "Fly {distance}LS ({time} flying time) to <a href=\"{stationLink}\">{station}</a>.",
      "no_results": "Could not find any trade routes",
      "purchase_instructions": "Sell everything in your hold and buy the commodities listed below.",
      "route": "Fly {distance} LY from {start_station} in {start_system} to {end_station} in {end_system}",
      "source_required": "Source system is required",
      "starting_system": "{station} (updated {updated_at}) in <a href=\"{link}\">{system}</a>",
      "starting_system_link": "<a href=\"{stationLink}\">{station}</a> (updated {updated_at}) in <a href=\"{link}\">{system}</a>",
      "title": "Trade Planner"
    },
    "trade-to-system": {
      "title": "Trading to {station} in {system}"
    },
    "type": {
      "Barycentre": "Barycentre",
      "Planet": "Planet",
      "Star": "Star"
    },
    "unknown": "Unknown",
    "user": {
      "settings": "User Settings",
      "update_scanned_bodies": "Update {scanType} scanned bodies"
    },
    "volcanism_type": {
      "Ammonia Magma": "Ammonia Magma",
      "Carbon Dioxide Geysers": "Carbon Dioxide Geysers",
      "Major Carbon Dioxide Geysers": "Major Carbon Dioxide Geysers",
      "Major Metallic Magma": "Major Metallic Magma",
      "Major Rocky Magma": "Major Rocky Magma",
      "Major Silicate Vapour Geysers": "Major Silicate Vapour Geysers",
      "Major Water Geysers": "Major Water Geysers",
      "Major Water Magma": "Major Water Magma",
      "Metallic Magma": "Metallic Magma",
      "Methane Magma": "Methane Magma",
      "Minor Ammonia Magma": "Minor Ammonia Magma",
      "Minor Carbon Dioxide Geysers": "Minor Carbon Dioxide Geysers",
      "Minor Metallic Magma": "Minor Metallic Magma",
      "Minor Methane Magma": "Minor Methane Magma",
      "Minor Nitrogen Magma": "Minor Nitrogen Magma",
      "Minor Rocky Magma": "Minor Rocky Magma",
      "Minor Silicate Vapour Geysers": "Minor Silicate Vapour Geysers",
      "Minor Water Geysers": "Minor Water Geysers",
      "Minor Water Magma": "Minor Water Magma",
      "Nitrogen Magma": "Nitrogen Magma",
      "No volcanism": "No volcanism",
      "Rocky Magma": "Rocky Magma",
      "Silicate Vapour Geysers": "Silicate Vapour Geysers",
      "Water Geysers": "Water Geysers",
      "Water Magma": "Water Magma"
    },
    "weapon_mode": {
      "Fixed": "Fixed",
      "Gimbal": "Gimbal",
      "Turret": "Turret"
    },
    "yes": "Yes"
  }], ["es", {
    "allegiance": {
      "Alliance": "Alianza",
      "Empire": "Imperio",
      "Federation": "Federación",
      "Guardian": "Guardian",
      "Independent": "Independiente",
      "None": "ninguna",
      "Pilots Federation": "Pilotos de la federación",
      "Player Pilots": "Player Pilots",
      "Thargoid": "Targoide"
    },
    "atmosphere": {
      "Ammonia": "Amoniaco",
      "Ammonia and Oxygen": "Amoniaco y oxígeno",
      "Ammonia-rich": "Rico en amoniaco",
      "Argon": "Argón",
      "Argon-rich": "Rico en argón",
      "Carbon dioxide": "Dióxido de carbono",
      "Carbon dioxide-rich": "Rico en dióxido de carbono",
      "Helium": "Helio",
      "Hot Argon": "Hot Argon",
      "Hot Argon-rich": "Hot Argon-rich",
      "Hot Carbon dioxide": "Hot Carbon dioxide",
      "Hot Carbon dioxide-rich": "Hot Carbon dioxide-rich",
      "Hot Metallic vapour": "Hot Metallic vapour",
      "Hot Silicate vapour": "Hot Silicate vapour",
      "Hot Sulphur dioxide": "Hot Sulphur dioxide",
      "Hot Water": "Hot Water",
      "Hot Water-rich": "Hot Water-rich",
      "Hot thick Ammonia": "Hot thick Ammonia",
      "Hot thick Ammonia-rich": "Hot thick Ammonia-rich",
      "Hot thick Argon": "Hot thick Argon",
      "Hot thick Argon-rich": "Hot thick Argon-rich",
      "Hot thick Carbon dioxide": "Hot thick Carbon dioxide",
      "Hot thick Carbon dioxide-rich": "Hot thick Carbon dioxide-rich",
      "Hot thick Metallic vapour": "Hot thick Metallic vapour",
      "Hot thick Methane": "Hot thick Methane",
      "Hot thick Methane-rich": "Hot thick Methane-rich",
      "Hot thick Nitrogen": "Hot thick Nitrogen",
      "Hot thick No atmosphere": "Hot thick No atmosphere",
      "Hot thick Silicate vapour": "Hot thick Silicate vapour",
      "Hot thick Sulphur dioxide": "Hot thick Sulphur dioxide",
      "Hot thick Water": "Hot thick Water",
      "Hot thick Water-rich": "Hot thick Water-rich",
      "Hot thin Carbon dioxide": "Hot thin Carbon dioxide",
      "Hot thin Metallic vapour": "Hot thin Metallic vapour",
      "Hot thin Silicate vapour": "Hot thin Silicate vapour",
      "Hot thin Sulphur dioxide": "Hot thin Sulphur dioxide",
      "Metallic vapour": "Metallic vapour",
      "Methane": "Methane",
      "Methane-rich": "Methane-rich",
      "Neon": "neón",
      "Neon-rich": "rico en neón",
      "Nitrogen": "Nitrógeno",
      "No atmosphere": "Sin Atmósfera",
      "Oxygen": "oxígeno",
      "Silicate vapour": "Vapor de silicato",
      "Suitable for water-based life": "Suitable for water-based life",
      "Sulphur dioxide": "Dióxido de sulfuro",
      "Thick Ammonia": "Thick Ammonia",
      "Thick Ammonia and Oxygen": "Thick Ammonia and Oxygen",
      "Thick Ammonia-rich": "Thick Ammonia-rich",
      "Thick Argon": "Thick Argon",
      "Thick Argon-rich": "Thick Argon-rich",
      "Thick Carbon dioxide": "Thick Carbon dioxide",
      "Thick Carbon dioxide-rich": "Thick Carbon dioxide-rich",
      "Thick Helium": "Thick Helium",
      "Thick Methane": "Thick Methane",
      "Thick Methane-rich": "Thick Methane-rich",
      "Thick Nitrogen": "Thick Nitrogen",
      "Thick No atmosphere": "Thick No atmosphere",
      "Thick Suitable for water-based life": "Thick Suitable for water-based life",
      "Thick Sulphur dioxide": "Thick Sulphur dioxide",
      "Thick Water": "Thick Water",
      "Thick Water-rich": "Thick Water-rich",
      "Thin Ammonia": "Thin Ammonia",
      "Thin Ammonia and Oxygen": "Thin Ammonia and Oxygen",
      "Thin Ammonia-rich": "Thin Ammonia-rich",
      "Thin Argon": "Thin Argon",
      "Thin Argon-rich": "Thin Argon-rich",
      "Thin Carbon dioxide": "Thin Carbon dioxide",
      "Thin Carbon dioxide-rich": "Thin Carbon dioxide-rich",
      "Thin Helium": "Thin Helium",
      "Thin Methane": "Thin Methane",
      "Thin Methane-rich": "Thin Methane-rich",
      "Thin Neon": "Thin Neon",
      "Thin Neon-rich": "Thin Neon-rich",
      "Thin Nitrogen": "Thin Nitrogen",
      "Thin No atmosphere": "Thin No atmosphere",
      "Thin Oxygen": "Thin Oxygen",
      "Thin Sulphur dioxide": "Thin Sulphur dioxide",
      "Thin Water": "Thin Water",
      "Thin Water-rich": "Thin Water-rich",
      "Water": "Agua",
      "Water-rich": "Rico en agua"
    },
    "atmosphere_composition": {
      "Ammonia": "Amoniaco",
      "Argon": "Argon",
      "Carbon dioxide": "Dioxido de carbono",
      "Helium": "helio",
      "Hydrogen": "Hidrógeno",
      "Iron": "Hierro",
      "Methane": "Metano",
      "Neon": "Neón",
      "Nitrogen": "Nitrogeno",
      "Oxygen": "Oxígeno",
      "Silicates": "Silicato",
      "Sulphur dioxide": "Dióxido de sulfuro",
      "Water": "Agua"
    },
    "belts": {
      "add_field": "Agregar filtro",
      "inner_radius": "Radio interior (m)",
      "mass": "Masa (T)",
      "outer_radius": "Radio exterior (m)",
      "remove_field": "Quitar filtro",
      "select_type": "Selecciona tipo",
      "type": "Tipo",
      "types": {
        "Icy": "Hielo",
        "Metal Rich": "Rico en metal",
        "Metallic": "Metálico",
        "Rocky": "Rocoso"
      }
    },
    "body": {
      "belts": "Cinturones de asteroides",
      "body": "Cuerpo",
      "composition": "Composición",
      "landmarks": "Sitio de aterrizaje",
      "no_id64": "Sin identificación del cuerpo",
      "not_found": "Cuerpo no encontrado",
      "rings": "Anillos",
      "signals": "Señales",
      "signals_updated_at": "Actualización de señales {relative_updated_at} ({updated_at})",
      "updated_at": "Actualización {relative_updated_at} ({updated_at})"
    },
    "boolean": {
      "Any": "cualquiera",
      "No": "No",
      "Yes": "Sí"
    },
    "categories": {
      "Chemicals": "Químicos",
      "Consumer Items": "Productos de consumo",
      "Foods": "Comida",
      "Industrial Materials": "Materiales industriales",
      "Legal Drugs": "Drogas legales",
      "Machinery": "Maquinería",
      "Medicines": "Medicinas",
      "Metals": "Metales",
      "Minerals": "Minerales",
      "Salvage": "Salvamento",
      "Slavery": "Esclavitud",
      "Technology": "Tecnología",
      "Textiles": "Textiles",
      "Waste": "Residuos",
      "Weapons": "Armas"
    },
    "commodities": {
      "AI Relics": "Reliquias de IA",
      "Advanced Catalysers": "Catalizadores avanzados",
      "Advanced Medicines": "Medicinas avanzadas",
      "Aepyornis Egg": "huevos de Aepyornis",
      "Aganippe Rush": "Incrementador de Aganippe",
      "Agri-Medicines": "Medicina agrícola",
      "Agronomic Treatment": "Tratamiento agronómico",
      "Alacarakmo Skin Art": "Tatuajes de Alacarakmo",
      "Albino Quechua Mammoth Meat": "Carne de mamut albino Quechua",
      "Alexandrite": "Alejandrita",
      "Algae": "Algas",
      "Altairian Skin": "Piel altairiana",
      "Aluminium": "Aluminio",
      "Alya Body Soap": "Jabón corporal de Alya",
      "Ancient Artefact": "Artefactos Antiguos",
      "Ancient Casket": "Cofre antiguo",
      "Ancient Key": "Llave antigua",
      "Ancient Orb": "Orbe antiguo",
      "Ancient Relic": "Reliquia antigua",
      "Ancient Tablet": "Tablilla antigua",
      "Ancient Totem": "Tótem antiguo",
      "Ancient Urn": "Urna antigua",
      "Anduliga Fire Works": "Fuegos artificiales de Anduliga",
      "Animal Meat": "Carne animal",
      "Animal Monitors": "Monitores de animales",
      "Anomaly Particles": "Partículas anómalas",
      "Antimatter Containment Unit": "Unidades de confinamiento de antimateria",
      "Antique Jewellery": "Joyería antigua",
      "Antiquities": "Antigüedades",
      "Any Na Coffee": "Café de Any Na",
      "Apa Vietii": "Apa Vietii",
      "Aquaponic Systems": "Sistemas de hidroponia",
      "Arouca Conventual Sweets": "Dulces conventuales de Arouca",
      "Articulation Motors": "Motores de articulación",
      "Assault Plans": "Planes de asalto",
      "Atmospheric Processors": "Procesadores atmosféricos",
      "Auto-Fabricators": "Autofabricantes",
      "Az Cancri Formula 42": "Fórmula 42 de AZ Cancri",
      "Azure Milk": "Leche de Azure",
      "Baked Greebles": "Cocido de greebles",
      "Baltah'sine Vacuum Krill": "Krill del vacío de Baltah'Sine",
      "Banki Amphibious Leather": "Cuero anfibio de Banki",
      "Basic Medicines": "Medicinas básicas",
      "Bast Snake Gin": "Bast Snake Gin",
      "Battle Weapons": "Armas de batalla",
      "Bauxite": "Bauxita",
      "Beer": "Cerveza",
      "Belalans Ray Leather": "Cuero de raya de Belalans",
      "Benitoite": "Benitoíta",
      "Bertrandite": "Bertrandita",
      "Beryllium": "Berilio",
      "Bioreducing Lichen": "Líquenes biorreductores",
      "Biowaste": "Residuos Biológicos",
      "Bismuth": "bismuto",
      "Black Box": "Caja negra",
      "Bootleg Liquor": "Licor ilegal",
      "Borasetani Pathogenetics": "Patógenos de Borasetani",
      "Bromellite": "Bromelita",
      "Buckyball Beer Mats": "Posavasos Buckyball",
      "Building Fabricators": "Constructores",
      "Burnham Bile Distillate": "Burnham Bile Distillate",
      "CD-75 Kitten Brand Coffee": "Café marca Kitten de CD-75",
      "CMM Composite": "Compuestos CMM",
      "Centauri Mega Gin": "Ginebra Mega de Alpha Centauri",
      "Ceramic Composites": "Compuestos cerámicos",
      "Ceremonial Heike Tea": "Té ceremonial de Heike",
      "Ceti Rabbits": "Conejos de 47 Ceti",
      "Chameleon Cloth": "Ropa mimética",
      "Chateau De Aegaeon": "Cheateau de Aegaeon",
      "Chemical Waste": "Residuos químicos",
      "Cherbones Blood Crystals": "Cristales sangre de Cherbones",
      "Chi Eridani Marine Paste": "Pasta marina de Chi Eridani",
      "Clothing": "Ropa",
      "Cobalt": "Cobalto",
      "Coffee": "Café",
      "Coltan": "Coltán",
      "Combat Stabilisers": "Estabilizadores de combate",
      "Commercial Samples": "Muestras comerciales",
      "Computer Components": "Componentes informáticos",
      "Conductive Fabrics": "Tejidos conductivos",
      "Consumer Technology": "Tecnología de consumo",
      "Copper": "Cobre",
      "Coquim Spongiform Victuals": "Víveres espongiformes de Coquim",
      "Crom Silver Fesh": "Aliento de plata de Crom",
      "Crop Harvesters": "Segadoras de cultivos",
      "Cryolite": "Criolita",
      "Crystalline Spheres": "Esferas cristalinas",
      "Damaged Escape Pod": "Cápsula de escape dañada",
      "Damna Carapaces": "Caparazones de Damna",
      "Data Core": "Núcleo de datos",
      "Delta Phoenicis Palms": "Palmeras de Delta Phoenicis",
      "Deuringas Truffles": "Trufas de Deuringas",
      "Diplomatic Bag": "Valija diplomática",
      "Diso Ma Corn": "Maíz ma de Diso",
      "Domestic Appliances": "Electrodomésticos",
      "Duradrives": "Duradrives",
      "Earth Relics": "Reliquias de la vieja Tierra",
      "Eden Apples of Aerial": "Manzanas del Edén de Aerial",
      "Eleu Thermals": "Tejidos térmicos de eleu",
      "Emergency Power Cells": "Células energía auxiliar",
      "Encrypted Correspondence": "Correspondencia encriptada",
      "Encrypted Data Storage": "Datos encriptados",
      "Energy Grid Assembly": "Red de energía",
      "Eranin Pearl Whisky": "Whisky perlado de Eranin",
      "Eshu Umbrellas": "Paraguas de Eshu",
      "Esuseku Caviar": "Caviar de Esuseku",
      "Ethgreze Tea Buds": "Brotes de té de Ethgreze",
      "Evacuation Shelter": "Refugio de evacuación",
      "Exhaust Manifold": "Colector de escape",
      "Experimental Chemicals": "Químicos experimentales",
      "Explosives": "Explosivos",
      "Fish": "Pescado",
      "Food Cartridges": "Cartuchos de alimentos",
      "Fossil Remnants": "Restos de fósiles",
      "Fruit and Vegetables": "Frutas y verduras",
      "Fujin Tea": "Té de Fujin",
      "Galactic Travel Guide": "Guía de viaje galáctico",
      "Gallite": "Galita",
      "Gallium": "Galio",
      "Geawen Dance Dust": "Polvo de baile de Geawen",
      "Gene Bank": "Banco de genes",
      "Geological Equipment": "Equipamiento geológico",
      "Geological Samples": "Muestras geológicas",
      "Gerasian Gueuze Beer": "Cerveza gerasiana Gueuze",
      "Giant Irukama Snails": "Caracoles gigantes de Ikurama",
      "Giant Verrix": "Verrix gigante",
      "Gilya Signature Weapons": "Armas personalizables de Gilya",
      "Gold": "Oro",
      "Goman Yaupon Coffee": "Café Yaupon de Goman",
      "Goslarite": "Goslarita",
      "Grain": "Grano",
      "Grandidierite": "Grandidierita",
      "Guardian Casket": "Ataúd guardián",
      "Guardian Orb": "Orbe antiguo",
      "Guardian Relic": "Reliquia antigua",
      "Guardian Tablet": "Tablilla antigua",
      "Guardian Totem": "Tótem antiguo",
      "Guardian Urn": "Urna antigua",
      "H.E. Suits": "Trajes de protección",
      "HIP 10175 Bush Meat": "Carne herbácea de HIP 10175",
      "HIP 118311 Swarm": "SWARM de HIP 118311",
      "HIP Organophosphates": "Organofosfatos de HIP 80364",
      "HIP Proto-Squid": "Protocalamar de HIP 41181",
      "HN Shock Mount": "Suspensión HN",
      "HR 7221 Wheat": "Trigo de HR 7221",
      "Hafnium 178": "Hafnio 178",
      "Haiden Black Brew": "Infusión negra de Haiden",
      "Hardware Diagnostic Sensor": "Sensor diagnóstico de hardware",
      "Harma Silver Sea Rum": "Ron Harma Silver Sea",
      "Havasupai Dream Catcher": "Atrapasueños de Havasupai",
      "Heatsink Interlink": "Interconector de eyector térmico",
      "Helvetitj Pearls": "Perlas de Helvetitj",
      "Holva Duelling Blades": "Espadas de duelo de Holva",
      "Honesty Pills": "Píldoras de la Honestidad",
      "Hostages": "rehenes",
      "Hydrogen Fuel": "Combustible de hidrógeno",
      "Hydrogen Peroxide": "Peróxido de hidrógeno",
      "Imperial Slaves": "Esclavos imperiales",
      "Indi Bourbon": "Bourbon de Epsilon Indi",
      "Indite": "Indita",
      "Indium": "Indio",
      "Insulating Membrane": "Membrana aislante",
      "Ion Distributor": "distribuidor de iones",
      "Jadeite": "Jadeita",
      "Jaques Quinentian Still": "Alambique Jaques Quinentian",
      "Jaradharre Puzzle Box": "Rompecabezas de Jaradharre",
      "Jaroua Rice": "Arroz de Jaroua",
      "Jotun Mookah": "Mookah de Jotun",
      "Kachirigin Filter Leeches": "Sanguijuelas filtradoras de Kachigirin",
      "Kamitra Cigars": "Puros de Kamitra",
      "Kamorin Historic Weapons": "Armas históricas de Kamorin",
      "Karetii Couture": "Alta costura de Karetii",
      "Karsuki Locusts": "Langostas de Karsuki Ti",
      "Kinago Violins": "Violines de Kinago",
      "Kongga Ale": "Cerveza de Kongga",
      "Koro Kung Pellets": "Gránulos de Koro Kung",
      "LTT Hyper Sweet": "Hiperdulce LTT",
      "Land Enrichment Systems": "Enriquecimiento terrestre",
      "Landmines": "Minas terrestres",
      "Lanthanum": "Lantano",
      "Large Survey Data Cache": "Memorias de reconocimiento grandes",
      "Lavian Brandy": "Brandy laviano",
      "Leather": "Cuero",
      "Leathery Eggs": "Huevos ornamentales",
      "Leestian Evil Juice": "Zumo diabólico leestiano",
      "Lepidolite": "Lepidolita",
      "Limpets": "drones",
      "Liquid oxygen": "Oxígenos líquido",
      "Liquor": "Licores",
      "Lithium": "Lítio",
      "Lithium Hydroxide": "Hidróxido de lítio",
      "Live Hecate Sea Worms": "Gusanos marinos de Hecate",
      "Low Temperature Diamonds": "Diamantes de baja temperatura",
      "Lucan Onionhead": "Cebollazo de Luncan",
      "Lyrae Weed": "Mala hierba de Lyrae",
      "Magnetic Emitter Coil": "Bobina de emisión magnética",
      "Marine Equipment": "Equipamiento marino",
      "Master Chefs": "Maestros de cocina",
      "Mechucos High Tea": "Alto té de Mechucos",
      "Medb Starlube": "Lubricante de Medb",
      "Medical Diagnostic Equipment": "Equipo de diagnóstico médico",
      "Meta-Alloys": "Metaaleaciones",
      "Methane Clathrate": "Hidrato de metano",
      "Methanol Monohydrate Crystals": "Cristales de monohidrato de metanol",
      "Micro Controllers": "Microcontroladores",
      "Micro-weave Cooling Hoses": "Mangueras de microtejidos",
      "Microbial Furnaces": "Hornos microbianos",
      "Military Grade Fabrics": "Tejido de categoría militar",
      "Military Intelligence": "Inteligencia militar",
      "Military Plans": "Planos militares",
      "Mineral Extractors": "extractores de minerales",
      "Mineral Oil": "Aceite mineral",
      "Modular Terminals": "Terminales modulares",
      "Moissanite": "Moissanita",
      "Mokojing Beast Feast": "Banquete Bestial de Mokojin",
      "Mollusc Brain Tissue": "Tejido cerebral de molusco",
      "Mollusc Fluid": "Fluidos de molusco",
      "Mollusc Membrane": "Membrana de molusco",
      "Mollusc Mycelium": "Mycelium de molusco",
      "Mollusc Soft Tissue": "Tejido suave de molusco",
      "Mollusc Spores": "Esporas de molusco",
      "Momus Bog Spaniel": "Spaniel de pantano de Momus Reach",
      "Monazite": "Monacita",
      "Motrona Experience Jelly": "Gelatina de la Experiencia de Dea Motrona",
      "Mukusubii Chitin-os": "Alas de baja gravedad de Uzumoku",
      "Mulachi Giant Fungus": "Hongo gigante de Mulachi",
      "Muon Imager": "Escáner muónico",
      "Musgravite": "Musgravita",
      "Mysterious Idol": "Ídolo misterioso",
      "Nanobreakers": "Nanorompedores",
      "Nanomedicines": "Nanomedicinas",
      "Narcotics": "Narcóticos",
      "Natural Fabrics": "Tejidos naturales",
      "Neofabric Insulation": "Neotejido asilante",
      "Neritus Berries": "Bayas de Neritus",
      "Nerve Agents": "Agentes nerviosos",
      "Ngadandari Fire Opals": "Ópalos ígneos de Ngandari",
      "Nguna Modern Antiques": "Antigüedades modernas de Nguna",
      "Njangari Saddles": "Sillas de montar de Njangari",
      "Non Euclidian Exotanks": "Exotanques no euclídeos",
      "Non-Lethal Weapons": "Armas no letales",
      "Occupied Escape Pod": "Cápsula de escape ocupada",
      "Ochoeng Chillies": "Chiles de Ochoeng",
      "Onionhead": "Cebollazo",
      "Onionhead Alpha Strain": "Cepa alfa de cebollazo",
      "Onionhead Beta Strain": "Cepa beta de cebollazo",
      "Ophiuch Exino Artefacts": "Artefactos de los Ophiuch Exino",
      "Orrerian Vicious Brew": "Fermento vicioso orreriano",
      "Osmium": "Osmio",
      "Painite": "Painita",
      "Palladium": "Paladio",
      "Pantaa Prayer Sticks": "Palos ceremoniales de los Pantaa",
      "Pavonis Ear Grubs": "Larvorejas de Pavonis",
      "Performance Enhancers": "Potenciadores de rendimiento",
      "Personal Effects": "Efectos personales",
      "Personal Gifts": "Regalos personales",
      "Personal Weapons": "Armas personales",
      "Pesticides": "Pesticidas",
      "Platinum": "Platino",
      "Platinum Alloy": "Aleación de platino",
      "Pod Core Tissue": "Tejido de núcleo de cápsula",
      "Pod Dead Tissue": "Tejido muerto de cápsula",
      "Pod Mesoglea": "Vaina de mesoglea",
      "Pod Outer Tissue": "Tejido exterior de cápsula",
      "Pod Shell Tissue": "Tejido de cáscara de cápsula",
      "Pod Surface Tissue": "Tejido de superficie de cápsula",
      "Pod Tissue": "Pod Tissue",
      "Political Prisoners": "Prisioneros políticos",
      "Polymers": "Polímeros",
      "Power Converter": "Convertidor de energía",
      "Power Generators": "Generadores de energía",
      "Power Transfer Bus": "Conductores de transferencia de energía",
      "Praseodymium": "Praseodimio",
      "Precious Gems": "Gemas preciosas",
      "Progenitor Cells": "Células madre",
      "Prohibited Research Materials": "Materiales de investigación prohibida",
      "Prototype Tech": "Prototipos tecnológicos",
      "Pyrophyllite": "Pirofilita",
      "Radiation Baffle": "Deflector de radiación",
      "Rajukru Multi-Stoves": "Multiestufas de Rajukru",
      "Rapa Bao Snake Skins": "Pieles de serpiente de Rapa Bao",
      "Rare Artwork": "Arte poco común",
      "Reactive Armour": "Blindaje reactivo",
      "Rebel Transmissions": "Transmisiones rebeldes",
      "Reinforced Mounting Plate": "Placa de anclaje reforzada",
      "Resonating Separators": "Separadores resonantes",
      "Rhodplumsite": "Rhodplumsita",
      "Robotics": "Robótica",
      "Rockforth Fertiliser": "Fertilizante Rockforth",
      "Rusani Old Smokey": "Old Smokey de Rusani",
      "Rutile": "Rutilio",
      "SAP 8 Core Container": "Núcleos SAP-8",
      "Samarium": "Samario",
      "Sanuma Decorative Meat": "Carne artística de Sanuma",
      "Saxon Wine": "Vino sajón",
      "Scientific Research": "Investigaciones científicas",
      "Scientific Samples": "Muestras científicas",
      "Scrap": "Chatarra",
      "Semiconductors": "Semiconductores",
      "Serendibite": "Serendibita",
      "Shan's Charis Orchid": "Orquídea Charis de Shan",
      "Silver": "Plata",
      "Skimmer Components": "Componentes de deslizador",
      "Slaves": "Esclavos",
      "Small Survey Data Cache": "Memorias de reconocimiento pequeñas",
      "Soontill Relics": "Reliquias de Soontill",
      "Sothis Crystalline Gold": "Oro cristalino de Sothis",
      "Space Pioneer Relics": "Reliquias de pioneros espaciales",
      "Structural Regulators": "Reguladores estructurales",
      "Superconductors": "Superconductores",
      "Surface Stabilisers": "Estabilizadores de superficie",
      "Survival Equipment": "Equipamiento de supervivencia",
      "Synthetic Fabrics": "tejidos sintéticos",
      "Synthetic Meat": "Carne sintética",
      "Synthetic Reagents": "Reagentes sintéticos",
      "Taaffeite": "Taaffeíta",
      "Tactical Data": "Datos tácticos",
      "Tanmark Tranquil Tea": "Té relajante de Tanmark",
      "Tantalum": "Tantalio",
      "Tarach Spice": "Especia de Tarach Tor",
      "Tauri Chimes": "Carillones de 39 Tauri",
      "Tea": "Té",
      "Technical Blueprints": "Planos técnicos",
      "Telemetry Suite": "Paquete de telemetría",
      "Terra Mater Blood Bores": "Potenciadores sanguíneos de Terra Mater",
      "Thallium": "Talio",
      "Thargoid Basilisk Tissue Sample": "Muestra de tejido de basilisco Targoide",
      "Thargoid Biological Matter": "Materia biológica Targoide",
      "Thargoid Cyclops Tissue Sample": "Muestra de tejido de cíclope Targoide",
      "Thargoid Heart": "Corazón Targoide",
      "Thargoid Hydra Tissue Sample": "Muestra de tejido de Hidra Targoide",
      "Thargoid Link": "Enlace Targoide",
      "Thargoid Medusa Tissue Sample": "Muestra de tejido de medusa Targoide",
      "Thargoid Probe": "Sonda Targoide",
      "Thargoid Resin": "Resina Targoide",
      "Thargoid Scout Tissue Sample": "Muestra de tejido de explorador Targoide",
      "Thargoid Sensor": "Sensor targoide",
      "Thargoid Technology Samples": "Muestras de tecnología targoide",
      "The Hutton Mug": "Taza de Hutton",
      "The Waters of Shintara": "Aguas de Shinrarta Dezhra",
      "Thermal Cooling Units": "Unidades de enfriamiento térmico",
      "Thorium": "Torio",
      "Thrutis Cream": "Crema de Thrutis",
      "Tiegfries Synth Silk": "Seda sintética de Tiegfries",
      "Time Capsule": "Cápsula de tiempo",
      "Tiolce Waste2Paste Units": "Pastaplasta de Tiolce",
      "Titanium": "Titanio",
      "Tobacco": "Tabaco",
      "Toxandji Virocide": "Virocida de Toxandji",
      "Toxic Waste": "Residuos tóxicos",
      "Trade Data": "Datos comerciales",
      "Trinkets of Hidden Fortune": "Baratijas de fortuna oculta",
      "Tritium": "Tritio",
      "Ultra-Compact Processor Prototypes": "Prototipos de procesador ultracompactos",
      "Unstable Data Core": "Unidad de datos inestable",
      "Uraninite": "Uranita",
      "Uranium": "Uranio",
      "Uszaian Tree Grub": "Larva de árbol uszaiana",
      "Utgaroar Millennial Eggs": "Huevos milenarios de Utgaroar",
      "Uzumoku Low-G Wings": "Alas de baja gravedad de Uzumoku",
      "V Herculis Body Rub": "Exfoliantes de V1090 Herculis",
      "Vanayequi Ceratomorpha Fur": "Piel de ceratomorfo de Vanayequi",
      "Vega Slimweed": "Planta parásita de Vega",
      "Vidavantian Lace": "Cordón vidavantino",
      "Void Extract Coffee": "Extracto de café al vacío",
      "Void Opals": "Ópalos de vacío",
      "Volkhab Bee Drones": "Abejas mecánicas de Volkhab",
      "Water": "Agua",
      "Water Purifiers": "Purificadores de agua",
      "Wheemete Wheat Cakes": "Pasteles de trigo de Wheemete",
      "Wine": "Vino",
      "Witchhaul Kobe Beef": "Carne de buey kobe de Witchhaul",
      "Wolf Fesh": "Jadeo del Lobo",
      "Wreckage Components": "Restos de accidentes",
      "Wulpa Hyperbore Systems": "Sistemas de hipertaladros de Wulpa",
      "Wuthielo Ku Froth": "Espuma de Wuthielo Ku",
      "Xihe Biomorphic Companions": "Mascotas biomórficas de Xihe",
      "Yaso Kondi Leaf": "Hojas de Yaso Kondi",
      "Zeessze Ant Grub Glue": "Pegamento de larvas de hormiga de Zeessze"
    },
    "dates": {
      "forever": "Por siempre"
    },
    "download": {
      "body": "Nombre del cuerpo",
      "distance": "Distancia para llegar",
      "distance_travelled": "Distancia",
      "fuel_remaining": "Combustible restante",
      "fuel_used": "Combustible usado",
      "icy_ring": "Anillo helado",
      "jumps": "Saltos",
      "mapping_value": "Rango de mapeo",
      "neutron": "Estrella de neutrón",
      "no": "No",
      "pristine": "Pristine",
      "refuel": "Repostar",
      "remaining": "Distancia restante",
      "restock_tritium": "Reabastecimiento de tritio",
      "scan_value": "Rango de escaneo",
      "subtype": "Body Subtype",
      "system": "Nombre del sistema",
      "terraform": "Terraformable",
      "yes": "Si"
    },
    "dumps": {
      "description": "Base de datos relacionada a la galaxia",
      "file_description": "Descripción",
      "filename": "Nombre del archivo",
      "files": {
        "galaxy.json.bz2": "La galaxia entera en formato bz2 (no se actualiza más)",
        "galaxy.json.gz": "Toda la galaxia en formato gzip",
        "galaxy_1day.json.gz": "sistemas donde alguna información fue actualizada en las ultimas 24 horas en formato gzip",
        "galaxy_1month.json.gz": "sistemas donde alguna información fue actualizada en los últimos 32 días en formato gzip",
        "galaxy_7days.json.gz": "sistemas donde alguna información fue actualizada en las últimos 7 días en formato gzip",
        "galaxy_populated.json.bz2": "Sistemas con población mayor a 0 en formato bz2 (no se actualiza más)",
        "galaxy_populated.json.gz": "Sistemas con población mayor a 0 en formato gzip",
        "galaxy_stations.json.bz2": "Sistemas con al menos una estación en formato bz2 (incluyendo Fleet Carriers, no se actualiza más)",
        "galaxy_stations.json.gz": "Sistemas con al menos una estación en formato gzip (incluyendo Fleet Carriers)"
      },
      "modified": "Actualizado",
      "size": "Tamaño del archivo",
      "title": "Base de datos"
    },
    "economy": {
      "Agriculture": "Agricultura",
      "Colony": "Colonia",
      "Extraction": "Extracción",
      "High Tech": "High Tech",
      "Industrial": "Industrial",
      "Military": "Militar",
      "None": "Ninguno",
      "Private Enterprise": "Private Enterprise",
      "Refinery": "Refinería",
      "Service": "Servicios",
      "Terraforming": "Terraformable",
      "Tourism": "Turístico"
    },
    "exact-plotter": {
      "description": "Ésta página le permitirá trazar ruta entre dos sistemas. Intentará trazar la ruta exacta incluyendo el uso del combustible, sobrecarga del motor de distorsión (neutrones) e inyecciones de motor si así lo desea",
      "title": "Trazador de rutas",
      "warning": "Ésta herramienta está en etapa beta y los resultados no está garantizados. También puedes trazar rutas por sistemas que requieran permiso"
    },
    "fields": {
      "add_atmosphere_composition_filter": "Agregar filtro de composición de atmosfera",
      "add_destination": "Agregar destino",
      "add_market_filter": "Agregar filtro de mercado",
      "add_material_filter": "Agregar filtro de material",
      "add_sort_field": "Agregar clasificación",
      "add_via": "Agregar escala",
      "allegiance": "Lealtad",
      "allow_planetary": "Permitir estaciones planetarias",
      "allow_planetary_description": "Incluir estaciones planetarias en la ruta",
      "allow_prohibited": "Permitir carga ilegal",
      "allow_prohibited_description": "Incluye carga que es ilegal en los sistemas de destino",
      "amount": "Cantidad",
      "atmosphere": "Atmósfera",
      "atmosphere_composition": "Composición de la atmósfera",
      "atmosphere_composition_fields": "Composición de la atmósfera",
      "atmosphere_compositions": "Composición de la atmosfera",
      "belt_fields": "Filtro de cinturones",
      "belts": "Cinturones",
      "body_name": "Nombre del cuerpo",
      "buy_price": "Precio de compra",
      "calculate": "Calcular",
      "combined_ring_signal": "Combined Ring Signal",
      "combined_signal": "Combined Body Signal",
      "commodity": "Commodity",
      "commodity_not_found": "Commodity no encontrado",
      "completed": "Hecho",
      "controlling_minor_faction": "Facción al control",
      "coordinates": "Coordenadas de referencia",
      "cumulative_profit": "Beneficio acumulado",
      "delete_destination": "Borrar destino",
      "delete_via": "Borrar escala",
      "demand": "Demanda",
      "destination_system": "Sistema de destino",
      "distance": "Distancia (AL)",
      "distance_from_coords": "Distancia (AL)",
      "distance_to_arrival": "Distancia de llegada (SL)",
      "distance_to_arrival_short": "Distancia (SL)",
      "earth_masses": "Masa de la tierra",
      "efficiency": "Eficiencia (%)",
      "efficiency_description": "Aumente este parámetro para reducir qué tan lejos de la ruta directa trazará el sistema para llegar a una estrella de neutrones (una eficiencia de 100% no desviará la ruta directa para trazar de \"A\" a \"B\" y lo más probable es que divida el viaje en bloques de 20k años luz).",
      "estimated_mapping_value": "Rango de mapeo",
      "estimated_mapping_value_short": "Rango de escaneo",
      "estimated_scan_value": "Rango de escaneo",
      "estimated_scan_value_short": "Rango",
      "export_commodities": "Exportación",
      "faction_presence": "Presencia de facción",
      "flying_time": "{time} tiempo de vuelo",
      "fuel_remaining": "Combustible restante (toneladas)",
      "fuel_remaining_description": "Tritio en el tanque de combustible",
      "fuel_used": "Combustible usado (toneladas)",
      "fuel_used_description": "Tritio usado en el salto",
      "full_spectral_class": "Tipo espectral",
      "government": "Gobierno",
      "gravity": "Gravedad",
      "has_large_pad": "Pad grande",
      "has_market": "Tiene mercado",
      "has_outfitting": "Tiene equipamiento",
      "has_shipyard": "Tiene hangar",
      "icy_ring": "Anillo helado",
      "icy_ring_description": "¿El sistema tiene algún anillo helado?",
      "import_commodities": "Importación",
      "influence": "Influencia",
      "is_landable": "Aterrizable",
      "is_main_star": "Estrella principal",
      "is_planetary": "Planetario",
      "is_supercharged": "Sobrecargado",
      "is_supercharged_description": "¿Su nave está sobrecargada?",
      "jumps": "Saltos",
      "jumps_description": "Número de saltos estimados para este sistema",
      "jumps_remaining": "Saltos restantes",
      "landmark_subtype": "Subtipo de referencia",
      "landmark_type": "Tipo de referencia",
      "landmarks": "Referencia",
      "latitude": "Latitud",
      "longitude": "Longitud",
      "loop": "Loop",
      "loop_description": "Obligar a la ruta a regresar el punto de partida",
      "luminosity_class": "Tipo de luminosidad",
      "main_fields": "Filtros",
      "market": "Mercado",
      "market_capacity": "Capacidad del mercado",
      "market_capacity_description": "La capacidad que ocupan los productos en el mercado",
      "market_fields": "Filtros de mercado",
      "market_updated_at": "Última actualización del mercado",
      "material": "Material",
      "material_fields": "Materiales",
      "materials": "Materiales",
      "maximum_cargo": "Capacidad máxima de carga",
      "maximum_distance": "Distancia máxima de llegada (SL)",
      "maximum_distance_description": "Distancia máxima entre la estación y la estrella de llegada",
      "maximum_hop_distance": "Distancia máxima de salto",
      "maximum_hop_distance_description": "Distancia máxima entre saltos (Puede ser tan grande como el rango de tu nave)",
      "maximum_hops": "Saltos máximos",
      "maximum_market_age": "Maximum Market Age",
      "maximum_market_age_description": "Maximum age the stations in the route can have had their market data updated",
      "maximum_systems": "Maximum Systems",
      "maximum_systems_description": "This is maximum number of systems that the plotter will route you through, lower this for a shorter trip.",
      "minimum_scan_value": "Valor de escaneo mínimo",
      "minor_faction_presences": "Facción presente",
      "module_capacity": "Capacidad de módulo",
      "module_capacity_description": "La capacidad que ocupan los módulos instalados en tu Fleet Carrier",
      "module_category": "Categoría",
      "module_fields": "Filtro de módulos",
      "module_price": "Module Price",
      "modules": "Módulos",
      "name": "Nombre",
      "needs_permit": "Se necesitan permisos",
      "neutron": "Neutrón",
      "neutron_description": "¿Es una estrella de neutrones?",
      "orbital_period": "Periodo orbital (días)",
      "outfitting_updated_at": "Las última actualización de equipamiento",
      "permit": "Activar sistemas que requieran permiso",
      "permit_description": "Incluir sistemas que requieran permisos",
      "population": "Población",
      "power": "Potencia",
      "power_state": "Estado",
      "primary_economy": "Economía",
      "pristine": "Pristino",
      "pristine_description": "¿Tiene el anillo reservas pristinas?",
      "profit": "Beneficio",
      "prohibited_commodities": "Productos prohibidos",
      "radius": "Radio",
      "range": "Rango (AL)",
      "range_description": "El rango de tu nave",
      "reference_coordinates": "Coordenadas de referencia",
      "reference_system": "Sistema de referencia",
      "refuel": "¿Repostaje?",
      "refuel_description": "¿Necesitas repostar?",
      "region": "Región",
      "remaining": "Restan (AL)",
      "requires_large_pad": "Se necesita pad grande",
      "requires_large_pad_description": "Filtrar por estaciones que no necesiten pad grande",
      "reserve_level": "Niveles de reserva",
      "reset": "Reinicio",
      "restock": "¿Reabastecimiento?",
      "restock_description": "¿Necesitas reabastecer tritio?",
      "reverse": "Invertir ruta",
      "ring_fields": "Filtro de anillos",
      "ring_signal": "Señal de anillos",
      "ring_signals": "Señales de anillos",
      "rings": "Anillos",
      "rotational_period": "Periodo de rotación",
      "search": "Búsqueda",
      "search_radius": "Radio (AL)",
      "search_radius_description": "Ésta es la distancia en años luz en la cual la herramienta buscará planetas valiosos para visitar. Un valor de 25 años luz proporcionará un buen equilibrio para las ruta trazada, manteniendo el número de saltos razonablemente bajo, y al mismo tiempo ofrece una buena recompensa. Para las rutas circulares (dejando el espacio en blanco) Probablemente deba aumentar este rango entre 100-500 años luz",
      "secondary_economy": "Economía secundaria",
      "security": "Seguridad",
      "select_all": "Todo",
      "select_allegiance": "Seleccionar alianza",
      "select_atmosphere": "Seleccionar atmósfera",
      "select_controlling_minor_faction": "Seleccionar facción al control",
      "select_export_commodities": "Seleccionar exportaciones",
      "select_faction_presence": "Seleccionar facción presente",
      "select_full_spectral_class": "Seleccionar clase espectral",
      "select_government": "Seleccionar gobierno",
      "select_import_commodities": "Seleccionar importaciones",
      "select_landmark_subtype": "Seleccionar subtipo de referencia",
      "select_landmark_type": "Seleccionar tipo de referencia",
      "select_modules": "Seleccionar módulos",
      "select_none": "Ninguno",
      "select_power": "Seleccionar potencia",
      "select_power_state": "Estado",
      "select_primary_economy": "Seleccionar economía",
      "select_prohibited_commodities": "Seleccionar productos prohibidos",
      "select_reference_system": "Seleccionar sistema de referencia",
      "select_region": "Seleccionar región",
      "select_reserve_level": "Seleccionar niveles de reserva",
      "select_ring_signal": "Seleccionar señal de anillo",
      "select_secondary_economy": "Seleccionar economía secundaria",
      "select_security": "Seleccionar seguridad",
      "select_services": "Seleccionar servicios",
      "select_ships": "Seleccionar naves",
      "select_signal": "Seleccionar señal de cuerpo",
      "select_spectral_class": "Seleccionar clase espectral",
      "select_state": "Seleccionar estado",
      "select_station_type": "Seleccionar tipo de estación",
      "select_subtype": "Seleccionar subtipo",
      "select_system_power": "Seleccionar la potencia del sistema",
      "select_terraforming_state": "Seleccionar estado de terraformación",
      "select_type": "Seleccionar tipo",
      "select_volcanism_type": "Seleccionar vulcanismo",
      "sell_price": "Precio de venta",
      "services": "Servicios",
      "ship_build": "Configuración de nave",
      "ship_build_description": "Pega la build de nave desde Json  <a href=\"https://coriolis.io\">https://coriolis.io</a> o <a href=\"https://edsy.org\">EDSY</a>",
      "ship_price": "Precio de la nave",
      "ships": "Naves",
      "shipyard_updated_at": "Última actualización del hangar",
      "signal": "Señal del cuerpo",
      "signal_count": "Cuenta",
      "signal_fields": "Filtrar señales",
      "signals": "Señales",
      "single_value": "Solo un posible valor",
      "solar_masses": "Masa solar",
      "solar_radius": "Radio Solar",
      "solid_composition": "Composición",
      "sorting": "Tipo de clasificación",
      "sorting_fields": "Clasificación",
      "source_station": "Source Station",
      "source_system": "Source System",
      "spectral_class": "Clase espectral",
      "starting_capital": "Starting Capital",
      "state": "Estado",
      "station_name": "Nombre de la estación",
      "station_type": "Tipo de estación",
      "subtype": "Subtipo",
      "summary": "Resultado de la búsqueda",
      "supply": "Suministro",
      "surface_pressure": "Presión atmosférica",
      "surface_temperature": "Temperatura de la superficie (k)",
      "system_name": "Nombre del sistema",
      "system_power": "Potencia del sistema",
      "system_region": "Región del sistema",
      "terraforming_description": "¿El cuerpo es candidato a terraformación?",
      "terraforming_short": "Terra",
      "terraforming_state": "Estado de terraformación",
      "time_remaining": "Tiempo estimado de llegada",
      "time_taken": "Tiempo estimado",
      "timestamp": "{from} para {to}",
      "total_profit": "Total de ganancias",
      "tritium_fuel": "Tritio en el tanque",
      "tritium_fuel_description": "La cantidad de tritio en tu tanque",
      "tritium_market": "Tritio en el almacén",
      "tritium_market_description": "La cantidad de tritio en el almacén de la Carrier",
      "type": "Tipo",
      "unique": "Evitar Loops",
      "unique_description": "Evitar que el sistema vuelva a visitar estaciones que están en la ruta",
      "updated_at": "Última actualización",
      "use_injections": "Usar inyecciones de MDD",
      "use_injections_description": "Usar síntesis de inyecciones de MDD con una estrella de neutrón no está activado",
      "use_mapping_value": "Usar rango de mapeo",
      "use_mapping_value_description": "Use el rango de mapeo en lugar del rango de escaneo",
      "use_supercharge": "Usar sobrecarga",
      "use_supercharge_description": "Usar estrellas de neutrón para sobrecargar el MDD",
      "used_capacity": "Capacidad usada",
      "used_capacity_description": "La cantidad usada por los módulos instalados y el mercado de la Fleet carrier",
      "via": "Ruta",
      "volcanism_type": "Vulcanismo",
      "x": "X",
      "x_coordinate": "Coordenada X",
      "y": "Y",
      "y_coordinate": "Coordenada Y",
      "z": "Z",
      "z_coordinate": "Coordenada z"
    },
    "fields_help": {
      "body_name": "Usa esto para filtrar los cuerpos con un nombre que coincida. Sol coincidirá con Sol pero no con Solati 6",
      "reference_coords": "Use esto en lugar de Sistema de referencia para indicar qué coordenadas desea buscar(para distancias, etc.)",
      "reference_system": "Use esto para indicar en qué sistema desea buscar (para distancias, etc.)",
      "station_name": "Usa esto para filtrar por estaciones con una palabra que coincida con el nombre",
      "system_name": "Use esto para filtrar por cuerpos en un sistema con coincidencia de palabras, es decir, Col coincidiría con Col 285 Sector IY-W b16-6 pero no Colonia"
    },
    "fleet-carrier": {
      "description": "Esta página le permitirá trazar entre dos sistemas estelares diferentes para su Fleet Carrier. El resultado le mostrará todos los sistemas a los que necesita trazar. También le permitirá saber cuándo combustible necesitará. Las estimaciones de tiempo se basan en 20 minutos por salto y producen 150 toneladas de tritio por hora de extracción para un solo jugador.",
      "title": "Crear ruta de Fleet Carrier",
      "warning": "Esta herramienta ( Y la de Fleet Carriers) están en beta y los resultados no están garantizados"
    },
    "footer": {
      "copyright": "Derechos Reservados {from}-{to} Gareth Harper.",
      "donations": "Si deseas donar para mantener ésta página funcionando y actualizada, quizá deberías pasarte a mi Patreon <a href=\"https://www.patreon.com/spansh\">here</a>",
      "features": "Si tienes sugerencias de funcionamiento o encuentras algún bug puedes visitar <a href=\"https://forums.frontier.co.uk/threads/neutron-highway-long-range-route-planner.308674/\">forum thread</a> o puedes escribirnos a <a href=\"mailto:plotter@spansh.co.uk\">plotter@spansh.co.uk</a>.",
      "thanks": "Gracias a todos los jugadores y exploradores que proporcionan los datos que mantienen este sitio web."
    },
    "government": {
      "Anarchy": "Anarquía",
      "Communism": "Comunismo",
      "Confederacy": "Confederación",
      "Cooperative": "Cooperativa",
      "Corporate": "Corporación",
      "Democracy": "Democracia",
      "Dictatorship": "Dictadura",
      "Engineer": "Ingeniero",
      "Feudal": "Feudal",
      "None": "Ninguno",
      "Patronage": "Patronazgo",
      "Prison": "Centro de detención",
      "Prison Colony": "Colonia penitenciaria",
      "Private Ownership": "Propiedad privada",
      "Theocracy": "Teocracia",
      "Workshop (Engineer)": "Taller de ingeniero"
    },
    "landmark_subtype": {
      "Abandoned Base": "Base abandonanda",
      "Alpha": "Alfa",
      "Ammonia Ice Fumarole": "Fumarola de hielo de amoniaco",
      "Ammonia Ice Geyser": "Géiser de hielo de amoníaco",
      "Amphora Plant": "Tubiformes sinuosos",
      "Aureum Brain Tree": "Árboles de cerebros aereum",
      "Aureus Brain Tree": "Árboles de cerebros Aereus",
      "Bark Mound": "Agrupaciones de corteza",
      "Bear": "Oso",
      "Beta": "Beta",
      "Blatteum Bioluminescent Anemone": "Anémona bioluminiscente blatteum",
      "Bowl": "Bowl",
      "Brain Tree": "Árboles de cerebros",
      "Carbon Dioxide Fumarole": "Fumarola de dióxido de carbono",
      "Carbon Dioxide Gas Vent": "Respiradero de gas de dióxido de carbono",
      "Carbon Dioxide Ice Fumarole": "Fumarola de hielo de dióxido de carbono",
      "Carbon Dioxide Ice Geyser": "Géiser de hielo de dióxido de carbono",
      "Carpasinus Brain Tree": "Árboles de cerebros carpasianus",
      "Crashed Ship": "Nave estrellada",
      "Crater Outpost": "Crater Outpost",
      "Crater Port": "Crater Port",
      "Croceum Anemone": "Croceum Anemone",
      "Crossroads": "Crossroads",
      "Crystalline Shards": "Crystalline Shards",
      "Cymatilis Brain Tree": "Árboles de cerebros cymatilis",
      "Delta": "Delta",
      "Epsilon": "Epsilon",
      "Fistbump": "Fistbump",
      "Gamma": "Gamma",
      "Gypseeum Brain Tree": "Árboles de cerebros gypseeum",
      "Hammerbot": "Hammerbot",
      "Iron Magma Lava Spout": "Iron Magma Lava Spout",
      "Lacrosse": "Lacrosse",
      "Lindigoticum Brain Tree": "Árboles de cerebros lindigoticum",
      "Livens Brain Tree": "Árboles de cerebros livens",
      "Lividum Brain Tree": "Árboles de cerebros lividum",
      "Luteolum Anemone": "Luteolum Anemone",
      "Mega": "Mega",
      "Methane Ice Fumarole": "Fumarola de metano",
      "Methane Ice Geyser": "Géiser de metano",
      "Nitrogen Ice Fumarole": "Fumarola de hielo de nitrógeno",
      "Nitrogen Ice Geyser": "Géiser de hielo de nitrógeno",
      "Ostrinum Brain Tree": "Árboles de cerebros ostrinum",
      "Ostrinus Brain Tree": "Árboles de cerebros ostrinus",
      "Prasinum Bioluminescent Anemone": "Prasinum Bioluminescent Anemone",
      "Prasinus Bioluminescent Anemone": "Prasinus Bioluminescent Anemone",
      "Puniceum Anemone": "Puniceum Anemone",
      "Puniceum Brain Tree": "Árboles de cerebros puniceum",
      "Puniceus Brain Tree": "Árboles de cerebros puniceus",
      "Robolobster": "Robolobster",
      "Roseum Anemone": "Roseum Anemone",
      "Roseum Bioluminescent Anemone": "Roseum Bioluminescent Anemone",
      "Roseum Brain Tree": "Árboles de cerebros roseum",
      "Roseum Sinuous Tubers": "Roseum Sinuous Tubers",
      "Roseus Brain Tree": "Árboles de cerebros roseus",
      "Roseus Sinuous Tuber": "Roseus Sinuous Tuber",
      "Rubeum Bioluminescent Anemone": "Rubeum Bioluminescent Anemone",
      "Silicate Magma Lava Spout": "Silicate Magma Lava Spout",
      "Silicate Vapour Fumarole": "Fumarola de vapor de silicato",
      "Silicate Vapour Gas Vent": "Respiradero de gas de vapor de silicatos",
      "Silicate Vapour Ice Fumarole": "Fumarola de hielo de vapor de silicato",
      "Squid": "Squid",
      "Stickyhand": "Stickyhand",
      "Sulphur Dioxide Fumarole": "Fumarola de dióxido de sulfuro",
      "Sulphur Dioxide Gas Vent": "Respiradero de gas de dióxido de sulfuro",
      "Sulphur Dioxide Ice Fumarole": "Fumarola de hielo de dióxido de sulfuro",
      "Surface Station": "Estación planetaria",
      "Thargoid Structure": "Estructura targoide",
      "Tourist Beacon": "Baliza turística",
      "Turtle": "Tortuga",
      "Unknown": "Desconocido",
      "Viridans Brain Tree": "Árboles de cerebros viridans",
      "Viride Brain Tree": "Árboles de cerebros viride",
      "Water Fumarole": "Fumarola de agua",
      "Water Gas Vent": "Respiradero de gas de agua",
      "Water Geyser": "Géiser de agua",
      "Water Ice Fumarole": "Fumarola de hielo de agua",
      "Water Ice Geyser": "Géiser de hielo de agua",
      "Wrecked Ship": "Restos de nave"
    },
    "landmark_type": {
      "Abandoned Base": "Base abandonada",
      "Amphora Plant": "Amphora Plant",
      "Bark Mound": "Bark Mound",
      "Brain Tree": "Árboles de cerebros",
      "Crashed Ship": "Nave estrellada",
      "Crystalline Shard": "Crystalline Shard",
      "Fumarole": "Fumarola",
      "Fungal Gourd": "Fungal Gourd",
      "Gas Vent": "Respiradero de gas",
      "Geyser": "Géiser",
      "Guardian Ruin": "Ruinas guardianas",
      "Guardian Structure": "Estructura guardián",
      "Lava Spout": "Lava Spout",
      "Surface Station": "Estación planetaria",
      "Thargoid Barnacle": "Thargoid Barnacle",
      "Thargoid Structure": "Thargoid Structure",
      "Tourist Beacon": "Baliza turística",
      "Tube Worm": "Tube Worm",
      "Wrecked Ship": "Restos de nave"
    },
    "map": {
      "description": "Un mapa con todas las estrellas de neutrones en la galaxia.",
      "title": "Mapa de estrellas de neutrones"
    },
    "market": {
      "buy_price": "Precio de compra",
      "buy_price_header": "{commodity} precio de compra",
      "demand": "Demanda",
      "demand_header": "{commodity} demanda",
      "remove_field": "Quitar filtro",
      "sell_price": "Precio de venta",
      "sell_price_header": "{commodity} precio de venta",
      "supply": "Suministro",
      "supply_header": "{commodity} suministro"
    },
    "material": {
      "Antimony": "Antinomio",
      "Arsenic": "Arsénico",
      "Cadmium": "Cadmio",
      "Carbon": "Carbono",
      "Chromium": "Cromo",
      "Germanium": "Germanio",
      "Iron": "Hierro",
      "Manganese": "Magnesio",
      "Mercury": "Mercurio",
      "Molybdenum": "Molibdeno",
      "Nickel": "Niquel",
      "Niobium": "Niobio",
      "Phosphorus": "Fósforo",
      "Polonium": "Polonio",
      "Ruthenium": "Rutenio",
      "Selenium": "Selenio",
      "Sulphur": "Sulfuro",
      "Technetium": "Tecnecio",
      "Tellurium": "Telurio",
      "Tin": "Estaño",
      "Tungsten": "Tungsteno",
      "Vanadium": "Vanadio",
      "Yttrium": "Ytrio",
      "Zinc": "Zinc",
      "Zirconium": "Circonio"
    },
    "modules": {
      "add_field": "Agregar filtro",
      "categories": {
        "hardpoint": "Anclaje",
        "internal": "Interno Principales",
        "standard": "Internos opcionales",
        "utility": "Anclajes de apoyo"
      },
      "class": "Clase",
      "name": "Nombre del módulo",
      "names": {
        "AX Missile Rack": "Misiles AX",
        "AX Multi-Cannon": "Multicañones AX",
        "Abrasion Blaster": "Láser de abrasión",
        "Advanced Discovery Scanner": "Escaneo Completo del Sistema",
        "Advanced Docking Computer": "Computadora avanzada de aterrizaje",
        "Advanced Missile Rack": "Misiles avanzados",
        "Advanced Multi-Cannon": "Multicañones avanzados",
        "Advanced Plasma Accelerator": "Acelerador de plasma avanzado",
        "Auto Field-Maintenance Unit": "Unidad de automantenimiento",
        "Basic Discovery Scanner": "Escáner de superficie",
        "Beam Laser": "Láser de rayo",
        "Bi-Weave Shield Generator": "Generador de escudo entrelazados",
        "Burst Laser": "Láser de ráfagas",
        "Business Class Passenger Cabin": "Cabina de pasajero clase preferente",
        "Cannon": "Cañón",
        "Cargo Rack": "Bodega de carga",
        "Cargo Scanner": "Escáner de manifiesto",
        "Chaff Launcher": "Señuelos",
        "Collector Limpet Controller": "Drones de recolección",
        "Corrosion Resistant Cargo Rack": "Bodega de carga anticorrosión",
        "Cytoscrambler Burst Laser": "Láser de ráfagas de citodistorción",
        "Decontamination Limpet Controller": "Drones de descontaminación",
        "Detailed Surface Scanner": "Escáner de superficie",
        "Economy Class Passenger Cabin": "Cabina de pasajero económica",
        "Electronic Countermeasure": "Contramedidas electrónicas",
        "Enforcer Cannon": "Cañón ejecutor",
        "Enhanced Performance Thrusters": "Enhanced Performance Thrusters",
        "Enzyme Missile Rack": "Lanzamisiles de enzimas",
        "Fighter Hangar": "Hangar de cazas",
        "First Class Passenger Cabin": "Cabina de pasajero primera clase",
        "Fragment Cannon": "Cañón de fragmentación",
        "Frame Shift Drive": "Motor de distorción",
        "Frame Shift Drive Interdictor": "Interdictador de motor de distorción",
        "Frame Shift Wake Scanner": "Escáner de estelas",
        "Fuel Scoop": "Colector de combustible",
        "Fuel Tank": "Tanque de combustible",
        "Fuel Transfer Limpet Controller": "Drones de transferencia de combustible",
        "Guardian FSD Booster": "Potenciador de motor de distorsión guardián",
        "Guardian Gauss Cannon": "Cañón Gauss guardián",
        "Guardian Hull Reinforcement": "Refuerzo de estructura guardián",
        "Guardian Hybrid Power Distributor": "Distribuidor híbrido de energía",
        "Guardian Hybrid Power Plant": "Generador de energía guardián",
        "Guardian Module Reinforcement": "Refuerzo de módulo guardían",
        "Guardian Plasma Charger": "Cargador de plasma guardián",
        "Guardian Shard Cannon": "Cañón de esquirlas guardián",
        "Guardian Shield Reinforcement": "Refuerzo de escudo guardián",
        "Hatch Breaker Limpet Controller": "Drones extractores",
        "Heat Sink Launcher": "Eyector térmico",
        "Hull Reinforcement Package": "Refuerzo estructural",
        "Imperial Hammer Rail Gun": "Cañón de riel martillo imperial",
        "Intermediate Discovery Scanner": "Escáner de exploración",
        "Kill Warrant Scanner": "Escáneres de registro criminal",
        "Life Support": "Soporte vital",
        "Lightweight Alloy": "Aleaciones ligeras",
        "Limpet Control": "Drones",
        "Luxury Class Passenger Cabin": "Cabina de pasajero de lujo",
        "Meta Alloy Hull Reinforcement": "Meta Alloy Hull Reinforcement",
        "Military Grade Composite": "Compuesto de clase militar",
        "Mine Launcher": "Lanzador de minas",
        "Mining Lance": "Láser de rayos Lanza perforadora",
        "Mining Laser": "Láser de minería",
        "Mirrored Surface Composite": "Compuesto de superficie reflectante",
        "Missile Rack": "Lanzamisiles",
        "Module Reinforcement Package": "refuerzo de módulo",
        "Multi-Cannon": "multicañón",
        "Pacifier Frag-Cannon": "Cañón de fragmentación pacificador",
        "Pack-Hound Missile Rack": "Lanzamisiles de jauría de sabuesos",
        "Planetary Approach Suite": "Equipo de aproximación planetaria",
        "Planetary Vehicle Hangar": "Hangar de vehículo planetario",
        "Plasma Accelerator": "Acelerador de plasma",
        "Point Defence": "Defensa de punto",
        "Power Distributor": "Distribuidor de energía",
        "Power Plant": "Generador de energía",
        "Prismatic Shield Generator": "Generador de escudos prismático",
        "Prospector Limpet Controller": "Drones de prospección",
        "Pulse Disruptor Laser": "Láser de pulsos disroptores",
        "Pulse Laser": "Láser de pulso",
        "Pulse Wave Analyser": "Analizador de ondas de pulso",
        "Rail Gun": "Cañón de riel",
        "Reactive Surface Composite": "Compuesto de superficie reactiva",
        "Recon Limpet Controller": "Dron de reconocimiento",
        "Refinery": "Refinería",
        "Reinforced Alloy": "Aleaciones reforzadas",
        "Remote Release Flak Launcher": "Lanzador antiaéreo remoto",
        "Remote Release Flechette Launcher": "Remote Release Flechette Launcher",
        "Repair Limpet Controller": "Drones de reparación",
        "Research Limpet Controller": "Drones de investigación",
        "Retributor Beam Laser": "Láser de rayos vengador",
        "Rocket Propelled FSD Disruptor": "Misil de contención propulsado por cohetes",
        "Seeker Missile Rack": "Lanzamisiles guiados",
        "Seismic Charge Launcher": "Lanzador de carga sísmica",
        "Sensors": "Sensores",
        "Shield Booster": "Potenciador de escudos",
        "Shield Cell Bank": "Acumulador de escudos",
        "Shield Generator": "Generador de escudo",
        "Shock Cannon": "Cañón de esquirlas guardián",
        "Shock Mine Launcher": "Lanzaminas de impacto",
        "Shutdown Field Neutraliser": "Neutralizador de campo de desactivación",
        "Standard Docking Computer": "Computadora de aterrizaje asistido",
        "Sub-Surface Displacement Missile": "Misil de desplazamiento subsuperficial",
        "Supercruise Assist": "Asistente de supercrucero",
        "Thrusters": "Impulsores",
        "Torpedo Pylon": "Torpedo",
        "Xeno Scanner": "Xeno escáner"
      },
      "rating": "Nivel",
      "remove_field": "Quitar filtro",
      "select_class": "Selecciona la clase",
      "select_name": "Selecciona el nombre del módulo",
      "select_rating": "Selecciona el nivel"
    },
    "navigation": {
      "bodies": "Cuerpos",
      "donations": "Donaciones",
      "dumps": "Base de datos",
      "exact-plotter": "Trazador de rutas",
      "fleet-carrier": "Fleet Carrier rutas",
      "language": "Lenguajes",
      "login": "Iniciar sesión",
      "logout": "Cerrar sesión",
      "map": "Mapa",
      "nearest": "Sistemas cercanos",
      "oldsite": "Sitio viejo",
      "plotter": "Ruta de neutrones",
      "riches": "Camino a la riqueza",
      "routers": "Trazador de rutas",
      "search": "Búsqueda",
      "settings": "Configuración",
      "stations": "Estaciones",
      "systems": "Sistemas",
      "thanks": "Agradecimientos",
      "theme": "Tema",
      "toggle": "Toggle navigation",
      "tourist": "Rutas turísticas",
      "trade": "Rutas comerciales"
    },
    "nearest": {
      "description": "Esta página permite encontrar los sistemas cercanos a las coordenadas",
      "title": "Sistemas cercanos conocidos"
    },
    "no": "No",
    "plotter": {
      "description": "Esta página te permitirá trazar una ruta entre dos sistemas  diferentes. El resultado se mostrará cada vez que necesites ir al mapa de galaxia para trazar una nueva ruta (por ejemplo, cuando se encuentre en una estrella de neutrones). Dará prioridad a las estrellas de neutrones, por lo que debes evitar viajes que sean inferiores a 500 años luz (es probable que primero intente llevarte a Jackson's Lighthouse si estás en la burbuja).",
      "exact_plotter": "Si está buscando un trazador más completo que incluya repostaje, estrellas de neutrones secundarias e inyección de MDD, utilice el nuevo <a href=\"{link}\"> Galaxy Plotter </a>\n",
      "title": "Trazador de rutas de neutrones",
      "warning": "Si su salto es de (10-20 AL) Puede potenciar el MDD con una síntesis de inyección del motor para alcanzar un sistema al que no tenga suficiente rango. Pero debe tener cuidado y verificar que pueda salir de ese sistema usando el mapa de la galaxia."
    },
    "power_state": {
      "Contested": "Disputado",
      "Control": "Control",
      "Controlled": "Controlado",
      "Exploited": "Explotado",
      "HomeSystem": "Cuartel general",
      "InPrepareRadius": "Preparación",
      "Prepared": "Preparado",
      "Turmoil": "Turmoil"
    },
    "region": {
      "Acheron": "Acheron",
      "Achilles's Altar": "Achilles's Altar",
      "Aquila's Halo": "Aquila's Halo",
      "Arcadian Stream": "Arcadian Stream",
      "Dryman's Point": "Dryman's Point",
      "Elysian Shore": "Elysian Shore",
      "Empyrean Straits": "Empyrean Straits",
      "Errant Marches": "Errant Marches",
      "Formorian Frontier": "Formorian Frontier",
      "Galactic Centre": "Centro galáctico",
      "Hawking's Gap": "Hawking's Gap",
      "Hieronymus Delta": "Hieronymus Delta",
      "Inner Orion Spur": "Inner Orion Spur",
      "Inner Orion-Perseus Conflux": "Inner Orion-Perseus Conflux",
      "Inner Scutum-Centaurus Arm": "Inner Scutum-Centaurus Arm",
      "Izanami": "Izanami",
      "Kepler's Crest": "Kepler's Crest",
      "Lyra's Song": "Lyra's Song",
      "Mare Somnia": "Mare Somnia",
      "Newton's Vault": "Newton's Vault",
      "Norma Arm": "Norma Arm",
      "Norma Expanse": "Norma Expanse",
      "Odin's Hold": "Odin's Hold",
      "Orion-Cygnus Arm": "Orion-Cygnus Arm",
      "Outer Arm": "Outer Arm",
      "Outer Orion Spur": "Outer Orion Spur",
      "Outer Orion-Perseus Conflux": "Outer Orion-Perseus Conflux",
      "Outer Scutum-Centaurus Arm": "Outer Scutum-Centaurus Arm",
      "Perseus Arm": "Perseus Arm",
      "Ryker's Hope": "Ryker's Hope",
      "Sagittarius-Carina Arm": "Sagittarius-Carina Arm",
      "Sanguineous Rim": "Sanguineous Rim",
      "Temple": "Temple",
      "Tenebrae": "Tenebrae",
      "The Abyss": "The Abyss",
      "The Conduit": "The Conduit",
      "The Formidine Rift": "The Formidine Rift",
      "The Veils": "The Veils",
      "The Void": "The Void",
      "Trojan Belt": "Trojan Belt",
      "Vulcan Gate": "Vulcan Gate",
      "Xibalba": "Xibalba"
    },
    "reserve_level": {
      "Common": "Común",
      "Depleted": "Agotado",
      "Low": "Bajo",
      "Major": "Major",
      "Pristine": "Pristine"
    },
    "results": {
      "atmosphere_composition_to_show": "Composición de la atmósfera que se mostrará",
      "bodies_count": "{count} cuerpos",
      "body_belts": "{name} Cinturones",
      "body_landmarks": "{name} Referencia",
      "body_rings": "{name} Anillos",
      "close": "Cerrado",
      "commodities_to_show": "Mercancías para mostrar",
      "commodity_fields_to_show": "Campos de mercancías para mostrar",
      "copy_to_clipboard": "Copiar {system} al portapapeles",
      "create_route": "Crear una ruta turística a partir de la búsqueda",
      "download": "Descargar excel",
      "estimated_jumps": "Saltos estimados: {jumps}",
      "estimated_time": "Tiempo estimado: {time}",
      "fields_to_show": "Campos que se muestran",
      "first": "Primero",
      "galaxy_map_visits": "Número de visitas al mapa necesarias: {total_visits}",
      "hide_search": "Ocultar búsqueda",
      "jumps": "Saltos: {jumps}",
      "last": "Último",
      "materials_to_show": "Materiales para mostrar",
      "next": "Siguiente",
      "no_results": "No hay resultados",
      "no_search": "Ejecute una búsqueda para ver resultados",
      "none": "Ninguno",
      "previous": "Previo",
      "results": "Resultados",
      "results_per_page": "Resultados por página",
      "show_route": "Mostrar ruta",
      "show_search": "Mostrar búsqueda",
      "showing": "Mostrar resultados {start} a {end} de {count}",
      "systems_count": "{count} Sistemas",
      "totals": "Total"
    },
    "riches": {
      "blank": "Deje el Destino en blanco para crear un recorrido de ida y vuelta (loop)",
      "guides": "Puede encontrar algunas guías y videos para usar ECS y el Escáner planetario <a href=\"https://forums.frontier.co.uk/showthread.php/464149-The-FSS-A-pictoral-guide\"> aquí </ a> y <a href=\"https://forums.frontier.co.uk/showthread.php/465649-Setup-and-Use-of-the-FSS-and-DSS-With-Demonstration-Videos\"> aquí </a>.",
      "low_results": "El número de sistemas mostrados es menor de lo que solicitó. Intente aumentar el radio de búsqueda para encontrar mas cuerpos. Si aún no obtiene suficientes resultados, es posible que haya establecido un valor de escaneo mínimo muy bajo",
      "title": "Camino a la riqueza",
      "warning": "Debe decidir si usará el escáner de superficie (que requiere ir hacia el cuerpo y mapear la superficie) o si usará simplemente el ECS (Escáner Completo de Sistema). El ECS es mas rápido, pero obtendrá menos créditos. La casilla de verificación \"Usar rango de mapeo\" clasificará los cuerpos de recompensa por escaneo en lugar del Escáner completo del sistema"
    },
    "ring_signal": {
      "Alexandrite": "Alejandrita",
      "Bauxite": "Bauxita",
      "Benitoite": "Benitoita",
      "Bertrandite": "Bertrandita",
      "Biological": "Biológica",
      "Bromellite": "Bromelita",
      "Cobalt": "Cobalto",
      "Coltan": "Coltan",
      "Gallite": "Galita",
      "Geological": "Geológica",
      "Gold": "Oro",
      "Grandidierite": "Grandidierita",
      "Guardian": "Guardián",
      "Human": "Humana",
      "Hydrogen Peroxide": "Peróxido de oxígeno",
      "Indite": "Indita",
      "Lepidolite": "Lepidolita",
      "Liquid oxygen": "oxígeno líquido",
      "Lithium Hydroxide": "Hidróxido de litio",
      "Low Temperature Diamonds": "diamantes de baja temperatura",
      "Methane Clathrate": "Methane Clathrate",
      "Methanol Monohydrate Crystals": "Methanol Monohydrate Crystals",
      "Monazite": "Monacita",
      "Musgravite": "Musgravita",
      "Osmium": "Osmio",
      "Other": "Otro",
      "Painite": "Painita",
      "Platinum": "Platino",
      "Praseodymium": "Praseodimio",
      "Rhodplumsite": "Rhodplumita",
      "Rutile": "Rutilio",
      "Samarium": "Samario",
      "Serendibite": "Serenbidita",
      "Silver": "Plata",
      "Thargoid": "Targoide",
      "Tritium": "Tritio",
      "Uraninite": "Uranita",
      "Void Opals": "Ópalos del vacío",
      "Water": "Agua"
    },
    "rings": {
      "add_field": "Agregar filtro",
      "inner_radius": "Radio interno (m)",
      "mass": "Masa (Mt)",
      "outer_radius": "Radio externo (m)",
      "remove_field": "Quitar filtro",
      "select_type": "Seleccionar tipo",
      "type": "Tipo",
      "types": {
        "Icy": "Helado",
        "Metal Rich": "Rico en metal",
        "Metallic": "Metálico",
        "Rocky": "Rocoso"
      }
    },
    "security": {
      "Anarchy": "Anarquía",
      "High": "Alta",
      "Lawless": "Ilegal",
      "Low": "Baja",
      "Medium": "Media"
    },
    "services": {
      "Autodock": "Asistente de aterrizaje",
      "Black Market": "Mercado negro",
      "Contacts": "Contactos",
      "Crew Lounge": "Sala de tripulación",
      "Dock": "Aterrizaje",
      "Facilitator": "Facilitator",
      "Fleet Carrier Fuel": "Combustible de carrier",
      "Fleet Carrier Management": "Administración de portanaves",
      "Fleet Carrier Vendor": "Proveedor de Fleet Carrier",
      "Flight Controller": "Controlador aéreo",
      "Interstellar Factors Contact": "Interstellar Factors",
      "Market": "Mercado",
      "Material Trader": "Comerciante de materiales",
      "Missions": "Misiones",
      "Missions Generated": "Misión garantizada",
      "Module Packs": "Module Packs",
      "On Dock Mission": "On Dock Mission",
      "Outfitting": "Equipamiento",
      "Powerplay": "Power Play",
      "Redemption Office": "Redemption Office",
      "Refuel": "Repostar",
      "Repair": "Reparación",
      "Restock": "Reabastecimiento",
      "Search and Rescue": "Búsqueda y rescate",
      "Shipyard": "Hangar",
      "Shop": "Shop",
      "Station Menu": "Station Menu",
      "Station Operations": "Station Operations",
      "Technology Broker": "Broker de tecnología",
      "Tuning": "Tuning",
      "Universal Cartographics": "Universal Cartographics",
      "Workshop": "Taller"
    },
    "settings": {
      "confirm_delete": "Confirmar borrar usuario",
      "confirm_delete_warning": "Advertencia, si continua borrará su cuenta de usuario y los datos relacionados con ella, incluyendo entre otros los escaneos guardados del camino a la riqueza. Puede volver a crear un usuario vinculando su cuenta de Frontier, pero sus datos no serán restaurados. ¿Aún así desea continuar?",
      "delete_help": "Borrar cuenta de usuario y cualquier dato guardado",
      "delete_user": "Borrar usuario"
    },
    "ships": {
      "Adder": "Adder",
      "Alliance Challenger": "Alliance Challenger",
      "Alliance Chieftain": "Alliance Chieftain",
      "Alliance Crusader": "Alliance Crusader",
      "Anaconda": "Anaconda",
      "Asp Explorer": "Asp Explorer",
      "Asp Scout": "Asp Scout",
      "Beluga Liner": "Beluga Liner",
      "Cobra MkIII": "Cobra MkIII",
      "Cobra MkIV": "Cobra MkIV",
      "Diamondback Explorer": "Diamondback Explorer",
      "Diamondback Scout": "Diamondback Scout",
      "Dolphin": "Dolphin",
      "Eagle": "Eagle",
      "Federal Assault Ship": "Nave de asalto federal",
      "Federal Corvette": "Corveta federal",
      "Federal Dropship": "Nave de descenso federal",
      "Federal Gunship": "Federal Gunship",
      "Fer-de-Lance": "Fer-de-Lance",
      "Hauler": "Hauler",
      "Imperial Clipper": "Cliper imperial",
      "Imperial Courier": "Courier imperial",
      "Imperial Cutter": "Cuter imperial",
      "Imperial Eagle": "Eagle imperial",
      "Keelback": "Keelback",
      "Krait MkII": "Krait MkII",
      "Krait Phantom": "Krait Phantom",
      "Mamba": "Mamba",
      "Orca": "Orca",
      "Python": "Python",
      "Sidewinder": "Sidewinder",
      "Type-10 Defender": "Type-10 Defender",
      "Type-6 Transporter": "Type-6 Transporter",
      "Type-7 Transporter": "Type-7 Transporter",
      "Type-9 Heavy": "Type-9 Heavy",
      "Viper MkIII": "Viper MkIII",
      "Viper MkIV": "Viper MkIV",
      "Vulture": "Vulture"
    },
    "signal": {
      "Alexandrite": "Alexandrita",
      "Bauxite": "Bauxita",
      "Benitoite": "Benitoita",
      "Bertrandite": "Bertrandrita",
      "Biological": "Biológica",
      "Bromellite": "Bromelita",
      "Cobalt": "Cobalto",
      "Coltan": "Coltán",
      "Gallite": "Galita",
      "Geological": "Geológica",
      "Gold": "Oro",
      "Grandidierite": "Grandidierita",
      "Guardian": "Guardián",
      "Human": "Humana",
      "Hydrogen Peroxide": "Peróxido de hidrógeno",
      "Indite": "Indita",
      "Lepidolite": "Lepidolita",
      "Liquid oxygen": "Oxígeno líquido",
      "Lithium Hydroxide": "Hidróxido de litio",
      "Low Temperature Diamonds": "Diamantes de baja temperatura",
      "Methane Clathrate": "Hidrato de metano",
      "Methanol Monohydrate Crystals": "Cristales de monohidrato de metanol",
      "Monazite": "Monacita",
      "Musgravite": "Musgravita",
      "Osmium": "Osmio",
      "Other": "Otro",
      "Painite": "Painita",
      "Platinum": "Platino",
      "Praseodymium": "Praseodimio",
      "Rhodplumsite": "Rodplumsita",
      "Rutile": "Rutilio",
      "Samarium": "Samario",
      "Serendibite": "Serendibita",
      "Silver": "Plata",
      "Thargoid": "Targoide",
      "Tritium": "Tritio",
      "Uraninite": "Uranita",
      "Void Opals": "Ópalos de vacío",
      "Water": "Agua"
    },
    "solid_composition": {
      "Ice": "Hielo",
      "Metal": "Metal",
      "Rock": "Roca"
    },
    "sorting": {
      "asc": "Ascendente",
      "desc": "Descendente"
    },
    "state": {
      "Blight": "Plaga",
      "Boom": "Auge",
      "Bust": "Quiebra",
      "Civil Liberty": "Libertad",
      "Civil Unrest": "Disturbios",
      "Civil War": "Guerra civil",
      "Drought": "Sequía",
      "Election": "Elecciones",
      "Expansion": "Expansión",
      "Famine": "Hambruna",
      "Infrastructure Failure": "Fallo de infraestructuras",
      "Investment": "Inversión",
      "Lockdown": "Bloqueo",
      "Natural Disaster": "Desastre natural",
      "None": "Ninguno",
      "Outbreak": "Epidemia",
      "Pirate Attack": "Ataque pirata",
      "Public Holiday": "Día festivo",
      "Retreat": "Retirada",
      "Terrorism": "Terrorismo",
      "Terrorist Attack": "Ataque terrorista",
      "War": "Guerra"
    },
    "station": {
      "market": "Mercado",
      "market_updated_at": "Actualización del mercado {relative_updated_at} ({updated_at})",
      "no_market_id": "Identificación de mercado no proporcionado",
      "not_found": "Estación no encontrada",
      "outfitting": "Equipamiento",
      "outfitting_updated_at": "Equipamiento actualizado {relative_updated_at} ({updated_at})",
      "shipyard": "Hangar",
      "shipyard_updated_at": "Hangar actualizado {relative_updated_at} ({updated_at})",
      "station": "Estación"
    },
    "station_type": {
      "Asteroid base": "Base en asteroide",
      "Coriolis Starport": "Estación coriolis",
      "Drake-Class Carrier": "Drake-Class Carrier",
      "Mega ship": "Meganave",
      "Ocellus Starport": "Estación Ocellus",
      "Orbis Starport": "Estación orbis",
      "Outpost": "Outpost",
      "Planetary Outpost": "Estación planetaria",
      "Planetary Port": "Puerto planetario"
    },
    "subtype": {
      "A (Blue-White super giant) Star": "Estrella (Blanco-azulada super gigante) A",
      "A (Blue-White) Star": "Estrella (Blanco-azulada) A",
      "Ammonia world": "Mundo de amoniaco",
      "B (Blue-White super giant) Star": "Estrella (Blanco-azulada super gigante) B",
      "B (Blue-White) Star": "Estrella (Blanco-azulada) B",
      "Black Hole": "Agujero negro",
      "C Star": "Estrella C",
      "CJ Star": "Estrella CJ",
      "CN Star": "Estrella CN",
      "Class I gas giant": "Gigante de gas clase I",
      "Class II gas giant": "Gigante de gas clase II",
      "Class III gas giant": "Gigante de gas clase III",
      "Class IV gas giant": "Gigante de gas clase IV",
      "Class V gas giant": "Gigante de gas clase V",
      "Earth-like world": "Mundo de tipo tierra",
      "F (White super giant) Star": "Estrella (Blanca super gigante) F",
      "F (White) Star": "Estrella (Blanca) F",
      "G (White-Yellow super giant) Star": "Estrella (Blanca-amarilla super gigante) G",
      "G (White-Yellow) Star": "Estrella (Blanca-amarilla) G",
      "Gas giant with ammonia-based life": "Gigante gaseoso con vida basada en amoniaco",
      "Gas giant with water-based life": "Gigante gaseoso con vida basada en agua",
      "Helium gas giant": "Gigante de gas de helio",
      "Helium-rich gas giant": "Gigante gaseoso rico en helio",
      "Herbig Ae/Be Star": "Herbig Ae/Be Star",
      "High metal content world": "Mundo con alto contenido en metal",
      "Icy body": "Cuerpo helado",
      "K (Yellow-Orange giant) Star": "Estrella (Amarillo-anaranjada gigante) K",
      "K (Yellow-Orange) Star": "Estrella (Amarillo-anaranjada) K",
      "L (Brown dwarf) Star": "Estrella (Enana marrón) L",
      "M (Red dwarf) Star": "Estrella (Enana roja) M",
      "M (Red giant) Star": "Estrella (Gigante roja) M",
      "M (Red super giant) Star": "Estrella (Roja Super gigante) M",
      "MS-type Star": "Estrella tipo MS",
      "Metal-rich body": "Cuerpo rico en metal",
      "Neutron Star": "Estrella de neutrones",
      "O (Blue-White) Star": "Estrella (Blanco-azulada) O",
      "Rocky Ice world": "Mundo rocoso helado",
      "Rocky body": "Mundo rocoso",
      "S-type Star": "Estrellas tipo-S",
      "Supermassive Black Hole": "Agujero negro supermasivo",
      "T (Brown dwarf) Star": "Estrella (Enana marrón) T",
      "T Tauri Star": "Estrella Tauri T",
      "Water giant": "Gigante de agua",
      "Water world": "Mundo acuático",
      "White Dwarf (D) Star": "Enana blanca (D)",
      "White Dwarf (DA) Star": "Enana blanca (DA)",
      "White Dwarf (DAB) Star": "Enana blanca (DAB)",
      "White Dwarf (DAV) Star": "Enana blanca (DAV)",
      "White Dwarf (DAZ) Star": "Enana blanca (DAZ)",
      "White Dwarf (DB) Star": "Enana blanca (DB)",
      "White Dwarf (DBV) Star": "Enana blanca (DBV)",
      "White Dwarf (DBZ) Star": "Enana blanca (DBZ)",
      "White Dwarf (DC) Star": "Enana blanca (DC)",
      "White Dwarf (DCV) Star": "Enana blanca (DCV)",
      "White Dwarf (DQ) Star": "Enana blanca (DQ)",
      "Wolf-Rayet C Star": "Estrella Wolf-Rayet C",
      "Wolf-Rayet N Star": "Estrella Wolf-Rayet N",
      "Wolf-Rayet NC Star": "Estrella Wolf-Rayet NC",
      "Wolf-Rayet O Star": "Estrella Wolf-Rayet O",
      "Wolf-Rayet Star": "Estrella Wolf-Rayet",
      "Y (Brown dwarf) Star": "Estrella (Enana marrón) Y"
    },
    "system": {
      "bodies": "Cuerpos ({size})",
      "factions": "Faccción ({size})",
      "no_id64": "Sin identificación del cuerpo",
      "not_found": "Sistema no encontrado",
      "stations": "Estaciones ({size})",
      "system": "Sistema",
      "title": "Sistema",
      "updated_at": "Actualización {relative_updated_at} ({updated_at})"
    },
    "terraforming_state": {
      "Candidate for terraforming": "Candidato a terraformación",
      "Not terraformable": "No terraformable",
      "Terraformable": "Candidato a terraformación",
      "Terraformed": "Terraformado",
      "Terraforming": "Terraformando"
    },
    "thanks": {
      "description": "Muchas de las funciones de esta página no hubieran sido posibles sin la amplia ayuda que he recibido. Esta lista no es exhaustiva y estoy agradecido con todos los que me han ayudado. Si alguien cree que se ha quedado fuera, póngase en contacto conmigo.",
      "developers": {
        "description": "Los otros desarrolladores que han ayudado proporcionando bases de datos, información y consejos.",
        "title": "Desarrolladores"
      },
      "patreon": {
        "description": "Patrons que han contribuido con los costos de hosting y desarrollo.",
        "title": "Patrons"
      },
      "players": {
        "description": "Me gustaría agradecer a todos los jugadores y exploradores que ejecutan las diversas herramientas de recopilación de datos que nos permiten reunir información sobre la galaxia, lo que hace que estos sitios sean posibles, pero algunos han ido más allá.",
        "title": "Jugadores"
      },
      "title": "Agradecimientos",
      "translators": {
        "description": "A los traductores que han hecho la versión de esta página a su lengua nativa.",
        "title": "Traductores"
      }
    },
    "titles": {
      "body": "Cuerpo {body}",
      "body_search": "Búsqueda de cuerpo",
      "body_search_results": "Resultados de la búsqueda",
      "dumps": "Base de datos",
      "exact_plotter": "Trazador de rutas",
      "exact_plotter_results": "Resultados del trazador de rutas",
      "fleet_carrier": "Fleet Carrier",
      "fleet_carrier_results": "Rutas de Carrier",
      "main": "Índice de Elite Dangerous",
      "nearest": "Sistemas conocidos cercanos",
      "plotter": "Ruta de neutrones",
      "plotter_results": "Trazador de rutas de neutrones",
      "riches": "Camino a la riqueza",
      "riches_results": "Trazador de rutas para el camino a la riqueza",
      "station": "Estación {station}",
      "station_search": "Búsqueda de estación",
      "station_search_results": "Resultados de la búsqueda de estación",
      "system": "Sistema {system}",
      "system_search": "Búsqueda del sistema",
      "system_search_results": "Resultados de la búsqueda del sistema",
      "thanks": "Gracias",
      "tourist": "Rutas turísticas",
      "tourist_results": "Trazador de rutas turísticas",
      "trade": "Rutas comerciales",
      "trade_results": "Resultados de las rutas comerciales"
    },
    "tourist": {
      "description": "Esta página le permitirá planificar una ruta entre varios sistemas que desea visitar en un orden óptimo.",
      "title": "Trazador de rutas turísticas"
    },
    "trade": {
      "copy_destination_to_clipboard": "Copiar el nombre del sistema de destino {system} al portapapeles",
      "copy_source_to_clipboard": "Copiar el nombre del sistema de origen {system} al portapapeles",
      "distance": "{distance} AL",
      "finishing_system": "Luego vuela a<a href=\"{link}\">{system}</a> ({station}) {distance} AL",
      "first_system": "Comenzar en <a href=\"{link}\">{system}</a> ({station}) updated {updated_at}",
      "flying_instructions": "Ve a {station} (Distance from arrival {distance}sl, {time} tiempo de arribo",
      "no_results": "No se han podido encontrar rutas de comercio",
      "purchase_instructions": "Venda todo lo que tenga en su bodega y compre la mercancía que se enumeran a continuación.",
      "route": "Vuela desde {start_system} ({start_station}) hasta {end_system} ({end_station}) {distance} AL",
      "source_required": "El sistema de origen es requerido",
      "starting_system": "<a href=\"{link}\">{system}</a> ({station}) actualizado{updated_at}",
      "title": "Rutas comerciales"
    },
    "type": {
      "Planet": "Planeta",
      "Star": "Estrella"
    },
    "volcanism_type": {
      "Ammonia Magma": "Ammonia Magma",
      "Carbon Dioxide Geysers": "Géiser de dióxido de carbono",
      "Major Carbon Dioxide Geysers": "Major Carbon Dioxide Geysers",
      "Major Metallic Magma": "Major Metallic Magma",
      "Major Rocky Magma": "Major Rocky Magma",
      "Major Silicate Vapour Geysers": "Major Silicate Vapour Geysers",
      "Major Water Geysers": "Major Water Geysers",
      "Major Water Magma": "Major Water Magma",
      "Metallic Magma": "Metallic Magma",
      "Methane Magma": "Methane Magma",
      "Minor Ammonia Magma": "Minor Ammonia Magma",
      "Minor Carbon Dioxide Geysers": "Minor Carbon Dioxide Geysers",
      "Minor Metallic Magma": "Minor Metallic Magma",
      "Minor Methane Magma": "Minor Methane Magma",
      "Minor Nitrogen Magma": "Minor Nitrogen Magma",
      "Minor Rocky Magma": "Minor Rocky Magma",
      "Minor Silicate Vapour Geysers": "Minor Silicate Vapour Geysers",
      "Minor Water Geysers": "Minor Water Geysers",
      "Minor Water Magma": "Minor Water Magma",
      "Nitrogen Magma": "Nitrogen Magma",
      "No volcanism": "No volcanism",
      "Rocky Magma": "Rocky Magma",
      "Silicate Vapour Geysers": "Silicate Vapour Geysers",
      "Water Geysers": "Géiseres de agua",
      "Water Magma": "Water Magma"
    },
    "yes": "Si"
  }], ["fr", {
    "admin": {
      "list_bad_data": "Lister les rapports de données erronées"
    },
    "allegiance": {
      "Alliance": "Alliance",
      "Empire": "Empire",
      "Federation": "Fédération",
      "Guardian": "Guardians",
      "Independent": "Indépendant",
      "None": "Aucune",
      "Pilots Federation": "Fédération des pilotes",
      "Player Pilots": "Pilote joueur",
      "Thargoid": "Thargoids"
    },
    "ammonia": {
      "blank": "Laisser la destination vide pour créer un itinéraire circulaire débutant au système source designé et qui se terminera dans ce même système",
      "guides": "Vous pouvez trouver plus d'informations sur les objectifs communautaire <a href=\"https://www.elitedangerous.com/community/goals/\">ici</a> et <a href=\"https://forums.frontier.co.uk/threads/support-sirius-atmospherics-initiative-by-providing-ammonia-world-exploration-data-exploration.559984/\">ici</a>.",
      "low_results": "Le nombre de systèmes retourné est moins important que ce que vous avez demandé. Essayez d'augmenter le rayon de recherche pour trouver plus de corps.",
      "title": "Planificateur de mondes ammoniacaux",
      "warning": "Ce planificateur d'itinéraire vous permettra de trouver des mondes d'ammoniac autour de votre localisation actuelle pour l'objectif communautaire. Il indiquera en priorité les mondes avec une valeur de scan élevée. Si vous trouvez des mondes trop lointains, vous pouvez diminuer le rayon de recherche"
    },
    "atmosphere": {
      "Ammonia": "Ammoniac",
      "Ammonia and Oxygen": "Ammoniac et oxygène",
      "Ammonia-rich": "Riche en ammoniac",
      "Argon": "Argon",
      "Argon-rich": "Riche en Argon",
      "Carbon dioxide": "Dioxyde de carbone",
      "Carbon dioxide-rich": "Riche en dioxyde de carbone",
      "Helium": "Hélium",
      "Hot Argon": "Argon (chaude)",
      "Hot Argon-rich": "Riche en argon (chaude)",
      "Hot Carbon dioxide": "Dioxyde de carbone (chaude)",
      "Hot Carbon dioxide-rich": "Riche en dioxyde de carbone (chaude)",
      "Hot Metallic vapour": "Vapeur métallique (chaude)",
      "Hot Silicate vapour": "Vapeur de silicate (chaude)",
      "Hot Sulphur dioxide": "Dioxyde de soufre (chaude)",
      "Hot Water": "Eau (chaude)",
      "Hot Water-rich": "Riche en eau (chaude)",
      "Hot thick Ammonia": "Ammoniac (chaude, épaisse)",
      "Hot thick Ammonia-rich": "Riche en ammoniac (chaude, épaisse)",
      "Hot thick Argon": "Argon (chaude, épaisse)",
      "Hot thick Argon-rich": "Riche en argon (chaude, épaisse)",
      "Hot thick Carbon dioxide": "Dioxyde de carbone (chaude, épaisse)",
      "Hot thick Carbon dioxide-rich": "Riche en dioxyde de carbone (chaude, épaisse)",
      "Hot thick Metallic vapour": "Vapeur métallique (chaude, épaisse)",
      "Hot thick Methane": "Méthane (chaude, épaisse)",
      "Hot thick Methane-rich": "Riche en méthane (chaude, épaisse)",
      "Hot thick Nitrogen": "Azote (chaude, épaisse)",
      "Hot thick No atmosphere": "Aucune atmosphère (chaude, épaisse)",
      "Hot thick Silicate vapour": "Vapeur de silicate (chaude, épaisse)",
      "Hot thick Sulphur dioxide": "Dioxyde de soufre (chaude, épaisse)",
      "Hot thick Water": "Eau (chaude, épaisse)",
      "Hot thick Water-rich": "Riche en eau (chaude, épaisse)",
      "Hot thin Carbon dioxide": "Dioxyde de carbone (chaude, épaisse)",
      "Hot thin Metallic vapour": "Vapeur métallique (chaude, mince)",
      "Hot thin Silicate vapour": "Vapeur de silicate (chaude, mince)",
      "Hot thin Sulphur dioxide": "Dioxyde de soufre (chaude, mince)",
      "Metallic vapour": "Vapeur Métallique",
      "Methane": "Méthane",
      "Methane-rich": "Riche en Méthane",
      "Neon": "Néon",
      "Neon-rich": "Riche en Néon",
      "Nitrogen": "Azote",
      "No atmosphere": "Sans atmosphère",
      "Oxygen": "Oxygène",
      "Silicate vapour": "Vapeur de silicate",
      "Suitable for water-based life": "Convient à la vie à base d'eau",
      "Sulphur dioxide": "Dioxyde de soufre",
      "Thick Ammonia": "Ammoniac (épaisse)",
      "Thick Ammonia and Oxygen": "Ammoniac et Oxygène (épaisse)",
      "Thick Ammonia-rich": "Riche en Ammoniac (épaisse)",
      "Thick Argon": "Argon (épaisse)",
      "Thick Argon-rich": "Riche en argon (épaisse)",
      "Thick Carbon dioxide": "Dioxyde de carbone (épaisse)",
      "Thick Carbon dioxide-rich": "Riche en dioxyde de carbone (épaisse)",
      "Thick Helium": "Hélium (épaisse)",
      "Thick Methane": "Méthane (épaisse)",
      "Thick Methane-rich": "Riche en méthane (épaisse)",
      "Thick Nitrogen": "Azote (épaisse)",
      "Thick No atmosphere": "Aucune atmosphère (épaisse)",
      "Thick Suitable for water-based life": "Convient pour la vie à base d'eau (épaisse)",
      "Thick Sulphur dioxide": "Dioxyde de soufre épais",
      "Thick Water": "Eau (épaisse)",
      "Thick Water-rich": "Riche en eau (épaisse)",
      "Thin Ammonia": "Ammoniac (mince)",
      "Thin Ammonia and Oxygen": "Ammoniac et oxygène (mince)",
      "Thin Ammonia-rich": "Riche en Ammoniac (mince)",
      "Thin Argon": "Argon (épaisse)",
      "Thin Argon-rich": "Riche en Argon (mince)",
      "Thin Carbon dioxide": "Dioxyde de carbone (mince)",
      "Thin Carbon dioxide-rich": "Riche en dioxyde de carbone (mince)",
      "Thin Helium": "Hélium (mince)",
      "Thin Methane": "Méthane (mince)",
      "Thin Methane-rich": "Riche en méthane (mince)",
      "Thin Neon": "Néon (mince)",
      "Thin Neon-rich": "Riche en Néon (mince)",
      "Thin Nitrogen": "Azote (mince)",
      "Thin No atmosphere": "Pas d'atmosphère (mince)",
      "Thin Oxygen": "Oxygène (mince)",
      "Thin Sulphur dioxide": "Dioxyde de soufre (mince)",
      "Thin Water": "Eau (mince)",
      "Thin Water-rich": "Riche en eau (mince)",
      "Water": "Eau",
      "Water-rich": "Riche en eau"
    },
    "atmosphere_composition": {
      "Ammonia": "Ammoniac",
      "Argon": "Argon",
      "Carbon dioxide": "Dioxyde de carbone",
      "Helium": "Hélium",
      "Hydrogen": "Hydrogène",
      "Iron": "Fer",
      "Methane": "Méthane",
      "Neon": "Néon",
      "Nitrogen": "Azote",
      "Oxygen": "oxygène",
      "Silicates": "Silicates",
      "Sulphur dioxide": "Dioxyde de soufre",
      "Water": "Eau"
    },
    "bad_data": {
      "archive": "Archive",
      "description": "Indiquer un problème avec {system}",
      "error_description": "Il y a eu un problème avec votre contribution. Merci de la transmettre en utilisant les coordonnées en bas de la page.",
      "reason": "Raison",
      "report": "Indiquer un problème avec ce système",
      "send": "Envoyer le rapport",
      "success_description": "Merci pour votre contribution, nous allons l'étudier"
    },
    "belts": {
      "add_field": "Ajouter un filtre",
      "inner_radius": "Rayon intérieur (m)",
      "mass": "Masse (Mt)",
      "outer_radius": "Rayon extérieur (m)",
      "remove_field": "Retirer un filtre",
      "select_type": "Sélectionner un type",
      "type": "Type",
      "types": {
        "Icy": "Glacé",
        "Metal Rich": "Riche en métal",
        "Metallic": "Métallique",
        "Rocky": "Rocheux"
      }
    },
    "body": {
      "belts": "Ceintures",
      "body": "Corps",
      "composition": "Composition",
      "landmarks": "Repères",
      "no_id64": "Aucun ID de corps spécifié",
      "not_found": "Corps non trouvé",
      "rings": "Anneaux",
      "signals": "Signaux",
      "signals_updated_at": "Signaux mis à jour {relative_updated_at} ({updated_at})",
      "updated_at": "Mis à jour {relative_updated_at} ({updated_at})"
    },
    "boolean": {
      "Any": "Aucun",
      "No": "Non",
      "Yes": "Oui"
    },
    "categories": {
      "Chemicals": "Produits chimiques",
      "Consumer Items": "Biens de consommation",
      "Foods": "Alimentation",
      "Industrial Materials": "Matétérieaux industriels",
      "Legal Drugs": "Drogues légales",
      "Machinery": "Machines",
      "Medicines": "Médicaments",
      "Metals": "Métaux",
      "Minerals": "Minerais",
      "Salvage": "Sauvetage",
      "Slavery": "Esclavage",
      "Technology": "Technologie",
      "Textiles": "Textiles",
      "Waste": "Déchets",
      "Weapons": "Armes"
    },
    "commodities": {
      "AI Relics": "Reliquats d'IA",
      "Advanced Catalysers": "Catalyseurs complexes",
      "Advanced Medicines": "Médicaments complexes",
      "Aepyornis Egg": "Œuf d'Aepyornis",
      "Aganippe Rush": "Aganippe Rush",
      "Agri-Medicines": "Agri-médicament(s)",
      "Agronomic Treatment": "Traitement Agronomique",
      "Alacarakmo Skin Art": "Art épidermique d'Alacarakmo",
      "Albino Quechua Mammoth Meat": "Viande de mammouth albinos quechua",
      "Alexandrite": "Alexandrite",
      "Algae": "Algue(s)",
      "Altairian Skin": "Peau d'Altaïr",
      "Aluminium": "Aluminium",
      "Alya Body Soap": "Savon Alya",
      "Ancient Artefact": "Artefact ancien",
      "Ancient Casket": "Cercueil des Guardians",
      "Ancient Key": "Clé antique",
      "Ancient Orb": "Orbe des Guardians",
      "Ancient Relic": "Relique antique",
      "Ancient Tablet": "Tablette des Guardians",
      "Ancient Totem": "Totem des Guardians",
      "Ancient Urn": "Urne des Guardians",
      "Anduliga Fire Works": "Feu d'artifice d'Anduliga",
      "Animal Meat": "Viande",
      "Animal Monitors": "Sys. surveillance animal",
      "Anomaly Particles": "Particules anormales",
      "Antimatter Containment Unit": "Unité de confinement d'antimatière",
      "Antique Jewellery": "Bijoux antiques",
      "Antiquities": "Antiquités",
      "Any Na Coffee": "Café Any Na",
      "Apa Vietii": "Apa Vietii",
      "Aquaponic Systems": "Systèmes aquaponiques",
      "Arouca Conventual Sweets": "Bonbons monastiques d'Arouca",
      "Articulation Motors": "Moteur à articulation",
      "Assault Plans": "Plans d'assaut",
      "Atmospheric Processors": "Processeurs atmosphériques",
      "Auto-Fabricators": "Dispositif d'autofabrication",
      "Az Cancri Formula 42": "Formule 42 d'AZ Cancri",
      "Azure Milk": "Lait Azure",
      "Baked Greebles": "Greebles au four",
      "Baltah'sine Vacuum Krill": "Crevettes stellaires de Baltah'sine",
      "Banki Amphibious Leather": "Cuir d'amphibien de Banki",
      "Basic Medicines": "Médicament(s) simple(s)",
      "Bast Snake Gin": "Gin de serpent Bast",
      "Battle Weapons": "Armes militaires",
      "Bauxite": "Bauxite",
      "Beer": "Bière",
      "Belalans Ray Leather": "Cuir de raie de Belalans",
      "Benitoite": "Bénitoïte",
      "Bertrandite": "Bertrandite",
      "Beryllium": "Béryllium",
      "Bioreducing Lichen": "Lichen bioréducteur",
      "Biowaste": "Biodéchts",
      "Bismuth": "Bismuth",
      "Black Box": "Boîte noire",
      "Bootleg Liquor": "Liqueur de contrebande",
      "Borasetani Pathogenetics": "Fléau de Borasetani",
      "Bromellite": "Bromellite",
      "Buckyball Beer Mats": "Sous-bocks Buckyball",
      "Building Fabricators": "Auto-Bâtisseurs",
      "Burnham Bile Distillate": "Distillé bileux de Burnham",
      "CD-75 Kitten Brand Coffee": "Café Kitten CD-75",
      "CMM Composite": "Composite MMC",
      "Centauri Mega Gin": "Gin Centauri Mega",
      "Ceramic Composites": "Composés en céramique",
      "Ceremonial Heike Tea": "Thé de cérémonie de Heike",
      "Ceti Rabbits": "Lapin de Ceti",
      "Chameleon Cloth": "Tissu caméléon",
      "Chateau De Aegaeon": "Château Aegaeon",
      "Chemical Waste": "Déchets Chimiques",
      "Cherbones Blood Crystals": "Cristaux de sang de Cherbones",
      "Chi Eridani Marine Paste": "Pâte marine de Chi Eridani",
      "Clothing": "Vêtements",
      "Cobalt": "Cobalt",
      "Coffee": "Café",
      "Coltan": "Coltan",
      "Combat Stabilisers": "Stabilisateurs de combat",
      "Commercial Samples": "Échantillons commerciaux",
      "Computer Components": "Composants d'ordinateur",
      "Conductive Fabrics": "Tissus conducteurs",
      "Consumer Technology": "Electronique grand public",
      "Copper": "Cuivre",
      "Coquim Spongiform Victuals": "Rations spongiformes de Coquim",
      "Crom Silver Fesh": "Fesh argenté de Crom",
      "Crop Harvesters": "Moissonneur",
      "Cryolite": "Cyolite",
      "Crystalline Spheres": "Sphères cristallines",
      "Damaged Escape Pod": "Nacelle d'évacuation endommagée",
      "Damna Carapaces": "Carapaces de Damna",
      "Data Core": "Noyau de données",
      "Delta Phoenicis Palms": "Palmiers de Delta Phoenicis",
      "Deuringas Truffles": "Truffes Deuringas",
      "Diplomatic Bag": "Valise diplomatique",
      "Diso Ma Corn": "Maïs Diso Ma",
      "Domestic Appliances": "Équipement ménager",
      "Duradrives": "Duradrives",
      "Earth Relics": "Reliques de la terre",
      "Eden Apples of Aerial": "Pommes d'eden d'Aerial",
      "Eleu Thermals": "Sous-vêtements thermiques d'Eleu",
      "Emergency Power Cells": "Cellules d'énergie de secours",
      "Encrypted Correspondence": "Correspondance cryptée",
      "Encrypted Data Storage": "Périphérique de stockage crypté",
      "Energy Grid Assembly": "Conduits de transfert d'énergie",
      "Eranin Pearl Whisky": "Whisky Eranin Pearl",
      "Eshu Umbrellas": "Ombrelles eshu",
      "Esuseku Caviar": "Caviar d'Esuseku",
      "Ethgreze Tea Buds": "Bourgeons de thé Ethgreze",
      "Evacuation Shelter": "Abri d'urgence",
      "Exhaust Manifold": "Collecteur d'échappement",
      "Experimental Chemicals": "Produits chimiques expérimentaux",
      "Explosives": "Explosifs",
      "Fish": "Poisson",
      "Food Cartridges": "Cartouches(s) alimentaire(s)",
      "Fossil Remnants": "Fossiles",
      "Fruit and Vegetables": "Fruits et légumes",
      "Fujin Tea": "Thé de Fujin",
      "Galactic Travel Guide": "Guide de voyage galactique",
      "Gallite": "Gallite",
      "Gallium": "Gallium",
      "Geawen Dance Dust": "Poussière de danse Geawen",
      "Gene Bank": "Banque de gènes",
      "Geological Equipment": "Equipement géologique",
      "Geological Samples": "Echantillons géologiques",
      "Gerasian Gueuze Beer": "Gueuze gerasianne",
      "Giant Irukama Snails": "Escargots géants d'Irukama",
      "Giant Verrix": "Verrix géant",
      "Gilya Signature Weapons": "Armes personnalisées Gilyanes",
      "Gold": "Or",
      "Goman Yaupon Coffee": "Café Goman Yaupon",
      "Goslarite": "Goslarite",
      "Grain": "Céréales",
      "Grandidierite": "Grandidiérite",
      "Guardian Casket": "Cerceuil Guardian",
      "Guardian Orb": "Orbe Guradian",
      "Guardian Relic": "Relique Guardian",
      "Guardian Tablet": "Tablette Guardian",
      "Guardian Totem": "Totem Guardian",
      "Guardian Urn": "Urne Guardian",
      "H.E. Suits": "Combinaisons de protection",
      "HIP 10175 Bush Meat": "Viande de gibier de HIP 10175",
      "HIP 118311 Swarm": "Essaim de HIP 118311",
      "HIP Organophosphates": "Organophosphates de Hip",
      "HIP Proto-Squid": "Proto-calamar de HIP",
      "HN Shock Mount": "Protection antichocs HP",
      "HR 7221 Wheat": "Blé de HR 7221",
      "Hafnium 178": "Hafnium 178",
      "Haiden Black Brew": "Tisane noire de Haiden",
      "Hardware Diagnostic Sensor": "Capteur diagnostic d'équipement",
      "Harma Silver Sea Rum": "Rhum marin Harma Silver",
      "Havasupai Dream Catcher": "Attrape-rêves Havasupai",
      "Heatsink Interlink": "Interconnexion dissipateur therm.",
      "Helvetitj Pearls": "Perles de Helvetitj",
      "Holva Duelling Blades": "Lame de duel de Holva",
      "Honesty Pills": "Pilules d'honnêteté",
      "Hostages": "Otages",
      "Hydrogen Fuel": "Carburant à base d'hydroène",
      "Hydrogen Peroxide": "Peroxine d'hydrogène",
      "Imperial Slaves": "Esclaves impériaux",
      "Indi Bourbon": "Bourbon Indi",
      "Indite": "Indite",
      "Indium": "Indium",
      "Insulating Membrane": "Membrane isolante",
      "Ion Distributor": "Distributeurs d'ions",
      "Jadeite": "Jadéite",
      "Jaques Quinentian Still": "Brûlerie quinentienne de Jaques",
      "Jaradharre Puzzle Box": "Boîte de jeux de Jaradharre",
      "Jaroua Rice": "Riz jarouan",
      "Jotun Mookah": "Mookah de Jotun",
      "Kachirigin Filter Leeches": "Sangsues de Kachirigin",
      "Kamitra Cigars": "Cigares de Kamitra",
      "Kamorin Historic Weapons": "Armes historiques de Kamorin",
      "Karetii Couture": "Haute couture karetii",
      "Karsuki Locusts": "Sauterelles de Karsuki",
      "Kinago Violins": "Violons de Kinago",
      "Kongga Ale": "Bière de Kongga",
      "Koro Kung Pellets": "Boulettes Korro Kung",
      "LTT Hyper Sweet": "Hyper sucre LTT",
      "Land Enrichment Systems": "Sys. enrichissement sols",
      "Landmines": "Mines terrestres",
      "Lanthanum": "Lanthane",
      "Large Survey Data Cache": "Important lot de données d'explorations",
      "Lavian Brandy": "Brandy lavien",
      "Leather": "Cuir",
      "Leathery Eggs": "Œufs antiques",
      "Leestian Evil Juice": "Leestian Evil Juice",
      "Lepidolite": "Lépidolite",
      "Limpets": "Drones",
      "Liquid oxygen": "Oxygène liquide",
      "Liquor": "Liqueur",
      "Lithium": "Lithium",
      "Lithium Hydroxide": "Hydroxyde de lithium",
      "Live Hecate Sea Worms": "Vers marins vivants d'Hecate",
      "Low Temperature Diamonds": "Diamant basse température",
      "Lucan Onionhead": "Tête d'oignon lucane",
      "Lyrae Weed": "Herbe Lyrane",
      "Magnetic Emitter Coil": "Bobine d'émission magnétique",
      "Marine Equipment": "Équipement aquamarin",
      "Master Chefs": "Spartan-chefs",
      "Mechucos High Tea": "Haut thé de Mechucos",
      "Medb Starlube": "Lubrifiant stellaire Medb",
      "Medical Diagnostic Equipment": "Équipement de diagnostic médical",
      "Meta-Alloys": "Méta-alliages",
      "Methane Clathrate": "Hydrate de méthane",
      "Methanol Monohydrate Crystals": "Cristaux de méthanol monohydraté",
      "Micro Controllers": "Microcontrôleurs",
      "Micro-weave Cooling Hoses": "Tuyaux de refroidissement",
      "Microbial Furnaces": "Hauts fourneaux microbiens",
      "Military Grade Fabrics": "Tissus militaires",
      "Military Intelligence": "Renseignements militaires",
      "Military Plans": "Plans militaires",
      "Mineral Extractors": "Extracteurs de minerai",
      "Mineral Oil": "Huile(s) minérale(s)",
      "Modular Terminals": "Terminaux modulaires",
      "Moissanite": "Carbure de silicium",
      "Mokojing Beast Feast": "Céréanimales de Mokojing",
      "Mollusc Brain Tissue": "Matière cérébrale de mollusque",
      "Mollusc Fluid": "Fluides de mollusque",
      "Mollusc Membrane": "Membrane de mollusque",
      "Mollusc Mycelium": "Mycélium de mollusque",
      "Mollusc Soft Tissue": "Tissu mou de mollusque",
      "Mollusc Spores": "Spores de mollusque",
      "Momus Bog Spaniel": "Épagneul fangeux de Momus",
      "Monazite": "Monazite",
      "Motrona Experience Jelly": "Gelée d'expérience de Motrona",
      "Mukusubii Chitin-os": "Os chitineux de Mukusubii",
      "Mulachi Giant Fungus": "Champignons géants de Mulachi",
      "Muon Imager": "Dispositif d'imagerie muonique",
      "Musgravite": "Musgravite",
      "Mysterious Idol": "Idole mystérieuse",
      "Nanobreakers": "Nanodestructeurs",
      "Nanomedicines": "Nanomédicaments",
      "Narcotics": "Narcotiques",
      "Natural Fabrics": "Fibre(s) textile(s) naturelle(s)",
      "Neofabric Insulation": "Isolant en néotextile",
      "Neritus Berries": "Baies de Neritus",
      "Nerve Agents": "Agent neurotoxiques",
      "Ngadandari Fire Opals": "Opales de feu de Ngadandari",
      "Nguna Modern Antiques": "Antiquités modernes de Nguna",
      "Njangari Saddles": "Selles de Njangari",
      "Non Euclidian Exotanks": "Vivariums non euclidiens",
      "Non-Lethal Weapons": "Armes incapacitantes",
      "Occupied Escape Pod": "Nacelle d'évacuation occupée",
      "Ochoeng Chillies": "Piments d'Ochoeng",
      "Onionhead": "Tête d'oignon",
      "Onionhead Alpha Strain": "Variété alpha de tête d'oignon",
      "Onionhead Beta Strain": "Variété bêta de tête d'oignon",
      "Ophiuch Exino Artefacts": "Artefacts Ophiuch Exino",
      "Orrerian Vicious Brew": "Vicieuse d'Orrere",
      "Osmium": "Osmium",
      "Painite": "Painite",
      "Palladium": "Palladium",
      "Pantaa Prayer Sticks": "Bâtonnets d'encens de Panta",
      "Pavonis Ear Grubs": "Larves Oreilles de Pavonis",
      "Performance Enhancers": "Produit(s) dopant(s)",
      "Personal Effects": "Effets personnels",
      "Personal Gifts": "Cadeaux personnels",
      "Personal Weapons": "Armes de poing",
      "Pesticides": "Pesticide(s)",
      "Platinum": "Platine",
      "Platinum Alloy": "Alliage de platine",
      "Pod Core Tissue": "Tissu de noyau de cosse",
      "Pod Dead Tissue": "Tissu mort de cosse",
      "Pod Mesoglea": "Mésoglée de cosse",
      "Pod Outer Tissue": "Tissu extérieur de cosse",
      "Pod Shell Tissue": "Tissu de coque de cosse",
      "Pod Surface Tissue": "Tissu de surface de cosse",
      "Pod Tissue": "Tissu de cosse",
      "Political Prisoners": "Prisonniers politiques",
      "Polymers": "Polymère(s)",
      "Power Converter": "Convertisseur d'énergie",
      "Power Generators": "Générateurs",
      "Power Transfer Bus": "Conduits de transfert d'énergie",
      "Praseodymium": "Praséodyme",
      "Precious Gems": "Pierres précieuses",
      "Progenitor Cells": "Cellules souches",
      "Prohibited Research Materials": "Matériaux de recherches interdits",
      "Prototype Tech": "Prototype technologique",
      "Pyrophyllite": "Pyrophyllite",
      "Radiation Baffle": "Écran antiradiation",
      "Rajukru Multi-Stoves": "Réchaud universel de Rajukru",
      "Rapa Bao Snake Skins": "Peau de serpent Rapa Bao",
      "Rare Artwork": "Œuvre d'art",
      "Reactive Armour": "Protection réactive",
      "Rebel Transmissions": "Transmissions rebelles",
      "Reinforced Mounting Plate": "Plaque de montage renforcée",
      "Resonating Separators": "Séparateurs à résonance",
      "Rhodplumsite": "Rhodplumsite",
      "Robotics": "Robots",
      "Rockforth Fertiliser": "Engrais Rockforth",
      "Rusani Old Smokey": "Rusani Old Smokey",
      "Rutile": "Rutile",
      "SAP 8 Core Container": "Conteneur fusion SAP 8",
      "Samarium": "Samarium",
      "Sanuma Decorative Meat": "Viande ornementale de Sanuma",
      "Saxon Wine": "Vin saxon",
      "Scientific Research": "Recherches scientifiques",
      "Scientific Samples": "Échantillons scientifiques",
      "Scrap": "Ferraille",
      "Semiconductors": "Semi-conducteur(s)",
      "Serendibite": "Sérendibite",
      "Shan's Charis Orchid": "Orchidée Shan Charis",
      "Silver": "Argent",
      "Skimmer Components": "Composants de protecteurs",
      "Slaves": "Escalves",
      "Small Survey Data Cache": "Petit lot de données d'explorations",
      "Soontill Relics": "Reliques de Soontill",
      "Sothis Crystalline Gold": "Or cristallin de Sothis",
      "Space Pioneer Relics": "Reliques des pionniers de l'espace",
      "Structural Regulators": "Régulateur(s) structurel(s)",
      "Superconductors": "Supraconducteur(s)",
      "Surface Stabilisers": "Stabilisateurs de surface",
      "Survival Equipment": "Équipement de survie",
      "Synthetic Fabrics": "Tissu(s) synthétique(s)",
      "Synthetic Meat": "Viande synthétique",
      "Synthetic Reagents": "Réactifs synthétiques",
      "Taaffeite": "Taafféite",
      "Tactical Data": "Donnée tactique",
      "Tanmark Tranquil Tea": "Thé de la tranquillité de Tanmark",
      "Tantalum": "Tantale",
      "Tarach Spice": "Épice de Tarash",
      "Tauri Chimes": "Carillons taurins",
      "Tea": "Thé",
      "Technical Blueprints": "Plan technique",
      "Telemetry Suite": "Système de télémétrie",
      "Terra Mater Blood Bores": "Plasma sanguin de Terra Mater",
      "Thallium": "Thallium",
      "Thargoid Basilisk Tissue Sample": "Échantillon de tissu thargoid - Basilisk",
      "Thargoid Biological Matter": "Matière biologique thargoid",
      "Thargoid Cyclops Tissue Sample": "Échantillon de tissu thargoid - Cyclops",
      "Thargoid Heart": "Cœur de Thargoid",
      "Thargoid Hydra Tissue Sample": "Échantillon de tissu d'un Hydra thargoid",
      "Thargoid Link": "Liaison thargoid",
      "Thargoid Medusa Tissue Sample": "Échantillon de tissu thargoid - Medusa",
      "Thargoid Probe": "Sonde thargoid",
      "Thargoid Resin": "Résine thargoid",
      "Thargoid Scout Tissue Sample": "Échantillon de tissu thargoid - éclaireur",
      "Thargoid Sensor": "Capteur thargoid",
      "Thargoid Technology Samples": "Échantillons de technologie thargoid",
      "The Hutton Mug": "La tasse Hutton",
      "The Waters of Shintara": "Eau de Shintara",
      "Thermal Cooling Units": "Unités de refroidissement",
      "Thorium": "Thorium",
      "Thrutis Cream": "Crème de Thrutis",
      "Tiegfries Synth Silk": "Soie synthétique de Tiegfries",
      "Time Capsule": "Capsule temporelle",
      "Tiolce Waste2Paste Units": "Pâte 2 Déchets de Tiolce",
      "Titanium": "Titane",
      "Tobacco": "Tabac",
      "Toxandji Virocide": "Virocide Toxandji",
      "Toxic Waste": "Déchets toxiques",
      "Trade Data": "Données commerciales",
      "Trinkets of Hidden Fortune": "Amulettes porte-bonheur",
      "Tritium": "Tritium",
      "Ultra-Compact Processor Prototypes": "Prototypes de processeurs ultra-compacts",
      "Unstable Data Core": "Centre de données instable",
      "Uraninite": "Uraninite",
      "Uranium": "Uranium",
      "Uszaian Tree Grub": "Larve uszaienne",
      "Utgaroar Millennial Eggs": "Œufs millénaires d'Utgaroar",
      "Uzumoku Low-G Wings": "Ailes à faible gravité Uzumoku",
      "V Herculis Body Rub": "Lotion de soin V Herculis",
      "Vanayequi Ceratomorpha Fur": "Fourrure de cératomorphe de Vanayequi",
      "Vega Slimweed": "Algues amincissantes de Vega",
      "Vidavantian Lace": "Dentelle vidavantienne",
      "Void Extract Coffee": "Café du néant",
      "Void Opals": "Opales du Vide",
      "Volkhab Bee Drones": "Drones butineurs Volkhab",
      "Water": "Eau",
      "Water Purifiers": "Purificateurs d'eau",
      "Wheemete Wheat Cakes": "Gâteaux de blé Wheemete",
      "Wine": "Vin",
      "Witchhaul Kobe Beef": "Bœuf de Kobe de Witchhaul",
      "Wolf Fesh": "Wolf Fesh",
      "Wreckage Components": "Débris d'épave",
      "Wulpa Hyperbore Systems": "Système hyperbore Wulpa",
      "Wuthielo Ku Froth": "Mousse de Wuthielo Ku",
      "Xihe Biomorphic Companions": "Compagnons biomorphiques de Xihe",
      "Yaso Kondi Leaf": "Feuille de Yaso Kondi",
      "Zeessze Ant Grub Glue": "Colle de larve de fourmi de Zeessze"
    },
    "dates": {
      "forever": "Toujours"
    },
    "download": {
      "body": "Nom du corps",
      "distance": "Distance depuis l'arrivée",
      "distance_travelled": "Distance",
      "fuel_remaining": "Carburant restant",
      "fuel_used": "Carburant consommé",
      "icy_ring": "Anneau glacé",
      "jumps": "Sauts",
      "mapping_value": "Valeur de cartographie estimée",
      "neutron": "Étoile à neutrons",
      "no": "Non",
      "pristine": "Inexploitées",
      "refuel": "Faire le plein",
      "remaining": "Distance restante",
      "restock_tritium": "Ravitailler en tritium",
      "scan_value": "Valeur de scan estimée",
      "subtype": "Sous-type de corps",
      "system": "Nom du système",
      "terraform": "Est terraformable",
      "tritium_left": "Tritium in tank",
      "tritium_market": "Tritium in market",
      "yes": "Oui"
    },
    "dumps": {
      "description": "Lots quotidiens de données relatives à la galaxie",
      "file_description": "Description",
      "filename": "Nom du fichier",
      "files": {
        "galaxy.json.bz2": "La totalité de la galaxie au format bz2 (n'est plus mis à jour)",
        "galaxy.json.gz": "La galaxie entière au format gzip",
        "galaxy_1day.json.gz": "Système dans lesquels une mise à jour a eu lieu dans les dernières 24 heures au format gzip",
        "galaxy_1month.json.gz": "Système dans lesquels une mise à jour a eu lieu dans les 32 derniers jours au format gzip",
        "galaxy_7days.json.gz": "Système dans lesquels une mise à jour a eu lieu dans les 7 derniers jours au format gzip",
        "galaxy_populated.json.bz2": "Système dont la population est supérieure à 0 au format bz2 (n'est plus mis à jour)",
        "galaxy_populated.json.gz": "Système dont la population est supérieure à 0 au format gzip",
        "galaxy_stations.json.bz2": "Système comportant une station au format bz2 (porte-vaisseaux inclus, n'est plus mis à jour)",
        "galaxy_stations.json.gz": "Système comportant une station au format gzip (porte-vaisseaux inclus)"
      },
      "modified": "Généré",
      "size": "Taille du fichier",
      "title": "Lots de données"
    },
    "earth": {
      "blank": "Leave destination blank in order to create a circular tour starting at your source system and returning there at the end.",
      "guides": "You can find more information about the community goal <a href=\"https://www.elitedangerous.com/community/goals/\">here</a> and <a href=\"https://forums.frontier.co.uk/threads/support-the-saud-kruger-exploration-campaign.590091/\">here</a>.",
      "low_results": "The number of systems returned is less than you asked for.  Try increasing the radius of the search in order to find more bodies.",
      "title": "Earth-like World Route",
      "warning": "This router will allow you to find earth-like worlds around your current location for the community goal.  It will prioritise high scan value so if you only find far away worlds you may wish to reduce your search radius."
    },
    "economy": {
      "Agriculture": "Agriculture",
      "Colony": "Colonie",
      "Extraction": "Exploitation minière",
      "High Tech": "Haute technologie",
      "Industrial": "Industrie",
      "Military": "Militaire",
      "None": "Aucune",
      "Private Enterprise": "Entreprise privée",
      "Refinery": "Raffinage",
      "Service": "Services",
      "Terraforming": "Terraformation",
      "Tourism": "Tourisme"
    },
    "engineer-plotter": {
      "add_blueprint": "Add blueprint",
      "blueprint": "{type} ({name} grade {grade})",
      "close_ship_build": "Close",
      "delete_blueprint": "Delete blueprint",
      "description": "This page will allow you to plot an efficient route to engineer your ship.",
      "engineer_blueprint": "Engineer {slot} with {name} to grade {grade}",
      "engineer_blueprint_effect": "Engineer {slot} with {name} to grade {grade} and effect {effect}",
      "load_ship_build": "Load ship build",
      "nothing_to_engineer": "Nothing to engineer",
      "process_current_ship_build": "Process current blueprints",
      "process_target_ship_build": "Process target blueprints",
      "system_title": "{engineer} in {system} ({distance}LY)",
      "title": "Engineer Plotter"
    },
    "exact-plotter": {
      "description": "Cette page vous permettra de planifier un itinéraire entre deux systèmes solaires. Cet outil essayera de planifier une route exacte, incluant la consommation de carburant, les supercharges d'étoiles à neutrons et les injections FSD si vous le souhaitez",
      "fuel_remaining_description": "Fuel currently in the fuel tank.  This does not include the reserve tank",
      "fuel_used_description": "Fuel used in the jump",
      "system_error": "Erreur dans le système <a href=\"{link}\">{system}</a>",
      "title": "Planificateur d'itinéraire galactique",
      "warning": "Ce planificateur est en phase beta et les résultats ne sont pas forcément idéaux, il planifiera possiblement un itinéraire contenant des système nécessitant un permis."
    },
    "fields": {
      "add_atmosphere_composition_filter": "Ajouter un filtre de composition d'atmosphère",
      "add_destination": "Ajouter une destination",
      "add_market_filter": "Ajouter un filtre de marché",
      "add_material_filter": "Ajouter un filtre de matériaux",
      "add_sort_field": "Ajouter un tri sur un champ",
      "add_via": "Ajouter un système intermédiaire",
      "allegiance": "Allégeance",
      "allow_planetary": "Permettre les stations planétaires",
      "allow_planetary_description": "Inclure les stations planétaires dans l'itinéraire",
      "allow_prohibited": "Permettre les marchandises illégales",
      "allow_prohibited_description": "Inclure des marchandises illégales dans le système de destination",
      "amount": "Quantité",
      "amount_of_commodity": "Quantité de {commodity}",
      "arg_of_periapsis": "Arg Of Periapsis",
      "atmosphere": "Atmosphère",
      "atmosphere_composition": "Composition de l'atmosphère",
      "atmosphere_composition_fields": "Composition de l'atomsphère",
      "atmosphere_compositions": "Compositions de l'atmosphère",
      "axis_tilt": "Axial Tilt",
      "belt_fields": "Filtres de ceintures",
      "belts": "Ceintures",
      "blueprint": "Blueprint",
      "body_count": "Body Count",
      "body_name": "Nom du corps",
      "buy_price": "Prix d'achat",
      "calculate": "Calculer",
      "cargo": "Cargo",
      "combined_ring_signal": "Combined Ring Signal",
      "combined_signal": "Combined Body Signal",
      "commodity": "Marchandise",
      "commodity_not_found": "Marchandise non trouvée",
      "completed": "Terminé",
      "controlling_minor_faction": "Faction dirigeante",
      "controlling_minor_faction_state": "État de la faction dirigeante",
      "coordinates": "Coordonnées de référence",
      "cumulative_profit": "Profit cumulé",
      "delete_destination": "Supprimer la destination",
      "delete_via": "Supprimer le système intermédiaire",
      "demand": "Demande",
      "destination_system": "Système de destination",
      "determine_required_fuel": "Determine Tritium Requirements",
      "determine_required_fuel_description": "Calculate how much Tritium would be required to complete the entire route.",
      "distance": "Distance (AL)",
      "distance_from_coords": "Distance (AL)",
      "distance_to_arrival": "Distance jusqu'à l'arrivée (SL)",
      "distance_to_arrival_short": "Distance (SL)",
      "earth_masses": "Masses terrestres",
      "effect": "Effect",
      "efficiency": "Efficacité (%)",
      "efficiency_description": "Augmenter la valeur va réduire la distance à laquelle l'outil va chercher à s'éloigner de l'itinéraire direct pour trouver une étoile à neutrons (une efficacité de 100 ne déviera pas de la ligne entre A et B et va certainement diviser l'itinéraire en portions de 20 000 AL)",
      "engineers": "Engineers",
      "engineers_description": "List of engineers to use, select none in order to allow all engineers.",
      "estimated_mapping_value": "Valeur de cartographie estimée",
      "estimated_mapping_value_short": "Valeur de cartographie",
      "estimated_scan_value": "Valeur de scan estimée",
      "estimated_scan_value_short": "Valeur de scan",
      "exclude_secondary": "Exclude Secondary Stars",
      "exclude_secondary_description": "Prevent the system using secondary neutron and scoopable stars to help with the route",
      "export_commodities": "Marchandises exportées",
      "faction_influence": "Influence de la faction",
      "faction_name": "Nom de la faction",
      "faction_presence": "Présence de la faction",
      "faction_state": "État de la faction",
      "find_stations": "Trouver des stations",
      "flying_time": "temps de vol : {time}",
      "fuel_remaining": "Carburant restant (tonnes)",
      "fuel_remaining_description": "Quantité de tritium dans le dépôt. Vous pourriez avoir besoin d'en transférer depuis le marché pour arriver à cette quantité",
      "fuel_used": "Carburant consommé (tonnes)",
      "fuel_used_description": "Tritium consommé pendant le saut",
      "full_spectral_class": "Classe spectrale complète",
      "government": "Gouvernement",
      "gravity": "Gravité",
      "has_large_pad": "Possède une grande plateforme d'atterrissage",
      "has_market": "Possède un marché",
      "has_outfitting": "Possède un équipementier",
      "has_shipyard": "Possède un chantier naval",
      "icy_ring": "Anneau glacé",
      "icy_ring_description": "Le système possède-t-il un anneau glacé ?",
      "import_commodities": "Marchandises importées",
      "influence": "Influence",
      "is_dssa_carrier": "DSSA Carrier",
      "is_landable": "Atterrissage possible",
      "is_main_star": "Étoile principale",
      "is_planetary": "Planétaire",
      "is_supercharged": "Déjà superchargé",
      "is_supercharged_description": "Votre vaisseau est-il déjà superchargé ?",
      "jumps": "Sauts",
      "jumps_description": "Nombre estimé de sauts jusqu'à ce système",
      "jumps_remaining": "Sauts restants",
      "landmark_subtype": "Sous-type de repère",
      "landmark_type": "Type de repère",
      "landmarks": "Repères",
      "large_pads": "Large Pads",
      "latitude": "Latitude",
      "longitude": "Logitude",
      "loop": "Boucle",
      "loop_description": "Obliger l'itinéraire à revenir au système de départ.",
      "luminosity_class": "Luminosity Class",
      "main_fields": "Champs principaux",
      "market": "Marchandise du marché",
      "market_capacity": "Capacité du marché",
      "market_capacity_description": "La capacité utilisée par les marchandises de votre marché",
      "market_fields": "Champs de marché",
      "market_updated_at": "Marché mis à jour le",
      "material": "Matériau",
      "material_fields": "Matériaux",
      "materials": "Matériaux",
      "maximum_cargo": "Capacité de soute maximale",
      "maximum_distance": "Distance maximale jusqu'à l'arrivée (LS)",
      "maximum_distance_description": "Distance maximale entre la station et l'étoile d'arrivée",
      "maximum_hop_distance": "Distance maximale entre les étapes",
      "maximum_hop_distance_description": "Distance maximum entre les étapes (peut être supérieure à la portée de votre vaisseau)",
      "maximum_hops": "Nombre maximum d'étapes",
      "maximum_market_age": "Ancienneté maximale du marché",
      "maximum_market_age_description": "Ancienneté maximale des données de marché des stations sur l'itinéraire",
      "maximum_systems": "Nombre maximum de systèmes",
      "maximum_systems_description": "Nombre maximum de système que le planificateur utilisera, réduire la valeur pour un voyage plus court.",
      "medium_pads": "Medium Pads",
      "minimum_scan_value": "Valeur de scan minimale",
      "minor_faction_presences": "Présence de la faction",
      "module_capacity": "Capacité des modules",
      "module_capacity_description": "Capacité utilisée par les modules installés sur votre porte-vaisseaux",
      "module_category": "Catégorie",
      "module_fields": "Champs de modules",
      "module_price": "Prix du module",
      "modules": "Modules",
      "name": "Noms",
      "needs_permit": "Nécessite un permis",
      "neutron": "Neutron",
      "neutron_description": "L'étoile est-elle une étoile à neutrons ?",
      "orbital_eccentricity": "Orbital Eccentricity",
      "orbital_inclination": "Orbital Inclination",
      "orbital_period": "Période de révolution (jours)",
      "outfitting_updated_at": "Equipement mis à jour le",
      "pads": "Pads",
      "permit": "Autoriser les systèmes nécessitant un permis",
      "permit_description": "Autoriser les système nécessitant un permis dans le calcul d'itinéraire",
      "population": "Population",
      "power": "Puissance",
      "power_state": "État de la puissance",
      "primary_economy": "Économie",
      "pristine": "Inexploitées",
      "pristine_description": "Les réserves de l'anneau sont-elle inexploitées ?",
      "profit": "Profit",
      "prohibited_commodities": "Marchandises illégales",
      "radius": "Rayon",
      "range": "Portée (AL)",
      "range_description": "La portée de saut de votre vaisseau.",
      "record_type": "Type",
      "reference_coordinates": "Coordonnées de référence",
      "reference_route": "Itinéraire de référence",
      "reference_system": "Système de référence",
      "refuel": "Faire le plein ?",
      "refuel_description": "Avez-vous besoin de faire le plein ?",
      "region": "Région",
      "remaining": "Restant (AL)",
      "requires_large_pad": "Nécessite une grande plateforme d'atterrissage",
      "requires_large_pad_description": "Retirer les stations qui ne possèdent pas de grande plateforme d'atterrissage",
      "reserve_level": "Niveau des réserves",
      "reset": "Remettre à zéro",
      "restock": "Ravitailler en tritium ?",
      "restock_amount": "Restock Amount",
      "restock_amount_description": "The amount of tritium you need to aquire to restock",
      "restock_description": "Avez-vous besoin de ravitailler en tritium ?",
      "reverse": "Inverser l'itinéraire",
      "ring_fields": "Filtres d'anneaux",
      "ring_signal": "Signal d'anneau",
      "ring_signals": "Signaux d'anneau",
      "rings": "Anneaux",
      "rotational_period": "Période de rotation",
      "scanned": "Scanned",
      "scanned_description": "Uncheck to mark a body as not scanned",
      "search": "Rechercher",
      "search_radius": "Rayon (AL)",
      "search_radius_description": "C'est le rayon en AL autour de l'itinéraire dans lequel le planificateur va chercher des mondes ayant une grande valeur pour que vous puissiez les visiter. Une valeur de 25 AL donné généralement un bon équilibre pour les itinéraires allant d'un point A à un point B, permettant de garder un nombre de sauts raisonnables tout en gardant un profit conséquent. Pour les itinéraires circulaires (obtenus en laissant la destination vide), il vaut mieux donner un rayon entre 100 et 500 AL.",
      "secondary_economy": "Économie secondaire",
      "security": "Sécurité",
      "select_all": "Tous",
      "select_allegiance": "Sélectionner une allégeance",
      "select_atmosphere": "Sélectionner l'atmosphère",
      "select_controlling_minor_faction": "Sélectionner la faction dirigeante",
      "select_controlling_minor_faction_state": "Sélectionner l'état de la faction dirigeante",
      "select_destination_system": "Sélectionner la destination",
      "select_export_commodities": "Sélectionner les marchandises exportées",
      "select_faction_presence": "Sélectionner la présence de la faction",
      "select_full_spectral_class": "Sélectionner la classe spectrale complète",
      "select_government": "Sélectionner le gouvernement",
      "select_import_commodities": "Sélectionner les marchandises importées",
      "select_landmark_subtype": "Sélectionner le sous-type de repère",
      "select_landmark_type": "Sélectionner le type de repère",
      "select_modules": "Sélectionner les modules",
      "select_none": "Aucun",
      "select_power": "Sélectionner la puissance",
      "select_power_state": "Sélectionner l'état de la puissance",
      "select_primary_economy": "Sélectionner l'économie",
      "select_prohibited_commodities": "Sélectionner les marchandises illégales",
      "select_reference_system": "Sélectionner le système de référence",
      "select_region": "Sélectionner la région",
      "select_reserve_level": "Sélectionner le niveau des réserves",
      "select_ring_signal": "Sélectionner un signal d'anneau",
      "select_secondary_economy": "Sélectionner l'économie secondaire",
      "select_security": "Sélectionner le niveau de sécurité",
      "select_services": "Sélectionner les services",
      "select_ships": "Sélectionner les vaisseaux",
      "select_signal": "Sélectionner un signal de corps",
      "select_source_system": "Sélectionner le système de départ",
      "select_spectral_class": "Sélectionner la classe spectrale",
      "select_state": "Sélectionner l'état",
      "select_station_type": "Sélectionner le type de station",
      "select_subtype": "Sélectionner le sous-type",
      "select_system_power": "Sélectionner la puissance du système",
      "select_terraforming_state": "Sélectionner l'état de terraformation",
      "select_type": "Sélectionner le type",
      "select_volcanism_type": "Sélectionner le type de volcanisme",
      "sell_price": "Prix de vente",
      "semi_major_axis": "Semi Major Axis",
      "services": "Services",
      "ship": "Vaisseau",
      "ship_build": "Équipement du vaisseau",
      "ship_build_description": "Collez l'équipement du vaisseau exporté au format JSON depuis <a href=\"https://coriolis.io\">https://coriolis.io</a> ou au format SLEF depuis <a href=\"https://edsy.org\">https://edsy.org</a>",
      "ship_price": "Prix du vaisseau",
      "ships": "Vaisseaux",
      "shipyard_updated_at": "Chantier naval actualisé la dernière fois le",
      "signal": "Signal de corps",
      "signal_count": "Nombre",
      "signal_fields": "Filtre de signaux",
      "signals": "Signaux",
      "signals_updated_at": "Signals Last Updated At",
      "single_value": "Une seule valeur possible",
      "small_pads": "Small Pads",
      "solar_masses": "Masses solaires",
      "solar_radius": "Rayon de l'étoile",
      "solid_composition": "Solid Composition",
      "sorting": "Champs de tri",
      "sorting_fields": "Tri",
      "source_station": "Station de départ",
      "source_system": "Système de départ",
      "spectral_class": "Classe spectrale",
      "starting_capital": "Capital de départ",
      "state": "État",
      "station_name": "Nom de la station",
      "station_type": "Type de station",
      "subtype": "Sous-type",
      "summary": "Résumé de la recherche",
      "supply": "Offre",
      "surface_pressure": "Pression de surface (Atmosphères)",
      "surface_temperature": "Température de surface (K)",
      "system_name": "Nom du système",
      "system_power": "Puissance du système",
      "system_power_state": "Power State",
      "system_region": "Région du système",
      "system_x": "X",
      "system_y": "Y",
      "system_z": "Z",
      "terraforming_description": "Le corps est-il un candidat à la terraformation ?",
      "terraforming_short": "Terra",
      "terraforming_state": "État de terraformation",
      "time": "Durée de calcul",
      "time_description": "Durée de calcul de l'itinéraire (en secondes, une durée plus élevée devrait donner un itinéraire plus court)",
      "time_remaining": "Temps restant estimé",
      "time_taken": "Estiamtion du temps pris",
      "timestamp": "de {from} à {to}",
      "total_profit": "Profit total",
      "tritium_fuel": "Tritium dans le dépôt",
      "tritium_fuel_description": "La quantité de tritium dans cotre dépôt de carburant",
      "tritium_market": "Tritium dans le marché",
      "tritium_market_description": "La quantité de tritium dans votre marché de marchandises",
      "type": "Type",
      "unique": "Éviter les boucles",
      "unique_description": "Empêcher l'outil de revisiter les stations qui sont déjà dans l'itinéraire",
      "updated_at": "Dernière mise à jour le",
      "use_injections": "Utiliser les injections FSD",
      "use_injections_description": "Utiliser la synthèse FSD pour supercharger quand il n'y a aucune étoile à neutrons à proximité.",
      "use_mapping_value": "Utiliser la valeur de cartographie",
      "use_mapping_value_description": "Utiliser la valeur de cartographie plutôt que la valeur de scan",
      "use_supercharge": "Utiliser la supercharge",
      "use_supercharge_description": "Utiliser les étoiles à neutrons pour supercharger votre réacteur FSD",
      "used_capacity": "Capacité utilisée",
      "used_capacity_description": "La capacité utilisée par les modules installés et le marché de marchandises dans votre porte-vaisseaux",
      "via": "Passer par le système",
      "volcanism_type": "Volcanisme",
      "x": "X",
      "x_coordinate": "Coordonnée X (abscisse)",
      "y": "Y",
      "y_coordinate": "Coordonnée Y (ordonnée)",
      "z": "Z",
      "z_coordinate": "Coordonnée Z (cote)"
    },
    "fields_help": {
      "body_name": "Utilisez ce champ pour filtrer les corps ayant un nom correspondant. Par exemple Sol correspondrait à Sol, mais pas à Solati 6.",
      "destination": "Utilisez ce champ pour indiquer la destination de l'itinéraire",
      "reference_coords": "Utilisez ceci plutôt qu'un système de référence pour indiquer par rapport à quelle coordonnées vous souhaitez effectuer la recherche (pour les distances etc).",
      "reference_route": "Utilisez ceci pour indiquer un itinéraire par rapport auquel effectuer la recherche (pour les distances etc).",
      "reference_system": "Utilisez ceci pour indiquer un système par rapport auquel effectuer la rechercher (pour les distances etc).",
      "source": "Utilisez ce champ pour indiquer le départ de l'itinéraire",
      "station_name": "Utilisez ce champ pour filtrer les stations dont le nom contient le mot indiqué",
      "system_name": "Utilisez ceci pour filtrer les corps d'un système à l'aide d'un mot correspondant. Par exemple \"Col\" correspondrait à Col 285 Sector IY-W b16-6 mais pas à Colonia"
    },
    "fleet-carrier": {
      "description": "Cette page vous permettra de planifier un itinéraire entre deux système solaires différents pour votre porte-vaisseaux. Le résultat vous indiquera tous les systèmes pour lesquels vous devrez planifier un itinéraire. Il vous indiquera également lorsque vous devrez récolter plus de carburant. Les estimations de temps sont fondées sur une durée de 20 minutes par saut, et un rendement de 150 tonnes de tritium par heure de minage pour un joueur seul.",
      "import_destinations": "Import destinations",
      "import_destinations_description": "Import a waypoint file from EDSM as a set of destinations (A simple text file with one system name per line).",
      "importing": "Importing destinations",
      "move_destination_down": "Move destination down",
      "move_destination_up": "Move destination up",
      "refuel_destination": "Refuel at this waypoint",
      "refueling_destination": "{destination} (Refuel)",
      "title": "Planificateur d'itinéraires de porte-vaisseaux",
      "warning": "Ce planificateur vous permettra de planifier un itinéraire pour porte-vaisseaux entre différents systèmes"
    },
    "footer": {
      "copyright": "Copyright {from}-{to} Gareth Harper.",
      "donations": "Si vous voulez effectuer un don pour aider au fonctionnement et à la mise à jour de ce site, vous pouvez visiter ma page patreon <a href=\"https://www.patreon.com/spansh\">ici</a>.",
      "features": "Si vous avez des suggestions de fonctionnalités ou si vous trouvez des bugs, vous pouvez visiter le <a href=\"https://forums.frontier.co.uk/threads/neutron-highway-long-range-route-planner.308674/\">sujet de forum</a> de cet outil ou envoyer un mail à <a href=\"mailto:plotter@spansh.co.uk\">plotter@spansh.co.uk</a>.",
      "thanks": "Merci à tous les joueurs et explorateurs qui fournissent les données utilisées par ce site."
    },
    "goals": {
      "bounty": "Prime",
      "carto": "Universal Cartographics",
      "combatbond": "Combat Bond",
      "expires": "Se termine le {date}",
      "fetch_error": "Impossible de récupérer les objectifs depuis les serveurs de Frontier",
      "fetching": "Récupération des objectifs depuis les serveurs de Frontier",
      "mining": "Mining",
      "none": "Aucun objectif communautaire listé actuellement",
      "research": "Recherche",
      "trade": "Apporter {commodity} à {station} dans {system}",
      "tradelist": "Commerce"
    },
    "government": {
      "Anarchy": "Anarchie",
      "Communism": "Communiste",
      "Confederacy": "Confédération",
      "Cooperative": "Coopérative",
      "Corporate": "Corporation",
      "Democracy": "Démocratie",
      "Dictatorship": "Dictature",
      "Engineer": "Ingénieur",
      "Feudal": "Société féodale",
      "None": "Aucun",
      "Patronage": "Société clientéliste",
      "Prison": "Centre de détention",
      "Prison Colony": "Colonie pénitentiaire",
      "Private Ownership": "Propriétaire privé",
      "Theocracy": "Théocratie",
      "Workshop (Engineer)": "Atelier (Ingénieur)"
    },
    "landmark_subtype": {
      "Abandoned Base": "Base abandonnée",
      "Albidum Sinuous Tubers": "Albidum Sinuous Tubers",
      "Alpha": "Alpha",
      "Ammonia Ice Fumarole": "Fumerolle d'ammoniac glacé",
      "Ammonia Ice Geyser": "Geyser d'ammoniac glacé",
      "Amphora Plant": "Plante amphore",
      "Aureum Brain Tree": "Céphalobore aureum",
      "Aureus Brain Tree": "Céphalobore aureus",
      "Bark Mound": "Rhizomes",
      "Bear": "Bear",
      "Beta": "Bêta",
      "Blatteum Bioluminescent Anemone": "Anémone bioluminescente blatteum",
      "Blatteum Sinuous Tubers": "Blatteum Sinuous Tubers",
      "Bowl": "Bowl",
      "Brain Tree": "Céphalobore",
      "Carbon Dioxide Fumarole": "Fumerolle de dioxyde de carbone",
      "Carbon Dioxide Gas Vent": "Cheminée de dioxyde de carbone",
      "Carbon Dioxide Ice Fumarole": "Fumerolle de dioxyde de carbone glacé",
      "Carbon Dioxide Ice Geyser": "Geyser de dioxyde de carbone glacé",
      "Carpasinus Brain Tree": "Céphalobore carpasinus",
      "Common Thargoid Barnacle": "Common Thargoid Barnacle",
      "Crashed Ship": "Vaisseau écrasé",
      "Crater Outpost": "Crater Outpost",
      "Crater Port": "Crater Port",
      "Croceum Anemone": "Anémone croceum",
      "Crossroads": "Crossroads",
      "Crystalline Shards": "Éclats cristallins",
      "Cymatilis Brain Tree": "Céphalobore cymatilis",
      "Delta": "Delta",
      "Epsilon": "Epsilon",
      "Fistbump": "Fistbump",
      "Gamma": "Gamma",
      "Gypseeum Brain Tree": "Céphalobore gypseeum",
      "Hammerbot": "Hammerbot",
      "Installation": "Installation",
      "Iron Magma Lava Spout": "Mare de lave de magma de fer",
      "Lacrosse": "Lacrosse",
      "Lindigoticum Brain Tree": "Céphalobore lindigoticum",
      "Lindigoticum Sinuous Tubers": "Lindigoticum Sinuous Tubers",
      "Livens Brain Tree": "Céphalobore livens",
      "Lividum Brain Tree": "Céphalobore lividum",
      "Luteolum Anemone": "Anémone luteolum",
      "Mega": "Mega",
      "Methane Ice Fumarole": "Fumerolle de méthane glacé",
      "Methane Ice Geyser": "Geyser de méthane glacé",
      "Nitrogen Ice Fumarole": "Fumerolle d'azote glacé",
      "Nitrogen Ice Geyser": "Geyser d'azote glacé",
      "Ostrinum Brain Tree": "Céphalobore ostrinum",
      "Ostrinus Brain Tree": "Céphalobore ostrinus",
      "Prasinum Bioluminescent Anemone": "Anémone bioluminescente prasinum",
      "Prasinum Sinuous Tubers": "Prasinum Sinuous Tubers",
      "Prasinus Bioluminescent Anemone": "Anémone bioluminescente prasinus",
      "Puniceum Anemone": "Anémone puniceum",
      "Puniceum Brain Tree": "Céphalobore puniceum",
      "Puniceus Brain Tree": "Céphalobore puniceus",
      "Robolobster": "Robolobster",
      "Roseum Anemone": "Anémone roseum",
      "Roseum Bioluminescent Anemone": "Anémone bioluminescente roseum",
      "Roseum Brain Tree": "Céphalobore roseum",
      "Roseum Sinuous Tubers": "Tubercules sinueux roseum",
      "Roseus Brain Tree": "Céphalobore roseus",
      "Roseus Sinuous Tuber": "Tubercules sinueux roseus",
      "Rubeum Bioluminescent Anemone": "Anémoné bioluminescente rubeum",
      "Silicate Magma Lava Spout": "Mare de lave de magma de silicates",
      "Silicate Vapour Fumarole": "Fumerolle de vapeur de silicates",
      "Silicate Vapour Gas Vent": "Geyser de vapeur de silicates",
      "Silicate Vapour Ice Fumarole": "Fumerolle de vapeur de silicates glacée",
      "Squid": "Squid",
      "Stickyhand": "Stickyhand",
      "Sulphur Dioxide Fumarole": "Fumerolle de dioxyde de soufre",
      "Sulphur Dioxide Gas Vent": "Cheminée de dioxyde de soufre",
      "Sulphur Dioxide Ice Fumarole": "Fumerolle de dioxyde de soufre glacé",
      "Surface Station": "Surface Station",
      "Thargoid Structure": "Thargoid Structure",
      "Tourist Beacon": "Balise touristique",
      "Turtle": "Turtle",
      "Unknown": "Inconnu",
      "Violaceum Sinuous Tubers": "Violaceum Sinuous Tubers",
      "Viridans Brain Tree": "Céphalobore viridans",
      "Viride Brain Tree": "Céphalobore viride",
      "Viride Sinuous Tubers": "Viride Sinuous Tubers",
      "Water Fumarole": "Fumerolle d'eau",
      "Water Gas Vent": "Cheminée d'eau",
      "Water Geyser": "Geyser d'eau",
      "Water Ice Fumarole": "Fumerolle d'eau glacée",
      "Water Ice Geyser": "Geyser aqueux glacé",
      "Wrecked Ship": "Épave de vaisseau"
    },
    "landmark_type": {
      "Abandoned Base": "Base abandonnée",
      "Amphora Plant": "Plante amphore",
      "Bark Mound": "Rhizomes",
      "Brain Tree": "Céphalobore",
      "Crashed Ship": "Vaisseau écrasé",
      "Crystalline Shard": "Éclats cristallins",
      "Fumarole": "Fumerolle",
      "Fungal Gourd": "Fungal Gourd",
      "Gas Vent": "Cheminée",
      "Geyser": "Geyser",
      "Guardian Ruin": "Guardian Ruin",
      "Guardian Structure": "Guardian Structure",
      "Lava Spout": "Mare de lave",
      "Surface Station": "Surface Station",
      "Thargoid Barnacle": "Balanes thargoid",
      "Thargoid Structure": "Thargoid Structure",
      "Tourist Beacon": "Balise touristique",
      "Tube Worm": "Tubercules sinueux",
      "Wrecked Ship": "Épave de vaisseau"
    },
    "map": {
      "description": "Une carte de toutes les étoiles à neutrons connues dans la galaxie.",
      "title": "Carte des étoiles à neutrons"
    },
    "market": {
      "buy_price": "Prix d'achat",
      "buy_price_header": "Prix d'achat de {commodity}",
      "demand": "Demande",
      "demand_header": "Demande de {commodity}",
      "remove_field": "Retirer un filtre",
      "sell_price": "Prix de vente",
      "sell_price_header": "Prix de vente de {commodity}",
      "supply": "Offre",
      "supply_header": "Offre de {commodity}"
    },
    "material": {
      "Antimony": "Antimoine",
      "Arsenic": "Arsenic",
      "Cadmium": "Cadmium",
      "Carbon": "Carbone",
      "Chromium": "Chrome",
      "Germanium": "Germanium",
      "Iron": "Fer",
      "Manganese": "Manganèse",
      "Mercury": "Mercure",
      "Molybdenum": "Molybdène",
      "Nickel": "Nickel",
      "Niobium": "Niobium",
      "Phosphorus": "Phosphore",
      "Polonium": "Polonium",
      "Ruthenium": "Ruthénium",
      "Selenium": "Sélénium",
      "Sulphur": "Soufre",
      "Technetium": "Technétium",
      "Tellurium": "Tellure",
      "Tin": "Étain",
      "Tungsten": "Tungstène",
      "Vanadium": "Vanadium",
      "Yttrium": "Yttrium",
      "Zinc": "Zinc",
      "Zirconium": "Zirconium"
    },
    "modules": {
      "add_field": "Ajouter un filtre",
      "categories": {
        "hardpoint": "Point d'emport",
        "internal": "Interne",
        "standard": "Standard",
        "utility": "Supports utilitaires"
      },
      "class": "Classe",
      "name": "Nom du module",
      "names": {
        "AX Missile Rack": "AX Missile Rack",
        "AX Multi-Cannon": "AX Multi-Cannon",
        "Abrasion Blaster": "Surfaceur abrasif",
        "Advanced Discovery Scanner": "Détecteur de découvertes avancé",
        "Advanced Docking Computer": "Ordinateur d'appontage avancé",
        "Advanced Missile Rack": "Batterie de missiles perfectionnée",
        "Advanced Multi-Cannon": "Multi-canon perfectionné",
        "Advanced Planetary Approach Suite": "Advanced Planetary Approach Suite",
        "Advanced Plasma Accelerator": "Advanced Plasma Accelerator",
        "Auto Field-Maintenance Unit": "Unité maintenance terrain auto",
        "Basic Discovery Scanner": "Basic Discovery Scanner",
        "Beam Laser": "Rayon laser",
        "Bi-Weave Shield Generator": "Générateur de bouclier entrelacé",
        "Burst Laser": "Laser à rafale",
        "Business Class Passenger Cabin": "Cabine passagers affaires",
        "Cannon": "Canon",
        "Cargo Rack": "Compartiment de soute",
        "Cargo Scanner": "Détecteur de manifeste",
        "Chaff Launcher": "Lanceur de paillettes",
        "Collector Limpet Controller": "Contrôleur de collecteurs",
        "Corrosion Resistant Cargo Rack": "Compartiment de soute résistant à la corrosion",
        "Cytoscrambler Burst Laser": "Cytoscrambler Burst Laser",
        "Decontamination Limpet Controller": "Decontamination Limpet Controller",
        "Detailed Surface Scanner": "Détecteur de surface détaillé",
        "Economy Class Passenger Cabin": "Cabine passagers économie",
        "Electronic Countermeasure": "Contre-mesures électro.",
        "Enforcer Cannon": "Enforcer Cannon",
        "Enhanced Performance Thrusters": "Enhanced Performance Thrusters",
        "Enzyme Missile Rack": "Batterie de missiles enzymatiques",
        "Fighter Hangar": "Hangar pour chasseur",
        "First Class Passenger Cabin": "Cabine passagers 1re",
        "Fragment Cannon": "Canon à fragmentation",
        "Frame Shift Drive": "Réacteur FSD",
        "Frame Shift Drive Interdictor": "Intercepteur de réacteur FSD",
        "Frame Shift Wake Scanner": "Détecteur de sillage FSD",
        "Fuel Scoop": "Récupérateur de carburant",
        "Fuel Tank": "Réservoir",
        "Fuel Transfer Limpet Controller": "Contrôleur de ravitailleur",
        "Guardian FSD Booster": "Survolteur de réacteur FSD - Guardians",
        "Guardian Gauss Cannon": "Canon de Gauss - Guardians",
        "Guardian Hull Reinforcement": "Pack de renforcement de coque - Guardians",
        "Guardian Hybrid Power Distributor": "Répartiteur hybride de puissance - Guardians",
        "Guardian Hybrid Power Plant": "Générateur hybride - Guardians",
        "Guardian Module Reinforcement": "Pack de renforcement de modules - Guardians",
        "Guardian Plasma Charger": "Chargeur à plasma - Guardians",
        "Guardian Shard Cannon": "Canon à éclats - Guardians",
        "Guardian Shield Reinforcement": "Pack de renforcement de bouclier - Guardians",
        "Hatch Breaker Limpet Controller": "Contrôle de drone perce-soute",
        "Heat Sink Launcher": "Éjecteur de dissipateur themique",
        "Hull Reinforcement Package": "Pack de renforcement de coque",
        "Imperial Hammer Rail Gun": "Imperial Hammer Rail Gun",
        "Intermediate Discovery Scanner": "Intermediate Discovery Scanner",
        "Kill Warrant Scanner": "Détecteur d'avis de recherche",
        "Life Support": "Systèmes de survie",
        "Lightweight Alloy": "Alliages légers",
        "Limpet Control": "Limpet Control",
        "Luxury Class Passenger Cabin": "Cabine de passagers de luxe",
        "Meta Alloy Hull Reinforcement": "Renforcement de coque en méta-alliage",
        "Military Grade Composite": "Composite militaire",
        "Mine Launcher": "Lance-mines",
        "Mining Lance": "Mining Lance",
        "Mining Laser": "Laser minier",
        "Mining Multi Limpet Controller": "Mining Multi Limpet Controller",
        "Mirrored Surface Composite": "Composite à surface mirroir",
        "Missile Rack": "Batterie de missiles",
        "Module Reinforcement Package": "Pack de renforcement de modules",
        "Multi-Cannon": "Multi-Cannon",
        "Operations Multi Limpet Controller": "Operations Multi Limpet Controller",
        "Pacifier Frag-Cannon": "Pacifier Frag-Cannon",
        "Pack-Hound Missile Rack": "Pack-Hound Missile Rack",
        "Planetary Approach Suite": "Instruments d'approche planétaire",
        "Planetary Vehicle Hangar": "Hangar de véhicules planétaires",
        "Plasma Accelerator": "Accélérateur à plasma",
        "Point Defence": "Défense ponctuelle",
        "Power Distributor": "Répartiteur de puissance",
        "Power Plant": "Générateur",
        "Prismatic Shield Generator": "Prismatic Shield Generator",
        "Prospector Limpet Controller": "Contrôleur de prospecteurs",
        "Pulse Disruptor Laser": "Pulse Disruptor Laser",
        "Pulse Laser": "Laser à impulsion",
        "Pulse Wave Analyser": "Analyseur à onde pulsée",
        "Rail Gun": "Canon électrique",
        "Reactive Surface Composite": "Composite à surface réactive",
        "Recon Limpet Controller": "Contrôle de drone de reconnaissance",
        "Refinery": "Raffinerie",
        "Reinforced Alloy": "Alliage renforcé",
        "Remote Release Flak Launcher": "Remote Release Flak Launcher",
        "Remote Release Flechette Launcher": "Lanceur de fléchettes déclenchées à distance",
        "Repair Limpet Controller": "Contrôleur de réparateurs",
        "Rescue Multi Limpet Controller": "Rescue Multi Limpet Controller",
        "Research Limpet Controller": "Contrôleur de drone de recherche",
        "Retributor Beam Laser": "Retributor Beam Laser",
        "Rocket Propelled FSD Disruptor": "Rocket Propelled FSD Disruptor",
        "Seeker Missile Rack": "Missiles TC",
        "Seismic Charge Launcher": "Lanceur à charges sismiques",
        "Sensors": "Capteurs",
        "Shield Booster": "Survolteur de bouclier",
        "Shield Cell Bank": "Réserve de cellules d'énergie",
        "Shield Generator": "Générateur de boucliers",
        "Shock Cannon": "Canon de choc",
        "Shock Mine Launcher": "Lance-mines électriques",
        "Shutdown Field Neutraliser": "Neutralisateur de champ incapacitant",
        "Standard Docking Computer": "Ordinateur d'appontage",
        "Sub-Surface Displacement Missile": "Missile à charge pénétrante souterraine",
        "Supercruise Assist": "Super-navigation assistée",
        "Thrusters": "Propulseurs",
        "Torpedo Pylon": "Tube lance-torpille",
        "Universal Multi Limpet Controller": "Universal Multi Limpet Controller",
        "Xeno Multi Limpet Controller": "Xeno Multi Limpet Controller",
        "Xeno Scanner": "Détecteur de xénomorphes"
      },
      "rating": "Note",
      "remove_field": "Retirer un filtre",
      "select_class": "Sélectionner une classe",
      "select_name": "Sélectionner un nom de module",
      "select_rating": "Sélectionner la note"
    },
    "navigation": {
      "admin": "Admin",
      "ammonia": "Planificateur de mondes ammoniacaux",
      "bodies": "Corps",
      "community-goals": "Objectifs communautaires",
      "donations": "Dons",
      "dumps": "Lots de données",
      "earth": "Earth-like World Route",
      "exact-plotter": "Planificateur d'itinéraire galactique",
      "fleet-carrier": "Plinificateur d'itinéraires de porte-vaisseaux",
      "helpers": "Aides",
      "language": "Langue",
      "login": "Connexion",
      "logout": "Déconnexion",
      "map": "Carte",
      "nearest": "Système le plus proche",
      "oldsite": "Ancien site",
      "plotter": "Planificateur d'itinéraire à neutrons",
      "riches": "Road to Riches",
      "routers": "Planificateurs d'itinéraires",
      "search": "Rechercher",
      "settings": "Paramètres",
      "stations": "Stations",
      "systems": "Systèmes",
      "thanks": "Remerciements",
      "theme": "Thème",
      "toggle": "Toggle navigation",
      "tourist": "Planificateur d'itinéraires touristiques",
      "trade": "Planificateur d'itinéraire commercial"
    },
    "nearest": {
      "description": "Cette page vous permettra de trouver le système connu le plus proche des coordonnées indiquées",
      "title": "Système connu le plus proche"
    },
    "no": "Non",
    "plotter": {
      "description": "Cette page vous permettra de planifier un itinéraire entre deux systèmes solaires différents. Une fois l'itinéraire planifié, le site vous indiquera à quel moment il sera nécessaire de consulter la carte galactique pour planifier un itinéraire (par exemple quand vous vous trouvez près d'une étoile à neutrons). Il privilégiera les étoiles à neutrons et devrait donc être évité pour des voyages de moins de 500 années lumières (Il vous indiquera certainement de vous rendre à Jackon's Lighthose d'abord si vous êtes dans la bulle).",
      "exact_plotter": "Si vous chercher un planificateur plus complet, qui prend en compte la récupération de carburant, les étoiles à neutrons secondaires et les injections FSD, utilisez plutôt le nouveau <a href=\"{link}\">Planificateur d'itinéraires galactique</a>",
      "title": "Planificateur d'itinéraire à neutrons",
      "warning": "Si votre portée de saut maximale est faible (entre 10 et 20 AL), vous pourriez faire un saut superchargé vous menant dans un système duquel votre portée de saut ne vous permettra pas de sortir. Vous devriez faire attention en utilisant cet outil et vérifier que vous pouvez sortir du système lorsque vous consultez la carte galactique."
    },
    "power_state": {
      "Contested": "Contesté",
      "Control": "Contrôle",
      "Controlled": "Contrôlé",
      "Exploited": "Exploité",
      "HomeSystem": "Quartier général",
      "InPrepareRadius": "In Prepare Radius",
      "Prepared": "Prepared",
      "Turmoil": "Trouble"
    },
    "region": {
      "Acheron": "Acheron",
      "Achilles's Altar": "Autel d'Achille",
      "Aquila's Halo": "Halo de l'aigle",
      "Arcadian Stream": "Courant arcadien",
      "Dryman's Point": "Point de Dryman",
      "Elysian Shore": "Rivage élyséen",
      "Empyrean Straits": "Détroit empyréen",
      "Errant Marches": "Marches errantes",
      "Formorian Frontier": "Frontière formorienne",
      "Galactic Centre": "Centre galactique",
      "Hawking's Gap": "Brèche de Hawking",
      "Hieronymus Delta": "Delta de Hieronymus",
      "Inner Orion Spur": "Boucle d'Orion interne",
      "Inner Orion-Perseus Conflux": "Conluent Orio-Perse interne",
      "Inner Scutum-Centaurus Arm": "Bras écu-croix interne",
      "Izanami": "Izanami",
      "Kepler's Crest": "Crête de Kepler",
      "Lyra's Song": "Chant de la Lyre",
      "Mare Somnia": "Mare Somnia",
      "Newton's Vault": "Voute de Newton",
      "Norma Arm": "Bras de la règle",
      "Norma Expanse": "Étendue de la règle",
      "Odin's Hold": "Forteresse d'Odin",
      "Orion-Cygnus Arm": "Bras d'Orion",
      "Outer Arm": "Bras extérieur",
      "Outer Orion Spur": "Boucle d'Orion externe",
      "Outer Orion-Perseus Conflux": "Confluent Orio-Perse externe",
      "Outer Scutum-Centaurus Arm": "Bras écu-croix externe",
      "Perseus Arm": "Bras de Persée",
      "Ryker's Hope": "Espérance de Ryker",
      "Sagittarius-Carina Arm": "Bras Sagittaire-Carène",
      "Sanguineous Rim": "Frontière sanguine",
      "Temple": "Temple",
      "Tenebrae": "Tenebrae",
      "The Abyss": "L'Abysse",
      "The Conduit": "Le Conduit",
      "The Formidine Rift": "Faille Formidine",
      "The Veils": "Dentelles du Cygne",
      "The Void": "Le Néant",
      "Trojan Belt": "Ceinture troyenne",
      "Vulcan Gate": "Porte de Vulcain",
      "Xibalba": "Xibalba"
    },
    "reserve_level": {
      "Common": "Communes",
      "Depleted": "Épuisées",
      "Low": "Faibles",
      "Major": "Abondantes",
      "Pristine": "Inexploitées"
    },
    "results": {
      "atmosphere_composition_to_show": "Composition atmosphérique à afficher",
      "bodies_count": "{count} corps",
      "body_belts": "{name} ceintures",
      "body_landmarks": "{name} repères",
      "body_rings": "{name} anneaux",
      "close": "Fermer",
      "commodities_to_show": "Marchandises à afficher",
      "commodity_fields_to_show": "Champs de marchandises à afficher",
      "copy_to_clipboard": "Copier {system} dans le presse-papiers",
      "create_route": "Créer un itinéraire touristique à partir de cette recherche",
      "display_search": "Indiquer la recherche sur la carte",
      "download": "Télécharger au format CSV",
      "download_search": "Télécharger au format CSV",
      "estimated_jumps": "Nombre de sauts estimé : {jumps}",
      "estimated_time": "Temps estimé : {time}",
      "fields_to_show": "Champs à afficher",
      "first": "Premier",
      "galaxy_map_visits": "Nombre d'utilisations de la carte de la galaxie nécessaires : {total_visits}",
      "hide_search": "Cacher la recherche",
      "jumps": "Sauts : {jumps}",
      "last": "Dernier",
      "materials_to_show": "Matériaux à afficher",
      "next": "Suivant",
      "no_results": "Cette recherche n'a retourné aucun résultat",
      "no_search": "Effectuer une recherche pour voir les résultats",
      "none": "Aucun",
      "previous": "Précédent",
      "results": "Résultats",
      "results_per_page": "Résultats par page",
      "route": "De {source} jusqu'a {destination}",
      "show_route": "Afficher l'itinéraire",
      "show_search": "Afficher la recherche",
      "showing": "Afficher les résultats à partir de {start} jusqu'à {end} sur {count}",
      "systems_count": "{count} systèmes",
      "totals": "Totaux"
    },
    "riches": {
      "blank": "Laisser la destination vide pour créer un itinéraire circulaire démarrant au système source désigné et qui se terminera dans ce même système",
      "guides": "Vous pouvez trouvez des guides et des vidéos pour apprendre à utiliser l'ACS et le DSD <a href=\"https://forums.frontier.co.uk/showthread.php/464149-The-FSS-A-pictoral-guide\">ici</a> et <a href=\"https://forums.frontier.co.uk/showthread.php/465649-Setup-and-Use-of-the-FSS-and-DSS-With-Demonstration-Videos\">là</a>.",
      "low_results": "Il y a moins de systèmes que demandé Essayez d'augmenter le rayon de recherche pour trouver plus de corps. Si vous n'avez toujours pas assez de résultats, vous avez peut-être indiqué une valeur de scan trop élevée.",
      "title": "Road 2 Riches",
      "warning": "Vous devriez décider d'utiliser le DSD (Détecteur de Surface Détaillé qui nécessite d'aller jusqu'au corps dans le système et de cartographier sa surface) ou simplement l'ACS (Analyse Complète du Système à l'arrivée dans le système). L'ACS est plus rapide mais rapporte beaucoup moins de crédits. La case à cocher \"Utiliser la valeur de cartographie\" triera les corps par récompense de DSD plutôt que d'ACS."
    },
    "ring_signal": {
      "Alexandrite": "Alexandrite",
      "Bauxite": "Bauxite",
      "Benitoite": "Bénitoïte",
      "Bertrandite": "Bertrandite",
      "Biological": "Biologique",
      "Bromellite": "Bromellite",
      "Cobalt": "Cobalt",
      "Coltan": "Coltan",
      "Gallite": "Gallite",
      "Geological": "Géologique",
      "Gold": "Or",
      "Grandidierite": "Grandidiérite",
      "Guardian": "Gardiens",
      "Human": "Humain",
      "Hydrogen Peroxide": "Peroxyde d'hydrogène",
      "Indite": "Indite",
      "Lepidolite": "Lépidolite",
      "Liquid oxygen": "Oxygène liquide",
      "Lithium Hydroxide": "Hydroxyde de lithium",
      "Low Temperature Diamonds": "Diamants basse température",
      "Methane Clathrate": "Hydrate de méthane",
      "Methanol Monohydrate Crystals": "Cristaux de méthanol monohydraté",
      "Monazite": "Monazite",
      "Musgravite": "Musgravite",
      "Osmium": "Osmium",
      "Other": "Autre",
      "Painite": "Painite",
      "Platinum": "Platine",
      "Praseodymium": "Praséodyme",
      "Rhodplumsite": "Rhodplumsite",
      "Rutile": "Rutile",
      "Samarium": "Samarium",
      "Serendibite": "Serendibite",
      "Silver": "Argent",
      "Thargoid": "Thargoid",
      "Tritium": "Tritium",
      "Uraninite": "Uraninite",
      "Void Opals": "Opales du vide",
      "Water": "Eau"
    },
    "rings": {
      "add_field": "Ajouter un filtre",
      "inner_radius": "Rayon intérieur (m)",
      "mass": "Masse (Mt)",
      "outer_radius": "Rayon extérieur (m)",
      "remove_field": "Retirer un filtre",
      "select_type": "Sélectionner un type",
      "type": "Type",
      "types": {
        "Icy": "Glacé",
        "Metal Rich": "Riche en métal",
        "Metallic": "Métallique",
        "Rocky": "Rocheux"
      }
    },
    "scanned-bodies": {
      "title": "Scanned Bodies ({start} to {end} of {total})"
    },
    "search": {
      "copy_name_to_clipboard": "Copy name to clipboard",
      "description": "Top {size} results of {count} searching for \"{query}\"",
      "fail": "Cannot complete search, please try later.",
      "types": {
        "body": "Body",
        "station": "Station",
        "system": "System"
      }
    },
    "security": {
      "Anarchy": "Anarchie",
      "High": "Élevée",
      "Lawless": "Sans foi ni loi",
      "Low": "Faible",
      "Medium": "Moyenne"
    },
    "services": {
      "Apex Interstellar": "Apex Interstellar",
      "Autodock": "Appontage automatique",
      "Bartender": "Bartender",
      "Black Market": "Marché noir",
      "Contacts": "Contacts",
      "Crew Lounge": "Salle d'attente de l'équipage",
      "Dock": "Appontage",
      "Facilitator": "Interstellar Factors Contact",
      "Fleet Carrier Administration": "Administration des porte-vaisseaux",
      "Fleet Carrier Fuel": "Dépôt de tritium",
      "Fleet Carrier Management": "Administration de porte-vaisseaux",
      "Fleet Carrier Vendor": "Vendeur de porte-vaisseaux",
      "Flight Controller": "Flight Controller",
      "Frontline Solutions": "Frontline Solutions",
      "Initiatives": "Initiatives",
      "Interstellar Factors Contact": "Interstellar factors",
      "Livery": "Livery",
      "Market": "Marché",
      "Material Trader": "Courtier en matériaux",
      "Missions": "Missions",
      "Missions Generated": "Missions générées",
      "Module Packs": "Stocks des services d'équipement",
      "On Dock Mission": "On Dock Mission",
      "Outfitting": "Équipement",
      "Pioneer Supplies": "Pioneer Supplies",
      "Powerplay": "Contact au sein de la puissance",
      "Redemption Office": "Bureau des récompenses",
      "Refuel": "Carburant",
      "Repair": "Réparations",
      "Restock": "Ravitaillement",
      "Search and Rescue": "Opérations de sauvetage",
      "Shipyard": "Chantier naval",
      "Shop": "Boutique",
      "Social Space": "Social Space",
      "Station Menu": "Menu de la station",
      "Station Operations": "Opérations de la station",
      "Technology Broker": "Courtier en technologies",
      "Tuning": "Personnalisation",
      "Universal Cartographics": "Universal Cartographics",
      "Vista Genomics": "Vista Genomics",
      "Workshop": "Atelier"
    },
    "settings": {
      "confirm_delete": "Confirmer la suppression de l'utilisateur",
      "confirm_delete_warning": "Attention, si vous continuez, vous supprimerez votre compte d'utilisateur et toutes les données enregistrées associées, y compris, mais sans s'y limiter, les scans enregistrés sur la route des richesses. Vous pouvez recréer votre compte utilisateur en liant à nouveau votre compte Frontier, mais vos données enregistrées seront définitivement perdues et ne pourront pas être restaurées. Êtes-vous sûr de vouloir continuer?",
      "delete_help": "Supprimez votre compte utilisateur et toutes les données associées.",
      "delete_user": "Supprimer l'utilisateur",
      "link_patreon": "Associer un compte patreon",
      "link_patreon_help": "Si vous êtes un mécène, associez ici votre compte patreon pour recevoir des récompenses supplémentaires",
      "linked_no_patron": "Vous avez associé un compte patreon mais vous n'êtes pas actuellement un mécène",
      "linked_patron": "Vous avez déjà associé un compte patreon et vous êtes un mécène, merci"
    },
    "ships": {
      "Adder": "Adder",
      "Alliance Challenger": "Alliance Challenger",
      "Alliance Chieftain": "Alliance Chieftain",
      "Alliance Crusader": "Alliance Crusader",
      "Anaconda": "Anaconda",
      "Asp Explorer": "Asp Explorer",
      "Asp Scout": "Asp Scout",
      "Beluga Liner": "Beluga Liner",
      "Cobra MkIII": "Cobra MkIII",
      "Cobra MkIV": "Cobra MkIV",
      "Diamondback Explorer": "Diamondback Explorer",
      "Diamondback Scout": "Diamondback Scout",
      "Dolphin": "Dolphin",
      "Eagle": "Eagle",
      "Federal Assault Ship": "Federal Assault Ship",
      "Federal Corvette": "Federal Corvette",
      "Federal Dropship": "Federal Dropship",
      "Federal Gunship": "Federal Gunship",
      "Fer-de-Lance": "Fer-de-Lance",
      "Hauler": "Hauler",
      "Imperial Clipper": "Imperial Clipper",
      "Imperial Courier": "Imperial Courier",
      "Imperial Cutter": "Imperial Cutter",
      "Imperial Eagle": "Imperial Eagle",
      "Keelback": "Keelback",
      "Krait MkII": "Krait MkII",
      "Krait Phantom": "Krait Phantom",
      "Mamba": "Mamba",
      "Orca": "Orca",
      "Python": "Python",
      "Sidewinder": "Sidewinder",
      "Type-10 Defender": "Type-10 Defender",
      "Type-6 Transporter": "Type-6 Transporter",
      "Type-7 Transporter": "Type-7 Transporter",
      "Type-9 Heavy": "Type-9 Heavy",
      "Viper MkIII": "Viper MkIII",
      "Viper MkIV": "Viper MkIV",
      "Vulture": "Vulture"
    },
    "signal": {
      "Alexandrite": "Alexandrite",
      "Bauxite": "Bauxite",
      "Benitoite": "Bénitoïte",
      "Bertrandite": "Bertrandite",
      "Biological": "Biologique",
      "Bromellite": "Bromellite",
      "Cobalt": "Cobalt",
      "Coltan": "Coltan",
      "Gallite": "Gallite",
      "Geological": "Géologique",
      "Gold": "Or",
      "Grandidierite": "Grandidiérite",
      "Guardian": "Gardien",
      "Human": "Humain",
      "Hydrogen Peroxide": "Peroxyde d'hydrogène",
      "Indite": "Indite",
      "Lepidolite": "Lépidolite",
      "Liquid oxygen": "Oxygène liquide",
      "Lithium Hydroxide": "Hydroxyde de lithium",
      "Low Temperature Diamonds": "Diamants basse température",
      "Methane Clathrate": "Hydrate de méthane",
      "Methanol Monohydrate Crystals": "Cristaux de méthanol monohydraté",
      "Monazite": "Monazite",
      "Musgravite": "Musgravite",
      "Osmium": "Osmium",
      "Other": "Autre",
      "Painite": "Painite",
      "Platinum": "Platine",
      "Praseodymium": "Praséodyme",
      "Rhodplumsite": "Rhodplumsite",
      "Rutile": "Rutile",
      "Samarium": "Samarium",
      "Serendibite": "Sérendibite",
      "Silver": "Argent",
      "Thargoid": "Thargoid",
      "Tritium": "Tritium",
      "Uraninite": "Uraninite",
      "Void Opals": "Opales du Vide",
      "Water": "Eau"
    },
    "solid_composition": {
      "Ice": "Glace",
      "Metal": "Métal",
      "Rock": "Roche"
    },
    "sorting": {
      "asc": "Croissant",
      "desc": "Décroissant",
      "field_ascending": "Trier par {field} croissant",
      "field_descending": "Trier par {field} décroissant"
    },
    "state": {
      "Blight": "Fléau",
      "Boom": "Essor économique",
      "Bust": "Faillite",
      "Civil Liberty": "Liberté civile",
      "Civil Unrest": "Émeutes",
      "Civil War": "Guerre civile",
      "Drought": "Sécheresse",
      "Election": "Élections",
      "Expansion": "Expansion",
      "Famine": "Famine",
      "Infrastructure Failure": "Infrastructure défaillante",
      "Investment": "Investissements",
      "Lockdown": "Loi martiale",
      "Natural Disaster": "Catastrophe naturelle",
      "None": "Aucun",
      "Outbreak": "Épidémie",
      "Pirate Attack": "Attaque pirate",
      "Public Holiday": "Jour férié",
      "Retreat": "Retraite",
      "Terrorism": "Terrorisme",
      "Terrorist Attack": "Attaque terroriste",
      "War": "Guerre"
    },
    "station": {
      "market": "Marché",
      "market_updated_at": "Marché mis à jour {relative_updated_at} ({updated_at})",
      "no_market_id": "Aucun ID de marché fourni",
      "not_found": "Station introuvable",
      "outfitting": "Équipement",
      "outfitting_updated_at": "Équipement mis à jour {relative_updated_at} ({updated_at})",
      "shipyard": "Chantier naval",
      "shipyard_updated_at": "Chantier naval mis à jour {relative_updated_at} ({updated_at})",
      "station": "Station"
    },
    "station_type": {
      "Asteroid base": "Base d'astéroïde",
      "Coriolis Starport": "Spatioport Coriolis",
      "Drake-Class Carrier": "Porte-vaisseaux de classe Drake",
      "Mega ship": "Méga-vaisseau",
      "Ocellus Starport": "Spatioport Ocellus",
      "Orbis Starport": "Spatioport Orbis",
      "Outpost": "Avant-poste",
      "Planetary Outpost": "Avant-poste planétaire",
      "Planetary Port": "Port planétaire",
      "Settlement": "Settlement"
    },
    "subtype": {
      "A (Blue-White super giant) Star": "Supergéante de type spectral A",
      "A (Blue-White) Star": "Étoile de type spectral A",
      "Ammonia world": "Planète d'ammoniac",
      "B (Blue-White super giant) Star": "Supergéante de type spectral B",
      "B (Blue-White) Star": "Étoile de type spectral B",
      "Black Hole": "Trou noir",
      "C Star": "Étoile de type spectral C",
      "CJ Star": "Étoile de type spectral CJ",
      "CN Star": "Étoile de type spectral CN",
      "Class I gas giant": "Géante gazeuse de classe I",
      "Class II gas giant": "Géante gazeuse de classe II",
      "Class III gas giant": "Géante gazeuse de classe III",
      "Class IV gas giant": "Géante gazeuse de classe IV",
      "Class V gas giant": "Géante gazeuse de classe V",
      "Earth-like world": "Planète tellurique",
      "F (White super giant) Star": "Supergéante de type spectral F",
      "F (White) Star": "Étoile de type spectral F",
      "G (White-Yellow super giant) Star": "Supergéante de type spectral G",
      "G (White-Yellow) Star": "Étoile de type spectral G",
      "Gas giant with ammonia-based life": "Géante gazeuse à la vie basée sur l'ammoniac",
      "Gas giant with water-based life": "Géante gazeuse à la vie basée sur l'eau",
      "Helium gas giant": "Géante gazeuse d'hélium",
      "Helium-rich gas giant": "Géante gazeuse riche en hélium",
      "Herbig Ae/Be Star": "Étoile de type spectral AEBE",
      "High metal content world": "Planète très riche en métaux",
      "Icy body": "Planète glacée",
      "K (Yellow-Orange giant) Star": "Géante de type spectral K",
      "K (Yellow-Orange) Star": "Étoile de type spectral K",
      "L (Brown dwarf) Star": "Étoile de type spectral L",
      "M (Red dwarf) Star": "Étoile de type spectral M",
      "M (Red giant) Star": "Géante de type spectral M",
      "M (Red super giant) Star": "Supergéant de type spectral M",
      "MS-type Star": "Étoile de type spectral MS",
      "Metal-rich body": "Planète riche en métaux",
      "Neutron Star": "Étoile à neutrons",
      "O (Blue-White) Star": "Étoile de type spectral O",
      "Rocky Ice world": "Planète rocheuse glacée",
      "Rocky body": "Planète rocheuse",
      "S-type Star": "Étoile de type spectral S",
      "Supermassive Black Hole": "Trou noir supermassif",
      "T (Brown dwarf) Star": "Naine brune de type spectral T",
      "T Tauri Star": "Étoile T Tauri",
      "Water giant": "Géante aquatique",
      "Water world": "Planète aquatique",
      "White Dwarf (D) Star": "Naine blanche de type spectral D",
      "White Dwarf (DA) Star": "Naine blanche de type spectral DA",
      "White Dwarf (DAB) Star": "Naine blanche de type spectral DAB",
      "White Dwarf (DAV) Star": "Naine blanche de type spectral DAV",
      "White Dwarf (DAZ) Star": "Naine blanche de type spectral DAZ",
      "White Dwarf (DB) Star": "Naine blanche de type spectral DB",
      "White Dwarf (DBV) Star": "Naine blanche de type spectral DBV",
      "White Dwarf (DBZ) Star": "Naine blanche de type spectral DBZ",
      "White Dwarf (DC) Star": "Naine blanche de type spectral DC",
      "White Dwarf (DCV) Star": "Naine blanche de type spectral DCV",
      "White Dwarf (DQ) Star": "Naine blanche de type spectral DQ",
      "Wolf-Rayet C Star": "Wolf-Rayet de type spectral WC",
      "Wolf-Rayet N Star": "Wolf-Rayet de type spectral WN",
      "Wolf-Rayet NC Star": "Wolf-Rayet de type spectral WNC",
      "Wolf-Rayet O Star": "Wolf-Rayet de type spectral WO",
      "Wolf-Rayet Star": "Wolf-Rayet de type spectral W",
      "Y (Brown dwarf) Star": "Naine brune Y"
    },
    "system": {
      "bodies": "Corp ({size})",
      "bodies_with_total": "Corps ({size}/{total})",
      "factions": "Factions ({size})",
      "no_id64": "Aucun ID de système fourni",
      "not_found": "Système non trouvé",
      "stations": "Station ({size})",
      "system": "Système",
      "title": "Système",
      "updated_at": "Actualisé {relative_updated_at}({updated_at})"
    },
    "terraforming_state": {
      "Candidate for terraforming": "Candidat à la terraformation",
      "Not terraformable": "Non terraformable",
      "Terraformable": "Candidat à la terraformation",
      "Terraformed": "Terraformé",
      "Terraforming": "Terraformation"
    },
    "thanks": {
      "description": "Beaucoup de fonctionnalités de ce site n'auraient pas été possibles sans l'aide considérable que j'ai reçue. Cette liste n'est pas exhaustive et je suis reconnaissant envers tous ceux qui m'ont aidé. Si vous pensez que quelqu'un a été oublié, veuillez me contacter.",
      "developers": {
        "description": "Les autres développeur m'ayant aidé en me fournissant des lots de données, des informations et des conseils",
        "title": "Développeurs"
      },
      "patreon": {
        "description": "Les mécènes qui ont contribué aux coûts d'hébergement et de développement.",
        "title": "Mécènes"
      },
      "players": {
        "description": "Je voudrais remercier tous les joueurs et explorateurs qui utilisent les différents outils de collecte de données qui nous permettent de recueillir des données sur la galaxie, ce qui permet à ce genre de sites d'exister, mais quelques-uns sont allés au-delà.",
        "title": "Joueurs"
      },
      "title": "Merci",
      "translators": {
        "description": "Les traducteurs qui ont réalisé les versions dans leur langue maternelle de ce site.",
        "title": "Traducteurs"
      }
    },
    "titles": {
      "admin": "Admin",
      "ammonia": "Mondes d'ammoniac",
      "ammonia_results": "Itinéraires de mondes d'ammoniac",
      "bad_data": "Indiquer un problème avec les données",
      "body": "Corps {body}",
      "body_search": "Rechercher un corps",
      "body_search_results": "Résultats de recherche de corps",
      "community-goals": "Objectifs communautaires",
      "dumps": "Lots de données",
      "earth": "Earth-like Worlds",
      "earth_results": "Earth-like Worlds Route",
      "engineer_plotter": "Engineer Plotter",
      "engineer_plotter_results": "Engineer Plotter Route",
      "exact_plotter": "Planificateur d'itinéraire galactique",
      "exact_plotter_results": "Résultats du planificateur d'itinéraire galactique",
      "fleet_carrier": "Porte vaisseaux",
      "fleet_carrier_results": "Itinéraire de porte vaisseaux",
      "list_bad_data": "Lister les rapports de données erronées",
      "main": "Elite: Dangerous Index",
      "nearest": "Système connu le plus proche",
      "plotter": "Planificateur d'itinéraire à neutrons",
      "plotter_results": "Itinéraire à neutrons",
      "riches": "Chemin vers la richesse",
      "riches_results": "Itinéraire de chemin vers la richesse",
      "scanned-bodies": "Scanned Bodies",
      "search": "Search",
      "station": "Station {station}",
      "station_search": "Rechercher une station",
      "station_search_results": "Résultats de recherche de station",
      "system": "Système {system}",
      "system_search": "Rechercher un système",
      "system_search_results": "Résultats de recherche de système",
      "thanks": "Remerciements",
      "tourist": "Planificateur d'itinéraires touristiques",
      "tourist_results": "Itinéraire touristique",
      "trade": "Planificateur d'itinéraire commercial",
      "trade-to-system": "Système dans lequel vendre",
      "trade-to-system_results": "Trade To System Results",
      "trade_results": "Résultats du planificateur d'itinéraires touristiques",
      "user": "User Settings"
    },
    "tourist": {
      "description": "Cette page vous permettra de planifier un itinéraires entre plusieurs systèmes que vous souhaitez visiter dans un ordre optimal",
      "import_destinations": "Importer les destinations",
      "import_destinations_description": "Import a waypoint file from EDSM as a set of destinations (A simple text file with one system name per line).",
      "importing": "Importation des destinations",
      "title": "Planificateur d'itinéraires pour touristes"
    },
    "trade": {
      "copy_destination_to_clipboard": "Copier le nom du système de destination {system} dans le presse-papiers",
      "copy_source_to_clipboard": "Copier le nom du système de départ {system} dans le presse-papiers",
      "distance": "{distance} AL",
      "finishing_system": "Dirigez-vous ensuite vers <a href=\"{link}\">{system}</a> ({station}) {distance} AL",
      "finishing_system_link": "Then fly to <a href=\"{link}\">{system}</a> (<a href=\"{stationLink}\">{station}</a>) {distance} LY",
      "first_system": "Débutant à <a href=\"{link}\">{system}</a> ({station}) mis à jour le {updated_at}",
      "first_system_link": "Starting at <a href=\"{link}\">{system}</a> (<a href=\"{stationLink}\">{station}</a>) updated {updated_at}",
      "flying_instructions": "Volez jusqu'à {station} (Distance depuis l'arrivée {distance} ls, temps de vol estimé à {time}).",
      "no_results": "Impossible de trouver un itinéraire commercial",
      "purchase_instructions": "Vendez tout le contenu de votre soute et achetez les marchandises listées ci-dessous.",
      "route": "Allez de {start_system} ({start_station}) à {end_system} ({end_station}) {distance} AL",
      "source_required": "Un système de départ est nécessaire",
      "starting_system": "<a href=\"{link}\">{system}</a> ({station}) mis à jour le {updated_at}",
      "starting_system_link": "<a href=\"{link}\">{system}</a> (<a href=\"{stationLink}\">{station}</a>) updated {updated_at}",
      "title": "Planificateur commercial"
    },
    "trade-to-system": {
      "title": "Vendre à {station} dans {system}"
    },
    "type": {
      "Planet": "Planète",
      "Star": "Étoile"
    },
    "unknown": "Inconnu",
    "user": {
      "settings": "User Settings",
      "update_scanned_bodies": "Update scanned bodies"
    },
    "volcanism_type": {
      "Ammonia Magma": "Magma d'ammoniac",
      "Carbon Dioxide Geysers": "Geysers de dioxyde de carbone",
      "Major Carbon Dioxide Geysers": "Geysers de dioxyde de carbone majeurs",
      "Major Metallic Magma": "Magma métallique majeur",
      "Major Rocky Magma": "Magma rocheux majeur",
      "Major Silicate Vapour Geysers": "Geysers de vapeur de silicate majeurs",
      "Major Water Geysers": "Geysers aqueux majeurs",
      "Major Water Magma": "Magma aqueux majeur",
      "Metallic Magma": "Magma métallique",
      "Methane Magma": "Magma de méthane",
      "Minor Ammonia Magma": "Magma d'ammoniac mineur",
      "Minor Carbon Dioxide Geysers": "Geysers de dioxyde de carbone mineurs",
      "Minor Metallic Magma": "Magma métallique mineur",
      "Minor Methane Magma": "Magma de méthane mineur",
      "Minor Nitrogen Magma": "Magma d'azote mineur",
      "Minor Rocky Magma": "Magma rocheux mineur",
      "Minor Silicate Vapour Geysers": "Geysers de vapeur de silicate mineurs",
      "Minor Water Geysers": "Geysers aqueux mineurs",
      "Minor Water Magma": "Magma aqueux mineur",
      "Nitrogen Magma": "Magma d'azote",
      "No volcanism": "Aucun volcanisme",
      "Rocky Magma": "Magma rocheux",
      "Silicate Vapour Geysers": "Geysers de vapeur de silicate",
      "Water Geysers": "Geysers aqueux",
      "Water Magma": "Magma aqueux"
    },
    "yes": "Oui"
  }], ["it-it", {
    "admin": {
      "bad_data_created_at": "Creato {relative_created_at} ({created_at})",
      "list_bad_data": "Elenco di rapporti sui dati non validi"
    },
    "algorithms": {
      "exhaistive": "Esauriente",
      "guided": "Guidato",
      "optimistic": "Ottimistico",
      "pessimistic": "Pessimistico"
    },
    "allegiance": {
      "Alliance": "Alleanza",
      "Empire": "Impero",
      "Federation": "Federazione",
      "Guardian": "Guardiani",
      "Independent": "Indipendente",
      "None": "Nessuno",
      "Pilots Federation": "Federazione dei Piloti",
      "Player Pilots": "Pilota Giocatore",
      "Thargoid": "Thargoid"
    },
    "ammonia": {
      "blank": "Lascia vuota la destinazione per creare un tour circolare che inizia dal tuo sistema di partenza e torna lì alla fine.",
      "guides": "Puoi trovare maggiori informazioni sul community goal <a href=\"https://www.elitedangerous.com/community/goals/\">qui</a> e <a href=\"https://forums.frontier.co.uk/threads/support-sirius-atmospherics-initiative-by-providing-ammonia-world-exploration-data-exploration.559984/\">qui</a>.",
      "low_results": "Il numero di sistemi ottenuti è inferiore a quello richiesto. Prova ad aumentare il raggio di ricerca per trovare più corpi.",
      "title": "Ammonia World Route",
      "warning": "Questo navigatore ti consentirà di trovare pianeti di ammoniaca vicino alla tua posizione attuale per il community goal. Darà priorità ad un valore di scansione elevato, quindi se trovi solo mondi lontani potresti voler ridurre il raggio di ricerca."
    },
    "atmosphere": {
      "Ammonia": "Ammonia",
      "Ammonia and Oxygen": "Ammonia and Oxygen",
      "Ammonia-rich": "Ammonia-rich",
      "Argon": "Argon",
      "Argon-rich": "Argon-rich",
      "Carbon dioxide": "Carbon dioxide",
      "Carbon dioxide-rich": "Carbon dioxide-rich",
      "Helium": "Helium",
      "Hot Argon": "Hot Argon",
      "Hot Argon-rich": "Hot Argon-rich",
      "Hot Carbon dioxide": "Hot Carbon dioxide",
      "Hot Carbon dioxide-rich": "Hot Carbon dioxide-rich",
      "Hot Metallic vapour": "Hot Metallic vapour",
      "Hot Silicate vapour": "Hot Silicate vapour",
      "Hot Sulphur dioxide": "Hot Sulphur dioxide",
      "Hot Water": "Acqua calda",
      "Hot Water-rich": "Ricco di Acqua calda",
      "Hot thick Ammonia": "Ammoniaca densa e calda",
      "Hot thick Ammonia-rich": "Ricco di Ammoniaca densa e calda",
      "Hot thick Argon": "Argon denso e caldo",
      "Hot thick Argon-rich": "Ricco di Argon denso e caldo",
      "Hot thick Carbon dioxide": "Anidride carbonica densa e calda",
      "Hot thick Carbon dioxide-rich": "Ricco di Anidride carbonica densa e calda",
      "Hot thick Metallic vapour": "Vapore metallico denso e caldo",
      "Hot thick Methane": "Metano denso e caldo",
      "Hot thick Methane-rich": "Ricco di Metano denso e caldo",
      "Hot thick Nitrogen": "Azoto denso e caldo",
      "Hot thick No atmosphere": "Nessuna atmosfera calda e densa",
      "Hot thick Silicate vapour": "Hot thick Silicate vapour",
      "Hot thick Sulphur dioxide": "Anidride solforosa densa e calda",
      "Hot thick Water": "Hot thick Water",
      "Hot thick Water-rich": "Hot thick Water-rich",
      "Hot thin Carbon dioxide": "Hot thin Carbon dioxide",
      "Hot thin Metallic vapour": "Hot thin Metallic vapour",
      "Hot thin Silicate vapour": "Hot thin Silicate vapour",
      "Hot thin Sulphur dioxide": "Hot thin Sulphur dioxide",
      "Metallic vapour": "Metallic vapour",
      "Methane": "Methane",
      "Methane-rich": "Methane-rich",
      "Neon": "Neon",
      "Neon-rich": "Neon-rich",
      "Nitrogen": "Nitrogen",
      "No atmosphere": "No atmosphere",
      "Oxygen": "Oxygen",
      "Silicate vapour": "Silicate vapour",
      "Suitable for water-based life": "Suitable for water-based life",
      "Sulphur dioxide": "Sulphur dioxide",
      "Thick Ammonia": "Thick Ammonia",
      "Thick Ammonia and Oxygen": "Thick Ammonia and Oxygen",
      "Thick Ammonia-rich": "Thick Ammonia-rich",
      "Thick Argon": "Thick Argon",
      "Thick Argon-rich": "Thick Argon-rich",
      "Thick Carbon dioxide": "Thick Carbon dioxide",
      "Thick Carbon dioxide-rich": "Thick Carbon dioxide-rich",
      "Thick Helium": "Thick Helium",
      "Thick Methane": "Thick Methane",
      "Thick Methane-rich": "Thick Methane-rich",
      "Thick Nitrogen": "Thick Nitrogen",
      "Thick No atmosphere": "Thick No atmosphere",
      "Thick Suitable for water-based life": "Thick Suitable for water-based life",
      "Thick Sulphur dioxide": "Thick Sulphur dioxide",
      "Thick Water": "Thick Water",
      "Thick Water-rich": "Thick Water-rich",
      "Thin Ammonia": "Thin Ammonia",
      "Thin Ammonia and Oxygen": "Thin Ammonia and Oxygen",
      "Thin Ammonia-rich": "Thin Ammonia-rich",
      "Thin Argon": "Thin Argon",
      "Thin Argon-rich": "Thin Argon-rich",
      "Thin Carbon dioxide": "Thin Carbon dioxide",
      "Thin Carbon dioxide-rich": "Thin Carbon dioxide-rich",
      "Thin Helium": "Thin Helium",
      "Thin Methane": "Thin Methane",
      "Thin Methane-rich": "Thin Methane-rich",
      "Thin Neon": "Thin Neon",
      "Thin Neon-rich": "Thin Neon-rich",
      "Thin Nitrogen": "Thin Nitrogen",
      "Thin No atmosphere": "Thin No atmosphere",
      "Thin Oxygen": "Thin Oxygen",
      "Thin Sulphur dioxide": "Thin Sulphur dioxide",
      "Thin Water": "Thin Water",
      "Thin Water-rich": "Ricco di acqua sottile",
      "Water": "Acqua",
      "Water-rich": "Water-rich"
    },
    "atmosphere_composition": {
      "Ammonia": "Ammonia",
      "Argon": "Argon",
      "Carbon dioxide": "Carbon dioxide",
      "Helium": "Helium",
      "Hydrogen": "Hydrogen",
      "Iron": "Iron",
      "Methane": "Methane",
      "Neon": "Neon",
      "Nitrogen": "Nitrogen",
      "Oxygen": "Oxygen",
      "Silicates": "Silicates",
      "Sulphur dioxide": "Sulphur dioxide",
      "Water": "Water"
    },
    "bad_data": {
      "archive": "Archivio",
      "description": "Segnala un problema con {system}",
      "error_description": "Si è verificato un problema con il tuo invio, segnalalo utilizzando i dettagli di contatto in fondo alla pagina.",
      "reason": "Motivo",
      "report": "Segnala un problema su questo sistema",
      "send": "Invia report",
      "success_description": "Grazie per la tua segnalazione, daremo un'occhiata."
    },
    "belts": {
      "add_field": "Aggiungi filtro",
      "inner_radius": "Raggio Interno (m)",
      "mass": "Messa (Mt)",
      "outer_radius": "Raggio Esterno (m)",
      "remove_field": "Rimuovi filtro",
      "select_type": "Seleziona il tipo",
      "type": "Tipo",
      "types": {
        "Icy": "Ghiacciato",
        "Metal Rich": "Ricco di metallo",
        "Metallic": "Metallico",
        "Rocky": "Roccioso"
      }
    },
    "body": {
      "belts": "Belts",
      "body": "Corpo",
      "composition": "Composizione",
      "landmarks": "Punti di riferimento",
      "no_id64": "Nessun ID corpo fornito",
      "not_found": "Corpo non trovato",
      "rings": "Anelli",
      "signals": "Segnali",
      "signals_updated_at": "Segnali aggiornati {relative_updated_at} ({updated_at})",
      "updated_at": "Aggiornato {relative_updated_at} ({updated_at})"
    },
    "boolean": {
      "Any": "Tutti",
      "No": "No",
      "Yes": "Si"
    },
    "categories": {
      "Chemicals": "Chemicals",
      "Consumer Items": "Consumer Items",
      "Foods": "Foods",
      "Industrial Materials": "Industrial Materials",
      "Legal Drugs": "Legal Drugs",
      "Machinery": "Machinery",
      "Medicines": "Medicines",
      "Metals": "Metals",
      "Minerals": "Minerals",
      "Salvage": "Salvage",
      "Slavery": "Slavery",
      "Technology": "Technology",
      "Textiles": "Textiles",
      "Waste": "Waste",
      "Weapons": "Weapons"
    },
    "commodities": {
      "AI Relics": "AI Relics",
      "Advanced Catalysers": "Advanced Catalysers",
      "Advanced Medicines": "Advanced Medicines",
      "Aepyornis Egg": "Aepyornis Egg",
      "Aganippe Rush": "Aganippe Rush",
      "Agri-Medicines": "Agri-Medicines",
      "Agronomic Treatment": "Agronomic Treatment",
      "Alacarakmo Skin Art": "Alacarakmo Skin Art",
      "Albino Quechua Mammoth Meat": "Albino Quechua Mammoth Meat",
      "Alexandrite": "Alexandrite",
      "Algae": "Algae",
      "Altairian Skin": "Altairian Skin",
      "Aluminium": "Aluminium",
      "Alya Body Soap": "Alya Body Soap",
      "Ancient Artefact": "Ancient Artefact",
      "Ancient Casket": "Ancient Casket",
      "Ancient Key": "Ancient Key",
      "Ancient Orb": "Ancient Orb",
      "Ancient Relic": "Ancient Relic",
      "Ancient Tablet": "Ancient Tablet",
      "Ancient Totem": "Ancient Totem",
      "Ancient Urn": "Ancient Urn",
      "Anduliga Fire Works": "Anduliga Fire Works",
      "Animal Meat": "Animal Meat",
      "Animal Monitors": "Animal Monitors",
      "Anomaly Particles": "Anomaly Particles",
      "Antimatter Containment Unit": "Antimatter Containment Unit",
      "Antique Jewellery": "Antique Jewellery",
      "Antiquities": "Antiquities",
      "Any Na Coffee": "Any Na Coffee",
      "Apa Vietii": "Apa Vietii",
      "Aquaponic Systems": "Aquaponic Systems",
      "Arouca Conventual Sweets": "Arouca Conventual Sweets",
      "Articulation Motors": "Articulation Motors",
      "Assault Plans": "Assault Plans",
      "Atmospheric Processors": "Atmospheric Processors",
      "Auto-Fabricators": "Auto-Fabricators",
      "Az Cancri Formula 42": "Az Cancri Formula 42",
      "Azure Milk": "Azure Milk",
      "Baked Greebles": "Baked Greebles",
      "Baltah'sine Vacuum Krill": "Baltah'sine Vacuum Krill",
      "Banki Amphibious Leather": "Banki Amphibious Leather",
      "Basic Medicines": "Basic Medicines",
      "Bast Snake Gin": "Bast Snake Gin",
      "Battle Weapons": "Battle Weapons",
      "Bauxite": "Bauxite",
      "Beer": "Beer",
      "Belalans Ray Leather": "Belalans Ray Leather",
      "Benitoite": "Benitoite",
      "Bertrandite": "Bertrandite",
      "Beryllium": "Beryllium",
      "Bioreducing Lichen": "Bioreducing Lichen",
      "Biowaste": "Biowaste",
      "Bismuth": "Bismuth",
      "Black Box": "Black Box",
      "Bootleg Liquor": "Bootleg Liquor",
      "Borasetani Pathogenetics": "Borasetani Pathogenetics",
      "Bromellite": "Bromellite",
      "Buckyball Beer Mats": "Buckyball Beer Mats",
      "Building Fabricators": "Building Fabricators",
      "Burnham Bile Distillate": "Burnham Bile Distillate",
      "CD-75 Kitten Brand Coffee": "CD-75 Kitten Brand Coffee",
      "CMM Composite": "CMM Composite",
      "Centauri Mega Gin": "Centauri Mega Gin",
      "Ceramic Composites": "Ceramic Composites",
      "Ceremonial Heike Tea": "Ceremonial Heike Tea",
      "Ceti Rabbits": "Ceti Rabbits",
      "Chameleon Cloth": "Chameleon Cloth",
      "Chateau De Aegaeon": "Chateau De Aegaeon",
      "Chemical Waste": "Chemical Waste",
      "Cherbones Blood Crystals": "Cherbones Blood Crystals",
      "Chi Eridani Marine Paste": "Chi Eridani Marine Paste",
      "Clothing": "Clothing",
      "Cobalt": "Cobalt",
      "Coffee": "Coffee",
      "Coltan": "Coltan",
      "Combat Stabilisers": "Combat Stabilisers",
      "Commercial Samples": "Commercial Samples",
      "Computer Components": "Computer Components",
      "Conductive Fabrics": "Conductive Fabrics",
      "Consumer Technology": "Consumer Technology",
      "Copper": "Copper",
      "Coquim Spongiform Victuals": "Coquim Spongiform Victuals",
      "Crom Silver Fesh": "Crom Silver Fesh",
      "Crop Harvesters": "Crop Harvesters",
      "Cryolite": "Cryolite",
      "Crystalline Spheres": "Crystalline Spheres",
      "Damaged Escape Pod": "Damaged Escape Pod",
      "Damna Carapaces": "Damna Carapaces",
      "Data Core": "Data Core",
      "Delta Phoenicis Palms": "Delta Phoenicis Palms",
      "Deuringas Truffles": "Tartufi Deuringas",
      "Diplomatic Bag": "Diplomatic Bag",
      "Diso Ma Corn": "Diso Ma Corn",
      "Domestic Appliances": "Domestic Appliances",
      "Duradrives": "Duradrives",
      "Earth Relics": "Earth Relics",
      "Eden Apples of Aerial": "Eden Apples of Aerial",
      "Eleu Thermals": "Eleu Thermals",
      "Emergency Power Cells": "Emergency Power Cells",
      "Encrypted Correspondence": "Encrypted Correspondence",
      "Encrypted Data Storage": "Encrypted Data Storage",
      "Energy Grid Assembly": "Energy Grid Assembly",
      "Eranin Pearl Whisky": "Eranin Pearl Whisky",
      "Eshu Umbrellas": "Eshu Umbrellas",
      "Esuseku Caviar": "Esuseku Caviar",
      "Ethgreze Tea Buds": "Ethgreze Tea Buds",
      "Evacuation Shelter": "Evacuation Shelter",
      "Exhaust Manifold": "Exhaust Manifold",
      "Experimental Chemicals": "Experimental Chemicals",
      "Explosives": "Explosives",
      "Fish": "Fish",
      "Food Cartridges": "Food Cartridges",
      "Fossil Remnants": "Fossil Remnants",
      "Fruit and Vegetables": "Frutta e verdura",
      "Fujin Tea": "Fujin Tea",
      "Galactic Travel Guide": "Galactic Travel Guide",
      "Gallite": "Gallite",
      "Gallium": "Gallium",
      "Geawen Dance Dust": "Geawen Dance Dust",
      "Gene Bank": "Gene Bank",
      "Geological Equipment": "Geological Equipment",
      "Geological Samples": "Campioni geologici",
      "Gerasian Gueuze Beer": "Gerasian Gueuze Beer",
      "Giant Irukama Snails": "Giant Irukama Snails",
      "Giant Verrix": "Giant Verrix",
      "Gilya Signature Weapons": "Armi firmate Gilya",
      "Gold": "Gold",
      "Goman Yaupon Coffee": "Goman Yaupon Coffee",
      "Goslarite": "Goslarite",
      "Grain": "Grain",
      "Grandidierite": "Grandidierite",
      "Guardian Casket": "Guardian Casket",
      "Guardian Orb": "Guardian Orb",
      "Guardian Relic": "Guardian Relic",
      "Guardian Tablet": "Guardian Tablet",
      "Guardian Totem": "Guardian Totem",
      "Guardian Urn": "Guardian Urn",
      "H.E. Suits": "H.E. Suits",
      "HIP 10175 Bush Meat": "HIP 10175 Bush Meat",
      "HIP 118311 Swarm": "HIP 118311 Swarm",
      "HIP Organophosphates": "HIP Organophosphates",
      "HIP Proto-Squid": "HIP Proto-Squid",
      "HN Shock Mount": "HN Shock Mount",
      "HR 7221 Wheat": "HR 7221 Wheat",
      "Hafnium 178": "Hafnium 178",
      "Haiden Black Brew": "Haiden Black Brew",
      "Hardware Diagnostic Sensor": "Hardware Diagnostic Sensor",
      "Harma Silver Sea Rum": "Harma Silver Sea Rum",
      "Havasupai Dream Catcher": "Havasupai Dream Catcher",
      "Heatsink Interlink": "Heatsink Interlink",
      "Helvetitj Pearls": "Helvetitj Pearls",
      "Holva Duelling Blades": "Holva Duelling Blades",
      "Honesty Pills": "Honesty Pills",
      "Hostages": "Hostages",
      "Hydrogen Fuel": "Hydrogen Fuel",
      "Hydrogen Peroxide": "Hydrogen Peroxide",
      "Imperial Slaves": "Imperial Slaves",
      "Indi Bourbon": "Indi Bourbon",
      "Indite": "Indite",
      "Indium": "Indium",
      "Insulating Membrane": "Insulating Membrane",
      "Ion Distributor": "Ion Distributor",
      "Jadeite": "Jadeite",
      "Jaques Quinentian Still": "Jaques Quinentian Still",
      "Jaradharre Puzzle Box": "Jaradharre Puzzle Box",
      "Jaroua Rice": "Jaroua Rice",
      "Jotun Mookah": "Jotun Mookah",
      "Kachirigin Filter Leeches": "Kachirigin Filter Leeches",
      "Kamitra Cigars": "Kamitra Cigars",
      "Kamorin Historic Weapons": "Kamorin Historic Weapons",
      "Karetii Couture": "Karetii Couture",
      "Karsuki Locusts": "Karsuki Locusts",
      "Kinago Violins": "Kinago Violins",
      "Kongga Ale": "Kongga Ale",
      "Koro Kung Pellets": "Koro Kung Pellets",
      "LTT Hyper Sweet": "LTT Hyper Sweet",
      "Land Enrichment Systems": "Land Enrichment Systems",
      "Landmines": "Landmines",
      "Lanthanum": "Lanthanum",
      "Large Survey Data Cache": "Large Survey Data Cache",
      "Lavian Brandy": "Lavian Brandy",
      "Leather": "Leather",
      "Leathery Eggs": "Leathery Eggs",
      "Leestian Evil Juice": "Leestian Evil Juice",
      "Lepidolite": "Lepidolite",
      "Limpets": "Limpets",
      "Liquid oxygen": "Liquid oxygen",
      "Liquor": "Liquor",
      "Lithium": "Lithium",
      "Lithium Hydroxide": "Lithium Hydroxide",
      "Live Hecate Sea Worms": "Live Hecate Sea Worms",
      "Low Temperature Diamonds": "Low Temperature Diamonds",
      "Lucan Onionhead": "Lucan Onionhead",
      "Lyrae Weed": "Lyrae Weed",
      "Magnetic Emitter Coil": "Magnetic Emitter Coil",
      "Marine Equipment": "Marine Equipment",
      "Master Chefs": "Master Chefs",
      "Mechucos High Tea": "Mechucos High Tea",
      "Medb Starlube": "Medb Starlube",
      "Medical Diagnostic Equipment": "Medical Diagnostic Equipment",
      "Meta-Alloys": "Meta-Alloys",
      "Methane Clathrate": "Methane Clathrate",
      "Methanol Monohydrate Crystals": "Methanol Monohydrate Crystals",
      "Micro Controllers": "Micro Controllers",
      "Micro-weave Cooling Hoses": "Micro-weave Cooling Hoses",
      "Microbial Furnaces": "Microbial Furnaces",
      "Military Grade Fabrics": "Military Grade Fabrics",
      "Military Intelligence": "Military Intelligence",
      "Military Plans": "Military Plans",
      "Mineral Extractors": "Mineral Extractors",
      "Mineral Oil": "Mineral Oil",
      "Modular Terminals": "Modular Terminals",
      "Moissanite": "Moissanite",
      "Mokojing Beast Feast": "Mokojing Beast Feast",
      "Mollusc Brain Tissue": "Mollusc Brain Tissue",
      "Mollusc Fluid": "Mollusc Fluid",
      "Mollusc Membrane": "Mollusc Membrane",
      "Mollusc Mycelium": "Mollusc Mycelium",
      "Mollusc Soft Tissue": "Mollusc Soft Tissue",
      "Mollusc Spores": "Mollusc Spores",
      "Momus Bog Spaniel": "Momus Bog Spaniel",
      "Monazite": "Monazite",
      "Motrona Experience Jelly": "Motrona Experience Jelly",
      "Mukusubii Chitin-os": "Mukusubii Chitin-os",
      "Mulachi Giant Fungus": "Mulachi Giant Fungus",
      "Muon Imager": "Muon Imager",
      "Musgravite": "Musgravite",
      "Mysterious Idol": "Mysterious Idol",
      "Nanobreakers": "Nanobreakers",
      "Nanomedicines": "Nanomedicines",
      "Narcotics": "Narcotics",
      "Natural Fabrics": "Natural Fabrics",
      "Neofabric Insulation": "Neofabric Insulation",
      "Neritus Berries": "Neritus Berries",
      "Nerve Agents": "Nerve Agents",
      "Ngadandari Fire Opals": "Ngadandari Fire Opals",
      "Nguna Modern Antiques": "Nguna Modern Antiques",
      "Njangari Saddles": "Njangari Saddles",
      "Non Euclidian Exotanks": "Non Euclidian Exotanks",
      "Non-Lethal Weapons": "Non-Lethal Weapons",
      "Occupied Escape Pod": "Occupied Escape Pod",
      "Ochoeng Chillies": "Ochoeng Chillies",
      "Onionhead": "Onionhead",
      "Onionhead Alpha Strain": "Onionhead Alpha Strain",
      "Onionhead Beta Strain": "Onionhead Beta Strain",
      "Ophiuch Exino Artefacts": "Ophiuch Exino Artefacts",
      "Orrerian Vicious Brew": "Orrerian Vicious Brew",
      "Osmium": "Osmium",
      "Painite": "Painite",
      "Palladium": "Palladium",
      "Pantaa Prayer Sticks": "Pantaa Prayer Sticks",
      "Pavonis Ear Grubs": "Pavonis Ear Grubs",
      "Performance Enhancers": "Performance Enhancers",
      "Personal Effects": "Personal Effects",
      "Personal Gifts": "Personal Gifts",
      "Personal Weapons": "Personal Weapons",
      "Pesticides": "Pesticides",
      "Platinum": "Platinum",
      "Platinum Alloy": "Platinum Alloy",
      "Pod Core Tissue": "Pod Core Tissue",
      "Pod Dead Tissue": "Pod Dead Tissue",
      "Pod Mesoglea": "Pod Mesoglea",
      "Pod Outer Tissue": "Pod Outer Tissue",
      "Pod Shell Tissue": "Pod Shell Tissue",
      "Pod Surface Tissue": "Pod Surface Tissue",
      "Pod Tissue": "Pod Tissue",
      "Political Prisoners": "Political Prisoners",
      "Polymers": "Polymers",
      "Power Converter": "Power Converter",
      "Power Generators": "Power Generators",
      "Power Transfer Bus": "Power Transfer Bus",
      "Praseodymium": "Praseodymium",
      "Precious Gems": "Precious Gems",
      "Progenitor Cells": "Progenitor Cells",
      "Prohibited Research Materials": "Prohibited Research Materials",
      "Prototype Tech": "Prototype Tech",
      "Pyrophyllite": "Pyrophyllite",
      "Radiation Baffle": "Radiation Baffle",
      "Rajukru Multi-Stoves": "Rajukru Multi-Stoves",
      "Rapa Bao Snake Skins": "Rapa Bao Snake Skins",
      "Rare Artwork": "Rare Artwork",
      "Reactive Armour": "Reactive Armour",
      "Rebel Transmissions": "Rebel Transmissions",
      "Reinforced Mounting Plate": "Reinforced Mounting Plate",
      "Resonating Separators": "Resonating Separators",
      "Rhodplumsite": "Rhodplumsite",
      "Robotics": "Robotics",
      "Rockforth Fertiliser": "Rockforth Fertiliser",
      "Rusani Old Smokey": "Rusani Old Smokey",
      "Rutile": "Rutile",
      "SAP 8 Core Container": "SAP 8 Core Container",
      "Samarium": "Samarium",
      "Sanuma Decorative Meat": "Sanuma Decorative Meat",
      "Saxon Wine": "Saxon Wine",
      "Scientific Research": "Scientific Research",
      "Scientific Samples": "Scientific Samples",
      "Scrap": "Scrap",
      "Semiconductors": "Semiconductors",
      "Serendibite": "Serendibite",
      "Shan's Charis Orchid": "Shan's Charis Orchid",
      "Silver": "Silver",
      "Skimmer Components": "Skimmer Components",
      "Slaves": "Slaves",
      "Small Survey Data Cache": "Small Survey Data Cache",
      "Soontill Relics": "Soontill Relics",
      "Sothis Crystalline Gold": "Sothis Crystalline Gold",
      "Space Pioneer Relics": "Space Pioneer Relics",
      "Structural Regulators": "Structural Regulators",
      "Superconductors": "Superconductors",
      "Surface Stabilisers": "Surface Stabilisers",
      "Survival Equipment": "Survival Equipment",
      "Synthetic Fabrics": "Synthetic Fabrics",
      "Synthetic Meat": "Synthetic Meat",
      "Synthetic Reagents": "Synthetic Reagents",
      "Taaffeite": "Taaffeite",
      "Tactical Data": "Tactical Data",
      "Tanmark Tranquil Tea": "Tanmark Tranquil Tea",
      "Tantalum": "Tantalum",
      "Tarach Spice": "Tarach Spice",
      "Tauri Chimes": "Tauri Chimes",
      "Tea": "Tea",
      "Technical Blueprints": "Technical Blueprints",
      "Telemetry Suite": "Telemetry Suite",
      "Terra Mater Blood Bores": "Terra Mater Blood Bores",
      "Thallium": "Thallium",
      "Thargoid Basilisk Tissue Sample": "Thargoid Basilisk Tissue Sample",
      "Thargoid Biological Matter": "Thargoid Biological Matter",
      "Thargoid Cyclops Tissue Sample": "Thargoid Cyclops Tissue Sample",
      "Thargoid Heart": "Thargoid Heart",
      "Thargoid Hydra Tissue Sample": "Thargoid Hydra Tissue Sample",
      "Thargoid Link": "Thargoid Link",
      "Thargoid Medusa Tissue Sample": "Thargoid Medusa Tissue Sample",
      "Thargoid Probe": "Thargoid Probe",
      "Thargoid Resin": "Thargoid Resin",
      "Thargoid Scout Tissue Sample": "Thargoid Scout Tissue Sample",
      "Thargoid Sensor": "Thargoid Sensor",
      "Thargoid Technology Samples": "Thargoid Technology Samples",
      "The Hutton Mug": "The Hutton Mug",
      "The Waters of Shintara": "The Waters of Shintara",
      "Thermal Cooling Units": "Thermal Cooling Units",
      "Thorium": "Thorium",
      "Thrutis Cream": "Thrutis Cream",
      "Tiegfries Synth Silk": "Tiegfries Synth Silk",
      "Time Capsule": "Time Capsule",
      "Tiolce Waste2Paste Units": "Tiolce Waste2Paste Units",
      "Titanium": "Titanium",
      "Tobacco": "Tobacco",
      "Toxandji Virocide": "Toxandji Virocide",
      "Toxic Waste": "Toxic Waste",
      "Trade Data": "Dati commerciali",
      "Trinkets of Hidden Fortune": "Trinkets of Hidden Fortune",
      "Tritium": "Tritium",
      "Ultra-Compact Processor Prototypes": "Ultra-Compact Processor Prototypes",
      "Unstable Data Core": "Unstable Data Core",
      "Uraninite": "Uraninite",
      "Uranium": "Uranium",
      "Uszaian Tree Grub": "Uszaian Tree Grub",
      "Utgaroar Millennial Eggs": "Utgaroar Millennial Eggs",
      "Uzumoku Low-G Wings": "Uzumoku Low-G Wings",
      "V Herculis Body Rub": "V Herculis Body Rub",
      "Vanayequi Ceratomorpha Fur": "Vanayequi Ceratomorpha Fur",
      "Vega Slimweed": "Vega Slimweed",
      "Vidavantian Lace": "Vidavantian Lace",
      "Void Extract Coffee": "Void Extract Coffee",
      "Void Opals": "Void Opals",
      "Volkhab Bee Drones": "Volkhab Bee Drones",
      "Water": "Water",
      "Water Purifiers": "Water Purifiers",
      "Wheemete Wheat Cakes": "Wheemete Wheat Cakes",
      "Wine": "Wine",
      "Witchhaul Kobe Beef": "Witchhaul Kobe Beef",
      "Wolf Fesh": "Wolf Fesh",
      "Wreckage Components": "Wreckage Components",
      "Wulpa Hyperbore Systems": "Wulpa Hyperbore Systems",
      "Wuthielo Ku Froth": "Wuthielo Ku Froth",
      "Xihe Biomorphic Companions": "Xihe Biomorphic Companions",
      "Yaso Kondi Leaf": "Yaso Kondi Leaf",
      "Zeessze Ant Grub Glue": "Zeessze Ant Grub Glue"
    },
    "dates": {
      "forever": "Sempre"
    },
    "download": {
      "body": "Nome del corpo",
      "count": "Conteggio",
      "distance": "Distanza dall'arrivo",
      "distance_travelled": "Distanza",
      "fuel_remaining": "Carburante Residuo",
      "fuel_used": "Carburante Usato",
      "icy_ring": "Icy Ring",
      "jumps": "Salti",
      "landmark_subtype": "Sottotipo Landmark",
      "landmark_value": "Valore",
      "mapping_value": "Valore della Mappatura Stimato",
      "neutron": "Neutron Star",
      "no": "No",
      "pristine": "Incontaminato",
      "refuel": "Rifornimento",
      "remaining": "Distanza Residua",
      "restock_tritium": "Rifornisci il Tritium",
      "scan_value": "Valore Stimato della Scansione1",
      "subtype": "Sottotipo di corpo",
      "system": "Nome del sistema",
      "terraform": "È terraformabile",
      "total_value": "Valore totale",
      "tritium_left": "Tritium nel serbatoio",
      "tritium_market": "Tritium nel mercato",
      "yes": "Si"
    },
    "dumps": {
      "description": "Nightly dumps dEi dati relativi alla galassia",
      "file_description": "Descrizione",
      "filename": "Nome del file",
      "files": {
        "galaxy.json.bz2": "L'intera galassia in formato bz2 (non più aggiornato)",
        "galaxy.json.gz": "L'intera galassia in formato gzip",
        "galaxy_1day.json.gz": "Sistemi in cui qualsiasi informazione all'interno di quel sistema è stata aggiornata nelle ultime 24 ore in formato gzip",
        "galaxy_1month.json.gz": "Sistemi in cui tutte le informazioni all'interno di quel sistema sono state aggiornate negli ultimi 32 giorni in formato gzip",
        "galaxy_7days.json.gz": "Sistemi in cui qualsiasi informazione all'interno di quel sistema è stata aggiornata negli ultimi 7 giorni in formato gzip",
        "galaxy_populated.json.bz2": "Sistemi con popolazione maggiore di 0 in formato bz2 (non più aggiornato)",
        "galaxy_populated.json.gz": "Sistemi con popolazione maggiore di 0 in formato gzip",
        "galaxy_stations.json.bz2": "Sistemi con una stazione in formato bz2 (compresi i fleet carriers, non più aggiornati)",
        "galaxy_stations.json.gz": "Sistemi con una stazione in formato gzip (comprese le fleet carriers)"
      },
      "modified": "Generato",
      "size": "Dimensione del file",
      "title": "Data Dumps"
    },
    "earth": {
      "blank": "Lascia vuota la destinazione per creare un tour circolare che inizia dal tuo sistema di partenza e torna lì alla fine.",
      "guides": "È possibile trovare ulteriori informazioni sulla community goal <a href=\"https://www.elitedangerous.com/community/goals/\">qui</a> e <a href=\"https://forums.frontier.co.uk/threads/support-the-saud-kruger-exploration-campaign.590091/\">qui</a>.",
      "low_results": "Il numero di sistemi ottenuti è inferiore a quello richiesto. Prova ad aumentare il raggio di ricerca per trovare più corpi.",
      "title": "Earth-like World Route",
      "warning": "Questo router ti consentirà di trovare mondi simili alla terra intorno alla tua posizione attuale per l'obiettivo della comunità. Darà priorità a un valore di scansione elevato, quindi se trovi solo mondi lontani potresti voler ridurre il raggio di ricerca."
    },
    "economy": {
      "Agriculture": "Agriculture",
      "Colony": "Colony",
      "Extraction": "Extraction",
      "High Tech": "High Tech",
      "Industrial": "Industrial",
      "Military": "Military",
      "None": "Nessuna",
      "Private Enterprise": "Impresa Privata",
      "Refinery": "Refinery",
      "Service": "Service",
      "Terraforming": "Terraforming1",
      "Tourism": "Tourism"
    },
    "engineer-plotter": {
      "add_blueprint": "Aggiungi progetto",
      "blueprint": "{type} ({name} grado {grade})",
      "close_ship_build": "Chiuso",
      "delete_blueprint": "Elimina progetto",
      "description": "Questa pagina ti permetterà di tracciare un percorso efficiente per progettare la tua nave.",
      "engineer_blueprint": "Ingegnere {slot} con {name} di grado {grade}",
      "engineer_blueprint_effect": "Ingegnere {slot} con {name} per valutare {grade} ed effettuare {effect}",
      "load_ship_build": "Carica la build della nave",
      "nothing_to_engineer": "Niente da ingegnerizzare",
      "process_current_ship_build": "Elabora i progetti correnti",
      "process_target_ship_build": "Elabora progetti target",
      "system_title": "{engineer} in {system} ({distance}LY)",
      "title": "Ingegnere Plotter"
    },
    "exact-plotter": {
      "description": "Questa pagina ti permetterà di tracciare tra due diversi sistemi stellari. Tenterà di tracciarti un percorso esatto, incluso il consumo di carburante, sovralimentazioni di neutroni e potenziamenti di iniezione, se lo desideri.",
      "edsy_profile": "Invia il profilo del comandante a ED:Shipyard",
      "edsy_profile_description": "Invia il tuo profilo comandante a ED:Shipyard in modo da poter esportare la tua attuale nave in SLEF. Si aprirà in una nuova finestra in modo da non perdere i dati nel sito.",
      "edsy_profile_fail": "Impossibile recuperare il profilo della nave.",
      "edsy_profile_instructions": "Una volta importato il profilo, puoi fare clic su Ops in alto a destra di ED:Shipyard navale ed esportare la tua build. Dovresti copiare lo SLEF e incollarlo nella casella di costruzione della nave sul router.",
      "fuel_remaining_description": "Carburante attualmente nel serbatoio. Questo non include il serbatoio di riserva",
      "fuel_used_description": "Carburante utilizzato nel salto",
      "load_profile": "Carica il profilo della nave",
      "load_profile_description": "Carica il tuo attuale profilo della nave dai server di Frontier",
      "submit_edsy_profile": "Invia profilo",
      "system_error": "Errore nel sistema <a href=\"{link}\">{system}</a>",
      "title": "Galaxy Plotter",
      "warning": "Questo navigatore è in versione beta e non è garantito che i risultati siano ideali, potrebbe anche indirizzarti attraverso sistemi che richiedono un permesso."
    },
    "exobiology": {
      "blank": "Lascia vuota la destinazione se non importa dove finisci",
      "low_results": "Il numero di sistemi ottenuti è inferiore a quello richiesto. Prova ad aumentare il raggio di ricerca per trovare più corpi. Se ancora non ottieni risultati sufficienti, potresti aver impostato il valore minimo troppo alto.",
      "title": "Expressway to Exomastery"
    },
    "fields": {
      "add_atmosphere_composition_filter": "Aggiungi un filtro per la composizione dell'atmosfera",
      "add_destination": "Aggiungi destinazione",
      "add_market_filter": "Aggiungi filtro di mercato",
      "add_material_filter": "Aggiungi filtro dei materiale",
      "add_sort_field": "Aggiungi campo di ordinamento",
      "add_via": "Aggiungi Tappa",
      "algorithm": "Algoritmo dell'itinerario",
      "algorithm_description": "Quale algoritmo di instradamento usare, un algoritmi potrebbero funzionare più velocemente di un altro, trovare percorsi migliori o in alcuni casi non essere in grado di trovarne affatto.",
      "allegiance": "Fedeltà",
      "allow_planetary": "Consenti Stazioni Planetarie",
      "allow_planetary_description": "Includere stazioni planetarie nell'itinerario",
      "allow_prohibited": "Consenti Illegali",
      "allow_prohibited_description": "Includi le merci illegali nel sistema di destinazione",
      "amount": "Quantità",
      "amount_of_commodity": "Quantità di {commodity}",
      "arg_of_periapsis": "Arg Of Periapsis",
      "ascending_node": "Ascending Node",
      "atmosphere": "Atmosfera",
      "atmosphere_composition": "Composizione dell'Atmosfera",
      "atmosphere_composition_fields": "Composizione dell'Atmosfera",
      "atmosphere_compositions": "Composizioni Atmosferiche",
      "axis_tilt": "Inclinazione assiale",
      "belt_fields": "Filto dei Belt",
      "belts": "Belts",
      "blueprint": "Progetto",
      "body_count": "Numero di Corpi",
      "body_name": "Nome del corpo",
      "body_subtype": "Sottotipo di corpo",
      "body_type": "Tipo di corpo",
      "buy_price": "Prezzo d'Acquisto",
      "calculate": "Calcola",
      "cargo": "Carico",
      "combined_ring_signal": "Segnale combinato dell'anello",
      "combined_signal": "Segnale combinato del corpo",
      "commodity": "Commodity",
      "commodity_not_found": "Commodity non trovata",
      "completed": "Fatto",
      "controlling_minor_faction": "Fazione di Controllo",
      "controlling_minor_faction_state": "Stato della Fazione di Controllo",
      "coordinates": "Coordinate di riferimento",
      "count": "Conteggio",
      "cumulative_profit": "Profitto cumulativo",
      "delete_destination": "Cancella destinazione",
      "delete_via": "Cancella Tappa",
      "demand": "Domanda",
      "destination_system": "Sistema di destinazione",
      "determine_required_fuel": "Determina i requisiti di Tritium",
      "determine_required_fuel_description": "Calcola quanto Tritium sarebbe necessario per completare l'intero percorso.",
      "distance": "Distanza (LY)",
      "distance_from_coords": "Distanza (LY)",
      "distance_to_arrival": "Distanza dall'Arrivo (LS)",
      "distance_to_arrival_short": "Distanza (LS)",
      "earth_masses": "Massa Planetaria",
      "effect": "Effetto",
      "efficiency": "Efficienza (%)",
      "efficiency_description": "Aumenta questo valore per ridurre la distanza della rotta diretta che il sistema traccerà per raggiungere una stella di neutroni (un'efficienza di 100 non devierà dalla rotta diretta per tracciare da A a B e molto probabilmente suddividerà il viaggio in blocchi da 20000 LY).",
      "engineers": "Ingegneri",
      "engineers_description": "Elenco di ingegneri da utilizzare, selezionare nessuno per consentire tutti gli ingegneri.",
      "estimated_mapping_value": "Valore Stimato della Mappatura",
      "estimated_mapping_value_short": "Valore della Mappatura",
      "estimated_scan_value": "Valore Stimato della Scansione",
      "estimated_scan_value_short": "Valore della scansione",
      "exclude_secondary": "Escludi stelle secondarie",
      "exclude_secondary_description": "Impedisci al sistema di utilizzare stelle secondari di neutroni e stelle scoopable nella rotta",
      "export_commodities": "Esporta Merci",
      "faction_influence": "Influenza della fazione",
      "faction_name": "Nome della fazione",
      "faction_presence": "Presenza della Fazione",
      "faction_state": "Stato della Fazione",
      "find_stations": "Trova le stazioni",
      "flying_time": "{time} tempo di volo",
      "fuel_remaining": "Carburante residuo (tonnellate)",
      "fuel_remaining_description": "Tritium attualmente nel serbatoio. Potrebbe essere necessario trasferire dal mercato per raggiungere questo livello",
      "fuel_used": "Carburante Usato (tonnellate)",
      "fuel_used_description": "Tritium usato per il salto",
      "full_spectral_class": "Classe spettrale",
      "government": "Governo",
      "gravity": "Gravità",
      "has_large_pad": "Ha un large pad",
      "has_market": "Ha il mercato",
      "has_outfitting": "Ha l'outfitting",
      "has_shipyard": "Ha lo shipyard",
      "icy_ring": "Icy ring",
      "icy_ring_description": "Il sistema ha un icy ring?",
      "import_commodities": "Importa Commodities",
      "influence": "Influenza",
      "is_dssa_carrier": "DSSA Carrier",
      "is_landable": "Atterrabile",
      "is_main_star": "Stella principale",
      "is_planetary": "Planetario",
      "is_supercharged": "Già sovralimentato",
      "is_supercharged_description": "La tua nave è già sovralimentata?",
      "jumps": "Salti",
      "jumps_description": "Numero stimato di salti da questo sistema.",
      "jumps_remaining": "Salta restanti",
      "landmark_subtype": "Sottotipo Landmark",
      "landmark_type": "Tipo di Landmark",
      "landmark_value": "Valore del Landmark",
      "landmarks": "Landmarks",
      "large_pads": "Large Pads",
      "latitude": "Latitudine ↕",
      "longitude": "Longitudine ↔",
      "loop": "Loop",
      "loop_description": "Forza il ritorno al sistema di partenza.",
      "luminosity_class": "Classe di luminosità",
      "main_fields": "Campi Principali",
      "market": "Mercato delle Commodity",
      "market_capacity": "Capacità del mercato",
      "market_capacity_description": "La quantità di occupata dalle merci nel tuo mercato",
      "market_fields": "Campi di mercato",
      "market_updated_at": "Ultimo Aggiornamento di Mercato Alle",
      "material": "Materiale",
      "material_fields": "Materiali",
      "materials": "Materiali",
      "maximum_cargo": "Capacità di Carico Massima",
      "maximum_distance": "Distanza massima dall'arrivo (LS)",
      "maximum_distance_description": "Distanza massima a cui la stazione può trovarsi dalla stella di arrivo",
      "maximum_hop_distance": "Distanza di Salto Massima",
      "maximum_hop_distance_description": "Distanza massima tra i salti (può essere maggiore della portata della tua nave)",
      "maximum_hops": "Salti Massimi",
      "maximum_market_age": "Periodo di Mercato Massimo",
      "maximum_market_age_description": "Periodo massimo per il quelle le stazioni sul percorso possono avere aggiornato i dati di mercato",
      "maximum_systems": "Sistemi massimi",
      "maximum_systems_description": "Questo è il numero massimo di sistemi attraverso i quali il navigatore ti indirizzerà, abbassalo per un viaggio più breve.",
      "mean_anomaly": "Anomalia media",
      "medium_pads": "Medium Pads",
      "minimum_landmark_value": "Valore minimo",
      "minimum_scan_value": "Valore minimo della scansione",
      "minor_faction_presences": "Presenza della fazione",
      "module_capacity": "Capacità modulo",
      "module_capacity_description": "La quantità di capacità occupata dai moduli installati sulla fleet carrier",
      "module_category": "Categoria",
      "module_fields": "Campi modulo",
      "module_price": "Prezzo modulo",
      "modules": "Moduli",
      "name": "Nome",
      "needs_permit": "Necessita del permesso",
      "neutron": "Neutron",
      "neutron_description": "È una stella di neutroni?",
      "orbital_eccentricity": "Eccentricità Orbitale",
      "orbital_inclination": "Inclinazione Orbitale",
      "orbital_period": "Periodo orbitale (giorni)",
      "outfitting_updated_at": "Ultimo aggiornamento degli allestimenti alle",
      "pads": "Pads",
      "permit": "Consenti sistemi con permesso",
      "permit_description": "Consenti ai sistemi che richiedono un permesso di essere inclusi nella rotta",
      "population": "Popolazione",
      "power": "Potenza",
      "power_state": "Stato della Potenza",
      "primary_economy": "Economia",
      "pristine": "Incontaminato",
      "pristine_description": "Il livello di riserva dell'anello è incontaminato?",
      "profit": "Profitto",
      "prohibited_commodities": "Commodities Illegali",
      "radius": "Raggio",
      "range": "Range (LY)",
      "range_description": "La portata della tua nave.",
      "record_type": "Tipo",
      "reference_coordinates": "Coordinate di Riferimento",
      "reference_route": "Percorso di Riferimento",
      "reference_system": "Sistema di Riferimento",
      "refuel": "Rifornimento?",
      "refuel_description": "Hai bisogno di fare rifornimento?",
      "region": "Regione",
      "remaining": "D. Restante (LY)",
      "requires_large_pad": "Richiede un Large Pad",
      "requires_large_pad_description": "Filtra le stazioni che non hanno un large pad",
      "reserve_level": "Livello di Riserva",
      "reset": "Reset",
      "restock": "Restock?",
      "restock_amount": "Quantità di Restock",
      "restock_amount_description": "La quantità di tritium di cui hai bisogno per rifornirti",
      "restock_description": "Hai bisogno di rifornire il trizio?",
      "reverse": "Inverti Percorso",
      "ring_fields": "Filtro degli anelli",
      "ring_signal": "Segnale dell'anello",
      "ring_signals": "Segnali dall'anello",
      "rings": "Anelli",
      "rotational_period": "Periodo di rotazione",
      "scanned": "Scansionato",
      "scanned_description": "Deseleziona per contrassegnare un corpo come non scansionato",
      "search": "Ricerca",
      "search_radius": "Raggio (LY)",
      "search_radius_description": "Questa è la distanza in LY attorno alla quale il navigatore cercherà mondi di alto valore da visitare. Un valore di 25 LY tende a dare un buon equilibrio per le rotte da A a B, mantenendo il numero di salti ragionevolmente basso, dando comunque una buon guadagno. Per i percorsi circolari (lasciando vuota la destinazione) probabilmente è meglio aumentarlo a 100-500 LY.",
      "secondary_economy": "Economia Secondaria",
      "security": "Sicurezza",
      "select_all": "Tutto",
      "select_allegiance": "Seleziona fedeltà",
      "select_atmosphere": "Seleziona l'atmosfera",
      "select_body_subtype": "Seleziona il sottotipo di corpo",
      "select_body_type": "Seleziona il tipo di corpo",
      "select_controlling_minor_faction": "Seleziona la fazione di controllo",
      "select_controlling_minor_faction_state": "Seleziona lo stato della fazione di controllo",
      "select_destination_system": "Seleziona la destinazione",
      "select_export_commodities": "Seleziona le merci di esportazione",
      "select_faction_presence": "Seleziona la presenza della fazione",
      "select_full_spectral_class": "Seleziona la classe spettrale",
      "select_government": "Seleziona governo",
      "select_import_commodities": "Seleziona l'importazione di merci",
      "select_landmark_subtype": "Seleziona il sottotipo di landmark",
      "select_landmark_type": "Seleziona il tipo di landmark",
      "select_modules": "Seleziona moduli",
      "select_none": "Reset",
      "select_power": "Seleziona la Potenza",
      "select_power_state": "Seleziona lo stato della Potenza",
      "select_primary_economy": "Seleziona l'economia",
      "select_prohibited_commodities": "Seleziona le merci proibite",
      "select_reference_system": "Seleziona il sistema di riferimento",
      "select_region": "Seleziona la regione",
      "select_reserve_level": "Seleziona il livello di riserva",
      "select_ring_signal": "Seleziona il segnale del anello",
      "select_secondary_economy": "Seleziona economia secondaria",
      "select_security": "Seleziona sicurezza",
      "select_services": "Seleziona i servizi",
      "select_ships": "Seleziona le navi",
      "select_signal": "Seleziona il segnale del corpo",
      "select_source_system": "Seleziona la partenza",
      "select_spectral_class": "Seleziona la classe spettrale",
      "select_state": "Seleziona lo stato",
      "select_station_type": "Select station type",
      "select_subtype": "Seleziona sottotipo",
      "select_system_power": "Seleziona la Potenza del sistema",
      "select_terraforming_state": "Seleziona lo stato di terraformazione",
      "select_type": "Seleziona il tipo",
      "select_volcanism_type": "Scegli il vulcanismo",
      "sell_price": "Prezzo di vendita",
      "semi_major_axis": "Semiasse maggiore",
      "services": "Services",
      "ship": "Nave",
      "ship_build": "Build della Nave",
      "ship_build_description": "Incolla la build della nave JSON esportata da <a href=\"https://coriolis.io\">https://coriolis.io</a> o SLEF da <a href=\"https://edsy.org\">https://edsy.org</a>",
      "ship_price": "Prezzo della Nave",
      "ships": "Navi",
      "shipyard_updated_at": "Ultimo Aggiornamento dello Shipyard alle",
      "signal": "Segnale del corpo",
      "signal_count": "Conteggio",
      "signal_fields": "Filtri di segnale",
      "signals": "Segnali",
      "signals_updated_at": "Segnali Ultimo Aggiornamento alle",
      "single_value": "Un solo valore possibile",
      "small_pads": "Small Pads",
      "solar_masses": "Massa Solare",
      "solar_radius": "Raggio solare",
      "solid_composition": "Composizione del Solido",
      "sorting": "Campi di ordinamento",
      "sorting_fields": "Ordinamento",
      "source_station": "Stazione di Partenza",
      "source_system": "Sistema di Partenza",
      "spectral_class": "Classe Spettrale",
      "starting_capital": "Capitale di partenza",
      "state": "Stato",
      "station_name": "Nome della stazione",
      "station_type": "Tipo di stazione",
      "subtype": "Sottotipo",
      "summary": "Riepilogo ricerca",
      "supply": "Offerta",
      "surface_pressure": "Pressione della superficie (Atmosfere)",
      "surface_temperature": "Temperatura della superficie (K)",
      "system_name": "Nome del sistema",
      "system_power": "Potenza del sistema",
      "system_power_state": "Stato della Potenza",
      "system_region": "Regione del sistema",
      "system_x": "X",
      "system_y": "Y",
      "system_z": "Z",
      "terraforming_description": "Il corpo è un candidato terraformabile?",
      "terraforming_short": "Terra",
      "terraforming_state": "Stato di terraformazione",
      "time": "Tempo di Calcolo",
      "time_description": "Tempo da spendere per calcolare il percorso (in secondi, più tempo dovrebbe produrre percorsi più brevi)",
      "time_remaining": "Tempo rimanente stimato",
      "time_taken": "Tempo stimato impiegato",
      "timestamp": "{from} a {to}",
      "total_profit": "Profitto totale",
      "total_value": "Valore totale",
      "tritium_fuel": "Tritium nel serbatoio",
      "tritium_fuel_description": "La quantità di tritium nel serbatoio",
      "tritium_market": "Tritium nel mercato",
      "tritium_market_description": "La quantità di tritium nel tuo mercato delle materie prime",
      "type": "Tipo",
      "unique": "Evita i loop",
      "unique_description": "Impedire al sistema di rivisitare le stazioni che sono già nel percorso",
      "updated_at": "Ultimo aggiornamento alle",
      "use_injections": "Usa FSD Injections",
      "use_injections_description": "Usa FSD synthesis per potenziare il salto quando non è disponibile una stella di neutroni.",
      "use_mapping_value": "Usa il Valore della Mappatura",
      "use_mapping_value_description": "Usa il valore di mappatura anziché il valore di scansione",
      "use_supercharge": "Usa il Supercharge",
      "use_supercharge_description": "Usa le stelle di neutroni per sovralimentare il tuo FSD",
      "used_capacity": "Capacità usata",
      "used_capacity_description": "Inserisci la capacità mostrata nell'angolo in alto a destra della schermata di gestione della carrier.",
      "via": "Tramite Sistema",
      "volcanism_type": "Volcanism",
      "x": "X",
      "x_coordinate": "Coordinata X",
      "y": "Y",
      "y_coordinate": "Coordinata Y",
      "z": "Z",
      "z_coordinate": "Coordinata Z"
    },
    "fields_help": {
      "body_name": "Usalo per filtrare i corpi con un nome corrispondente, ad esempio Sol corrisponderebbe a Sol ma non a Solati 6.",
      "destination": "Usalo per impostare la destinazione del percorso",
      "reference_coords": "Utilizzare questo al posto del Sistema di Riferimento per indicare a quali coordinate vuoi cercare (per distanze ecc.).",
      "reference_route": "Usalo per indicare un percorso rispetto al quale vuoi cercare (per distanze ecc.).",
      "reference_system": "Utilizzare questo per indicare da quale sistema si desidera effettuare la ricerca (per distanze ecc.).",
      "source": "Usalo per impostare l'inizio del percorso",
      "station_name": "Usalo per filtrare le stazioni con una parola corrispondente nel nome",
      "system_name": "Utilizzare questo per filtrare i corpi in un sistema con una corrispondenza di parole, ad esempio Col corrisponderebbe a Col 285 Settore IY-W b16-6 ma non Colonia."
    },
    "fleet-carrier": {
      "description": "Questa pagina ti consentirà di tracciare una rotta tra due diversi sistemi stellari per la tua fleet carrier. Il risultato ti mostrerà tutti i sistemi a cui devi andare. Ti farà anche sapere quando dovresti raccogliere più carburante. Le stime del tempo si basano su 20 minuti per salto e producono 150 tonnellate di trizio all'ora di estrazione per un singolo giocatore.",
      "import_destinations": "Importa destinazioni",
      "import_destinations_description": "Importa un file waypoint da EDSM come insieme di destinazioni (un semplice file di testo con un nome di sistema per riga).",
      "importing": "Importazione delle destinazioni",
      "move_destination_down": "Sposta la destinazione in basso",
      "move_destination_up": "Sposta la destinazione in alto",
      "refuel_destination": "Fare rifornimento in questo punto",
      "refueling_destination": "{destination} (Rifornimento)",
      "title": "Fleet Carrier Router",
      "warning": "Questo navigatore ti consentirà di tracciare un percorso per fleet carrier tra i sistemi"
    },
    "footer": {
      "copyright": "Copyright {from}-{to} Gareth Harper.",
      "donations": "Se desideri donare per mantenere aggiornato e funzionante questo sito, puoi controllare la mia pagina <a href=\"https://www.patreon.com/spansh\">Patreon </a>.",
      "features": "Se hai richieste di funzionalità o trovi bug, puoi visitare il <a href=\"https://forums.frontier.co.uk/threads/neutron-highway-long-range-route-planner.308674/\">forum thread</a> per questo strumento, oppure puoi inviare un'e-mail a <a href=\"mailto:plotter@spansh.co.uk\">plotter@spansh.co.uk</a>.",
      "thanks": "Grazie a tutti i giocatori e gli esploratori che forniscono i dati che alimentano questo sito web."
    },
    "goals": {
      "bounty": "Taglia",
      "carto": "Universal Cartographics",
      "combatbond": "Combat Bond",
      "expires": "Termina {date}",
      "fetch_error": "Impossibile recuperare gli obiettivi da Frontier",
      "fetching": "Recupero degli obiettivi da Frontier",
      "mining": "Estrazione",
      "none": "Nessun obiettivo della community attualmente elencato",
      "research": "Ricerca",
      "trade": "Porta {commodity} a {station} in {system}",
      "tradelist": "Trading"
    },
    "government": {
      "Anarchy": "Anarchy",
      "Communism": "Communism",
      "Confederacy": "Confederacy",
      "Cooperative": "Cooperative",
      "Corporate": "Corporazione",
      "Democracy": "Democracy",
      "Dictatorship": "Dittatura",
      "Engineer": "Ingegnere",
      "Feudal": "Feudal",
      "None": "Assente4",
      "Patronage": "Patronage",
      "Prison": "Prison",
      "Prison Colony": "Prison Colony",
      "Private Ownership": "Proprietà Privata",
      "Theocracy": "Theocracy",
      "Workshop (Engineer)": "Officina (ingegnere)"
    },
    "landmark_subtype": {
      "Abandoned Base": "Abandoned Base",
      "Albens Bell Mollusc": "Albens Bell Mollusc",
      "Albidum Chalice Pod": "Albidum Chalice Pod",
      "Albidum Ice Crystals": "Albidum Ice Crystals",
      "Albidum Peduncle Tree": "Albidum Peduncle Tree",
      "Albidum Quadripartite Pod": "Albidum Quadripartite Pod",
      "Albidum Silicate Crystals": "Albidum Silicate Crystals",
      "Albidum Sinuous Tubers": "Albidum Sinuous Tubers",
      "Albulum Gourd Mollusc": "Albulum Gourd Mollusc",
      "Aleoida Arcus": "Aleoida Arcus",
      "Aleoida Coronamus": "Aleoida Coronamus",
      "Aleoida Gravis": "Aleoida Gravis",
      "Aleoida Laminiae": "Aleoida Laminiae",
      "Aleoida Spica": "Aleoida Spica",
      "Ammonia Ice Fumarole": "Ammonia Ice Fumarole",
      "Ammonia Ice Geyser": "Ammonia Ice Geyser",
      "Amphora Plant": "Amphora Plant",
      "Aurarium Gyre Pod": "Aurarium Gyre Pod",
      "Aureum Brain Tree": "Aureum Brain Tree",
      "Bacterium Acies": "Bacterium Acies",
      "Bacterium Alcyoneum": "Bacterium Alcyoneum",
      "Bacterium Aurasus": "Bacterium Aurasus",
      "Bacterium Bullaris": "Bacterium Bullaris",
      "Bacterium Cerbrus": "Bacterium Cerbrus",
      "Bacterium Informem": "Bacterium Informem",
      "Bacterium Nebulus": "Bacterium Nebulus",
      "Bacterium Omentum": "Bacterium Omentum",
      "Bacterium Scopulum": "Bacterium Scopulum",
      "Bacterium Tela": "Bacterium Tela",
      "Bacterium Verrata": "Bacterium Verrata",
      "Bacterium Vesicula": "Bacterium Vesicula",
      "Bacterium Volu": "Bacterium Volu",
      "Bark Mounds": "Bark Mounds",
      "Bear": "Bear",
      "Blatteum Bell Mollusc": "Blatteum Bell Mollusc",
      "Blatteum Bioluminescent Anemone": "Blatteum Bioluminescent Anemone",
      "Blatteum Collared Pod": "Blatteum Collared Pod",
      "Blatteum Quadripartite Pod": "Blatteum Quadripartite Pod",
      "Blatteum Sinuous Tubers": "Blatteum Sinuous Tubers",
      "Bowl": "Bowl",
      "Cactoida Cortexum": "Cactoida Cortexum",
      "Cactoida Lapis": "Cactoida Lapis",
      "Cactoida Peperatis": "Cactoida Peperatis",
      "Cactoida Pullulanta": "Cactoida Pullulanta",
      "Cactoida Vermis": "Cactoida Vermis",
      "Caeruleum Lagrange Cloud": "Caeruleum Lagrange Cloud",
      "Caeruleum Octahedral Pod": "Caeruleum Octahedral Pod",
      "Caeruleum Peduncle Pod": "Caeruleum Peduncle Pod",
      "Caeruleum Peduncle Tree": "Caeruleum Peduncle Tree",
      "Caeruleum Quadripartite Pod": "Caeruleum Quadripartite Pod",
      "Caeruleum Sinuous Tubers": "Caeruleum Sinuous Tubers",
      "Caeruleum Torus Mollusc": "Caeruleum Torus Mollusc",
      "Candidum Peduncle Pod": "Candidum Peduncle Pod",
      "Candidum Rhizome Pod": "Candidum Rhizome Pod",
      "Carbon Dioxide Fumarole": "Carbon Dioxide Fumarole",
      "Carbon Dioxide Gas Vent": "Carbon Dioxide Gas Vent",
      "Carbon Dioxide Ice Fumarole": "Carbon Dioxide Ice Fumarole",
      "Carbon Dioxide Ice Geyser": "Carbon Dioxide Ice Geyser",
      "Cereum Aster Pod": "Cereum Aster Pod",
      "Cereum Aster Tree": "Cereum Aster Tree",
      "Cereum Bullet Mollusc": "Cereum Bullet Mollusc",
      "Clypeus Lacrimam": "Clypeus Lacrimam",
      "Clypeus Margaritus": "Clypeus Margaritus",
      "Clypeus Speculumi": "Clypeus Speculumi",
      "Cobalteum Globe Mollusc": "Cobalteum Globe Mollusc",
      "Cobalteum Rhizome Pod": "Cobalteum Rhizome Pod",
      "Common Thargoid Barnacle": "Common Thargoid Barnacle",
      "Concha Aureolas": "Concha Aureolas",
      "Concha Biconcavis": "Concha Biconcavis",
      "Concha Labiata": "Concha Labiata",
      "Concha Renibus": "Concha Renibus",
      "Crashed Ship": "Nave precipitata",
      "Crater Outpost": "Crater Outpost",
      "Crater Port": "Crater Port",
      "Croceum Anemone": "Croceum Anemone",
      "Croceum Globe Mollusc": "Croceum Globe Mollusc",
      "Croceum Gourd Mollusc": "Croceum Gourd Mollusc",
      "Croceum Lagrange Cloud": "Croceum Lagrange Cloud",
      "Croceum Lagrange Storm Cloud": "Croceum Lagrange Storm Cloud",
      "Crossroads": "Crossroads",
      "Crystalline Shards": "Crystalline Shards",
      "E02-Type Anomaly": "E02-Type Anomaly",
      "E03-Type Anomaly": "E03-Type Anomaly",
      "E04-Type Anomaly": "E04-Type Anomaly",
      "Electricae Pluma": "Electricae Pluma",
      "Electricae Radialem": "Electricae Radialem",
      "Fistbump": "Fistbump",
      "Flavum Bullet Mollusc": "Flavum Bullet Mollusc",
      "Flavum Ice Crystals": "Flavum Ice Crystals",
      "Flavum Metallic Crystals": "Flavum Metallic Crystals",
      "Flavum Silicate Crystals": "Flavum Silicate Crystals",
      "Fonticulua Campestris": "Fonticulua Campestris",
      "Fonticulua Digitos": "Fonticulua Digitos",
      "Fonticulua Fluctus": "Fonticulua Fluctus",
      "Fonticulua Lapida": "Fonticulua Lapida",
      "Fonticulua Segmentatus": "Fonticulua Segmentatus",
      "Fonticulua Upupam": "Fonticulua Upupam",
      "Frutexa Acus": "Frutexa Acus",
      "Frutexa Collum": "Frutexa Collum",
      "Frutexa Fera": "Frutexa Fera",
      "Frutexa Flabellum": "Frutexa Flabellum",
      "Frutexa Flammasis": "Frutexa Flammasis",
      "Frutexa Metallicum": "Frutexa Metallicum",
      "Frutexa Sponsae": "Frutexa Sponsae",
      "Fumerola Aquatis": "Fumerola Aquatis",
      "Fumerola Carbosis": "Fumerola Carbosis",
      "Fumerola Extremus": "Fumerola Extremus",
      "Fumerola Nitris": "Fumerola Nitris",
      "Fungoida Bullarum": "Fungoida Bullarum",
      "Fungoida Gelata": "Fungoida Gelata",
      "Fungoida Setisis": "Fungoida Setisis",
      "Fungoida Stabitis": "Fungoida Stabitis",
      "Green Class I Gas Giant": "Green Class I Gas Giant",
      "Green Class II Gas Giant": "Green Class II Gas Giant",
      "Green Class III Gas Giant": "Green Class III Gas Giant",
      "Green Class IV Gas Giant": "Green Class IV Gas Giant",
      "Green Gas Giant with Ammonia Life": "Green Gas Giant with Ammonia Life",
      "Green Water Giant": "Green Water Giant",
      "Guardian Beacon": "Guardian Beacon",
      "Guardian Codex": "Guardian Codex",
      "Guardian Data Terminal": "Guardian Data Terminal",
      "Guardian Pylon": "Guardian Pylon",
      "Guardian Relic Tower": "Guardian Relic Tower",
      "Guardian Sentinel": "Guardian Sentinel",
      "Gypseeum Bell Mollusc": "Gypseeum Bell Mollusc",
      "Gypseeum Brain Tree": "Gypseeum Brain Tree",
      "Gypseeum Peduncle Pod": "Gypseeum Peduncle Pod",
      "Gypseeum Rhizome Pod": "Gypseeum Rhizome Pod",
      "Hammerbot": "Hammerbot",
      "Installation": "Installation",
      "Iron Magma Lava Spout": "Iron Magma Lava Spout",
      "K01-Type Anomaly": "K01-Type Anomaly",
      "K03-Type Anomaly": "K03-Type Anomaly",
      "K04-Type Anomaly": "K04-Type Anomaly",
      "K06-Type Anomaly": "K06-Type Anomaly",
      "K08-Type Anomaly": "K08-Type Anomaly",
      "K09-Type Anomaly": "K09-Type Anomaly",
      "K12-Type Anomaly": "K12-Type Anomaly",
      "K13-Type Anomaly": "K13-Type Anomaly",
      "L01-Type Anomaly": "L01-Type Anomaly",
      "L06-Type Anomaly": "L06-Type Anomaly",
      "L08-Type Anomaly": "L08-Type Anomaly",
      "L09-Type Anomaly": "L09-Type Anomaly",
      "Lacrosse": "Lacrosse",
      "Lattice Mineral Spheres": "Lattice Mineral Spheres",
      "Lindigoticum Aster Pod": "Lindigoticum Aster Pod",
      "Lindigoticum Bell Mollusc": "Lindigoticum Bell Mollusc",
      "Lindigoticum Brain Tree": "Lindigoticum Brain Tree",
      "Lindigoticum Bulb Mollusc": "Lindigoticum Bulb Mollusc",
      "Lindigoticum Capsule Mollusc": "Lindigoticum Capsule Mollusc",
      "Lindigoticum Ice Crystals": "Lindigoticum Ice Crystals",
      "Lindigoticum Parasol Mollusc": "Lindigoticum Parasol Mollusc",
      "Lindigoticum Reel Mollusc": "Lindigoticum Reel Mollusc",
      "Lindigoticum Silicate Crystals": "Lindigoticum Silicate Crystals",
      "Lindigoticum Sinuous Tubers": "Lindigoticum Sinuous Tubers",
      "Lindigoticum Umbrella Mollusc": "Lindigoticum Umbrella Mollusc",
      "Lividum Brain Tree": "Lividum Brain Tree",
      "Lividum Bullet Mollusc": "Lividum Bullet Mollusc",
      "Lividum Collared Pod": "Lividum Collared Pod",
      "Luteolum Anemone": "Luteolum Anemone",
      "Luteolum Bell Mollusc": "Luteolum Bell Mollusc",
      "Luteolum Bulb Mollusc": "Luteolum Bulb Mollusc",
      "Luteolum Calcite Plates": "Luteolum Calcite Plates",
      "Luteolum Capsule Mollusc": "Luteolum Capsule Mollusc",
      "Luteolum Lagrange Cloud": "Luteolum Lagrange Cloud",
      "Luteolum Lagrange Storm Cloud": "Luteolum Lagrange Storm Cloud",
      "Luteolum Parasol Mollusc": "Luteolum Parasol Mollusc",
      "Luteolum Reel Mollusc": "Luteolum Reel Mollusc",
      "Luteolum Umbrella Mollusc": "Luteolum Umbrella Mollusc",
      "Methane Ice Fumarole": "Crater Outpost",
      "Methane Ice Geyser": "Methane Ice Geyser",
      "Nitrogen Ice Fumarole": "Nitrogen Ice Fumarole",
      "Nitrogen Ice Geyser": "Nitrogen Ice Geyser",
      "Niveum Globe Mollusc": "Niveum Globe Mollusc",
      "Niveum Octahedral Pod": "Niveum Octahedral Pod",
      "Osseus Cornibus": "Osseus Cornibus",
      "Osseus Discus": "Osseus Discus",
      "Osseus Fractus": "Osseus Fractus",
      "Osseus Pellebantus": "Osseus Pellebantus",
      "Osseus Pumice": "Osseus Pumice",
      "Osseus Spiralis": "Osseus Spiralis",
      "Ostrinum Brain Tree": "Ostrinum Brain Tree",
      "Ostrinum Chalice Pod": "Ostrinum Chalice Pod",
      "Ostrinum Globe Mollusc": "Ostrinum Globe Mollusc",
      "Ostrinum Peduncle Tree": "Ostrinum Peduncle Tree",
      "P01-Type Anomaly": "P01-Type Anomaly",
      "P02-Type Anomaly": "P02-Type Anomaly",
      "P03-Type Anomaly": "P03-Type Anomaly",
      "P04-Type Anomaly": "P04-Type Anomaly",
      "P05-Type Anomaly": "P05-Type Anomaly",
      "P07-Type Anomaly": "P07-Type Anomaly",
      "P09-Type Anomaly": "P09-Type Anomaly",
      "P11-Type Anomaly": "P11-Type Anomaly",
      "P12-Type Anomaly": "P12-Type Anomaly",
      "P13-Type Anomaly": "P13-Type Anomaly",
      "P14-Type Anomaly": "P14-Type Anomaly",
      "P15-Type Anomaly": "P15-Type Anomaly",
      "Phoeniceum Gourd Mollusc": "Phoeniceum Gourd Mollusc",
      "Prasinum Aster Tree": "Prasinum Aster Tree",
      "Prasinum Bioluminescent Anemone": "Prasinum Bioluminescent Anemone",
      "Prasinum Globe Mollusc": "Prasinum Globe Mollusc",
      "Prasinum Ice Crystals": "Prasinum Ice Crystals",
      "Prasinum Metallic Crystals": "Prasinum Metallic Crystals",
      "Prasinum Silicate Crystals": "Prasinum Silicate Crystals",
      "Prasinum Sinuous Tubers": "Prasinum Sinuous Tubers",
      "Prasinus Bioluminescent Anemone": "Prasinus Bioluminescent Anemone",
      "Proto-Lagrange Cloud": "Proto-Lagrange Cloud",
      "Puniceum Anemone": "Puniceum Anemone",
      "Puniceum Brain Tree": "Puniceum Brain Tree",
      "Purpureum Gourd Mollusc": "Purpureum Gourd Mollusc",
      "Purpureum Ice Crystals": "Purpureum Ice Crystals",
      "Purpureum Metallic Crystals": "Purpureum Metallic Crystals",
      "Purpureum Peduncle Pod": "Purpureum Peduncle Pod",
      "Purpureum Silicate Crystals": "Purpureum Silicate Crystals",
      "Q02-Type Anomaly": "Q02-Type Anomaly",
      "Q06-Type Anomaly": "Q06-Type Anomaly",
      "Q08-Type Anomaly": "Q08-Type Anomaly",
      "Q09-Type Anomaly": "Q09-Type Anomaly",
      "Recepta Conditivus": "Recepta Conditivus",
      "Recepta Deltahedronix": "Recepta Deltahedronix",
      "Recepta Umbrux": "Recepta Umbrux",
      "Robolobster": "Robolobster",
      "Roseum Anemone": "Roseum Anemone",
      "Roseum Bioluminescent Anemone": "Roseum Bioluminescent Anemone",
      "Roseum Brain Tree": "Roseum Brain Tree",
      "Roseum Globe Mollusc": "Roseum Globe Mollusc",
      "Roseum Ice Crystals": "Roseum Ice Crystals",
      "Roseum Lagrange Cloud": "Roseum Lagrange Cloud",
      "Roseum Lagrange Storm Cloud": "Roseum Lagrange Storm Cloud",
      "Roseum Silicate Crystals": "Roseum Silicate Crystals",
      "Roseum Sinuous Tubers": "Roseum Sinuous Tubers",
      "Roseus Sinuous Tubers": "Roseus Sinuous Tubers",
      "Rubellum Aster Pod": "Rubellum Aster Pod",
      "Rubellum Peduncle Tree": "Rubellum Peduncle Tree",
      "Rubeum Bioluminescent Anemone": "Rubeum Bioluminescent Anemone",
      "Rubeum Bullet Mollusc": "Rubeum Bullet Mollusc",
      "Rubeum Ice Crystals": "Rubeum Ice Crystals",
      "Rubeum Metallic Crystals": "Rubeum Metallic Crystals",
      "Rubeum Silicate Crystals": "Rubeum Silicate Crystals",
      "Rubicundum Collared Pod": "Rubicundum Collared Pod",
      "Rubicundum Lagrange Cloud": "Rubicundum Lagrange Cloud",
      "Rubicundum Lagrange Storm Cloud": "Rubicundum Lagrange Storm Cloud",
      "Rufum Gourd Mollusc": "Rufum Gourd Mollusc",
      "Rufum Peduncle Pod": "Rufum Peduncle Pod",
      "Rutulum Globe Mollusc": "Rutulum Globe Mollusc",
      "Settlement": "Insediamento",
      "Silicate Magma Lava Spout": "Silicate Magma Lava Spout",
      "Silicate Vapour Fumarole": "Silicate Vapour Fumarole",
      "Silicate Vapour Gas Vent": "Silicate Vapour Gas Vent",
      "Silicate Vapour Ice Fumarole": "Silicate Vapour Ice Fumarole",
      "Solid Mineral Spheres": "Solid Mineral Spheres",
      "Squid": "Squid",
      "Stickyhand": "Stickyhand",
      "Stolon Pod": "Stolon Pod",
      "Stratum Araneamus": "Stratum Araneamus",
      "Stratum Cucumisis": "Stratum Cucumisis",
      "Stratum Excutitus": "Stratum Excutitus",
      "Stratum Frigus": "Stratum Frigus",
      "Stratum Laminamus": "Stratum Laminamus",
      "Stratum Limaxus": "Stratum Limaxus",
      "Stratum Paleas": "Stratum Paleas",
      "Stratum Tectonicas": "Stratum Tectonicas",
      "Sulphur Dioxide Fumarole": "Sulphur Dioxide Fumarole",
      "Sulphur Dioxide Gas Vent": "Sulphur Dioxide Gas Vent",
      "Sulphur Dioxide Ice Fumarole": "Sulphur Dioxide Ice Fumarole",
      "Surface Station": "Surface Station",
      "T01-Type Anomaly": "T01-Type Anomaly",
      "T04-Type Anomaly": "T04-Type Anomaly",
      "Thargoid Barnacle Barbs": "Thargoid Barnacle Barbs",
      "Thargoid Device": "Thargoid Device",
      "Thargoid Interceptor Shipwreck": "Thargoid Interceptor Shipwreck",
      "Thargoid Pod": "Thargoid Pod",
      "Thargoid Scavengers": "Thargoid Scavengers",
      "Thargoid Scout Shipwreck": "Thargoid Scout Shipwreck",
      "Thargoid Structure": "Thargoid Structure",
      "Thargoid Uplink Device": "Thargoid Uplink Device",
      "Tourist Beacon": "Tourist Beacon",
      "Tubus Cavas": "Tubus Cavas",
      "Tubus Compagibus": "Tubus Compagibus",
      "Tubus Conifer": "Tubus Conifer",
      "Tubus Rosarium": "Tubus Rosarium",
      "Tubus Sororibus": "Tubus Sororibus",
      "Turtle": "Turtle",
      "Tussock Albata": "Tussock Albata",
      "Tussock Capillum": "Tussock Capillum",
      "Tussock Caputus": "Tussock Caputus",
      "Tussock Catena": "Tussock Catena",
      "Tussock Cultro": "Tussock Cultro",
      "Tussock Divisa": "Tussock Divisa",
      "Tussock Ignis": "Tussock Ignis",
      "Tussock Pennata": "Tussock Pennata",
      "Tussock Pennatis": "Tussock Pennatis",
      "Tussock Propagito": "Tussock Propagito",
      "Tussock Serrati": "Tussock Serrati",
      "Tussock Stigmasis": "Tussock Stigmasis",
      "Tussock Triticum": "Tussock Triticum",
      "Tussock Ventusa": "Tussock Ventusa",
      "Tussock Virgam": "Tussock Virgam",
      "Unknown": "Sconosciuto",
      "Violaceum Sinuous Tubers": "Violaceum Sinuous Tubers",
      "Viride Brain Tree": "Viride Brain Tree",
      "Viride Bulb Mollusc": "Viride Bulb Mollusc",
      "Viride Bullet Mollusc": "Viride Bullet Mollusc",
      "Viride Capsule Mollusc": "Viride Capsule Mollusc",
      "Viride Gyre Tree": "Viride Gyre Tree",
      "Viride Lagrange Cloud": "Viride Lagrange Cloud",
      "Viride Lagrange Storm Cloud": "Viride Lagrange Storm Cloud",
      "Viride Peduncle Tree": "Viride Peduncle Tree",
      "Viride Reel Mollusc": "Viride Reel Mollusc",
      "Viride Sinuous Tubers": "Viride Sinuous Tubers",
      "Viride Umbrella Mollusc": "Viride Umbrella Mollusc",
      "Water Fumarole": "Water Fumarole",
      "Water Gas Vent": "Water Gas Vent",
      "Water Geyser": "Water Geyser",
      "Water Ice Fumarole": "Water Ice Fumarole",
      "Water Ice Geyser": "Water Ice Geyser",
      "Wrecked Ship": "Wrecked Ship"
    },
    "landmark_type": {
      "Abandoned Base": "Base abbandonata",
      "Aleoida": "Aleoida",
      "Amphora Plant": "Amphora Plant",
      "Anemone": "Anemone",
      "Aster": "Aster",
      "Bacterium": "Bacterium",
      "Bark Mounds": "Bark Mounds",
      "Brain Tree": "Brain Tree",
      "Cactoida": "Cactoida",
      "Calcite Plates": "Calcite Plates",
      "Chalice Pod": "Chalice Pod",
      "Clypeus": "Clypeus",
      "Collared Pod": "Collared Pod",
      "Concha": "Concha",
      "Crashed Ship": "Crashed Ship",
      "Crystalline Shard": "Crystalline Shard",
      "E-Type Anomaly": "E-Type Anomaly",
      "Electricae": "Electricae",
      "Fonticulua": "Fonticulua",
      "Frutexa": "Frutexa",
      "Fumarole": "Fumarole",
      "Fumerola": "Fumerola",
      "Fungal Gourd": "Fungal Gourd",
      "Fungoida": "Fungoida",
      "Gas Vent": "Gas Vent",
      "Geyser": "Geyser",
      "Guardian": "Guardian",
      "Guardian Ruin": "Guardian Ruin",
      "Guardian Structure": "Guardian Structure",
      "Gyre": "Gyre",
      "Ice Crystals": "Ice Crystals",
      "K-Type Anomaly": "K-Type Anomaly",
      "L-Type Anomaly": "L-Type Anomaly",
      "Lagrange Cloud": "Lagrange Cloud",
      "Lava Spout": "Lava Spout",
      "Metallic Crystals": "Metallic Crystals",
      "Mineral Spheres": "Mineral Spheres",
      "Mollusc": "Mollusc",
      "Osseus": "Osseus",
      "P-Type Anomaly": "P-Type Anomaly",
      "Peduncle": "Peduncle",
      "Planets": "Pianeti",
      "Q-Type Anomaly": "Q-Type Anomaly",
      "Quadripartite": "Quadripartite",
      "Recepta": "Recepta",
      "Rhizome": "Rhizome",
      "Shards": "Shards",
      "Silicate Crystals": "Silicate Crystals",
      "Stolon": "Stolon",
      "Storm Cloud": "Storm Cloud",
      "Stratum": "Stratum",
      "Surface Station": "Stazione di Superficie",
      "T-Type Anomaly": "T-Type Anomaly",
      "Thargoid": "Thargoid",
      "Thargoid Barnacle": "Thargoid Barnacle",
      "Thargoid Structure": "Thargoid Structure",
      "Tourist Beacon": "Tourist Beacon",
      "Tubers": "Tubers",
      "Tubus": "Tubus",
      "Tussock": "Tussock",
      "Void": "Vuoto",
      "Wrecked Ship": "Wrecked Ship"
    },
    "map": {
      "description": "Una mappa di tutte le stelle di neutroni conosciute nella galassia.",
      "title": "Neutron Star Map"
    },
    "market": {
      "buy_price": "Prezzo d'acquisto",
      "buy_price_header": "{commodity} prezzo d'acquisto",
      "demand": "Domanda",
      "demand_header": "Domanda di {commodity}",
      "remove_field": "Rimuovi filtro",
      "sell_price": "Prezzo di vendita",
      "sell_price_header": "Prezzo di vendita di {commodity}",
      "supply": "Offerta",
      "supply_header": "Offerta di {commodity}"
    },
    "material": {
      "Antimony": "Antimony",
      "Arsenic": "Arsenic",
      "Cadmium": "Cadmium",
      "Carbon": "Carbon",
      "Chromium": "Chromium",
      "Germanium": "Germanium",
      "Iron": "Iron",
      "Manganese": "Manganese",
      "Mercury": "Mercury",
      "Molybdenum": "Molybdenum",
      "Nickel": "Nickel",
      "Niobium": "Niobium",
      "Phosphorus": "Phosphorus",
      "Polonium": "Polonium",
      "Ruthenium": "Ruthenium",
      "Selenium": "Selenium",
      "Sulphur": "Sulphur",
      "Technetium": "Technetium",
      "Tellurium": "Tellurium",
      "Tin": "Tin",
      "Tungsten": "Tungsten",
      "Vanadium": "Vanadium",
      "Yttrium": "Yttrium",
      "Zinc": "Zinc",
      "Zirconium": "Zirconium"
    },
    "modules": {
      "add_field": "Aggiungi filtro",
      "categories": {
        "hardpoint": "Hardpoint",
        "internal": "Internal",
        "standard": "Standard",
        "utility": "Utility"
      },
      "class": "Classe",
      "name": "Nome del modulo",
      "names": {
        "AX Missile Rack": "AX Missile Rack",
        "AX Multi-Cannon": "AX Multi-Cannon",
        "Abrasion Blaster": "Abrasion Blaster",
        "Advanced Docking Computer": "Advanced Docking Computer",
        "Advanced Missile Rack": "Advanced Missile Rack",
        "Advanced Multi-Cannon": "Advanced Multi-Cannon",
        "Advanced Planetary Approach Suite": "Advanced Planetary Approach Suite",
        "Advanced Plasma Accelerator": "Advanced Plasma Accelerator",
        "Auto Field-Maintenance Unit": "Auto Field-Maintenance Unit",
        "Beam Laser": "Beam Laser",
        "Bi-Weave Shield Generator": "Bi-Weave Shield Generator",
        "Burst Laser": "Burst Laser",
        "Business Class Passenger Cabin": "Business Class Passenger Cabin",
        "Cannon": "Cannon",
        "Cargo Rack": "Cargo Rack",
        "Cargo Scanner": "Cargo Scanner",
        "Chaff Launcher": "Chaff Launcher",
        "Collector Limpet Controller": "Collector Limpet Controller",
        "Corrosion Resistant Cargo Rack": "Corrosion Resistant Cargo Rack",
        "Cytoscrambler Burst Laser": "Cytoscrambler Burst Laser",
        "Decontamination Limpet Controller": "Decontamination Limpet Controller",
        "Detailed Surface Scanner": "Detailed Surface Scanner",
        "Economy Class Passenger Cabin": "Economy Class Passenger Cabin",
        "Electronic Countermeasure": "Electronic Countermeasure",
        "Enforcer Cannon": "Enforcer Cannon",
        "Enhanced Performance Thrusters": "Enhanced Performance Thrusters",
        "Enzyme Missile Rack": "Enzyme Missile Rack",
        "Fighter Hangar": "Fighter Hangar",
        "First Class Passenger Cabin": "First Class Passenger Cabin",
        "Fragment Cannon": "Fragment Cannon",
        "Frame Shift Drive": "Frame Shift Drive",
        "Frame Shift Drive Interdictor": "Frame Shift Drive Interdictor",
        "Frame Shift Wake Scanner": "Frame Shift Wake Scanner",
        "Fuel Scoop": "Fuel Scoop",
        "Fuel Tank": "Fuel Tank",
        "Fuel Transfer Limpet Controller": "Fuel Transfer Limpet Controller",
        "Guardian FSD Booster": "Guardian FSD Booster",
        "Guardian Gauss Cannon": "Guardian Gauss Cannon",
        "Guardian Hull Reinforcement": "Guardian Hull Reinforcement",
        "Guardian Hybrid Power Distributor": "Guardian Hybrid Power Distributor",
        "Guardian Hybrid Power Plant": "Guardian Hybrid Power Plant",
        "Guardian Module Reinforcement": "Guardian Module Reinforcement",
        "Guardian Plasma Charger": "Guardian Plasma Charger",
        "Guardian Shard Cannon": "Guardian Shard Cannon",
        "Guardian Shield Reinforcement": "Guardian Shield Reinforcement",
        "Hatch Breaker Limpet Controller": "Hatch Breaker Limpet Controller",
        "Heat Sink Launcher": "Heat Sink Launcher",
        "Hull Reinforcement Package": "Hull Reinforcement Package",
        "Imperial Hammer Rail Gun": "Imperial Hammer Rail Gun",
        "Kill Warrant Scanner": "Kill Warrant Scanner",
        "Life Support": "Life Support",
        "Lightweight Alloy": "Lightweight Alloy",
        "Limpet Control": "Limpet Control",
        "Luxury Class Passenger Cabin": "Luxury Class Passenger Cabin",
        "Meta Alloy Hull Reinforcement": "Meta Alloy Hull Reinforcement",
        "Military Grade Composite": "Military Grade Composite",
        "Mine Launcher": "Mine Launcher",
        "Mining Lance": "Mining Lance",
        "Mining Laser": "Mining Laser",
        "Mining Multi Limpet Controller": "Mining Multi Limpet Controller",
        "Mirrored Surface Composite": "Mirrored Surface Composite",
        "Missile Rack": "Missile Rack",
        "Module Reinforcement Package": "Module Reinforcement Package",
        "Multi-Cannon": "Multi-Cannon",
        "Operations Multi Limpet Controller": "Operations Multi Limpet Controller",
        "Pacifier Frag-Cannon": "Pacifier Frag-Cannon",
        "Pack-Hound Missile Rack": "Pack-Hound Missile Rack",
        "Planetary Approach Suite": "Planetary Approach Suite",
        "Planetary Vehicle Hangar": "Planetary Vehicle Hangar",
        "Plasma Accelerator": "Plasma Accelerator",
        "Point Defence": "Point Defence",
        "Power Distributor": "Power Distributor",
        "Power Plant": "Power Plant",
        "Prismatic Shield Generator": "Prismatic Shield Generator",
        "Prospector Limpet Controller": "Prospector Limpet Controller",
        "Pulse Disruptor Laser": "Pulse Disruptor Laser",
        "Pulse Laser": "Pulse Laser",
        "Pulse Wave Analyser": "Pulse Wave Analyser",
        "Rail Gun": "Rail Gun",
        "Reactive Surface Composite": "Reactive Surface Composite",
        "Recon Limpet Controller": "Recon Limpet Controller",
        "Refinery": "Refinery",
        "Reinforced Alloy": "Reinforced Alloy",
        "Remote Release Flak Launcher": "Remote Release Flak Launcher",
        "Remote Release Flechette Launcher": "Remote Release Flechette Launcher",
        "Repair Limpet Controller": "Repair Limpet Controller",
        "Rescue Multi Limpet Controller": "Rescue Multi Limpet Controller",
        "Research Limpet Controller": "Research Limpet Controller",
        "Retributor Beam Laser": "Retributor Beam Laser",
        "Rocket Propelled FSD Disruptor": "Rocket Propelled FSD Disruptor",
        "Seeker Missile Rack": "Seeker Missile Rack",
        "Seismic Charge Launcher": "Seismic Charge Launcher",
        "Sensors": "Sensors",
        "Shield Booster": "Shield Booster",
        "Shield Cell Bank": "Shield Cell Bank",
        "Shield Generator": "Shield Generator",
        "Shock Cannon": "Shock Cannon",
        "Shock Mine Launcher": "Shock Mine Launcher",
        "Shutdown Field Neutraliser": "Shutdown Field Neutraliser",
        "Standard Docking Computer": "Standard Docking Computer",
        "Sub-Surface Displacement Missile": "Sub-Surface Displacement Missile",
        "Supercruise Assist": "Supercruise Assist",
        "Thrusters": "Thrusters",
        "Torpedo Pylon": "Torpedo Pylon",
        "Universal Multi Limpet Controller": "Universal Multi Limpet Controller",
        "Xeno Multi Limpet Controller": "Xeno Multi Limpet Controller",
        "Xeno Scanner": "Xeno Scanner"
      },
      "rating": "Rating",
      "remove_field": "Rimuovi filtro",
      "select_class": "Seleziona classe",
      "select_name": "Seleziona il nome del modulo",
      "select_rating": "Seleziona rating"
    },
    "navigation": {
      "admin": "Admin",
      "ammonia": "Ammonia World Route",
      "bodies": "Corpi",
      "community-goals": "Community Goals",
      "donations": "Donazioni",
      "dumps": "Data Dumps",
      "earth": "Earth-like World Route",
      "exact-plotter": "Galaxy Plotter",
      "exobiology": "Expressway to Exomastery",
      "fleet-carrier": "Fleet Carrier Router",
      "helpers": "Aiuti",
      "language": "Lingua",
      "login": "Login",
      "logout": "Logout",
      "map": "Mappa",
      "nearest": "Sistema più vicino",
      "new": "(New)",
      "oldsite": "Vecchio sito",
      "plotter": "Neutron Plotter",
      "riches": "Road to Riches",
      "routers": "Route Planners",
      "search": "Ricerca",
      "settings": "Impostazioni",
      "stations": "Stazioni",
      "systems": "Sistemi",
      "thanks": "Ringraziamenti",
      "theme": "Tema",
      "toggle": "Attiva/disattiva navigazione",
      "tourist": "Tourist Planner",
      "trade": "Trade Planner"
    },
    "nearest": {
      "description": "Questa pagina ti permetterà di trovare il sistema conosciuto più vicino a un insieme di coordinate.",
      "title": "Sistema conosciuto più vicino"
    },
    "no": "No",
    "plotter": {
      "description": "Questa pagina ti permetterà di tracciare una rotta tra due diversi sistemi stellari. Il risultato ti mostrerà ogni volta che devi andare sulla mappa della galassia per tracciare un nuovo percorso (ad esempio quando sei su una stella di neutroni). Darà la priorità alle stelle di neutroni, quindi andrebbe evitato per viaggi inferiori a 500 anni luce (probabilmente proverà a portarti prima al faro di Jackson se sei nella bolla).",
      "exact_plotter": "Se stai cercando un navigatore più completo che includa il rifornimento, le stelle di neutroni secondarie e l'iniezione di FSD, usa il nuovo <a href=\"{link}\">Galaxy Plotter</a>",
      "title": "Neutron Router",
      "warning": "Se hai una capacità di salto massimo basso (10-20 LY) potresti finire per usare un salto potenziato in un sistema da cui non hai abbastanza capacità di salto per andare oltre. Dovresti stare attento quando pianifichi usando questo tool, e controlla di poter andartene dal sistema quando sei sulla mappa della galassia."
    },
    "power_state": {
      "Contested": "Contestato",
      "Control": "Controllo",
      "Controlled": "Controllato",
      "Exploited": "Sfruttato",
      "HomeSystem": "Quartier Generale",
      "InPrepareRadius": "In Preparazione",
      "Prepared": "Prepared",
      "Turmoil": "Turmoil"
    },
    "region": {
      "Acheron": "Acheron",
      "Achilles's Altar": "Achilles's Altar",
      "Aquila's Halo": "Aquila's Halo",
      "Arcadian Stream": "Arcadian Stream",
      "Dryman's Point": "Dryman's Point",
      "Elysian Shore": "Elysian Shore",
      "Empyrean Straits": "Empyrean Straits",
      "Errant Marches": "Errant Marches",
      "Formorian Frontier": "Formorian Frontier",
      "Galactic Centre": "Galactic Centre",
      "Hawking's Gap": "Hawking's Gap",
      "Hieronymus Delta": "Hieronymus Delta",
      "Inner Orion Spur": "Inner Orion Spur",
      "Inner Orion-Perseus Conflux": "Inner Orion-Perseus Conflux",
      "Inner Scutum-Centaurus Arm": "Inner Scutum-Centaurus Arm",
      "Izanami": "Izanami",
      "Kepler's Crest": "Kepler's Crest",
      "Lyra's Song": "Lyra's Song",
      "Mare Somnia": "Mare Somnia",
      "Newton's Vault": "Newton's Vault",
      "Norma Arm": "Norma Arm",
      "Norma Expanse": "Norma Expanse",
      "Odin's Hold": "Odin's Hold",
      "Orion-Cygnus Arm": "Orion-Cygnus Arm",
      "Outer Arm": "Outer Arm",
      "Outer Orion Spur": "Outer Orion Spur",
      "Outer Orion-Perseus Conflux": "Outer Orion-Perseus Conflux",
      "Outer Scutum-Centaurus Arm": "Outer Scutum-Centaurus Arm",
      "Perseus Arm": "Perseus Arm",
      "Ryker's Hope": "Ryker's Hope",
      "Sagittarius-Carina Arm": "Sagittarius-Carina Arm",
      "Sanguineous Rim": "Sanguineous Rim",
      "Temple": "Temple",
      "Tenebrae": "Tenebrae",
      "The Abyss": "The Abyss",
      "The Conduit": "The Conduit",
      "The Formidine Rift": "The Formidine Rift",
      "The Veils": "The Veils",
      "The Void": "The Void",
      "Trojan Belt": "Trojan Belt",
      "Vulcan Gate": "Vulcan Gate",
      "Xibalba": "Xibalba"
    },
    "reserve_level": {
      "Common": "Comune",
      "Depleted": "Esaurito",
      "Low": "Basso",
      "Major": "Abbondante",
      "Pristine": "Incontaminato"
    },
    "results": {
      "atmosphere_composition_to_show": "Composizione dell'atmosfera da mostrare",
      "bodies_count": "{count} corpi",
      "body_belts": "{name} Belts",
      "body_landmarks": "{name} Landmarks",
      "body_rings": "{name} Anelli",
      "close": "Chiuso",
      "commodities_to_show": "Commodities da mostrare",
      "commodity_fields_to_show": "Campi Commodities da mostrare",
      "copy_to_clipboard": "Copia {system} negli appunti",
      "create_route": "Crea un itinerario turistico da questa ricerca",
      "display_search": "Mostra la ricerca sulla mappa",
      "download": "Scarica come CSV",
      "download_search": "Scarica come CSV",
      "estimated_jumps": "Salti stimati: {jumps}",
      "estimated_time": "Tempo stimato: {time}",
      "fields_to_show": "Campi da mostrare",
      "first": "Primo",
      "galaxy_map_visits": "Numero di visite necessarie alla mappa della galassia: {total_visits}",
      "hide_search": "Nascondi Ricerca",
      "jumps": "Salti: {jumps}",
      "landmarks_count": "{count} landmarks",
      "last": "Ultimo",
      "materials_to_show": "Materiali da mostrare",
      "next": "Successivo",
      "no_results": "Non sono stati trovati risultati per questa ricerca",
      "no_search": "Esegui una ricerca per vedere i risultati",
      "none": "Assente5",
      "previous": "Precedente",
      "results": "Risultati",
      "results_per_page": "Risultati per pagina",
      "route": "Da {source} a {destination}",
      "show_route": "Mostra la Rotta",
      "show_search": "Mostra Ricerca",
      "showing": "Visualizzazione dei risultati da {start} a {end} di {count}",
      "systems_count": "{count} sistemi",
      "totals": "Totali",
      "unknown_location": "Posizione sconosciuta"
    },
    "riches": {
      "blank": "Lascia vuota la destinazione per creare un tour circolare che inizia dal tuo sistema di partenza e torna lì alla fine.",
      "guides": "Puoi trovare alcune guide e video per l'utilizzo del <a href=\"https://forums.frontier.co.uk/showthread.php/464149-The-FSS-A-pictoral-guide\">FSS </a> e del <a href=\"https://forums.frontier.co.uk/showthread.php/465649-Setup-and-Use-of-the-FSS-and-DSS-With-Demonstration-Videos\">DSS</a>.",
      "low_results": "Il numero di sistemi ottenuti è inferiore a quello richiesto. Prova ad aumentare il raggio di ricerca per trovare più corpi. Se ancora non si ottengono risultati sufficienti, è possibile che il valore di scansione minimo sia troppo alto.",
      "title": "Strada per la ricchezza",
      "warning": "Dovresti decidere se vuoi il DSS (Detailed Surface Scanner che richiede di viaggiare verso il corpo nel sistema e nella mappa della superficie) o semplicemente FSS (Full Spectrum Scanner dal punto di partenza del sistema). FSS è più veloce per ogni sistema ma pagherà sostanzialmente meno crediti. La casella di controllo \"Usa valore di mappatura\" classificherà i corpi in base al premio DSS anziché al premio FSS."
    },
    "ring_signal": {
      "Alexandrite": "Alexandrite",
      "Bauxite": "Bauxite",
      "Benitoite": "Benitoite",
      "Bertrandite": "Bertrandite",
      "Biological": "Biological",
      "Bromellite": "Bromellite",
      "Cobalt": "Cobalt",
      "Coltan": "Coltan",
      "Gallite": "Gallite",
      "Geological": "Geological",
      "Gold": "Gold",
      "Grandidierite": "Grandidierite",
      "Guardian": "Guardian",
      "Human": "Human",
      "Hydrogen Peroxide": "Hydrogen Peroxide",
      "Indite": "Indite",
      "Lepidolite": "Lepidolite",
      "Liquid oxygen": "Liquid oxygen",
      "Lithium Hydroxide": "Lithium Hydroxide",
      "Low Temperature Diamonds": "Low Temperature Diamonds",
      "Methane Clathrate": "Methane Clathrate",
      "Methanol Monohydrate Crystals": "Methanol Monohydrate Crystals",
      "Monazite": "Monazite",
      "Musgravite": "Musgravite",
      "Osmium": "Osmium",
      "Other": "Altro",
      "Painite": "Painite",
      "Platinum": "Platinum",
      "Praseodymium": "Praseodymium",
      "Rhodplumsite": "Rhodplumsite",
      "Rutile": "Rutile",
      "Samarium": "Samarium",
      "Serendibite": "Serendibite",
      "Silver": "Silver",
      "Thargoid": "Thargoid",
      "Tritium": "Tritium",
      "Uraninite": "Uraninite",
      "Void Opals": "Void Opals",
      "Water": "Water"
    },
    "rings": {
      "add_field": "Aggiungi filtro",
      "inner_radius": "Raggio Interno (m)",
      "mass": "Messa (Mt)",
      "outer_radius": "Raggio Esterno (m)",
      "remove_field": "Rimuovi filtro",
      "select_type": "Seleziona il tipo",
      "type": "Tipo",
      "types": {
        "Icy": "Icy",
        "Metal Rich": "Metal Rich",
        "Metallic": "Metallic",
        "Rocky": "Rocky"
      }
    },
    "scan_types": {
      "Exobiology": "Esobiologia",
      "Salesman": "Road to Riches"
    },
    "scanned-bodies": {
      "title": "{scanType} Corpi Scansionati (da {start} a {end} di {total})"
    },
    "search": {
      "copy_name_to_clipboard": "Copia il nome negli appunti",
      "description": "Primi risultati di {size} di {count} alla ricerca di \"{query}\"",
      "fail": "Impossibile completare la ricerca, riprovare più tardi.",
      "types": {
        "body": "Corpo",
        "station": "Stazione",
        "system": "Sistema"
      }
    },
    "security": {
      "Anarchy": "Anarchy",
      "High": "Alto",
      "Lawless": "Senza legge",
      "Low": "Basso",
      "Medium": "Medio"
    },
    "services": {
      "Apex Interstellar": "Apex Interstellar",
      "Autodock": "Autodock",
      "Bartender": "Bartender",
      "Black Market": "Black Market",
      "Contacts": "Contacts",
      "Crew Lounge": "Crew Lounge",
      "Dock": "Dock",
      "Facilitator": "Interstellar Factors Contact",
      "Fleet Carrier Administration": "Fleet Carrier Administration",
      "Fleet Carrier Fuel": "Fleet Carrier Fuel",
      "Fleet Carrier Management": "Fleet Carrier Management",
      "Fleet Carrier Vendor": "Fleet Carrier Vendor",
      "Flight Controller": "Flight Controller",
      "Frontline Solutions": "Frontline Solutions",
      "Interstellar Factors Contact": "Interstellar Factors Contact",
      "Livery": "Livery",
      "Market": "Market",
      "Material Trader": "Material Trader",
      "Missions": "Missions",
      "Missions Generated": "Missions Generated",
      "Module Packs": "Fleet Carrier Administration",
      "On Dock Mission": "On Dock Mission",
      "Outfitting": "Outfitting",
      "Pioneer Supplies": "Pioneer Supplies",
      "Powerplay": "Powerplay",
      "Redemption Office": "Redemption Office",
      "Refuel": "Refuel",
      "Repair": "Repair",
      "Restock": "Restock",
      "Search and Rescue": "Search and Rescue",
      "Shipyard": "Shipyard",
      "Shop": "Shop",
      "Social Space": "Social Space",
      "Station Menu": "Station Menu",
      "Station Operations": "Operazioni della stazione",
      "Technology Broker": "Technology Broker",
      "Tuning": "Tuning",
      "Universal Cartographics": "Universal Cartographics",
      "Vista Genomics": "Vista Genomics",
      "Workshop": "Workshop"
    },
    "settings": {
      "confirm_delete": "Conferma per Eliminare l'Utente",
      "confirm_delete_warning": "Attenzione, se continui eliminerai il tuo account e tutti i dati salvati associati, incluse ma non limitate alle scansioni salvate Road to riches. Puoi ricreare il tuo account utente collegando nuovamente il tuo account di Frontier, ma i tuoi dati salvati saranno persi per sempre e non potranno essere ripristinati. Sei sicuro di voler continuare?",
      "delete_help": "Elimina il tuo account utente e tutti i dati associati.",
      "delete_user": "Elimina utente",
      "link_patreon": "Collega l'Account Patreon",
      "link_patreon_help": "Se sei un patron, collega qui il tuo account patreon per abilitare ricompense extra.",
      "linked_no_patron": "Ti sei già collegato ma al momento non sei un patron.",
      "linked_patron": "Ti sei già collegato e sei un patron, grazie."
    },
    "ships": {
      "Adder": "Adder",
      "Alliance Challenger": "Alliance Challenger",
      "Alliance Chieftain": "Alliance Chieftain",
      "Alliance Crusader": "Alliance Crusader",
      "Anaconda": "Anaconda",
      "Asp Explorer": "Asp Explorer",
      "Asp Scout": "Asp Scout",
      "Beluga Liner": "Beluga Liner",
      "Cobra MkIII": "Cobra MkIII",
      "Cobra MkIV": "Cobra MkIV",
      "Diamondback Explorer": "Diamondback Explorer",
      "Diamondback Scout": "Diamondback Scout",
      "Dolphin": "Dolphin",
      "Eagle": "Eagle",
      "Federal Assault Ship": "Federal Assault Ship",
      "Federal Corvette": "Federal Corvette",
      "Federal Dropship": "Federal Dropship",
      "Federal Gunship": "Federal Gunship",
      "Fer-de-Lance": "Fer-de-Lance",
      "Hauler": "Hauler",
      "Imperial Clipper": "Imperial Clipper",
      "Imperial Courier": "Imperial Courier",
      "Imperial Cutter": "Imperial Cutter",
      "Imperial Eagle": "Imperial Eagle",
      "Keelback": "Keelback",
      "Krait MkII": "Krait MkII",
      "Krait Phantom": "Krait Phantom",
      "Mamba": "Mamba",
      "Orca": "Orca",
      "Python": "Python",
      "Sidewinder": "Sidewinder",
      "Type-10 Defender": "Type-10 Defender",
      "Type-6 Transporter": "Type-6 Transporter",
      "Type-7 Transporter": "Type-7 Transporter",
      "Type-9 Heavy": "Type-9 Heavy",
      "Viper MkIII": "Viper MkIII",
      "Viper MkIV": "Viper MkIV",
      "Vulture": "Vulture"
    },
    "signal": {
      "Alexandrite": "Alexandrite",
      "Bauxite": "Bauxite",
      "Benitoite": "Benitoite",
      "Bertrandite": "Bertrandite",
      "Biological": "Biological",
      "Bromellite": "Bromellite",
      "Cobalt": "Cobalt",
      "Coltan": "Coltan",
      "Gallite": "Gallite",
      "Geological": "Geological",
      "Gold": "Gold",
      "Grandidierite": "Grandidierite",
      "Guardian": "Guardian",
      "Human": "Umano",
      "Hydrogen Peroxide": "Hydrogen Peroxide",
      "Indite": "Indite",
      "Lepidolite": "Lepidolite",
      "Liquid oxygen": "Liquid oxygen",
      "Lithium Hydroxide": "Lithium Hydroxide",
      "Low Temperature Diamonds": "Low Temperature Diamonds",
      "Methane Clathrate": "Methane Clathrate",
      "Methanol Monohydrate Crystals": "Methanol Monohydrate Crystals",
      "Monazite": "Monazite",
      "Musgravite": "Musgravite",
      "Osmium": "Osmium",
      "Other": "Altro",
      "Painite": "Painite",
      "Platinum": "Platinum",
      "Praseodymium": "Praseodymium",
      "Rhodplumsite": "Rhodplumsite",
      "Rutile": "Rutile",
      "Samarium": "Samarium",
      "Serendibite": "Serendibite",
      "Silver": "Silver",
      "Thargoid": "Thargoid",
      "Tritium": "Tritium",
      "Uraninite": "Uraninite",
      "Void Opals": "Void Opals",
      "Water": "Water"
    },
    "solid_composition": {
      "Ice": "Ice",
      "Metal": "Metal",
      "Rock": "Rock"
    },
    "sorting": {
      "asc": "Crescente",
      "desc": "Decrescente",
      "field_ascending": "Ordina per {field} crescente",
      "field_descending": "Ordina per {field} decrescente"
    },
    "state": {
      "Blight": "Blight",
      "Boom": "Boom",
      "Bust": "Bust",
      "Civil Liberty": "Civil Liberty",
      "Civil Unrest": "Civil Unrest",
      "Civil War": "Civil War",
      "Drought": "Drought",
      "Election": "Election",
      "Expansion": "Expansion",
      "Famine": "Famine",
      "Infrastructure Failure": "Infrastructure Failure",
      "Investment": "Investment",
      "Lockdown": "Lockdown",
      "Natural Disaster": "Natural Disaster",
      "None": "Assente6",
      "Outbreak": "Outbreak",
      "Pirate Attack": "Pirate Attack",
      "Public Holiday": "Public Holiday",
      "Retreat": "Retreat",
      "Terrorism": "Terrorism",
      "Terrorist Attack": "Terrorist Attack",
      "War": "War"
    },
    "station": {
      "market": "Market",
      "market_updated_at": "Mercato aggiornato {relative_updated_at} ({updated_at})",
      "no_market_id": "ID di mercato non fornito",
      "not_found": "Stazione non trovata",
      "outfitting": "Outfitting",
      "outfitting_updated_at": "Aggiornamento dell'outfitting {relative_updated_at} ({updated_at})",
      "shipyard": "Shipyard",
      "shipyard_updated_at": "Aggiornamento shipyard {relative_updated_at} ({updated_at})",
      "station": "Stazione"
    },
    "station_type": {
      "Asteroid base": "Asteroid base",
      "Coriolis Starport": "Coriolis Starport",
      "Drake-Class Carrier": "Drake-Class Carrier",
      "Mega ship": "Mega ship",
      "Ocellus Starport": "Ocellus Starport",
      "Orbis Starport": "Orbis Starport",
      "Outpost": "Outpost",
      "Planetary Outpost": "Planetary Outpost",
      "Planetary Port": "Planetary Port",
      "Settlement": "Settlement"
    },
    "subtype": {
      "A (Blue-White super giant) Star": "A (Blue-White super giant) Star",
      "A (Blue-White) Star": "A (Blue-White) Star",
      "Ammonia world": "Ammonia world",
      "B (Blue-White super giant) Star": "B (Blue-White super giant) Star",
      "B (Blue-White) Star": "B (Blue-White) Star",
      "Black Hole": "Black Hole",
      "C Star": "C Star",
      "CJ Star": "CJ Star",
      "CN Star": "CN Star",
      "Class I gas giant": "Class I gas giant",
      "Class II gas giant": "Class II gas giant",
      "Class III gas giant": "Class III gas giant",
      "Class IV gas giant": "Class IV gas giant",
      "Class V gas giant": "Class V gas giant",
      "Earth-like world": "Earth-like world",
      "F (White super giant) Star": "F (White super giant) Star",
      "F (White) Star": "F (White) Star",
      "G (White-Yellow super giant) Star": "G (White-Yellow super giant) Star",
      "G (White-Yellow) Star": "G (White-Yellow) Star",
      "Gas giant with ammonia-based life": "Gas giant with ammonia-based life",
      "Gas giant with water-based life": "Gas giant with water-based life",
      "Helium gas giant": "Helium gas giant",
      "Helium-rich gas giant": "Helium-rich gas giant",
      "Herbig Ae/Be Star": "Herbig Ae/Be Star",
      "High metal content world": "High metal content world",
      "Icy body": "Icy body",
      "K (Yellow-Orange giant) Star": "K (Yellow-Orange giant) Star",
      "K (Yellow-Orange) Star": "K (Yellow-Orange) Star",
      "L (Brown dwarf) Star": "L (Brown dwarf) Star",
      "M (Red dwarf) Star": "M (Red dwarf) Star",
      "M (Red giant) Star": "M (Red giant) Star",
      "M (Red super giant) Star": "M (Red super giant) Star",
      "MS-type Star": "MS-type Star",
      "Metal-rich body": "Metal-rich body",
      "Neutron Star": "Neutron Star",
      "O (Blue-White) Star": "O (Blue-White) Star",
      "Rocky Ice world": "Rocky Ice world",
      "Rocky body": "Rocky body",
      "S-type Star": "S-type Star",
      "Supermassive Black Hole": "Supermassive Black Hole",
      "T (Brown dwarf) Star": "T (Brown dwarf) Star",
      "T Tauri Star": "T Tauri Star",
      "Water giant": "Water giant",
      "Water world": "Water world",
      "White Dwarf (D) Star": "White Dwarf (D) Star",
      "White Dwarf (DA) Star": "White Dwarf (DA) Star",
      "White Dwarf (DAB) Star": "White Dwarf (DAB) Star",
      "White Dwarf (DAV) Star": "White Dwarf (DAV) Star",
      "White Dwarf (DAZ) Star": "White Dwarf (DAZ) Star",
      "White Dwarf (DB) Star": "White Dwarf (DB) Star",
      "White Dwarf (DBV) Star": "White Dwarf (DBV) Star",
      "White Dwarf (DBZ) Star": "White Dwarf (DBZ) Star",
      "White Dwarf (DC) Star": "White Dwarf (DC) Star",
      "White Dwarf (DCV) Star": "White Dwarf (DCV) Star",
      "White Dwarf (DQ) Star": "White Dwarf (DQ) Star",
      "Wolf-Rayet C Star": "Wolf-Rayet C Star",
      "Wolf-Rayet N Star": "Wolf-Rayet N Star",
      "Wolf-Rayet NC Star": "Wolf-Rayet NC Star",
      "Wolf-Rayet O Star": "Wolf-Rayet O Star",
      "Wolf-Rayet Star": "Wolf-Rayet Star",
      "Y (Brown dwarf) Star": "Y (Brown dwarf) Star"
    },
    "system": {
      "bodies": "Corpi ({size})",
      "bodies_with_total": "Corpi ({size}/{total})",
      "factions": "Fazioni ({size})",
      "no_id64": "Nessun ID di sistema fornito",
      "not_found": "Sistema non trovato",
      "stations": "Stazioni ({size})",
      "system": "Sistema",
      "title": "Sistema",
      "updated_at": "Aggiornamento {relative_updated_at} ({updated_at})"
    },
    "terraforming_state": {
      "Candidate for terraforming": "Candidato per la terraformazione",
      "Not terraformable": "Non terraformabile",
      "Terraformable": "Candidato per la terraformazione",
      "Terraformed": "Terraformato",
      "Terraforming": "Terraforming2"
    },
    "thanks": {
      "description": "Molte delle funzionalità di questo sito non sarebbero state possibili senza l'ampio aiuto che ho ricevuto. Questo elenco non è esaustivo e sono grato a tutti coloro che hanno aiutato. Se ritieni che qualcuno sia stato escluso, contattami.",
      "developers": {
        "description": "Gli altri sviluppatori che hanno aiutato fornendo dump di dati, informazioni e consigli.",
        "title": "Sviluppatori"
      },
      "patreon": {
        "description": "I sostenitori che hanno contribuito ai costi di hosting e sviluppo.",
        "title": "Patrons"
      },
      "players": {
        "description": "Vorrei ringraziare tutti i giocatori e gli esploratori che gestiscono i vari strumenti di raccolta dati che ci consentono di raccogliere informazioni sulla galassia il che rende possibili questi siti, ma alcuni sono andati oltre.",
        "title": "Players"
      },
      "title": "Grazie",
      "translators": {
        "description": "I traduttori che hanno realizzato le versioni in lingua madre di questo sito.",
        "title": "Traduttori"
      }
    },
    "titles": {
      "admin": "Admin",
      "ammonia": "Ammonia Worlds",
      "ammonia_results": "Ammonia Worlds Route",
      "bad_data": "Segnala un problema con i dati",
      "body": "Corpo {body}",
      "body_search": "Ricerca corpo",
      "body_search_results": "Risultati della ricerca del corpo",
      "community-goals": "Obiettivi della Community",
      "dumps": "Data Dumps",
      "earth": "Earth-like Worlds",
      "earth_results": "Earth-like World Route",
      "engineer_plotter": "Ingegnere plotter",
      "engineer_plotter_results": "Engineer Plotter Route",
      "exact_plotter": "Galaxy Plotter",
      "exact_plotter_results": "Risultati del Plotter Results",
      "exobiology": "Expressway to Exomastery",
      "exobiology_results": "Risultati del Navigatore per l'Esomaestria",
      "fleet_carrier": "Fleet Carrier",
      "fleet_carrier_results": "Fleet Carrier Route",
      "list_bad_data": "Lista di rapporti sui dati non validi",
      "main": "Elite: Dangerous Indice",
      "nearest": "Nearest Known System",
      "plotter": "Neutron Plotter",
      "plotter_results": "Neutron Plotter Route",
      "riches": "Road to Riches",
      "riches_results": "Road to Riches Route",
      "scanned-bodies": "Corpi Scansionati",
      "search": "Ricerca",
      "station": "Stazione {station}",
      "station_search": "Ricerca stazione",
      "station_search_results": "Risultati della Ricerca stazione",
      "system": "Sistema {system}",
      "system_search": "Ricerca Sistema",
      "system_search_results": "Risultati della Ricerca Sistema",
      "thanks": "Ringraziamenti",
      "tourist": "Tourist Planner",
      "tourist_results": "Tourist Planner Route",
      "trade": "Trade Planner",
      "trade-to-system": "Commercio col Sistema",
      "trade-to-system_results": "Risultati del Commercio col Sistema",
      "trade_results": "Risultati del Trade Planner",
      "user": "Impostazioni Utente"
    },
    "tourist": {
      "description": "Questa pagina ti consentirà di pianificare un percorso tra diversi sistemi che desideri visitare in un ordine ottimale.",
      "import_destinations": "Importa destinazioni",
      "import_destinations_description": "Importa un file waypoint da EDSM come insieme di destinazioni (un semplice file di testo con un nome di sistema per riga).",
      "importing": "Importazione delle destinazioni",
      "title": "Tourist Router"
    },
    "trade": {
      "copy_destination_to_clipboard": "Copia il nome del sistema di destinazione {system} negli appunti",
      "copy_source_to_clipboard": "Copia il nome del sistema di partenza {system} negli appunti",
      "distance": "{distance} LY",
      "finishing_system": "Quindi vola {distance} LY a {station} in <a href=\"{link}\">{system}</a>",
      "finishing_system_link": "Quindi vola a {distance} LY verso <a href=\"{stationLink}\">{station}</a> in <a href=\"{link}\">{system}</a>",
      "first_system": "A partire da {station} (aggiornato {updated_at}) in <a href=\"{link}\">{system}</a>",
      "first_system_link": "A partire da <a href=\"{stationLink}\">{station}</a> (aggiornato {updated_at}) in <a href=\"{link}\">{system}</a>",
      "flying_instructions": "Vola a {distance}LS ({time} tempo di volo) fino a {station}.",
      "flying_instructions_link": "Vola a {distance}LS ({time} tempo di volo) fino a <a href=\"{stationLink}\">{station}</a>.",
      "no_results": "Impossibile trovare rotte commerciali",
      "purchase_instructions": "Vendi tutto ciò che hai in stiva e acquista le materie prime elencate di seguito.",
      "route": "Vola a {distance} LY da {start_station} in {start_system} a {end_station} in {end_system}",
      "source_required": "È richiesto il sistema di partenza",
      "starting_system": "{station} (aggiornato {updated_at}) in <a href=\"{link}\">{system}</a>",
      "starting_system_link": "<a href=\"{stationLink}\">{station}</a> (aggiornato {updated_at}) in <a href=\"{link}\">{system}</a>",
      "title": "Trade Planner"
    },
    "trade-to-system": {
      "title": "Commercia con {station} in {system}"
    },
    "type": {
      "Planet": "Pianeta",
      "Star": "Stella"
    },
    "unknown": "Sconosciuto",
    "user": {
      "settings": "Impostazioni Utente",
      "update_scanned_bodies": "Aggiorna {scanType} corpi scansionati"
    },
    "volcanism_type": {
      "Ammonia Magma": "Ammonia Magma",
      "Carbon Dioxide Geysers": "Carbon Dioxide Geysers",
      "Major Carbon Dioxide Geysers": "Major Carbon Dioxide Geysers",
      "Major Metallic Magma": "Major Metallic Magma",
      "Major Rocky Magma": "Major Rocky Magma",
      "Major Silicate Vapour Geysers": "Major Silicate Vapour Geysers",
      "Major Water Geysers": "Major Water Geysers",
      "Major Water Magma": "Major Water Magma",
      "Metallic Magma": "Metallic Magma",
      "Methane Magma": "Methane Magma",
      "Minor Ammonia Magma": "Minor Ammonia Magma",
      "Minor Carbon Dioxide Geysers": "Minor Carbon Dioxide Geysers",
      "Minor Metallic Magma": "Minor Metallic Magma",
      "Minor Methane Magma": "Minor Methane Magma",
      "Minor Nitrogen Magma": "Minor Nitrogen Magma",
      "Minor Rocky Magma": "Minor Rocky Magma",
      "Minor Silicate Vapour Geysers": "Minor Silicate Vapour Geysers",
      "Minor Water Geysers": "Minor Water Geysers",
      "Minor Water Magma": "Minor Water Magma",
      "Nitrogen Magma": "Nitrogen Magma",
      "No volcanism": "No volcanism",
      "Rocky Magma": "Rocky Magma",
      "Silicate Vapour Geysers": "Silicate Vapour Geysers",
      "Water Geysers": "Water Geysers",
      "Water Magma": "Water Magma"
    },
    "yes": "Si"
  }], ["pt-br", {
    "allegiance": {
      "Alliance": "Aliança",
      "Empire": "Império",
      "Federation": "Federação",
      "Independent": "Independente",
      "None": "Nenhuma",
      "Pilots Federation": "Federação de Pilotos"
    },
    "atmosphere": {
      "Ammonia": "Amônia",
      "Ammonia and Oxygen": "Amônia e oxigênio",
      "Ammonia-rich": "Rica em amonia",
      "Argon": "Argônio",
      "Argon-rich": "Rica em argônio",
      "Carbon dioxide": "Dióxido de carbono",
      "Carbon dioxide-rich": "Rica em dióxido de carbono",
      "Helium": "Hélio",
      "Hot Argon": "Argônio quente",
      "Hot Argon-rich": "Rico em argônio quente",
      "Hot Carbon dioxide": "Dióxido de carbono quente",
      "Hot Carbon dioxide-rich": "Rica em dióxido de carbono quente",
      "Hot Silicate vapour": "Vapor quente de silicatos",
      "Hot Sulphur dioxide": "Dióxido de enxofre quente",
      "Hot Water": "Água quente",
      "Hot Water-rich": "Rica em água quente",
      "Hot thick Ammonia": "Amônia quente espessa",
      "Hot thick Ammonia-rich": "Rica em amônia quente espessa",
      "Hot thick Argon": "Argônio quente espesso",
      "Hot thick Argon-rich": "Rica em argônio quente espesso",
      "Hot thick Carbon dioxide": "Dióxido de carbono quente espesso",
      "Hot thick Carbon dioxide-rich": "Rica em dióxido de carbono quente espesso",
      "Hot thick Metallic vapour": "Vapor metálico quente espesso",
      "Hot thick Methane": "Metano quente espesso",
      "Hot thick Methane-rich": "Rico em metano quente espesso",
      "Hot thick Nitrogen": "Nitrogênio quente espesso",
      "Hot thick No atmosphere": "Sem atmosfera quente espesso",
      "Hot thick Silicate vapour": "Vapor de silicato quente espesso",
      "Hot thick Sulphur dioxide": "Dióxido de enxofre quente espesso",
      "Hot thick Water": "Água quente espessa",
      "Hot thick Water-rich": "Rica em água quente espessa",
      "Hot thin Carbon dioxide": "Dióxido de carbono quente rarefeito",
      "Hot thin Metallic vapour": "Vapor metálico quente rarefeito",
      "Hot thin Silicate vapour": "Vapor de silicato quente rarefeito",
      "Hot thin Sulphur dioxide": "Dióxido de enxofre quente rarefeito",
      "Metallic vapour": "Vapor metálico",
      "Methane": "Metano",
      "Methane-rich": "Rico em metano",
      "Neon": "Neon",
      "Neon-rich": "Rico em neon",
      "Nitrogen": "Nitrogênio",
      "No atmosphere": "Sem atmosfera",
      "Oxygen": "Oxigênio",
      "Silicate vapour": "Vapor de silicato",
      "Suitable for water-based life": "Apropriado para vida baseada em água",
      "Sulphur dioxide": "Dióxido de enxofre",
      "Thick Ammonia": "Amônia espessa",
      "Thick Ammonia and Oxygen": "Amônia e oxigênio espesso",
      "Thick Ammonia-rich": "Rica em amônia espessa",
      "Thick Argon": "Argônio espesso",
      "Thick Argon-rich": "Rica em argônio espesso",
      "Thick Carbon dioxide": "Dióxido de carbono espesso",
      "Thick Carbon dioxide-rich": "Rica em dióxido de carbono espesso",
      "Thick Helium": "Hélio espesso",
      "Thick Methane": "Metano espesso",
      "Thick Methane-rich": "Rica em metano espesso",
      "Thick Nitrogen": "Nitrogênio espesso",
      "Thick No atmosphere": "Sem atmosfera espessa",
      "Thick Suitable for water-based life": "Espessa apropriada para vida baseada em água",
      "Thick Sulphur dioxide": "Dióxido de enxofre espesso",
      "Thick Water": "Água espessa",
      "Thick Water-rich": "Rica em água espessa",
      "Thin Ammonia": "Amônia rarefeita",
      "Thin Ammonia and Oxygen": "Amônia e oxigênio rarefeito",
      "Thin Ammonia-rich": "Rica em amônia rarefeita",
      "Thin Argon": "Argônio rarefeito",
      "Thin Argon-rich": "Rica em argônio rarefeito",
      "Thin Carbon dioxide": "Dióxido de carbono rarefeito",
      "Thin Carbon dioxide-rich": "Rica em dióxido de carbono rarefeito",
      "Thin Helium": "Hélio rarefeito",
      "Thin Methane": "Metano rarefeito",
      "Thin Methane-rich": "Rico em metano rarefeito",
      "Thin Neon": "Neon rarefeito",
      "Thin Neon-rich": "Rica em neon rarefeito",
      "Thin Nitrogen": "Nitrogênio rarefeito",
      "Thin No atmosphere": "Sem atmosfera rarefeita",
      "Thin Oxygen": "Oxigênio rarefeito",
      "Thin Sulphur dioxide": "Dióxido de enxofre rarefeito",
      "Thin Water": "Água rarefeita",
      "Thin Water-rich": "Rica em água rarefeita",
      "Water": "Água",
      "Water-rich": "Rico em água"
    },
    "atmosphere_composition": {
      "Ammonia": "Amônia",
      "Argon": "Argônio",
      "Carbon dioxide": "Dióxido de carbono",
      "Helium": "Hélio",
      "Hydrogen": "Hidrogênio",
      "Iron": "Ferro",
      "Methane": "Metano",
      "Neon": "Neon",
      "Nitrogen": "Nitrogênio",
      "Oxygen": "Oxigênio",
      "Silicates": "Silicatos",
      "Sulphur dioxide": "Dióxido de enxofre",
      "Water": "Água"
    },
    "boolean": {
      "Any": "Qualquer",
      "No": "Não",
      "Yes": "Sim"
    },
    "economy": {
      "Agriculture": "Agricultura",
      "Colony": "Colônia",
      "Extraction": "Extração",
      "High Tech": "Alta Tecnologia",
      "Industrial": "Industrial",
      "Military": "Militar",
      "None": "Nenhuma",
      "Refinery": "Refinaria",
      "Service": "Serviço",
      "Terraforming": "Terraformação",
      "Tourism": "Turismo"
    },
    "fields": {
      "add_atmosphere_composition_filter": "Adicionar filtro de composição da atmosfera",
      "add_material_filter": "Adicionar filtro de material",
      "add_sort_field": "Adicionar campo de ordenação",
      "add_via": "Adicionar Parada",
      "allegiance": "Fidelidade",
      "atmosphere": "Atmosfera",
      "atmosphere_composition": "Composição da atmosfera",
      "atmosphere_composition_fields": "Composição da atmosfera",
      "atmosphere_compositions": "Composições da atmosfera",
      "calculate": "Calcular",
      "completed": "Completado",
      "controlling_minor_faction": "Facção Controladora",
      "delete_via": "Apagar Parada",
      "destination_system": "Sistema de Destino",
      "distance": "Distância (LY)",
      "distance_from_coords": "Distância (AL)",
      "distance_to_arrival": "Distância para chegada (SL)",
      "distance_to_arrival_short": "Distância (SL)",
      "earth_masses": "Massas da Terra",
      "efficiency": "Eficiência (%)",
      "efficiency_description": "Aumente esse valor para reduzir a distância da rota principal que o calculador vai desviar para uma estrela de nêutron (uma eficiência de 100 não irá desviar da rota direta para calcular uma rota de A até B, e bem provavelmente vai quebrar a jornada em blocos de 20.000 AL).",
      "estimated_mapping_value": "Valor estimado do mapeamento",
      "estimated_mapping_value_short": "Valor do mapeamento",
      "estimated_scan_value": "Valor estimado do escaneamento",
      "estimated_scan_value_short": "Valor do escaneamento",
      "faction_presence": "Presença de Facção",
      "full_spectral_class": "Classe de espectro completa",
      "government": "Governo",
      "gravity": "Gravidade",
      "is_landable": "Pousável",
      "is_main_star": "Estrela Principal",
      "jumps": "Saltos",
      "jumps_description": "Número estimado de saltos para esse sistema.",
      "main_fields": "Campos Principais",
      "material": "Material",
      "material_fields": "Materiais",
      "materials": "Materiais",
      "maximum_distance": "Distância máxima até a chegada (SL)",
      "maximum_systems": "Máximo de Sistemas",
      "maximum_systems_description": "Esse é o número máximo de sistemas que o calculador irá te guiar, diminua esse número para uma viagem mais curta.",
      "minimum_scan_value": "Valor mínimo para escanear",
      "name": "Nome",
      "needs_permit": "Permissão Necessária",
      "neutron": "Nêutron",
      "neutron_description": "Essa estrela é uma estrela de nêutron",
      "population": "População",
      "power": "Poder",
      "power_state": "Estado do Poder",
      "primary_economy": "Economia",
      "radius": "Raio",
      "range": "Alcance (AL)",
      "range_description": "O alcance da sua nave",
      "reference_coordinates": "Coordenadas de Referência",
      "reference_system": "Sistema de Referência",
      "remaining": "Restante (LY)",
      "reserve_level": "Nível de reserva",
      "reset": "Reiniciar",
      "search": "Pesquisa",
      "search_radius": "Raio (AL)",
      "search_radius_description": "Essa é a distância em AL que o calculador irá usar para procurar mundos valiosos para visita no raio.",
      "secondary_economy": "Economia secundária",
      "security": "Segurança",
      "select_allegiance": "Selecione a Fidelidade",
      "select_atmosphere": "Selecione atmosfera",
      "select_controlling_minor_faction": "Selecione a Facção Controladora",
      "select_faction_presence": "Selecione a Facção Presente",
      "select_full_spectral_class": "Selecione classe de espectro completa",
      "select_government": "Selecione o Governo",
      "select_power": "Selecione o Poder",
      "select_power_state": "Select o Estado do Power",
      "select_primary_economy": "Selecione a Economia",
      "select_reference_system": "Selecione o Sistema de Referência",
      "select_reserve_level": "Selecione o nível de reserva",
      "select_secondary_economy": "Selecione a economia secundária",
      "select_security": "Selecione a Segurança",
      "select_spectral_class": "Selecione classe de espectro",
      "select_state": "Selecione o Estado",
      "select_subtype": "Selecione o subtipo",
      "select_terraforming_state": "Selecione estado da terraformação",
      "select_type": "Selecione tipo",
      "select_volcanism_type": "Selecione vulcanismo",
      "sorting": "Campos de ordenação",
      "sorting_fields": "Ordenação",
      "source_system": "Sistema de Origem",
      "spectral_class": "Classe de espectro",
      "state": "Estado",
      "subtype": "Subtipo",
      "surface_temperature": "Temperatura da superfície (K)",
      "system_name": "Nome do Sistema",
      "terraforming_description": "Esse corpo é candidato à terraformação?",
      "terraforming_short": "Terra",
      "terraforming_state": "Estado da terraformação",
      "type": "Tipo",
      "use_mapping_value": "Use o valor do mapeamento",
      "use_mapping_value_description": "Utilize o valor do mapeamento em vez do valor de escaneamento",
      "via": "Parada",
      "volcanism_type": "Vulcanismo",
      "x": "X",
      "x_coordinate": "Coordenada X",
      "y": "Y",
      "y_coordinate": "Coordenada Y",
      "z": "Z",
      "z_coordinate": "Coordenada Z"
    },
    "fields_help": {
      "body_name": "Use este campo para filtrar por corpos com palavras em comum. Ex.: Sol resultaria em Sol, mas não Solati 6.",
      "reference_coords": "Use este campo ao invés de Sistema de Referência para indicar a partir de quais coordenadas você deseja executar a busca.",
      "reference_system": "Use este campo para indicar a partir de qual sistema você deseja executar a busca.",
      "system_name": "Use este campo para filtrar corpos no sistema com palavras em comum. Ex.: Col resulta em Col 285 Sector IY-W b16-6, mas não Colonia."
    },
    "footer": {
      "copyright": "Copyright {from}-{to} Gareth Harper.",
      "donations": "Se gostaria de fazer uma doação para manter esse site funcionando, visite a minha página do Patreon <a href=\"https://www.patreon.com/spansh\">aqui</a>.",
      "features": "Se você tiver solicitações de recursos ou se encontrar bugs, você pode visitar a <a href=\"https://forums.frontier.co.uk/threads/neutron-highway-long-range-route-planner.308674/\">página do fórum</a> dessa ferramenta ou mandar um e-mail para <a href=\"mailto:plotter@spansh.co.uk\">plotter@spansh.co.uk</a>.",
      "thanks": "Agradeço ao <a href=\"https://edsm.net\">Elite: Dangerous Star Map</a> e <a href=\"https://eddb.io\">Elite: Dangerous Database</a> por fornecer as informações que alimentam esse site."
    },
    "government": {
      "Anarchy": "Anarquia",
      "Communism": "Comunismo",
      "Confederacy": "Confederação",
      "Cooperative": "Cooperativa",
      "Corporate": "Corporação",
      "Democracy": "Democracia",
      "Dictatorship": "Ditadura",
      "Feudal": "Feudal",
      "None": "Nenhum",
      "Patronage": "Patrocínio",
      "Prison": "Prisão",
      "Prison colony": "Colônia Prisional",
      "Theocracy": "Teocracia"
    },
    "map": {
      "description": "Um mapa com todas as estrelas de nêutron conhecidas na galáxia",
      "title": "Mapa de Estrelas de Nêutron"
    },
    "material": {
      "Antimony": "Antimônio",
      "Arsenic": "Arsênico",
      "Cadmium": "Cádmio",
      "Carbon": "Carbono",
      "Chromium": "Cromo",
      "Germanium": "Germânio",
      "Iron": "Ferro",
      "Manganese": "Manganês",
      "Mercury": "Mercúrio",
      "Molybdenum": "Molibdênio",
      "Nickel": "Níquel",
      "Niobium": "Nióbio",
      "Phosphorus": "Fósforo",
      "Polonium": "Polônio",
      "Ruthenium": "Rutênio",
      "Selenium": "Selênio",
      "Sulphur": "Enxofre",
      "Technetium": "Tecnécio",
      "Tellurium": "Telúrio",
      "Tin": "Estanho",
      "Tungsten": "Tungstênio",
      "Vanadium": "Vanádio",
      "Yttrium": "Ítrio",
      "Zinc": "Zinco",
      "Zirconium": "Zircônio"
    },
    "navigation": {
      "bodies": "Corpos",
      "language": "Idioma",
      "login": "Logar",
      "logout": "Deslogar",
      "map": "Mapa",
      "nearest": "Sistema mais próximio",
      "oldsite": "Site antigo",
      "plotter": "Calculador",
      "riches": "Caminho para as riquezas",
      "settings": "Configurações",
      "stations": "Estações",
      "systems": "Sistemas",
      "thanks": "Agradecimentos",
      "theme": "Tema"
    },
    "nearest": {
      "description": "Essa página permite encontrar o sistema conhecido mais próximo a um conjunto de coordenadas.",
      "title": "Sistema conhecido mais próximo"
    },
    "plotter": {
      "description": "Essa página permite que você calcule a rota entre dois sistemas estelares diferentes. O resultado irá mostrar toda vez em que o mapa da galáxia deve ser aberto para calcular uma nova rota (por exemplo, quando estiver em uma estrela de nêutron). Estrelas de nêutron serão priorizadas, dessa forma essa ferramenta deve ser evitada caso a jornada tenha menos de 500 anos-luz (Você bem provavelmente será redirecionado para Jackson's Lighthouse primeiro se estiver na bolha da civilização).",
      "title": "Calculador de rotas de estrelas de nêutron",
      "warning": "Se a sua capacidade máxima de salto for baixa (entre 10-20aL) você pode acabar indo para um sistema da qual não terá como sair. Seja cuidadoso quando traçar seus saltos e verifique se poderá sair do sistema quando estiver no mapa da galáxia"
    },
    "power_state": {
      "Contested": "Contestado",
      "Control": "Controle",
      "Controlled": "Controlado",
      "Exploited": "Explorado",
      "HomeSystem": "Sistema natal",
      "InPrepareRadius": "No raio de preparo",
      "Prepared": "Preparado",
      "Turmoil": "Turbulência"
    },
    "reserve_level": {
      "Common": "Comum",
      "Depleted": "Esgotada",
      "Low": "Pequena",
      "Major": "Maior",
      "Pristine": "Primitiva"
    },
    "results": {
      "atmosphere_composition_to_show": "Composição da atmosfera para exibir",
      "bodies_count": "{count} corpos",
      "copy_to_clipboard": "Copiar para a área de transferência",
      "fields_to_show": "Campos para exibir",
      "first": "Primeiro",
      "galaxy_map_visits": "Números de visitas ao mapa da galáxia necessárias: {total_visits}",
      "last": "Último",
      "materials_to_show": "Materiais para exibir",
      "next": "Próximo",
      "no_results": "Não há resultados para essa pesquisa",
      "previous": "Anterior",
      "results": "Resultados",
      "results_per_page": "Resultados por página",
      "show_route": "Mostrar rota",
      "showing": "Mostrando resultados {start} a {end} de {count}",
      "systems_count": "{count} sistemas",
      "totals": "Totais"
    },
    "riches": {
      "blank": "Deixe em branco para criar uma viagem de ida e volta iniciando no sistema de origem e voltando para o mesmo lugar no final.",
      "guides": "Você pode encontrar alguns guias de como usar os scanners de sistema e de superfície <a href=\"https://forums.frontier.co.uk/showthread.php/464149-The-FSS-A-pictoral-guide\">aqui</a> e <a href=\"https://forums.frontier.co.uk/showthread.php/465649-Setup-and-Use-of-the-FSS-and-DSS-With-Demonstration-Videos\">aqui (em inglês)</a>.",
      "title": "Caminho para as Riquezas",
      "warning": "Por favor, certifique-se de que possui um Scanner Detalhado de Superfície antes de embarcar em uma viagem. Um Scanner Avançado de Descoberta também facilita a localizar os corpos astronômicos para escanear dentro do sistema."
    },
    "security": {
      "Anarchy": "Anarquia",
      "High": "Alta",
      "Lawless": "Sem Lei",
      "Low": "Baixa",
      "Medium": "Média"
    },
    "settings": {
      "confirm_delete": "Confirma deletar usuário",
      "confirm_delete_warning": "Cuidado, se você continuar, a sua conta de usuários e dados associados setão deletados, incluindo mas não limitados aos dados de escaneamento do Caminho para as riquezas. Você pode recriar a sua conta de usuário associando a sua conta Frontier novamente, mas os seus dados salvos serào perdidos permanentemente e não poderão ser restaurados. Tem certeza que quer continuar?",
      "delete_help": "Delete a sua conta de usuário e quaisquer dados associados",
      "delete_user": "Deletar usuário"
    },
    "sorting": {
      "asc": "Ascendente",
      "desc": "Descendente"
    },
    "state": {
      "Boom": "Boom",
      "Bust": "Falência",
      "Civil liberty": "Liberdade civil",
      "Civil unrest": "Inquietação civil",
      "Civil war": "Guerra civil",
      "Election": "Eleição",
      "Expansion": "Expansão",
      "Famine": "Fome",
      "Investment": "Investimento",
      "Lockdown": "Quarentena",
      "None": "Nenhum",
      "Outbreak": "Surto",
      "Retreat": "Recuo",
      "War": "Guerra"
    },
    "subtype": {
      "A (Blue-White super giant) Star": "Estrela tipo A (Azul-branca super gigante)",
      "A (Blue-White) Star": "Estrela tipo A (Azul-branca)",
      "Ammonia world": "Mundo de amônia",
      "B (Blue-White) Star": "Estrela tipo B (Azul-branca)",
      "Black Hole": "Buraco negro",
      "C Star": "Estrela tipo C",
      "CJ Star": "Estrela tipo CJ",
      "CN Star": "Estrela tipo CN",
      "Class I gas giant": "Gigante gasoso de Classe I",
      "Class II gas giant": "Gigante gasoso de Classe II",
      "Class III gas giant": "Gigante gasoso de Classe III",
      "Class IV gas giant": "Gigante gasoso de Classe IV",
      "Class V gas giant": "Gigante gasoso de Classe V",
      "Earth-like world": "Mundo tipo Terra",
      "F (White super giant) Star": "Estrela tipo F (Branca super gigante)",
      "F (White) Star": "Estrela tipo F (Branca)",
      "G (White-Yellow super giant) Star": "Estrela tipo G (Branca-amarela super gigante)",
      "G (White-Yellow) Star": "Estrela tipo B (Branca-amarela)",
      "Gas giant with ammonia-based life": "Gigante gasoso com vida baseada em amônia",
      "Gas giant with water-based life": "Gigante gasoso com vida baseada em água",
      "Helium gas giant": "Gigante gasoso de hélio",
      "Helium-rich gas giant": "Gigante gasoso rico em hélio",
      "Herbig Ae/Be Star": "Estrela tipo Herbig Ae/Be",
      "High metal content world": "Planeta rico em metais",
      "Icy body": "Corpo gelado",
      "K (Yellow-Orange giant) Star": "Estrela tipo K (Amarela-laranja gigante)",
      "K (Yellow-Orange) Star": "Estrela tipo K (Amarelo-Laranja)",
      "L (Brown dwarf) Star": "Estrela tipo L (Anã Marrom)",
      "M (Red dwarf) Star": "Estrela tipo M (Anã Vermelha)",
      "M (Red giant) Star": "Estrela tipo M (Gigante vermelha)",
      "M (Red super giant) Star": "Estrela tipo M (Vermelha super gigante)",
      "MS-type Star": "Estrela tipo MS",
      "Metal-rich body": "Corpo rico em metais",
      "Neutron Star": "Estrela de nêutron",
      "O (Blue-White) Star": "Estrela tipo O (Azul-branca)",
      "Rocky Ice world": "Planeta rochoso gelado",
      "Rocky body": "Corpo rochoso",
      "S-type Star": "Estrela tipo S",
      "Supermassive Black Hole": "Buraco Negro Supermassivo",
      "T (Brown dwarf) Star": "Estrela tipo T (Anã Marrom)",
      "T Tauri Star": "Estrela tipo T Tauri",
      "Water giant": "Gigante de água",
      "Water world": "Mundo de água",
      "White Dwarf (D) Star": "Estrela tipo D (Anã Branca)",
      "White Dwarf (DA) Star": "Estrela tipo DA (Anã Branca)",
      "White Dwarf (DAB) Star": "Estrela tipo DAB (Anã Branca)",
      "White Dwarf (DAV) Star": "Estrela tipo DAV (Anã Branca)",
      "White Dwarf (DAZ) Star": "Estrela tipo DAZ (Anã Branca)",
      "White Dwarf (DB) Star": "Estrela tipo DB (Anã Branca)",
      "White Dwarf (DBV) Star": "Estrela tipo DBV (Anã Branca)",
      "White Dwarf (DBZ) Star": "Estrela tipo DBZ (Anã Branca)",
      "White Dwarf (DC) Star": "Estrela tipo DC (Anã Branca)",
      "White Dwarf (DCV) Star": "Estrela tipo DCV (Anã Branca)",
      "White Dwarf (DQ) Star": "Estrela tipo DQ (Anã Branca)",
      "Wolf-Rayet C Star": "Estrela tipo C Wolf-Rayet",
      "Wolf-Rayet N Star": "Estrela tipo N Wolf-Rayet",
      "Wolf-Rayet NC Star": "Estrela tipo NC Wolf-Rayet",
      "Wolf-Rayet O Star": "Estrela tipo O Wolf-Rayer",
      "Wolf-Rayet Star": "Estrela tipo Wolf-Rayet",
      "Y (Brown dwarf) Star": "Estrela tipo Y (Anã Marrom)"
    },
    "terraforming_state": {
      "Candidate for terraforming": "Candidato à terraformação",
      "Not terraformable": "Não terraformável",
      "Terraformed": "Terraformado",
      "Terraforming": "Em terraformação"
    },
    "thanks": {
      "description": "Muitos recursos desse site não seriam possíveis sem a grande ajuda que recebo. Essa lista está longe de estar completa, mas sou grato por todos que ajudaram. Se achar que alguém não foi incluído, por favor entre em contato",
      "developers": {
        "description": "Os outros desenvolvedores que ajudaram.",
        "title": "Desenvolvedores"
      },
      "patreon": {
        "description": "Os patrons que contribuiram com os custos de hospedagem e desenvolviento.",
        "title": "Patrons"
      },
      "players": {
        "description": "Gostaria de agradecer a todos os jogadores e exploradores que usam as ferramentas de coleta de dados que nos permite coletar informação sobre a galáxia, o que faz sites como esse possíveis, mas alguns foram além.",
        "title": "Jogadores"
      },
      "title": "Obrigado",
      "translators": {
        "description": "Os tradutores que fizeram a versão desse site em sua língua materna.",
        "title": "Tradutores"
      }
    },
    "type": {
      "Planet": "Planeta",
      "Star": "Estrela"
    },
    "volcanism_type": {
      "Ammonia Magma": "Magma de amônia",
      "Carbon Dioxide Geysers": "Gêiseres de dióxido de carbono",
      "Major Carbon Dioxide Geysers": "Gêiseres de dióxido de carbono abundantes",
      "Major Metallic Magma": "Magma metálico abundante",
      "Major Rocky Magma": "Magma rochoso abundante",
      "Major Silicate Vapour Geysers": "Gêiseres de vapor de silicato abundantes",
      "Major Water Geysers": "Gêiseres de água abundantes",
      "Major Water Magma": "Magma de água abundante",
      "Metallic Magma": "Magma metálico",
      "Methane Magma": "Magma de metano",
      "Minor Ammonia Magma": "Pouco magma de amônia",
      "Minor Carbon Dioxide Geysers": "Poucos gêiseres de dióxido de carbono",
      "Minor Metallic Magma": "Pouco magma metálico",
      "Minor Methane Magma": "Pouco magma de metano",
      "Minor Nitrogen Magma": "Pouco magma de nitrogênio",
      "Minor Rocky Magma": "Pouco magma metálico",
      "Minor Silicate Vapour Geysers": "Poucos gêiseres de vapor de silicato",
      "Minor Water Geysers": "Poucos gêiseres de água",
      "Minor Water Magma": "Pouco magma de água",
      "Nitrogen Magma": "Magma de nitrogênio",
      "No volcanism": "Sem vulcanismo",
      "Rocky Magma": "Magma rochoso",
      "Silicate Vapour Geysers": "Gêiseres de vapor de silicato",
      "Water Geysers": "Gêiseres de água",
      "Water Magma": "Magma de água"
    },
    "yes": "Sim"
  }], ["ru", {
    "admin": {
      "list_bad_data": "List Bad Data Reports"
    },
    "allegiance": {
      "Alliance": "Альянс",
      "Empire": "Империя",
      "Federation": "Федерация",
      "Guardian": "Стражи",
      "Independent": "Независимые",
      "None": "Нет",
      "Pilots Federation": "Федерация пилотов",
      "Player Pilots": "Игроки",
      "Thargoid": "Таргоиды"
    },
    "ammonia": {
      "blank": "Оставьте систему назначения пустой для того, чтобы создать кольцевой маршрут, начинающийся и заканчивающийся в вашей стартовой системе.",
      "guides": "Дополнительную информацию о цели сообщества можно найти <a href=\"https://www.elitedangerous.com/community/goals/\">здесь</a> и <a href=\"https://forums.frontier.co.uk/threads/support-sirius-atmospherics-initiative-by-providing-ammonia-world-exploration-data-exploration.559984/\">здесь</a>",
      "low_results": "Количество систем меньше, чем вы запросили. Попробуйте увеличить радиус поиска, чтобы найти больше тел. Если вы всё ещё не получаете достаточно результатов, то возможно вы выставили слишком высокую минимальную цену сканирования.",
      "title": "Путь аммиачных миров",
      "warning": "Этот маршрутизатор позволит вам находить миры аммиака вокруг вашего текущего местоположения для целей сообщества. Он будет отдавать приоритет высокой ценности сканирования, поэтому, если вы находите только далекие миры, вы можете уменьшить радиус поиска."
    },
    "atmosphere": {
      "Ammonia": "Аммиачная",
      "Ammonia and Oxygen": "Аммиачно-кислородная",
      "Ammonia-rich": "Богатая аммиаком",
      "Argon": "Аргоновая",
      "Argon-rich": "Богатая аргоном",
      "Carbon dioxide": "Углекислотная",
      "Carbon dioxide-rich": "Богатая углекислотой",
      "Helium": "Гелий",
      "Hot Argon": "Горячая аргоновая",
      "Hot Argon-rich": "Горячая богатая аргоном",
      "Hot Carbon dioxide": "Горячая углекислотная",
      "Hot Carbon dioxide-rich": "Горячая богатая углекислотой",
      "Hot Metallic vapour": "Горячие пары металлов",
      "Hot Silicate vapour": "Горячие силикатные пары",
      "Hot Sulphur dioxide": "Горячая из двуокиси серы",
      "Hot Water": "Горячая водная",
      "Hot Water-rich": "Горячая богатая водой",
      "Hot thick Ammonia": "Горячая плотная аммиачная",
      "Hot thick Ammonia-rich": "Горячая плотная богатая аммиаком",
      "Hot thick Argon": "Горячая плотная аргоновая",
      "Hot thick Argon-rich": "Горячая плотная богатая аргоном",
      "Hot thick Carbon dioxide": "Горячая плотная углекислотная",
      "Hot thick Carbon dioxide-rich": "Горячая плотная богатая углекислотой",
      "Hot thick Metallic vapour": "Горячая плотная из металлических паров",
      "Hot thick Methane": "Горячая плотная метановая",
      "Hot thick Methane-rich": "Горячая плотная богатая метаном",
      "Hot thick Nitrogen": "Горячая плотная азотная",
      "Hot thick No atmosphere": "Нет атмосферы",
      "Hot thick Silicate vapour": "Горячая плотная силикатная",
      "Hot thick Sulphur dioxide": "Горячая плотная сернистая",
      "Hot thick Water": "Горячая плотная водная",
      "Hot thick Water-rich": "Горячая плотная богатая водой",
      "Hot thin Carbon dioxide": "Горячая разреженная углекислотная",
      "Hot thin Metallic vapour": "Горячая разреженная металлическая",
      "Hot thin Silicate vapour": "Горячая разреженная силикатная",
      "Hot thin Sulphur dioxide": "Горячая разреженная сернистая",
      "Metallic vapour": "Металлические пары",
      "Methane": "Метан",
      "Methane-rich": "Богатая метаном",
      "Neon": "Неон",
      "Neon-rich": "Богатая неоном",
      "Nitrogen": "Азот",
      "No atmosphere": "Без атмосферы",
      "Oxygen": "Кислород",
      "Silicate vapour": "Силикатная",
      "Suitable for water-based life": "Пригодная для органической жизни",
      "Sulphur dioxide": "Оксид серы",
      "Thick Ammonia": "Плотная аммиачная",
      "Thick Ammonia and Oxygen": "Плотная кислородно-аммиачная",
      "Thick Ammonia-rich": "Плотная богатая аммиаком",
      "Thick Argon": "Плотная аргоновая",
      "Thick Argon-rich": "Плотная богатая аргоном",
      "Thick Carbon dioxide": "Плотная углекислотная",
      "Thick Carbon dioxide-rich": "Плотная богатая углекислотой",
      "Thick Helium": "Плотная гелиевая",
      "Thick Methane": "Плотная метановая",
      "Thick Methane-rich": "Плотная богатая метаном",
      "Thick Nitrogen": "Плотная азотная",
      "Thick No atmosphere": "Без атмосферы",
      "Thick Suitable for water-based life": "Плотная пригодная для жизни",
      "Thick Sulphur dioxide": "Плотная сернистая",
      "Thick Water": "Плотная водная",
      "Thick Water-rich": "Плотная богатая водой",
      "Thin Ammonia": "Плотная аммиачная",
      "Thin Ammonia and Oxygen": "Плотная аммиачно-кислородная",
      "Thin Ammonia-rich": "Разреженная богатая аммиаком",
      "Thin Argon": "Разреженная аргоновая",
      "Thin Argon-rich": "Разреженная богатая аргоном",
      "Thin Carbon dioxide": "Разреженная углекислотная",
      "Thin Carbon dioxide-rich": "Разреженная богатая углекислотой",
      "Thin Helium": "Разреженная гелиевая",
      "Thin Methane": "Разреженная метановая",
      "Thin Methane-rich": "Разреженная богатая метаном",
      "Thin Neon": "Плотная неоновая",
      "Thin Neon-rich": "Плотная богатая неоном",
      "Thin Nitrogen": "Разреженная азотная",
      "Thin No atmosphere": "Нет атмосферы",
      "Thin Oxygen": "Разреженная кислородная",
      "Thin Sulphur dioxide": "Разреженная сернистая",
      "Thin Water": "Разреженная водная",
      "Thin Water-rich": "Разреженная богатая водой",
      "Water": "Вода",
      "Water-rich": "Богатая водой"
    },
    "atmosphere_composition": {
      "Ammonia": "Аммиак",
      "Argon": "Аргон",
      "Carbon dioxide": "Диоксид углерода",
      "Helium": "Гелий",
      "Hydrogen": "Водород",
      "Iron": "Железо",
      "Methane": "Метан",
      "Neon": "Неон",
      "Nitrogen": "Азот",
      "Oxygen": "Кислород",
      "Silicates": "Силикаты",
      "Sulphur dioxide": "Диоксид серы",
      "Water": "Вода"
    },
    "bad_data": {
      "archive": "Archive",
      "description": "Report a problem with {system}",
      "error_description": "There was a problem with your submission, please report it using the contact details at the bottom of the page.",
      "reason": "Reason",
      "report": "Report problem with this system",
      "send": "Send report",
      "success_description": "Thank you for your submission, we'll take a look at it."
    },
    "belts": {
      "add_field": "Добавить фильтр",
      "inner_radius": "Внутренний радиус (м)",
      "mass": "Масса (Mt)",
      "outer_radius": "Внешний радиус (м)",
      "remove_field": "Убрать фильтр",
      "select_type": "Выбрать тип",
      "type": "Тип",
      "types": {
        "Icy": "Ледяная",
        "Metal Rich": "Богатая металлами",
        "Metallic": "Металлическая",
        "Rocky": "Каменистая"
      }
    },
    "body": {
      "belts": "Пояса",
      "body": "Тело",
      "composition": "Состав",
      "landmarks": "Наземные метки",
      "no_id64": "ID тела отсутствует",
      "not_found": "Тело не обнаружено",
      "rings": "Кольца",
      "signals": "Сигналы",
      "signals_updated_at": "Сигналы обновлены {relative_updated_at} ({updated_at})",
      "updated_at": "Обновлено {relative_updated_at} ({updated_at})"
    },
    "boolean": {
      "Any": "Любое",
      "No": "Нет",
      "Yes": "Да"
    },
    "categories": {
      "Chemicals": "Химикаты",
      "Consumer Items": "Потребительские товары",
      "Foods": "Еда",
      "Industrial Materials": "Индустриальные материалы",
      "Legal Drugs": "Легальные препараты",
      "Machinery": "Машинерия",
      "Medicines": "Медикаменты",
      "Metals": "Металлы",
      "Minerals": "Минералы",
      "Salvage": "Подобранное",
      "Slavery": "Работорговля",
      "Technology": "Технологии",
      "Textiles": "Текстиль",
      "Waste": "Отходы",
      "Weapons": "Оружие"
    },
    "commodities": {
      "AI Relics": "Фрагменты ИИ",
      "Advanced Catalysers": "Продвинутые катализаторы",
      "Advanced Medicines": "Продвинутые лекарства",
      "Aepyornis Egg": "Яйцо Эпиорниса",
      "Aganippe Rush": "Аганиппийский кайф",
      "Agri-Medicines": "Ветмедикаменты",
      "Agronomic Treatment": "Средство очистки почвы",
      "Alacarakmo Skin Art": "Алакаракмовские татуировки",
      "Albino Quechua Mammoth Meat": "Мясо белого кечуанского мамонта",
      "Alexandrite": "Александрит",
      "Algae": "Водоросли",
      "Altairian Skin": "Альтаирская кожа",
      "Aluminium": "Алюминий",
      "Alya Body Soap": "Алийское мыло для тела",
      "Ancient Artefact": "Древний артефакт",
      "Ancient Casket": "Шкатулка Стражей",
      "Ancient Key": "Древний ключ",
      "Ancient Orb": "Сфера Стражей",
      "Ancient Relic": "Реликвия Стражей",
      "Ancient Tablet": "Табличка Стражей",
      "Ancient Totem": "Тотем Стражей",
      "Ancient Urn": "Урна Стражей",
      "Anduliga Fire Works": "Андулигские фейерверки",
      "Animal Meat": "Мясо животных",
      "Animal Monitors": "Мониторы фауны",
      "Anomaly Particles": "Аномальные частицы",
      "Antimatter Containment Unit": "Контейнер с антиматерией",
      "Antique Jewellery": "Древние ювелирные украшения",
      "Antiquities": "Антиквариат",
      "Any Na Coffee": "Кофе Any Na",
      "Apa Vietii": "Apa Vietii",
      "Aquaponic Systems": "Аквапонные системы",
      "Arouca Conventual Sweets": "Монастырские сладости Ароуки",
      "Articulation Motors": "Шарнирные моторы",
      "Assault Plans": "Планы атак",
      "Atmospheric Processors": "Атмосферные процессоры",
      "Auto-Fabricators": "Автосинтезаторы",
      "Az Cancri Formula 42": "Формула 42 AZ Cancri",
      "Azure Milk": "Лазурное молоко",
      "Baked Greebles": "Печеные гриблы",
      "Baltah'sine Vacuum Krill": "Вакуумный криль Балта'сина",
      "Banki Amphibious Leather": "Кожа амфибии Банки",
      "Basic Medicines": "Основные медикаменты",
      "Bast Snake Gin": "Настойка из бастетовых змей",
      "Battle Weapons": "Боевое оружие",
      "Bauxite": "Боксит",
      "Beer": "Пиво",
      "Belalans Ray Leather": "Кожа белаланских скатов",
      "Benitoite": "Бенитоит",
      "Bertrandite": "Бертрандит",
      "Beryllium": "Бериллий",
      "Bioreducing Lichen": "Лишайник-биоредуктор",
      "Biowaste": "Биоотходы",
      "Bismuth": "Висмут",
      "Black Box": "Черный ящик",
      "Bootleg Liquor": "Ликер Бутлег",
      "Borasetani Pathogenetics": "Патогенетика Борасетани",
      "Bromellite": "Бромеллит",
      "Buckyball Beer Mats": "Пивные коврики Бакиболла",
      "Building Fabricators": "Строительные фабрикаторы",
      "Burnham Bile Distillate": "Дистиллят Бурнема Биле",
      "CD-75 Kitten Brand Coffee": " Кошачий кофе CD-75",
      "CMM Composite": "CMM-композит",
      "Centauri Mega Gin": " Кентаврский мега-джин",
      "Ceramic Composites": "Керамические композиты",
      "Ceremonial Heike Tea": " Церемониальный чай Heike",
      "Ceti Rabbits": " Кролики с Кита",
      "Chameleon Cloth": "Ткань-хамелеон",
      "Chateau De Aegaeon": "Шато де Эгеон",
      "Chemical Waste": "Химические отходы",
      "Cherbones Blood Crystals": "Кровяные кристаллы Шербона",
      "Chi Eridani Marine Paste": "Морская паста Chi Eridani",
      "Clothing": "Одежда",
      "Cobalt": "Кобальт",
      "Coffee": "Кофе",
      "Coltan": "Колтан",
      "Combat Stabilisers": "Боевые стабилизаторы",
      "Commercial Samples": "Коммерческие образцы",
      "Computer Components": "Компьютерные компоненты",
      "Conductive Fabrics": "Проводящие ткани",
      "Consumer Technology": "Потребительские технологии",
      "Copper": "Медь",
      "Coquim Spongiform Victuals": "Губковые продукты с Кокуима",
      "Crom Silver Fesh": "Кромская серебряная дурь",
      "Crop Harvesters": "Уборочные комбайны",
      "Cryolite": "Криолит",
      "Crystalline Spheres": "Прозрачные сферы",
      "Damaged Escape Pod": "Поврежденная спасательная капсула",
      "Damna Carapaces": "Панцири с Дамны",
      "Data Core": "Ядро данных",
      "Delta Phoenicis Palms": "Фениксовые пальмы с Дельты",
      "Deuringas Truffles": "Трюфели с Деуринги",
      "Diplomatic Bag": "Дипломатическая сумка",
      "Diso Ma Corn": "Зерна дисо ма",
      "Domestic Appliances": "Бытовая техника",
      "Duradrives": "Дюрадрайвы",
      "Earth Relics": "Реликвии с Земли",
      "Eden Apples of Aerial": "Райские яблоки с Эриала",
      "Eleu Thermals": "Элеусская теплая одежда",
      "Emergency Power Cells": "Аварийные энергоячейки",
      "Encrypted Correspondence": "Шифрованная переписка",
      "Encrypted Data Storage": "Зашифрованный носитель данных",
      "Energy Grid Assembly": "Электросеть в сборе",
      "Eranin Pearl Whisky": "Эранинские жемчужные виски",
      "Eshu Umbrellas": "Зонтики Эшу",
      "Esuseku Caviar": "Эсусекусская осетровая икра",
      "Ethgreze Tea Buds": "Чайные бутоны Этгриза",
      "Evacuation Shelter": "Эвакуационное убежище",
      "Exhaust Manifold": "Выпускной коллектор",
      "Experimental Chemicals": "Экспериментальные химикаты",
      "Explosives": "Взрывчатка",
      "Fish": "Рыба",
      "Food Cartridges": "Пищевые брикеты",
      "Fossil Remnants": "Ископаемые останки",
      "Fruit and Vegetables": "Фрукты и овощи",
      "Fujin Tea": "Фудзинский чай",
      "Galactic Travel Guide": "Путеводитель галактического путешественника",
      "Gallite": "Галлит",
      "Gallium": "Галлий",
      "Geawen Dance Dust": "Геавенская танцевальная пыль",
      "Gene Bank": "Генотека",
      "Geological Equipment": "Геологическое оборудование",
      "Geological Samples": "Образцы породы",
      "Gerasian Gueuze Beer": "Герасианское пиво гез",
      "Giant Irukama Snails": "Огромные улитки с Ирукамы",
      "Giant Verrix": "Гигант Веррикс",
      "Gilya Signature Weapons": "Именное оружие из системы Гилья",
      "Gold": "Золото",
      "Goman Yaupon Coffee": "Кофе Goman Yaupon",
      "Goslarite": "Госларит",
      "Grain": "Зерно",
      "Grandidierite": "Грандидиерит",
      "Guardian Casket": "Шкатулка стражей",
      "Guardian Orb": "Сфера стражей",
      "Guardian Relic": "Реликвия стражей",
      "Guardian Tablet": "Табличка стражей",
      "Guardian Totem": "Тотем стражей",
      "Guardian Urn": "Урна стражей",
      "H.E. Suits": "Защитные костюмы",
      "HIP 10175 Bush Meat": "Мясо лесной дичи с HIP 10175",
      "HIP 118311 Swarm": "Рой HIP 118311",
      "HIP Organophosphates": "Мощные органофосфаты",
      "HIP Proto-Squid": "Протокальмар с HIP",
      "HN Shock Mount": "Разрядная установка HN",
      "HR 7221 Wheat": "Пшеница HR 7221",
      "Hafnium 178": "Гафний-178",
      "Haiden Black Brew": "Гайдновский черный напиток",
      "Hardware Diagnostic Sensor": "Сенсор диагностики оборудования",
      "Harma Silver Sea Rum": "Ром \"Серебряное море Harma\"",
      "Havasupai Dream Catcher": "Хавасупайский ловец снов",
      "Heatsink Interlink": "Радиаторный соединитель",
      "Helvetitj Pearls": "Жемчуг с Хельветитч",
      "Holva Duelling Blades": "Хольванские дуэльные клинки",
      "Honesty Pills": "Пилюли честности",
      "Hostages": "Заложник",
      "Hydrogen Fuel": "Водородное топливо",
      "Hydrogen Peroxide": "Пероксид водорода",
      "Imperial Slaves": "Имперские рабы",
      "Indi Bourbon": "Инди-бурбон",
      "Indite": "Индит",
      "Indium": "Индий",
      "Insulating Membrane": "Изолирующая мембрана",
      "Ion Distributor": "Ионный распределитель",
      "Jadeite": "Жадеит",
      "Jaques Quinentian Still": "Дистиллятор Жака Квиненциана",
      "Jaradharre Puzzle Box": "Джарадхаррская головоломка",
      "Jaroua Rice": "Жаруйский рис",
      "Jotun Mookah": "Йотунская моока",
      "Kachirigin Filter Leeches": "Очищающие пиявки с Качиригина",
      "Kamitra Cigars": "Сигары Kamitra",
      "Kamorin Historic Weapons": "Каморинское историческое оружие",
      "Karetii Couture": "Модные вещи от Karetii",
      "Karsuki Locusts": "Саранча с Карсуки",
      "Kinago Violins": "Скрипки Kinago",
      "Kongga Ale": "Эль с Кунгги",
      "Koro Kung Pellets": "Корокунгские катышки",
      "LTT Hyper Sweet": "Гиперсладости LTT",
      "Land Enrichment Systems": "Системы обогащения почвы",
      "Landmines": "Мины",
      "Lanthanum": "Лантан",
      "Large Survey Data Cache": "Большой пакет с данными исследования",
      "Lavian Brandy": "Лавианский бренди",
      "Leather": "Кожа",
      "Leathery Eggs": "Кожистые яйца",
      "Leestian Evil Juice": "Леестийский сок зла",
      "Lepidolite": "Лепидолит",
      "Limpets": "Дроны",
      "Liquid oxygen": "Жидкий кислород",
      "Liquor": "Спиртное",
      "Lithium": "Литий",
      "Lithium Hydroxide": "Гидроксид лития",
      "Live Hecate Sea Worms": "Живые морские черви с Гекаты",
      "Low Temperature Diamonds": "Низкотемпературные алмазы",
      "Lucan Onionhead": "Луканская луковая головка",
      "Lyrae Weed": "Трава лираи",
      "Magnetic Emitter Coil": "Спираль магнитного излучателя",
      "Marine Equipment": "Морское оборудование",
      "Master Chefs": "Мастер-шефы",
      "Mechucos High Tea": "Мечукосский кайфочай",
      "Medb Starlube": "Звездная смазка с Медба",
      "Medical Diagnostic Equipment": "Диагностическое медоборудование",
      "Meta-Alloys": "Метасплавы",
      "Methane Clathrate": "Клатрат метана",
      "Methanol Monohydrate Crystals": "Кристаллы моногидрата метанола",
      "Micro Controllers": "Микроконтроллеры",
      "Micro-weave Cooling Hoses": "Шланги системы охлаждения малых диаметров",
      "Microbial Furnaces": "Микробные печи",
      "Military Grade Fabrics": "Ткани военного класса",
      "Military Intelligence": "Разведданные",
      "Military Plans": "Военные планы",
      "Mineral Extractors": "Экстракторы минералов",
      "Mineral Oil": "Нефтепродукты",
      "Modular Terminals": "Модульные терминалы",
      "Moissanite": "Муассанит",
      "Mokojing Beast Feast": "Мокоджингское зверское яство",
      "Mollusc Brain Tissue": "Ткань мозга моллюска",
      "Mollusc Fluid": "Физиологическая жидкость моллюска",
      "Mollusc Membrane": "Мембрана моллюска",
      "Mollusc Mycelium": "Мицелий моллюска",
      "Mollusc Soft Tissue": "Мягкие ткани моллюска",
      "Mollusc Spores": "Споры моллюска",
      "Momus Bog Spaniel": "Момусовский болотный спаниель",
      "Monazite": "Монацит",
      "Motrona Experience Jelly": "Мотронское желе",
      "Mukusubii Chitin-os": "Мукусубские хитинос",
      "Mulachi Giant Fungus": "Гигантские грибы с Мулачи",
      "Muon Imager": "Мюонное видеоустройство",
      "Musgravite": "Мусгравит",
      "Mysterious Idol": "Таинственный идол",
      "Nanobreakers": "Нанопрерыватели",
      "Nanomedicines": "Нанолекарства",
      "Narcotics": "Наркотики",
      "Natural Fabrics": "Натуральная ткань",
      "Neofabric Insulation": "Высокотехнологичная изоляция",
      "Neritus Berries": "Нерутские ягоды",
      "Nerve Agents": "Агенты нервно-паралитического действия",
      "Ngadandari Fire Opals": "Нгадандарийские огненные опалы",
      "Nguna Modern Antiques": "Современные древности с Нгуны",
      "Njangari Saddles": "Седла Njangari",
      "Non Euclidian Exotanks": "Неевклидовы экзобаки",
      "Non-Lethal Weapons": "Нелетальное оружие",
      "Occupied Escape Pod": "Спасательная капсула с пассажиром",
      "Ochoeng Chillies": "Перчик чили с Очоенга",
      "Onionhead": "Луковая головка",
      "Onionhead Alpha Strain": "Луковая головка, сорт альфа",
      "Onionhead Beta Strain": "Луковая головка, сорт бета",
      "Ophiuch Exino Artefacts": "Артефакты офиух экзино",
      "Orrerian Vicious Brew": "Orrerian Vicious Brew",
      "Osmium": "Осмий",
      "Painite": "Пейнит",
      "Palladium": "Палладий",
      "Pantaa Prayer Sticks": "Пантаские молитвенные палочки",
      "Pavonis Ear Grubs": "Павлинские ухочерви",
      "Performance Enhancers": "Стимуляторы",
      "Personal Effects": "Личные вещи",
      "Personal Gifts": "Персональные подарки",
      "Personal Weapons": "Личное оружие",
      "Pesticides": "Пестициды",
      "Platinum": "Платина",
      "Platinum Alloy": "Платиновый сплав",
      "Pod Core Tissue": "Ткань ядра семянки",
      "Pod Dead Tissue": "Мертвая ткань семянки",
      "Pod Mesoglea": "Мезоглея семянки",
      "Pod Outer Tissue": "Ткань оболочки семянки",
      "Pod Shell Tissue": "Ткань оболочки семянки",
      "Pod Surface Tissue": "Ткань поверхности семянки",
      "Pod Tissue": "Ткань семянки",
      "Political Prisoners": "Политический заключенный",
      "Polymers": "Полимеры",
      "Power Converter": "Преобразователь энергии",
      "Power Generators": "Электрогенераторы",
      "Power Transfer Bus": "Энергообменная шина",
      "Praseodymium": "Празеодим",
      "Precious Gems": "Драгоценные камни",
      "Progenitor Cells": "Прогениторные клетки",
      "Prohibited Research Materials": "Запретные материалы исследований",
      "Prototype Tech": "Экспериментальная техника",
      "Pyrophyllite": "Пирофиллит",
      "Radiation Baffle": "Отражатель излучения",
      "Rajukru Multi-Stoves": "Мультипечи Rajukru",
      "Rapa Bao Snake Skins": "Змеиные шкуры с Рапа Бао",
      "Rare Artwork": "Редкие произведения искусства",
      "Reactive Armour": "Реактивная защита",
      "Rebel Transmissions": "Переговоры повстанцев",
      "Reinforced Mounting Plate": "Усиленная монтажная плита",
      "Resonating Separators": "Резонансные сепараторы",
      "Rhodplumsite": "Родплумсайт",
      "Robotics": "Роботы",
      "Rockforth Fertiliser": "Удобрения Rockforth",
      "Rusani Old Smokey": "Старые папиросы Rusani",
      "Rutile": "Рутил",
      "SAP 8 Core Container": "Контейнер «SAP 8 Core»",
      "Samarium": "Самарий",
      "Sanuma Decorative Meat": "Декоративное мясо с Касанумы",
      "Saxon Wine": "Саксонское вино",
      "Scientific Research": "Материалы исследования",
      "Scientific Samples": "Научные образцы",
      "Scrap": "Утильсырье",
      "Semiconductors": "Полупроводники",
      "Serendibite": "Серендибит",
      "Shan's Charis Orchid": "Орхидея Shan Charis",
      "Silver": "Серебро",
      "Skimmer Components": "Компоненты оборонного беспилотника",
      "Slaves": "Рабы",
      "Small Survey Data Cache": "Малый пакет с данными исследования",
      "Soontill Relics": "Реликты с Сунтилла",
      "Sothis Crystalline Gold": "Кристаллическое золото системы Sothis",
      "Space Pioneer Relics": "Следы первопроходцев космоса",
      "Structural Regulators": "Конструкционные регуляторы",
      "Superconductors": "Сверхпроводники",
      "Surface Stabilisers": "Стабилизаторы поверхности",
      "Survival Equipment": "Снаряжение для выживания",
      "Synthetic Fabrics": "Синтетическая ткань",
      "Synthetic Meat": "Синтетическое мясо",
      "Synthetic Reagents": "Синтетические реагенты",
      "Taaffeite": "Тааффеит",
      "Tactical Data": "Тактические данные",
      "Tanmark Tranquil Tea": "Успокаивающий чай Tanmark",
      "Tantalum": "Тантал",
      "Tarach Spice": "Таракская пряность",
      "Tauri Chimes": "Колокольчики с Тельца",
      "Tea": "Чай",
      "Technical Blueprints": "Промышленные чертежи",
      "Telemetry Suite": "Телеметрический комплект",
      "Terra Mater Blood Bores": "Кровяные усилители с Терра матер",
      "Thallium": "Таллий",
      "Thargoid Basilisk Tissue Sample": "Образец ткани таргоидского корабля «Василиск»",
      "Thargoid Biological Matter": "Таргоидская биомасса",
      "Thargoid Cyclops Tissue Sample": "Образец ткани таргоидского корабля «Циклоп»",
      "Thargoid Heart": "Таргоидское «сердце»",
      "Thargoid Hydra Tissue Sample": "Образец ткани таргоидского корабля «Гидра»",
      "Thargoid Link": "Таргоидское звено",
      "Thargoid Medusa Tissue Sample": "Образец ткани таргоидского корабля «Медуза»",
      "Thargoid Probe": "Таргоидский зонд",
      "Thargoid Resin": "Таргоидская смола",
      "Thargoid Scout Tissue Sample": "Образец тканей таргоида-разведчика",
      "Thargoid Sensor": "Таргоидский сенсор",
      "Thargoid Technology Samples": "Образцы таргоидских технологий",
      "The Hutton Mug": "Кружка Hutton",
      "The Waters of Shintara": "Воды Шинтары",
      "Thermal Cooling Units": "Термальные охладители",
      "Thorium": "Торий",
      "Thrutis Cream": "Трутисские сливки",
      "Tiegfries Synth Silk": "Тигфрайский синтешелк",
      "Time Capsule": "Капсула  времени",
      "Tiolce Waste2Paste Units": "Устройства Мусор-в-пасту Tiolce",
      "Titanium": "Титан",
      "Tobacco": "Табак",
      "Toxandji Virocide": "Токсанджийские вирициды",
      "Toxic Waste": "Токсичные отходы",
      "Trade Data": "Торговая информация",
      "Trinkets of Hidden Fortune": "Безделушки таинственной Фортуны",
      "Tritium": "Тритий",
      "Ultra-Compact Processor Prototypes": "Экспериментальные ультракомпактные процессоры",
      "Unstable Data Core": "Нестабильное ядро данных",
      "Uraninite": "Уранинит",
      "Uranium": "Уран",
      "Uszaian Tree Grub": "Ушжаанский росток дерева",
      "Utgaroar Millennial Eggs": "Миллениальные яйца с Утгарора",
      "Uzumoku Low-G Wings": "Узумокские крылья для малой гравитации",
      "V Herculis Body Rub": "Скраб с V Геркулеса",
      "Vanayequi Ceratomorpha Fur": "Мех носорогов с Ванайеку",
      "Vega Slimweed": "Водоросли для похудения с Веги",
      "Vidavantian Lace": "Видавантийский шнурок",
      "Void Extract Coffee": "Кофе \"Экстракт пустоты\"",
      "Void Opals": "Опалы бездны",
      "Volkhab Bee Drones": "Дроны Volkhab Bee",
      "Water": "Вода",
      "Water Purifiers": "Водоочистители",
      "Wheemete Wheat Cakes": "Уимитские пшеничные галеты",
      "Wine": "Вино",
      "Witchhaul Kobe Beef": "Мясо кобе с Вичхола",
      "Wolf Fesh": "Волчья дурь",
      "Wreckage Components": "Обломки кораблекрушения",
      "Wulpa Hyperbore Systems": "Гипербольные системы вульпа",
      "Wuthielo Ku Froth": "Пиво Ку с Вутхиело",
      "Xihe Biomorphic Companions": "Биоморфные спутники Xihe",
      "Yaso Kondi Leaf": "Лист ясо Конди",
      "Zeessze Ant Grub Glue": "Зесский муравьиный клей"
    },
    "dates": {
      "forever": "Навсегда"
    },
    "download": {
      "body": "Наименование тела",
      "distance": "Расстояние от точки прибытия",
      "distance_travelled": "Расстояние",
      "fuel_remaining": "Топлива осталось",
      "fuel_used": "Топлива использовано",
      "icy_ring": "Ледяное кольцо",
      "jumps": "Прыжков",
      "mapping_value": "Расчётная стоимость картографирования",
      "neutron": "Нейтронная звезда",
      "no": "Нет",
      "pristine": "Изобилие",
      "refuel": "Заправить",
      "remaining": "Оставшееся расстояние",
      "restock_tritium": "Пополнить Тритий",
      "scan_value": "Примерная награда за сканирование",
      "subtype": "Подтип тела",
      "system": "Наименование системы",
      "terraform": "терраформируемая",
      "tritium_left": "Tritium in tank",
      "tritium_market": "Tritium in market",
      "yes": "Да"
    },
    "dumps": {
      "description": "Дампы ночных обновлений данных о галактике",
      "file_description": "Описание",
      "filename": "Имя файла",
      "files": {
        "galaxy.json.bz2": "Вся галактика в формате bz2 (Более не обновляется)",
        "galaxy.json.gz": "Вся галактика в формате gzip",
        "galaxy_1day.json.gz": "Системы, в которых любая информация в рамках этой системы обновлялась в течение последних 24 часов в формате gzip",
        "galaxy_1month.json.gz": "Системы, в которых любая информация в рамках этой системы обновлялась в течение последних 32 дней в формате gzip",
        "galaxy_7days.json.gz": "Системы, в которых любая информация в рамках этой системы обновлялась в течение последних 7 дней в формате gzip",
        "galaxy_populated.json.bz2": "Системы с населением больше 0 в формате bz2 (Более не обновляется)",
        "galaxy_populated.json.gz": "Системы с населением больше 0 в формате gzip",
        "galaxy_stations.json.bz2": "Системы со станцией в формате bz2 (включая флотоносцы, более не обновляется)",
        "galaxy_stations.json.gz": "Системы со станцией в формате gzip (включая флотоносцы)"
      },
      "modified": "Создано",
      "size": "Размер файла",
      "title": "Дампы данных"
    },
    "earth": {
      "blank": "Leave destination blank in order to create a circular tour starting at your source system and returning there at the end.",
      "guides": "You can find more information about the community goal <a href=\"https://www.elitedangerous.com/community/goals/\">here</a> and <a href=\"https://forums.frontier.co.uk/threads/support-the-saud-kruger-exploration-campaign.590091/\">here</a>.",
      "low_results": "The number of systems returned is less than you asked for.  Try increasing the radius of the search in order to find more bodies.",
      "title": "Earth-like World Route",
      "warning": "This router will allow you to find earth-like worlds around your current location for the community goal.  It will prioritise high scan value so if you only find far away worlds you may wish to reduce your search radius."
    },
    "economy": {
      "Agriculture": "Сельское хозяйство",
      "Colony": "Колония",
      "Extraction": "Добыча",
      "High Tech": "Высокие технологии",
      "Industrial": "Промышленность",
      "Military": "Военная",
      "None": "Нет",
      "Private Enterprise": "Частное предприятие",
      "Refinery": "Переработка",
      "Service": "Сфера услуг",
      "Terraforming": "Терраформирование",
      "Tourism": "Туризм"
    },
    "engineer-plotter": {
      "add_blueprint": "Add blueprint",
      "blueprint": "{type} ({name} grade {grade})",
      "close_ship_build": "Close",
      "delete_blueprint": "Delete blueprint",
      "description": "This page will allow you to plot an efficient route to engineer your ship.",
      "engineer_blueprint": "Engineer {slot} with {name} to grade {grade}",
      "engineer_blueprint_effect": "Engineer {slot} with {name} to grade {grade} and effect {effect}",
      "load_ship_build": "Load ship build",
      "nothing_to_engineer": "Nothing to engineer",
      "process_current_ship_build": "Process current blueprints",
      "process_target_ship_build": "Process target blueprints",
      "system_title": "{engineer} in {system} ({distance}LY)",
      "title": "Engineer Plotter"
    },
    "exact-plotter": {
      "description": "Эта страница позволит вам проложить курс между двумя разными звёздными системами. Она попытается проложить точный маршрут, включая расход топлива, зарядку от нейтронных звёзд и впрыск FSD, если вы того пожелаете.",
      "fuel_remaining_description": "Fuel currently in the fuel tank.  This does not include the reserve tank",
      "fuel_used_description": "Fuel used in the jump",
      "system_error": "Error in system  <a href=\"{link}\">{system}</a>",
      "title": "Галактический навигатор",
      "warning": "Этот маршрутизатор находится в стадии бета-тестирования, и результаты не гарантируются, что они будут идеальными, он также может направлять вас через системы, требующие разрешения."
    },
    "fields": {
      "add_atmosphere_composition_filter": "Добавить фильтр состава атмосферы",
      "add_destination": "Добавить пункт назначения",
      "add_market_filter": "Добавить фильтр рынка",
      "add_material_filter": "Добавить фильтр материалов",
      "add_sort_field": "Добавить поле сортировки",
      "add_via": "а так же через",
      "allegiance": "Принадлежность",
      "allow_planetary": "Разрешить планетарные станции",
      "allow_planetary_description": "Включить в маршрут планетарные станции",
      "allow_prohibited": "Разрешить запрещённые",
      "allow_prohibited_description": "Включить товары, которые запрещены в системе назначения",
      "amount": "Количество",
      "amount_of_commodity": "Количество {commodity}",
      "arg_of_periapsis": "Arg Of Periapsis",
      "atmosphere": "Атмосфера",
      "atmosphere_composition": "Состав атмосферы",
      "atmosphere_composition_fields": "Состав атмосферы",
      "atmosphere_compositions": "Состав атмосферы",
      "axis_tilt": "Axial Tilt",
      "belt_fields": "Фильтры астероидных поясов",
      "belts": "Пояс астероидов",
      "blueprint": "Blueprint",
      "body_count": "Body Count",
      "body_name": "Наименование тела",
      "buy_price": "Цена покупки",
      "calculate": "Расчитать",
      "cargo": "Cargo",
      "combined_ring_signal": "Комбинированные сигналы колец",
      "combined_signal": "Комбинированные планетарные сигналы",
      "commodity": "Товар",
      "commodity_not_found": "Товар не найден",
      "completed": "Завершено",
      "controlling_minor_faction": "Контролирующая фракция",
      "controlling_minor_faction_state": "Состояние контроллирующей фракции",
      "coordinates": "Текущие координаты",
      "cumulative_profit": "Совокупный доход",
      "delete_destination": "Удалить пункт назначения",
      "delete_via": "Удалить промежуточную систему",
      "demand": "Спрос",
      "destination_system": "Система назначения",
      "determine_required_fuel": "Determine Tritium Requirements",
      "determine_required_fuel_description": "Calculate how much Tritium would be required to complete the entire route.",
      "distance": "Дистанция (св.лет)",
      "distance_from_coords": "Дистанция (св.лет)",
      "distance_to_arrival": "Точка прибытия (св.с)",
      "distance_to_arrival_short": "Прибытие (св.с)",
      "earth_masses": "Земных масс",
      "effect": "Effect",
      "efficiency": "Эффективность (%)",
      "efficiency_description": "Чем выше это значение тем меньше навигатор будет отклоняться от прямого маршрута для использования нейтронных звёзд (Эффективность 100 не будет отклоняться от прямого маршрута чтобы проложить путь между А и Б и скорее всего разобьёт путешествие на промежутки по 20000 св.лет)",
      "engineers": "Engineers",
      "engineers_description": "List of engineers to use, select none in order to allow all engineers.",
      "estimated_mapping_value": "Примерная награда за картографирование",
      "estimated_mapping_value_short": "Награда за карту",
      "estimated_scan_value": "Примерная награда за сканирование",
      "estimated_scan_value_short": "Награда за сканирование",
      "exclude_secondary": "Exclude Secondary Stars",
      "exclude_secondary_description": "Prevent the system using secondary neutron and scoopable stars to help with the route",
      "export_commodities": "Экспортируемые товары",
      "faction_influence": "Влияние фракции",
      "faction_name": "Название фракции",
      "faction_presence": "Присутствие фракций",
      "faction_state": "Состояние фракции",
      "find_stations": "Найти станции",
      "flying_time": "время полета {time}",
      "fuel_remaining": "Топлива осталось (тонн)",
      "fuel_remaining_description": "Текущий уровень трития в топливном баке. Возможно, вам надо будет перевести его с рынка до этого уровня.",
      "fuel_used": "Топлива использовано (тонн)",
      "fuel_used_description": "Использовано тритиума на прыжок",
      "full_spectral_class": "Полный спектральный класс",
      "government": "Форма правления",
      "gravity": "Гравитация",
      "has_large_pad": "Есть большая площадка",
      "has_market": "Есть рынок",
      "has_outfitting": "Со снаряжением",
      "has_shipyard": "С верфями",
      "icy_ring": "Ледяное кольцо",
      "icy_ring_description": "В этой системе есть ледяное кольцо?",
      "import_commodities": "Импортируемые товары",
      "influence": "Влияние",
      "is_dssa_carrier": "DSSA Carrier",
      "is_landable": "Пригодные для посадки",
      "is_main_star": "Основная звезда",
      "is_planetary": "Планетарный",
      "is_supercharged": "Уже заряжен",
      "is_supercharged_description": "Ваш корабль уже заряжен от нейтронной звезды?",
      "jumps": "Прыжков",
      "jumps_description": "Примерное количество прыжков до этой системы.",
      "jumps_remaining": "Осталось прыжков",
      "landmark_subtype": "Подтипы планетарных меток",
      "landmark_type": "Типы планетарных меток",
      "landmarks": "Планетарные метки",
      "large_pads": "Large Pads",
      "latitude": "Широта",
      "longitude": "Долгота",
      "loop": "Петля",
      "loop_description": "Заставить маршрут вернуться в стартовую систему.",
      "luminosity_class": "Класс светимости",
      "main_fields": "Основное поле",
      "market": "Потребительский рынок",
      "market_capacity": "Размер рынка",
      "market_capacity_description": "Количество грузоподъёмности, занятое товарами на вашем рынке",
      "market_fields": "Рыночный фильтр",
      "market_updated_at": "Время последнего обновления рынка",
      "material": "Материал",
      "material_fields": "Материалы",
      "materials": "Метериалы",
      "maximum_cargo": "Максимальная вместимость груза",
      "maximum_distance": "Максимальное расстояние до прибытия (св.секунд)",
      "maximum_distance_description": "Максимальная дистанция от звезды прибытия на которой может находиться станция.",
      "maximum_hop_distance": "Максимальная дистанция прыжка.",
      "maximum_hop_distance_description": "Максимальное расстояние между точками (оно может быть больше, чем дальность прыжка вашего корабля)",
      "maximum_hops": "Максимум прыжков",
      "maximum_market_age": "Максимальное время обновления рынка",
      "maximum_market_age_description": "Максимальный срок с момента последнего обновления рынка, который могут иметь станции на маршруте",
      "maximum_systems": "Максимальное количество тел",
      "maximum_systems_description": "Максимальное количество ценных миров, через которые навигатор будет прокладывать ваш маршрут; уменьшите это значение для более короткого маршрута.",
      "medium_pads": "Medium Pads",
      "minimum_scan_value": "Минимальный доход от скана",
      "minor_faction_presences": "Присутствие фракций",
      "module_capacity": "Размер модулей",
      "module_capacity_description": "Количество грузоподъёмности, занятое модулями, установленными на флотоносец",
      "module_category": "Категория",
      "module_fields": "Фильтр модулей",
      "module_price": "Стоимость модулей",
      "modules": "Модули",
      "name": "Наименование",
      "needs_permit": "Требуется разрешение",
      "neutron": "Нейтронная",
      "neutron_description": "Является ли звезда нейтронной",
      "orbital_eccentricity": "Orbital Eccentricity",
      "orbital_inclination": "Orbital Inclination",
      "orbital_period": "Орбитальный период (дней)",
      "outfitting_updated_at": "Последнее обновление снаряжения",
      "pads": "Pads",
      "permit": "Использовать системы требующие разрешения",
      "permit_description": "Разрешить включение систем, требующих разрешения, в анализ",
      "population": "Население",
      "power": "Держава",
      "power_state": "Состояние державы",
      "primary_economy": "Экономика",
      "pristine": "Обилие ресурсов",
      "pristine_description": "Является ли уровень запасов кольца нетронутым?",
      "profit": "Доход",
      "prohibited_commodities": "Запрещённые товары",
      "radius": "Радиус",
      "range": "Расстояние (св.лет)",
      "range_description": "Дальность прыжка корабля",
      "record_type": "Type",
      "reference_coordinates": "Относительные координаты",
      "reference_route": "Референсный маршрут",
      "reference_system": "Относительная система",
      "refuel": "Дозаправка?",
      "refuel_description": "Нужна ли дозаправка?",
      "region": "Регион",
      "remaining": "Оставшееся (св.лет)",
      "requires_large_pad": "Требуется большая площадка",
      "requires_large_pad_description": "Отфильтровать станции, на которых нет большой посадочной площадки.",
      "reserve_level": "Уровень резервов",
      "reset": "Отменить",
      "restock": "Пополнять запасы?",
      "restock_amount": "Restock Amount",
      "restock_amount_description": "The amount of tritium you need to aquire to restock",
      "restock_description": "Нужно ли вам пополнять запасы тритиума?",
      "reverse": "Развернуть маршрут",
      "ring_fields": "Фильтр колец",
      "ring_signal": "Кольцевой сигнал",
      "ring_signals": "Кольцевые сигналы",
      "rings": "Кольца",
      "rotational_period": "Период вращения",
      "scanned": "Scanned",
      "scanned_description": "Uncheck to mark a body as not scanned",
      "search": "Поиск",
      "search_radius": "Радиус (св.лет)",
      "search_radius_description": "Размер в световых годах сферы вокруг начальной системы, в которой навигатор будет искать ценные миры. Значение в 25 св. лет обычно полезно для маршрутов из точки А в точку Б малым количеством прыжков при достаточно хорошем доходе. Для циклических маршрутов (пустая система назначения) можно попробовать увеличить до 100-500 св. лет.",
      "secondary_economy": "Вторичная экономика",
      "security": "Безопасность",
      "select_all": "Все",
      "select_allegiance": "Выбрать принадлежность",
      "select_atmosphere": "Тип атмосферы",
      "select_controlling_minor_faction": "Выбрать контролирующую фракцию",
      "select_controlling_minor_faction_state": "Выберите состояние контролирующей фракции",
      "select_destination_system": "Выбрать пункт назначения",
      "select_export_commodities": "Выбрать экспортируемые товары",
      "select_faction_presence": "Выбрать фракции",
      "select_full_spectral_class": "Выбрать полный спектральный класс",
      "select_government": "Выбрать тип правительства",
      "select_import_commodities": "Выбрать импортируемые товары",
      "select_landmark_subtype": "Выбрать подтипы планетарных сигналов",
      "select_landmark_type": "Выбрать типы планетарных сигналов",
      "select_modules": "Выбрать модули",
      "select_none": "Нет",
      "select_power": "Выбрать державу",
      "select_power_state": "Выбрать состояние державы",
      "select_primary_economy": "Выбрать тип экономики",
      "select_prohibited_commodities": "Выбрать запрещенные товары",
      "select_reference_system": "Выбрать относительную систему",
      "select_region": "Выберите регион",
      "select_reserve_level": "Выбрать уровень резервов",
      "select_ring_signal": "Выбрать кольцевой сигнал",
      "select_secondary_economy": "Выбрать тип вторичной экономики",
      "select_security": "Выбрать уровень безопасности",
      "select_services": "Выберите услуги",
      "select_ships": "Выберите корабли",
      "select_signal": "Выбрать планетарный сигнал",
      "select_source_system": "Выбрать пункт старта",
      "select_spectral_class": "Выбрать спектральный класс",
      "select_state": "Выбрать состояние",
      "select_station_type": "Выберите тип станции",
      "select_subtype": "Выбрать подтип",
      "select_system_power": "Выберите галактическую державу системы",
      "select_terraforming_state": "Выбрать статус терраформирования",
      "select_type": "Выбрать тип",
      "select_volcanism_type": "Тип вулканизма",
      "sell_price": "Цена продажи",
      "semi_major_axis": "Semi Major Axis",
      "services": "Услуги",
      "ship": "Ship",
      "ship_build": "Конфигурация корабля",
      "ship_build_description": "Вставить сборку корабля JSON, экспортированную из <a href=\"https://coriolis.io\"> https://coriolis.io </a> или SLEF из <a href=\"https://edsy.org\"> https: //edsy.org </a>",
      "ship_price": "Стоимость корабля",
      "ships": "Корабли",
      "shipyard_updated_at": "Данные верфи обновлены в",
      "signal": "Сигнал тела",
      "signal_count": "Количество",
      "signal_fields": "Фильтр сигналов",
      "signals": "Сигналы",
      "signals_updated_at": "Signals Last Updated At",
      "single_value": "Возможно только одно значение",
      "small_pads": "Small Pads",
      "solar_masses": "Масса звезды",
      "solar_radius": "Радиус звезды",
      "solid_composition": "Состав",
      "sorting": "Поля сортировки",
      "sorting_fields": "Отсортировать",
      "source_station": "Исходная станция",
      "source_system": "Стартовая система",
      "spectral_class": "Спектральный класс",
      "starting_capital": "Стартовый капитал",
      "state": "Состояние",
      "station_name": "Наименование станции",
      "station_type": "Тип станции",
      "subtype": "Подтип",
      "summary": "Суммарный поиск",
      "supply": "Предложение",
      "surface_pressure": "Давление на поверхности (атмосфер)",
      "surface_temperature": "Температура на поверхности (Кельвин)",
      "system_name": "Название системы",
      "system_power": "Система державы",
      "system_power_state": "Power State",
      "system_region": "Система региона",
      "system_x": "X",
      "system_y": "Y",
      "system_z": "Z",
      "terraforming_description": "Является ли планета кандидатом для терраформирования",
      "terraforming_short": "Терра",
      "terraforming_state": "Статус терраформирования",
      "time": "Расчетное время",
      "time_description": "Время, затрачиваемое на расчет маршрута (в секундах, чем больше времени, тем короче маршруты)",
      "time_remaining": "Оставшееся время",
      "time_taken": "Расчетное время",
      "timestamp": "{from} в {to}",
      "total_profit": "Итоговый доход",
      "tritium_fuel": "Трития в баке",
      "tritium_fuel_description": "Количество трития в вашем топливном баке",
      "tritium_market": "Трития на рынке",
      "tritium_market_description": "Количество трития на вашем рынке",
      "type": "Тип",
      "unique": "Избегать петель",
      "unique_description": "Предотвратить повторное посещение станций, которые уже находятся на маршруте",
      "updated_at": "Последнее обновление",
      "use_injections": "Использовать впрыск в FSD",
      "use_injections_description": "Использовать синтез FSD, чтобы увеличить прыжок, когда нет доступной нейтронной звезды.",
      "use_mapping_value": "Использовать цену за картографирование",
      "use_mapping_value_description": "Сортировать планеты по цене за полное сканирование поверхности вместо базового скана",
      "use_supercharge": "Использовать Supercharge",
      "use_supercharge_description": "Используйте нейтронные звезды, чтобы перезарядить свой FSD",
      "used_capacity": "Используемый размер",
      "used_capacity_description": "Объем емкости, занимаемой установленными модулями, и товарные позиции на рынке вашего корабля-носителя.",
      "via": "через систему",
      "volcanism_type": "Вулканизм",
      "x": "X",
      "x_coordinate": "X координат",
      "y": "Y",
      "y_coordinate": "Y координат",
      "z": "Z",
      "z_coordinate": "Z координат"
    },
    "fields_help": {
      "body_name": "Фильтр имени тела в системе, например Sol выберет Sol, но не Solati 6",
      "destination": "Используйте это, чтобы установить пункт назначения маршрута",
      "reference_coords": "Координаты системы, относительно которых производится поиск",
      "reference_route": "Используйте это, чтобы указать маршрут, относительно которого вы хотите выполнить поиск (для расстояний и т.д.).",
      "reference_system": "Система, относительно которой производится поиск",
      "source": "Используйте это, чтобы установить пункт старта маршрута",
      "station_name": "Используйте этот фильтр, чтобы получить станции с совпадающими названиями",
      "system_name": "Фильтр имени системы, например Col выберет Col 285 Sector IY-W b16-6, но не выберет Colonia"
    },
    "fleet-carrier": {
      "description": "Эта страница позволит вам проложить маршрут для флотоносца между двумя различными системами. Результат покажет каждую систему через которую вам надо проложить маршрут, он так же даст вам знать когда нужно будет собрать больше топлива. Оценка времени основана на 20-ти минутах на прыжок и добыче 150 тонн трития за час на одного человека.",
      "import_destinations": "Import destinations",
      "import_destinations_description": "Import a waypoint file from EDSM as a set of destinations (A simple text file with one system name per line).",
      "importing": "Importing destinations",
      "move_destination_down": "Move destination down",
      "move_destination_up": "Move destination up",
      "refuel_destination": "Refuel at this waypoint",
      "refueling_destination": "{destination} (Refuel)",
      "title": "Планировщик маршрута корабля-носителя",
      "warning": "Этот навигатор (и сами флотоносцы) находятся в бете и результаты не могут быть гарантированы."
    },
    "footer": {
      "copyright": "Авторские права {from}-{to} Gareth Harper.",
      "donations": "Если вы хотите пожертвовать средства на обновление и работу этого сайта, вы можете сделать это на <a href=\"https://www.patreon.com/spansh\">Patreon</a>.",
      "features": "Предложения и сообщения об ошибках принимаются на <a href=\"https://forums.frontier.co.uk/threads/neutron-highway-long-range-route-planner.308674/\">форуме</a> либо по почте: <a href=\"mailto:plotter@spansh.co.uk\">plotter@spansh.co.uk</a>.",
      "thanks": "Автор выражает благодарность <a href=\"https://edsm.net\">Elite: Dangerous Star Map</a> и <a href=\"https://eddb.io\">Elite: Dangerous Database</a>, предоставившим данные для этого сайта."
    },
    "goals": {
      "bounty": "Награда",
      "carto": "Universal Cartographics",
      "combatbond": "Combat Bond",
      "expires": "Окончание {date}",
      "fetch_error": "Не удалось получить цели из Frontier",
      "fetching": "Получение целей из Frontier",
      "mining": "Mining",
      "none": "В настоящее время нет целей сообщества",
      "research": "Исследование",
      "trade": "Перевезти {commodity} на {station} в {system}",
      "tradelist": "Торговля"
    },
    "government": {
      "Anarchy": "Анархия",
      "Communism": "Коммунизм",
      "Confederacy": "Конфедерация",
      "Cooperative": "Кооперация",
      "Corporate": "Корпоративная",
      "Democracy": "Демократия",
      "Dictatorship": "Диктатура",
      "Engineer": "Инженер",
      "Feudal": "Феодальная",
      "None": "Нет",
      "Patronage": "Попечительство",
      "Prison": "Тюремная",
      "Prison Colony": "Тюремная колония",
      "Private Ownership": "Частная собственность",
      "Theocracy": "Теократия",
      "Workshop (Engineer)": "Мастерская (Инженер)"
    },
    "landmark_subtype": {
      "Abandoned Base": "Покинутая база",
      "Albidum Sinuous Tubers": "Albidum Sinuous Tubers",
      "Alpha": "Альфа",
      "Ammonia Ice Fumarole": "Аммиачная ледяная фумарола",
      "Ammonia Ice Geyser": "Аммиачный ледяной гейзер",
      "Amphora Plant": "Амфоры",
      "Aureum Brain Tree": "Мозговое дерево Aureum",
      "Aureus Brain Tree": "Мозговое дерево Aureus",
      "Bark Mound": "Корковые бугры",
      "Bear": "Медведь",
      "Beta": "Бета",
      "Blatteum Bioluminescent Anemone": "Биолюминисцентный анемон Blatteum",
      "Blatteum Sinuous Tubers": "Blatteum Sinuous Tubers",
      "Bowl": "Чаша",
      "Brain Tree": "Мозговое дерево",
      "Carbon Dioxide Fumarole": "Фумаролы с диоксидом углерода",
      "Carbon Dioxide Gas Vent": "Газовая воронка с диоксидом углерода",
      "Carbon Dioxide Ice Fumarole": "Ледяная фумарола с диоксидом углерода",
      "Carbon Dioxide Ice Geyser": "Ледяной гейзер с диоксидом углерода",
      "Carpasinus Brain Tree": "Мозговое дерево Carpasinus",
      "Common Thargoid Barnacle": "Common Thargoid Barnacle",
      "Crashed Ship": "Разбившийся корабль",
      "Crater Outpost": "Поселение в кратере",
      "Crater Port": "Порт в кратере",
      "Croceum Anemone": "Анемон Croceum",
      "Crossroads": "Перекресток",
      "Crystalline Shards": "Осколки кристаллов",
      "Cymatilis Brain Tree": "Мозговое дерево Cymatilis",
      "Delta": "Дельта",
      "Epsilon": "Эпсилон",
      "Fistbump": "Fistbump",
      "Gamma": "Гамма",
      "Gypseeum Brain Tree": "Мозговое дерево Gypseeum",
      "Hammerbot": "Hammerbot",
      "Installation": "Installation",
      "Iron Magma Lava Spout": "Лавовый фонтан с железной магмой",
      "Lacrosse": "Lacrosse",
      "Lindigoticum Brain Tree": "Мозговое дерево Lindigoticum",
      "Lindigoticum Sinuous Tubers": "Lindigoticum Sinuous Tubers",
      "Livens Brain Tree": "Мозговое дерево Livens",
      "Lividum Brain Tree": "Мозговое дерево Lividum",
      "Luteolum Anemone": "Анемон Luteolum",
      "Mega": "Мега",
      "Methane Ice Fumarole": "Метановая ледяная фумарола",
      "Methane Ice Geyser": "Метановый ледяной гейзер",
      "Nitrogen Ice Fumarole": "Азотная ледяная фумарола",
      "Nitrogen Ice Geyser": "Азотный ледяной гейзер",
      "Ostrinum Brain Tree": "Мозговое дерево Ostrinum",
      "Ostrinus Brain Tree": "Мозговое дерево Ostrinus",
      "Prasinum Bioluminescent Anemone": "Биолюминисцентный анемон Prasinum",
      "Prasinum Sinuous Tubers": "Prasinum Sinuous Tubers",
      "Prasinus Bioluminescent Anemone": "Биолюминисцентный анемон Prasinus",
      "Puniceum Anemone": "Анемон Puniceum",
      "Puniceum Brain Tree": "Мозговое дерево Puniceum",
      "Puniceus Brain Tree": "Мозговое дерево Puniceus",
      "Robolobster": "Robolobster",
      "Roseum Anemone": "Анемон Roseum",
      "Roseum Bioluminescent Anemone": "Биолюминисцентный анемон Roseum",
      "Roseum Brain Tree": "Мозговое дерево Roseum",
      "Roseum Sinuous Tubers": "Волнистые клубни Roseum",
      "Roseus Brain Tree": "Мозговое дерево Roseus",
      "Roseus Sinuous Tuber": "Волнистые клубни Roseus",
      "Rubeum Bioluminescent Anemone": "Биолюминисцентный анемон Rubeum",
      "Silicate Magma Lava Spout": "Лавовый фонтан с силикатной магмой",
      "Silicate Vapour Fumarole": "Фумаролы с силикатными парами",
      "Silicate Vapour Gas Vent": "Газовая воронка с силикатными парами",
      "Silicate Vapour Ice Fumarole": "Ледяная фумарола с силикатными парами",
      "Squid": "Осьминог",
      "Stickyhand": "Stickyhand",
      "Sulphur Dioxide Fumarole": "Фумаролы с диоксидом серы",
      "Sulphur Dioxide Gas Vent": "Газовая воронка с диоксидом серы",
      "Sulphur Dioxide Ice Fumarole": "Ледяная фумарола с диоксидом серы",
      "Surface Station": "Планетарная станция",
      "Thargoid Structure": "Таргоидская конструкция",
      "Tourist Beacon": "Туристический маяк",
      "Turtle": "Turtle",
      "Unknown": "Неизвестно",
      "Violaceum Sinuous Tubers": "Violaceum Sinuous Tubers",
      "Viridans Brain Tree": "Мозговое дерево Viridans",
      "Viride Brain Tree": "Мозговое дерево Viride",
      "Viride Sinuous Tubers": "Viride Sinuous Tubers",
      "Water Fumarole": "Водная фумарола",
      "Water Gas Vent": "Водная газовая воронка",
      "Water Geyser": "Водяной гейзер",
      "Water Ice Fumarole": "Водно-ледяная фумарола",
      "Water Ice Geyser": "Водно-ледяной гейзер",
      "Wrecked Ship": "Разбившийся корабль"
    },
    "landmark_type": {
      "Abandoned Base": "Покинутое поселение",
      "Amphora Plant": "Амфоры",
      "Bark Mound": "Корковые бугры",
      "Brain Tree": "Мозговые деревья",
      "Crashed Ship": "Разбившийся корабль",
      "Crystalline Shard": "Осколки кристаллов",
      "Fumarole": "Фумарола",
      "Fungal Gourd": "Грибковая Тыква",
      "Gas Vent": "Газовая воронка",
      "Geyser": "Гейзер",
      "Guardian Ruin": "Руины Стражей",
      "Guardian Structure": "Структура Стражей",
      "Lava Spout": "Лавовый фонтан",
      "Surface Station": "Планетарная станция",
      "Thargoid Barnacle": "Таргоидский «Нарост»",
      "Thargoid Structure": "Таргоидская структура",
      "Tourist Beacon": "Туристический маяк",
      "Tube Worm": "Трубчатый червь",
      "Wrecked Ship": "Разбившийся корабль"
    },
    "map": {
      "description": "Карта всех известных нейтронных звезд",
      "title": "Карта нейтронных звёзд"
    },
    "market": {
      "buy_price": "Цена покупки",
      "buy_price_header": "{commodity} цена покупки",
      "demand": "Спрос",
      "demand_header": "{commodity} спрос",
      "remove_field": "Убрать фильтр",
      "sell_price": "Цена продажи",
      "sell_price_header": "{commodity} цена продажи",
      "supply": "Предложение",
      "supply_header": "{commodity} поставка"
    },
    "material": {
      "Antimony": "Сурьма",
      "Arsenic": "Мышьяк",
      "Cadmium": "Кадмий",
      "Carbon": "Углерод",
      "Chromium": "Хром",
      "Germanium": "Германий",
      "Iron": "Железо",
      "Manganese": "Марганец",
      "Mercury": "Ртуть",
      "Molybdenum": "Молибден",
      "Nickel": "Никель",
      "Niobium": "Ниобий",
      "Phosphorus": "Фосфор",
      "Polonium": "Полоний",
      "Ruthenium": "Рутений",
      "Selenium": "Селен",
      "Sulphur": "Сера",
      "Technetium": "Технеций",
      "Tellurium": "Теллур",
      "Tin": "Олово",
      "Tungsten": "Вольфрам",
      "Vanadium": "Ванадий",
      "Yttrium": "Иттрий",
      "Zinc": "Цинк",
      "Zirconium": "Цирконий"
    },
    "modules": {
      "add_field": "Добавить фильтр",
      "categories": {
        "hardpoint": "Слот вооружения",
        "internal": "Доп.оборудование",
        "standard": "Базовое",
        "utility": "Внешнее оборудование"
      },
      "class": "Класс",
      "name": "Наименование модуля",
      "names": {
        "AX Missile Rack": "Блок ракет АИ",
        "AX Multi-Cannon": "Многоствольное орудие АИ",
        "Abrasion Blaster": "Абразивный бластер",
        "Advanced Discovery Scanner": "Улучшенный сканер обнаружения",
        "Advanced Docking Computer": "Улучшенный стыковочный компьютер",
        "Advanced Missile Rack": "Улучшенный ракетный лоток",
        "Advanced Multi-Cannon": "Продвинутое многоствольное орудие",
        "Advanced Planetary Approach Suite": "Advanced Planetary Approach Suite",
        "Advanced Plasma Accelerator": "Улучшенный ускоритель плазмы",
        "Auto Field-Maintenance Unit": "Блок автоматического полевого ремонта",
        "Basic Discovery Scanner": "Сканер обнаружения",
        "Beam Laser": "Пучковый лазер",
        "Bi-Weave Shield Generator": "Двухпоточный щитогенератор",
        "Burst Laser": "Пульсирующий лазер",
        "Business Class Passenger Cabin": "Пассажирская каюта бизнес класса",
        "Cannon": "Орудие",
        "Cargo Rack": "Грузовой стеллаж",
        "Cargo Scanner": "Сканер груза",
        "Chaff Launcher": "Разбрасыватели дипольных отражателей",
        "Collector Limpet Controller": "Контроллер дронов-сборщиков",
        "Corrosion Resistant Cargo Rack": "Коррозийно-устойчивый грузовой стеллаж",
        "Cytoscrambler Burst Laser": "Пульсирующий лазер «Дезинтегратор»",
        "Decontamination Limpet Controller": "Контроллер дронов-очистителей",
        "Detailed Surface Scanner": "Подробный сканер поверхности",
        "Economy Class Passenger Cabin": "Пассажирская каюта эконом класса",
        "Electronic Countermeasure": "Электронное противодействие",
        "Enforcer Cannon": "Убийца",
        "Enhanced Performance Thrusters": "Усиленные маневровые двигатели",
        "Enzyme Missile Rack": "Блок энзимных ракет",
        "Fighter Hangar": "Ангар для истребителя",
        "First Class Passenger Cabin": "Пассажирская каюта первого класса",
        "Fragment Cannon": "Залповое орудие",
        "Frame Shift Drive": "Рамочно-сместительный двигатель",
        "Frame Shift Drive Interdictor": "Перехватчик FSD",
        "Frame Shift Wake Scanner": "Сканер следа FSD",
        "Fuel Scoop": "Топливозаборник",
        "Fuel Tank": "Топливный бак",
        "Fuel Transfer Limpet Controller": "Контроллер дронов-заправщиков",
        "Guardian FSD Booster": "Ускоритель FSD Стражей",
        "Guardian Gauss Cannon": "Пушка Гаусса Стражей",
        "Guardian Hull Reinforcement": "Набор для усиления корпуса Стражей",
        "Guardian Hybrid Power Distributor": "Гибридный распределитель питания Стражей",
        "Guardian Hybrid Power Plant": "Гибридная силовая установка Стражей",
        "Guardian Module Reinforcement": "Набор для усиления модуля Стражей",
        "Guardian Plasma Charger": "Плазменная пушка Стражей",
        "Guardian Shard Cannon": "Осколочное орудие Стражей",
        "Guardian Shield Reinforcement": "Набор для усиления щита Стражей",
        "Hatch Breaker Limpet Controller": "Дроны-взломщики трюмов",
        "Heat Sink Launcher": "Теплоотводная катапульта",
        "Hull Reinforcement Package": "Набор для усиления корпуса",
        "Imperial Hammer Rail Gun": "Электромагнитная пушка «Имперский молот»",
        "Intermediate Discovery Scanner": "Сканер обнаружения",
        "Kill Warrant Scanner": "Сканер преступников",
        "Life Support": "Система жизнеобеспечения",
        "Lightweight Alloy": "Легкие сплавы",
        "Limpet Control": "Контроллер дрона",
        "Luxury Class Passenger Cabin": "Пассажирская каюта класса люкс",
        "Meta Alloy Hull Reinforcement": "Метасплавное усиление корпуса",
        "Military Grade Composite": "Композит военного класса",
        "Mine Launcher": "Установщик мин",
        "Mining Lance": "Копьё шахтера",
        "Mining Laser": "Проходочный лазер",
        "Mining Multi Limpet Controller": "Mining Multi Limpet Controller",
        "Mirrored Surface Composite": "Композит с зеркальной поверхностью",
        "Missile Rack": "Ракетный лоток",
        "Module Reinforcement Package": "Набор для усиления модуля",
        "Multi-Cannon": "Многоствольное орудие",
        "Operations Multi Limpet Controller": "Operations Multi Limpet Controller",
        "Pacifier Frag-Cannon": "Миротворец",
        "Pack-Hound Missile Rack": "Гончие",
        "Planetary Approach Suite": "Комплект для сближения с планетой",
        "Planetary Vehicle Hangar": "Гараж для планетарного транспорта",
        "Plasma Accelerator": "Ускоритель плазмы",
        "Point Defence": "Точечная оборона",
        "Power Distributor": "Распределитель питания",
        "Power Plant": "Силовая установка",
        "Prismatic Shield Generator": "Призматический щитогенератор",
        "Prospector Limpet Controller": "Контроллер дронов-геологоразведчиков",
        "Pulse Disruptor Laser": "Диверсант",
        "Pulse Laser": "Импульсный лазер",
        "Pulse Wave Analyser": "Анализатор импульсных волн",
        "Rail Gun": "Электромагнитная пушка",
        "Reactive Surface Composite": "Композит с реактивной поверхностью",
        "Recon Limpet Controller": "Контроллер дронов-разведчиков",
        "Refinery": "Устройство переработки",
        "Reinforced Alloy": "Укрепленные сплавы",
        "Remote Release Flak Launcher": "Зенитная установка (снаряды с дистанционным подрывом)",
        "Remote Release Flechette Launcher": "Установка для стрельбы стреловидными снарядами с дистанционным подрывом",
        "Repair Limpet Controller": "Контроллер дронов-ремонтников",
        "Rescue Multi Limpet Controller": "Rescue Multi Limpet Controller",
        "Research Limpet Controller": "Контроллер дронов-исследователей",
        "Retributor Beam Laser": "Пучковый лазер «Каратель»",
        "Rocket Propelled FSD Disruptor": "Ракетный дисраптор FSD",
        "Seeker Missile Rack": "Блок ракет с самонаведением",
        "Seismic Charge Launcher": "Пусковая установка для сейсмических снарядов",
        "Sensors": "Сенсоры",
        "Shield Booster": "Усилитель щита",
        "Shield Cell Bank": "Щитонакопитель",
        "Shield Generator": "Щитогенератор",
        "Shock Cannon": "Шоковое орудие",
        "Shock Mine Launcher": "Миномет: шоковые мины",
        "Shutdown Field Neutraliser": "Нейтрализатор глушащего поля",
        "Standard Docking Computer": "Стандартный стыковочный компьютер",
        "Sub-Surface Displacement Missile": "Вытесняющая ракета для добычи глубинных залежей",
        "Supercruise Assist": "Помощь в гиперкрейсерском режиме",
        "Thrusters": "Маневровые двигатели",
        "Torpedo Pylon": "Торпедная стойка",
        "Universal Multi Limpet Controller": "Universal Multi Limpet Controller",
        "Xeno Multi Limpet Controller": "Xeno Multi Limpet Controller",
        "Xeno Scanner": "Сканер «инопланетянин»"
      },
      "rating": "Рейтинг",
      "remove_field": "Убрать фильтр",
      "select_class": "Выберите класс",
      "select_name": "Выберите наименование модуля",
      "select_rating": "Выберите рейтинг"
    },
    "navigation": {
      "admin": "Admin",
      "ammonia": "Путь аммиачных миров",
      "bodies": "Тела",
      "community-goals": "Цели сообщества",
      "donations": "Поддержать проект",
      "dumps": "Дампы данных",
      "earth": "Earth-like World Route",
      "exact-plotter": "Галактический навигатор",
      "fleet-carrier": "Планировщик маршрута корабля-носителя",
      "helpers": "Помощники",
      "language": "Язык",
      "login": "Вход",
      "logout": "Выход",
      "map": "Карта",
      "nearest": "Ближайшая система",
      "oldsite": "Старый сайт",
      "plotter": "Нейтронный навигатор",
      "riches": "Дорога-к-Богатству",
      "routers": "Планировщик маршрутов",
      "search": "Поиск",
      "settings": "Настройки",
      "stations": "Станции",
      "systems": "Системы",
      "thanks": "Благодарности",
      "theme": "Тема",
      "toggle": "Переключить навигацию",
      "tourist": "Планировщик туристических маршрутов",
      "trade": "Планировщик торговли"
    },
    "nearest": {
      "description": "Эта страница позволит найти ближайшую известную систему к набору координат.",
      "title": "Ближайшая известная система"
    },
    "no": "Нет",
    "plotter": {
      "description": "Эта страница позволит проложить маршрут между двумя системами. Каждая строка результата означает необходимость открыть карту галактики для прокладки маршрута (например, если используется нейтронная звезда). Приоритет отдаётся нейтронным звёздам, поэтому маршруты длиной менее 500 св. лет будут неэффективны (скорее всего инструмент попытается направить к системе Jackson's Lighthouse, если вы в обитаемом пространстве).",
      "exact_plotter": "Если вы ищете более полный навигатор, который будет включать дозаправки, не основные нейтронные звёзды и впрыск FSD, тогда используйте новый <a href=\"{link}\">Галактический навигатор</a>",
      "title": "Нейтронный навигатор",
      "warning": "Если у вас низкая дальность прыжка (менее 20 св.лет) вы можете оказаться в ситуации, когда после прыжка в систему от нейтронной звезды вам не хватит дальности прыжка покинуть систему. Будьте осторожны и заранее проверяйте, что вы сможете покинуть систему назначения."
    },
    "power_state": {
      "Contested": "Под угрозой",
      "Control": "Контролируется",
      "Controlled": "Контролируется",
      "Exploited": "Эксплуатируется",
      "HomeSystem": "Домашняя система",
      "InPrepareRadius": "В радиусе для подготовки",
      "Prepared": "Готовится",
      "Turmoil": "Беспорядки"
    },
    "region": {
      "Acheron": "Acheron",
      "Achilles's Altar": "Achilles's Altar",
      "Aquila's Halo": "Aquila's Halo",
      "Arcadian Stream": "Arcadian Stream",
      "Dryman's Point": "Dryman's Point",
      "Elysian Shore": "Elysian Shore",
      "Empyrean Straits": "Empyrean Straits",
      "Errant Marches": "Errant Marches",
      "Formorian Frontier": "Formorian Frontier",
      "Galactic Centre": "Galactic Centre",
      "Hawking's Gap": "Hawking's Gap",
      "Hieronymus Delta": "Hieronymus Delta",
      "Inner Orion Spur": "Inner Orion Spur",
      "Inner Orion-Perseus Conflux": "Inner Orion-Perseus Conflux",
      "Inner Scutum-Centaurus Arm": "Inner Scutum-Centaurus Arm",
      "Izanami": "Izanami",
      "Kepler's Crest": "Kepler's Crest",
      "Lyra's Song": "Lyra's Song",
      "Mare Somnia": "Mare Somnia",
      "Newton's Vault": "Newton's Vault",
      "Norma Arm": "Norma Arm",
      "Norma Expanse": "Norma Expanse",
      "Odin's Hold": "Odin's Hold",
      "Orion-Cygnus Arm": "Orion-Cygnus Arm",
      "Outer Arm": "Outer Arm",
      "Outer Orion Spur": "Outer Orion Spur",
      "Outer Orion-Perseus Conflux": "Outer Orion-Perseus Conflux",
      "Outer Scutum-Centaurus Arm": "Outer Scutum-Centaurus Arm",
      "Perseus Arm": "Perseus Arm",
      "Ryker's Hope": "Ryker's Hope",
      "Sagittarius-Carina Arm": "Sagittarius-Carina Arm",
      "Sanguineous Rim": "Sanguineous Rim",
      "Temple": "Temple",
      "Tenebrae": "Tenebrae",
      "The Abyss": "The Abyss",
      "The Conduit": "The Conduit",
      "The Formidine Rift": "The Formidine Rift",
      "The Veils": "The Veils",
      "The Void": "The Void",
      "Trojan Belt": "Trojan Belt",
      "Vulcan Gate": "Vulcan Gate",
      "Xibalba": "Xibalba"
    },
    "reserve_level": {
      "Common": "Среднее кол-во ресурсов",
      "Depleted": "Ресурсы исчерпаны",
      "Low": "Мало ресурсов",
      "Major": "Много ресурсов",
      "Pristine": "Обилие ресурсов"
    },
    "results": {
      "atmosphere_composition_to_show": "Отображаемые составы атмосфер",
      "bodies_count": "{count} тела",
      "body_belts": "{name} Пояс астероидов",
      "body_landmarks": "{name} Планетарные метки",
      "body_rings": "{name} кольца",
      "close": "Закрыть",
      "commodities_to_show": "Товаров для отображения",
      "commodity_fields_to_show": "Отобразить поля товаров",
      "copy_to_clipboard": "Скопировать {system} в буфер обмена",
      "create_route": "Создать туристический маршрут из этого поиска",
      "display_search": "Display search on map",
      "download": "Скачать как CSV",
      "download_search": "Download as CSV",
      "estimated_jumps": "Предполагаемое количество прыжков: {jumps}",
      "estimated_time": "Расчетное время: {time}",
      "fields_to_show": "Отображаемые поля",
      "first": "Начало",
      "galaxy_map_visits": "Количество посещений галактической карты: {total_visits}",
      "hide_search": "Скрыть поиск",
      "jumps": "Прыжков: {jumps}",
      "last": "Конец",
      "materials_to_show": "Отображаемые материалы",
      "next": "дальше",
      "no_results": "По данному запросу ничего не было найдено",
      "no_search": "Запустите поиск, чтобы увидеть результаты",
      "none": "Нет",
      "previous": "Назад",
      "results": "Результаты",
      "results_per_page": "Результатов на страницу",
      "route": "Из {source} в {destination}",
      "show_route": "Показать маршрут",
      "show_search": "Показать поиск",
      "showing": "Результаты {start} до {end} из {count}",
      "systems_count": "{count} Системы",
      "totals": "Итого"
    },
    "riches": {
      "blank": "Оставьте систему назначения пустой для того, чтобы создать кольцевой маршрут, начинающийся и заканчивающийся в вашей стартовой системе.",
      "guides": "Инструкции по использованию сканеров можно найти <a href=\"https://forums.frontier.co.uk/showthread.php/464149-The-FSS-A-pictoral-guide\">здесь</a> и <a href=\"https://forums.frontier.co.uk/showthread.php/465649-Setup-and-Use-of-the-FSS-and-DSS-With-Demonstration-Videos\">здесь</a>.",
      "low_results": "Количество систем меньше, чем вы запросили. Попробуйте увеличить радиус поиска, чтобы найти больше тел. Если вы всё ещё не получаете достаточно результатов, то возможно вы выставили слишком высокую минимальную цену сканирования.",
      "title": "Дорога-к-Богатствам",
      "warning": "Решите, хотите ли вы заниматься картографированием планет с использованием зондов. Флажок \"Использовать цену за картографирование\" сортирует планеты по награде за картографирование"
    },
    "ring_signal": {
      "Alexandrite": "Александрит",
      "Bauxite": "Боксит",
      "Benitoite": "Бенитоит",
      "Bertrandite": "Бертрандит",
      "Biological": "Биологический",
      "Bromellite": "Бромеллит",
      "Cobalt": "Кобальт",
      "Coltan": "Колтан",
      "Gallite": "Галлит",
      "Geological": "Геологический",
      "Gold": "Золото",
      "Grandidierite": "Грандидьерит",
      "Guardian": "Стражи",
      "Human": "Люди",
      "Hydrogen Peroxide": "Пероксид водорода",
      "Indite": "Индит",
      "Lepidolite": "Лепидолит",
      "Liquid oxygen": "Жидкий кислород",
      "Lithium Hydroxide": "Гидроксид лития",
      "Low Temperature Diamonds": "Низкотемпературные алмазы",
      "Methane Clathrate": "Клатрат метана",
      "Methanol Monohydrate Crystals": "Кристаллы моногидрата метанола",
      "Monazite": "Монацит",
      "Musgravite": "Мусгравит",
      "Osmium": "Осмий",
      "Other": "Прочие",
      "Painite": "Пейнит",
      "Platinum": "Платина",
      "Praseodymium": "Празеодим",
      "Rhodplumsite": "Родплумсайт",
      "Rutile": "Рутил",
      "Samarium": "Самарий",
      "Serendibite": "Серендибит",
      "Silver": "Серебро",
      "Thargoid": "Таргоиды",
      "Tritium": "Тритий",
      "Uraninite": "Уранинит",
      "Void Opals": "Опалы бездны",
      "Water": "Вода"
    },
    "rings": {
      "add_field": "Добавить фильтр",
      "inner_radius": "Внутренний радиус (м)",
      "mass": "Масса (Mt)",
      "outer_radius": "Внешний радиус (м)",
      "remove_field": "Убрать фильтр",
      "select_type": "Выбрать тип",
      "type": "Тип",
      "types": {
        "Icy": "Ледяная",
        "Metal Rich": "Богатая металлами",
        "Metallic": "Металлическая",
        "Rocky": "Каменистая"
      }
    },
    "scanned-bodies": {
      "title": "Scanned Bodies ({start} to {end} of {total})"
    },
    "search": {
      "copy_name_to_clipboard": "Copy name to clipboard",
      "description": "Top {size} results of {count} searching for \"{query}\"",
      "fail": "Cannot complete search, please try later.",
      "types": {
        "body": "Body",
        "station": "Station",
        "system": "System"
      }
    },
    "security": {
      "Anarchy": "Анархия",
      "High": "Высокая",
      "Lawless": "Беззаконие",
      "Low": "Низкая",
      "Medium": "Средняя"
    },
    "services": {
      "Apex Interstellar": "Apex Interstellar",
      "Autodock": "Автостыковка",
      "Bartender": "Bartender",
      "Black Market": "Чёрный рынок",
      "Contacts": "Контакты",
      "Crew Lounge": "Зал для экипажа",
      "Dock": "Док",
      "Facilitator": "Фасилитатор",
      "Fleet Carrier Administration": "Fleet Carrier Administration",
      "Fleet Carrier Fuel": "Топливо корабля-носителя",
      "Fleet Carrier Management": "Администрация кораблей-носителей",
      "Fleet Carrier Vendor": "Поставщик кораблей-носителей",
      "Flight Controller": "Диспетчер",
      "Frontline Solutions": "Frontline Solutions",
      "Initiatives": "Initiatives",
      "Interstellar Factors Contact": "Контакт Interstellar Factors",
      "Livery": "Livery",
      "Market": "Рынок",
      "Material Trader": "Торговец материалами",
      "Missions": "Миссии",
      "Missions Generated": "Созданные миссии",
      "Module Packs": "Пакеты модулей",
      "On Dock Mission": "Миссия в доке",
      "Outfitting": "Снаряжение",
      "Pioneer Supplies": "Pioneer Supplies",
      "Powerplay": "Державы",
      "Redemption Office": "Бюро выплат",
      "Refuel": "Заправить",
      "Repair": "Ремонт",
      "Restock": "Пополнить запасы",
      "Search and Rescue": "Поиск и спасение",
      "Shipyard": "Верфь",
      "Shop": "Магазин",
      "Social Space": "Social Space",
      "Station Menu": "Меню станции",
      "Station Operations": "Операции на станции",
      "Technology Broker": "Техноброкер",
      "Tuning": "Тюнинг",
      "Universal Cartographics": "Universal Cartographics",
      "Vista Genomics": "Vista Genomics",
      "Workshop": "Мастерская"
    },
    "settings": {
      "confirm_delete": "Подтвердите удаление пользователя",
      "confirm_delete_warning": "Внимание, если вы продолжите, то удалите все данные вашего аккаунта и любые связанные с ним сохраненные данные, включая, помимо прочего, сохраненные сканирования \"пути к богатству\". Вы можете восстановить свой аккаунт, снова связав его с уÑ?",
      "delete_help": "Удалить ваш аккаунт и все связанные с ним данные.",
      "delete_user": "Удалить пользователя",
      "link_patreon": "Подключить аккаунт Patreon",
      "link_patreon_help": "Если вы являетесь нашим покровителем, привяжите свой аккаунт Patreon здесь, чтобы получить дополнительные вознаграждения.",
      "linked_no_patron": "Вы уже подключились, но в настоящее время не являетесь нашим покровителем.",
      "linked_patron": "Вы уже подключились и являетесь нашим покровителем, спасибо."
    },
    "ships": {
      "Adder": "Adder",
      "Alliance Challenger": "Alliance Challenger",
      "Alliance Chieftain": "Alliance Chieftain",
      "Alliance Crusader": "Alliance Crusader",
      "Anaconda": "Anaconda",
      "Asp Explorer": "Asp Explorer",
      "Asp Scout": "Asp Scout",
      "Beluga Liner": "Beluga Liner",
      "Cobra MkIII": "Cobra Mk III",
      "Cobra MkIV": "Cobra Mk IV",
      "Diamondback Explorer": "Diamondback Explorer",
      "Diamondback Scout": "Diamondback Scout",
      "Dolphin": "Dolphin",
      "Eagle": "Eagle",
      "Federal Assault Ship": "Federal Assault Ship",
      "Federal Corvette": "Federal Corvette",
      "Federal Dropship": "Federal Dropship",
      "Federal Gunship": "Federal Gunship",
      "Fer-de-Lance": "Fer-de-Lance",
      "Hauler": "Hauler",
      "Imperial Clipper": "Imperial Clipper",
      "Imperial Courier": "Imperial Courier",
      "Imperial Cutter": "Imperial Cutter",
      "Imperial Eagle": "Imperial Eagle",
      "Keelback": "Keelback",
      "Krait MkII": "Krait Mk II",
      "Krait Phantom": "Krait Phantom",
      "Mamba": "Mamba",
      "Orca": "Orca",
      "Python": "Python",
      "Sidewinder": "Sidewinder",
      "Type-10 Defender": "Type-10 Defender",
      "Type-6 Transporter": "Type-6 Transporter",
      "Type-7 Transporter": "Type-7 Transporter",
      "Type-9 Heavy": "Type-9 Heavy",
      "Viper MkIII": "Viper Mk III",
      "Viper MkIV": "Viper Mk IV",
      "Vulture": "Vulture"
    },
    "signal": {
      "Alexandrite": "Александрит",
      "Bauxite": "Боксит",
      "Benitoite": "Бенитоит",
      "Bertrandite": "Бертрандит",
      "Biological": "Биологические",
      "Bromellite": "Бромеллит",
      "Cobalt": "Кобальт",
      "Coltan": "Колтан",
      "Gallite": "Галлит",
      "Geological": "Геологические",
      "Gold": "Золото",
      "Grandidierite": "Грандидиерит",
      "Guardian": "Стражи",
      "Human": "Человеческие",
      "Hydrogen Peroxide": "Пероксиды водорода",
      "Indite": "Индит",
      "Lepidolite": "Лепидолит",
      "Liquid oxygen": "Жидкий кислород",
      "Lithium Hydroxide": "Гидроксид лития",
      "Low Temperature Diamonds": "Низкотемпературные алмазы",
      "Methane Clathrate": "Клатрат метана",
      "Methanol Monohydrate Crystals": "Кристаллы моногидрата метанола",
      "Monazite": "Монацит",
      "Musgravite": "Мусгравит",
      "Osmium": "Осмий",
      "Other": "Прочие",
      "Painite": "Пейнит",
      "Platinum": "Платина",
      "Praseodymium": "Празеодим",
      "Rhodplumsite": "Родплумсит",
      "Rutile": "Рутил",
      "Samarium": "Самарий",
      "Serendibite": "Серендибит",
      "Silver": "Серебро",
      "Thargoid": "Таргоидские",
      "Tritium": "Тритий",
      "Uraninite": "Ураниит",
      "Void Opals": "Опалы Бездны",
      "Water": "Вода"
    },
    "solid_composition": {
      "Ice": "Лёд",
      "Metal": "Метал",
      "Rock": "Камень"
    },
    "sorting": {
      "asc": "По увеличению",
      "desc": "По уменьшению",
      "field_ascending": "Sort by {field} ascending",
      "field_descending": "Sort by {field} descending"
    },
    "state": {
      "Blight": "Неурожай",
      "Boom": "Бум",
      "Bust": "Спад",
      "Civil Liberty": "Гражданская свобода",
      "Civil Unrest": "Гражданские беспорядки",
      "Civil War": "Гражданская война",
      "Drought": "Засуха",
      "Election": "Выборы",
      "Expansion": "Экспансия",
      "Famine": "Голод",
      "Infrastructure Failure": "Отказ инфраструктуры",
      "Investment": "Инвестирование",
      "Lockdown": "Изоляция",
      "Natural Disaster": "Стихийное бедствие",
      "None": "Нет",
      "Outbreak": "Эпидемия",
      "Pirate Attack": "Атака пиратов",
      "Public Holiday": "Официальный праздник",
      "Retreat": "Отступление",
      "Terrorism": "Терроризм",
      "Terrorist Attack": "Атака террористов",
      "War": "Война"
    },
    "station": {
      "market": "Рынок",
      "market_updated_at": "Рынок обновлен {relative_updated_at} ({updated_at})",
      "no_market_id": "Не указан ID рынка",
      "not_found": "Станция не найдена",
      "outfitting": "Снаряжение",
      "outfitting_updated_at": "Снаряжение обновлено {relative_updated_at} ({updated_at})",
      "shipyard": "Космоверфь",
      "shipyard_updated_at": "Данные верфи обновлены {relative_updated_at} ({updated_at})",
      "station": "Станция"
    },
    "station_type": {
      "Asteroid base": "Астероидная база",
      "Coriolis Starport": "Космопорт Кориолис",
      "Drake-Class Carrier": "Корабль-носитель класса Drake",
      "Mega ship": "Гигантский корабль",
      "Ocellus Starport": "Космопорт Оцеллус",
      "Orbis Starport": "Космопорт Орбис",
      "Outpost": "Аванпост",
      "Planetary Outpost": "Планетарный аванпост",
      "Planetary Port": "Планетарный порт",
      "Settlement": "Settlement"
    },
    "subtype": {
      "A (Blue-White super giant) Star": "Бело-голубой супергигант (A)",
      "A (Blue-White) Star": "Звезда A-класса (бело-голубая)",
      "Ammonia world": "Аммиачный мир",
      "B (Blue-White super giant) Star": "Звезда класса B (бело-голубой сверхгигант)",
      "B (Blue-White) Star": "Звезда B-класса (бело-голубая)",
      "Black Hole": "Чёрная дыра",
      "C Star": "Звезда C-класса (углеродная)",
      "CJ Star": "Звезда CJ-класса",
      "CN Star": "Звезда CN-класса",
      "Class I gas giant": "Газовый гигант класса I",
      "Class II gas giant": "Газовый гигант класса II",
      "Class III gas giant": "Газовый гигант класса III",
      "Class IV gas giant": "Газовый гигант класса IV",
      "Class V gas giant": "Газовый гигант класса V",
      "Earth-like world": "Землеподобная планета",
      "F (White super giant) Star": "Белый супергигант (F)",
      "F (White) Star": "Звезда F-класса (оранжевая)",
      "G (White-Yellow super giant) Star": "Бело-жёлтый супергигант (G)",
      "G (White-Yellow) Star": "Звезда G-класса (бело-жёлтая)",
      "Gas giant with ammonia-based life": "Газовый гигант с жизнью, основанной на аммиаке",
      "Gas giant with water-based life": "Газовый гигант с органической жизнью",
      "Helium gas giant": "Гелиевый гигант",
      "Helium-rich gas giant": "Газовый гигант, богатый гелием",
      "Herbig Ae/Be Star": "Звезда Хербига (Ae/Be)",
      "High metal content world": "Планета с высоким содержание металлов",
      "Icy body": "Ледяная планета",
      "K (Yellow-Orange giant) Star": "Оранжевый гигант",
      "K (Yellow-Orange) Star": "Звезда K-класса (оранжевая)",
      "L (Brown dwarf) Star": "Коричневый карлик (L)",
      "M (Red dwarf) Star": "Красный карлик (M)",
      "M (Red giant) Star": "Красный гигант (M)",
      "M (Red super giant) Star": "Красный супергигант (M)",
      "MS-type Star": "Звезда MS-класса",
      "Metal-rich body": "Планета, богатая металлом",
      "Neutron Star": "Нейтронная звезда",
      "O (Blue-White) Star": "Звезда O-класса (бело-голубая)",
      "Rocky Ice world": "Каменисто-ледяная планета",
      "Rocky body": "Каменистая планета",
      "S-type Star": "Звезда S-класса",
      "Supermassive Black Hole": "Сверхмассивная чёрная дыра",
      "T (Brown dwarf) Star": "Коричневый карлик (T)",
      "T Tauri Star": "Звезда типа Т-Таури",
      "Water giant": "Водный гигант",
      "Water world": "Водная планета",
      "White Dwarf (D) Star": "Белый карлик (D)",
      "White Dwarf (DA) Star": "Белый карлик (DA)",
      "White Dwarf (DAB) Star": "Белый карлик (DAB)",
      "White Dwarf (DAV) Star": "Белый карлик (DAV)",
      "White Dwarf (DAZ) Star": "Белый карлик (DAZ)",
      "White Dwarf (DB) Star": "Белый карлик (DB)",
      "White Dwarf (DBV) Star": "Белый карлик (DBV)",
      "White Dwarf (DBZ) Star": "Белый карлик (DBZ)",
      "White Dwarf (DC) Star": "Белый карлик (DC)",
      "White Dwarf (DCV) Star": "Белый карлик (DCV)",
      "White Dwarf (DQ) Star": "Белый карлик (DQ)",
      "Wolf-Rayet C Star": "Звезда Вольфа-Райе (C)",
      "Wolf-Rayet N Star": "Звезда Вольфа-Райе (N)",
      "Wolf-Rayet NC Star": "Звезда Вольфа-Райе (NC)",
      "Wolf-Rayet O Star": "Звезда Вольфа-Райе (O)",
      "Wolf-Rayet Star": "Звезда Вольфа-Райе (W)",
      "Y (Brown dwarf) Star": "Коричневый субкарлик (Y)"
    },
    "system": {
      "bodies": "Тела ({size})",
      "bodies_with_total": "Bodies ({size}/{total})",
      "factions": "Фракции ({size})",
      "no_id64": "Не указан ID системы",
      "not_found": "Система не найдена",
      "stations": "Станции ({size})",
      "system": "Система",
      "title": "Система",
      "updated_at": "Обновлено {relative_updated_at} ({updated_at})"
    },
    "terraforming_state": {
      "Candidate for terraforming": "Кандидат для терраформирования",
      "Not terraformable": "Невозможно терраформировать",
      "Terraformable": "Кандидат для терраформирования",
      "Terraformed": "Терраформирование завершено",
      "Terraforming": "В процессе терраформирования"
    },
    "thanks": {
      "description": "Большая часть функциональности этого сайта не была бы возможной без оказанной мне помощи. Список ниже неполон и я благодарен всем, кто помог. Если вы считаете, что в этом списке кого-то не хватает, пожалуйста, свяжитесь со мной",
      "developers": {
        "description": "Помогли с разработкой",
        "title": "Разработчики"
      },
      "patreon": {
        "description": "Спонсоры, пожертвовавшие деньги на развитие и поддержание проекта",
        "title": "Спонсоры"
      },
      "players": {
        "description": "Я хотел бы поблагодарить всех игроков и исследователей, которые используют инструменты сбора данных о галактике, что сделало возможным создание этого и других сайтов",
        "title": "Игроки"
      },
      "title": "Благодарности",
      "translators": {
        "description": "Те, кто помог с созданием версий сайта на других языках",
        "title": "Переводчики"
      }
    },
    "titles": {
      "admin": "Admin",
      "ammonia": "Аммиачные миры",
      "ammonia_results": "Путь аммиачных миров",
      "bad_data": "Report Data Problem",
      "body": "Тело {body}",
      "body_search": "Поиск тела",
      "body_search_results": "Результаты поиска тела",
      "community-goals": "Цели сообщества",
      "dumps": "Дампы данных",
      "earth": "Earth-like Worlds",
      "earth_results": "Earth-like Worlds Route",
      "engineer_plotter": "Engineer Plotter",
      "engineer_plotter_results": "Engineer Plotter Route",
      "exact_plotter": "Галактический навигатор",
      "exact_plotter_results": "Результаты галактического навигатора",
      "fleet_carrier": "Корабль-носитель",
      "fleet_carrier_results": "Маршрут корабля-носителя",
      "list_bad_data": "List Bad Data Reports",
      "main": "Elite: Dangerous индекс",
      "nearest": "Ближайшие известные системы:",
      "plotter": "Нейтронный навигатор",
      "plotter_results": "Маршрут нейтронного навигатора",
      "riches": "Путь обогащения",
      "riches_results": "Маршрут пути обогащения",
      "scanned-bodies": "Scanned Bodies",
      "search": "Search",
      "station": "Станция  {station}",
      "station_search": "Поиск станции",
      "station_search_results": "Результат поиска станции",
      "system": "Система {system}",
      "system_search": "Поиск системы",
      "system_search_results": "Результаты поиска системы",
      "thanks": "Спасибо",
      "tourist": "Планировщик туристических маршрутов",
      "tourist_results": "Маршрут туристического планировщика",
      "trade": "Планировщик торговли",
      "trade-to-system": "Торговля в системе",
      "trade-to-system_results": "Результаты торговли в системе",
      "trade_results": "Результаты планировщика торговли",
      "user": "User Settings"
    },
    "tourist": {
      "description": "Эта страница позволит вам спланировать самым оптимальном образом свой маршрут между несколькими системами, которые вы хотите посетить.",
      "import_destinations": "Import destinations",
      "import_destinations_description": "Import a waypoint file from EDSM as a set of destinations (A simple text file with one system name per line).",
      "importing": "Importing destinations",
      "title": "Туристический навигатор"
    },
    "trade": {
      "copy_destination_to_clipboard": "Скопировать имя целевой системы {system} в буфер обмена",
      "copy_source_to_clipboard": "Скопировать имя исходной системы {system} в буфер обмена",
      "distance": "{distance}  св. лет",
      "finishing_system": "Тогда лети в <a href=\"{link}\">{system}</a> ({station} {distance} св.л.",
      "finishing_system_link": "Then fly to <a href=\"{link}\">{system}</a> (<a href=\"{stationLink}\">{station}</a>) {distance} LY",
      "first_system": "Старт из <a href=\"{link}\">{system}</a> ({station} обновлено {updated_at}",
      "first_system_link": "Starting at <a href=\"{link}\">{system}</a> (<a href=\"{stationLink}\">{station}</a>) updated {updated_at}",
      "flying_instructions": "Лететь к {station} (расстояние от точки прибытия {distance} св.с, время полёта {time}).",
      "no_results": "Не удалось найти торговых маршрутов",
      "purchase_instructions": "Продайте все, что у вас есть, и купите товары, перечисленные ниже.",
      "route": "Полёт от {start_system} ({start_station}) до {end_system} ({end_station}) {distance} св.лет",
      "source_required": "Требуется начальная система",
      "starting_system": "<a href=\"{link}\">{system}</a> ({station}) обновлено {updated_at}",
      "starting_system_link": "<a href=\"{link}\">{system}</a> (<a href=\"{stationLink}\">{station}</a>) updated {updated_at}",
      "title": "Планировщик торговли"
    },
    "trade-to-system": {
      "title": "Торговать на {station} в {system}"
    },
    "type": {
      "Planet": "Планета",
      "Star": "Звезда"
    },
    "unknown": "Unknown",
    "user": {
      "settings": "User Settings",
      "update_scanned_bodies": "Update scanned bodies"
    },
    "volcanism_type": {
      "Ammonia Magma": "Аммиачная магма",
      "Carbon Dioxide Geysers": "Углекислотные гейзеры",
      "Major Carbon Dioxide Geysers": "Много, углекислотные гейзеры",
      "Major Metallic Magma": "Много, металлическая магма",
      "Major Rocky Magma": "Много, магма",
      "Major Silicate Vapour Geysers": "Много, гейзеры с сили парами",
      "Major Water Geysers": "Много, водные гейзеры",
      "Major Water Magma": "Много, водная магма",
      "Metallic Magma": "Металлическая магма",
      "Methane Magma": "Метановая магма",
      "Minor Ammonia Magma": "Мало, аммиачная магма",
      "Minor Carbon Dioxide Geysers": "Мало, углекислотные гейзеры",
      "Minor Metallic Magma": "Мало, металлическая магма",
      "Minor Methane Magma": "Мало, метановая магма",
      "Minor Nitrogen Magma": "Мало, азотная магма",
      "Minor Rocky Magma": "Мало, магма",
      "Minor Silicate Vapour Geysers": "Мало, силикатные гейзеры",
      "Minor Water Geysers": "Мало, водяные гейзеры",
      "Minor Water Magma": "Редкая Водная магма",
      "Nitrogen Magma": "Азотная магма",
      "No volcanism": "Без вулканизма",
      "Rocky Magma": "Магма",
      "Silicate Vapour Geysers": "Гейзеры с силикатными парами",
      "Water Geysers": "Водяные гейзеры",
      "Water Magma": "Водяная магма"
    },
    "yes": "Да"
  }]];
  _exports.default = _default;
});